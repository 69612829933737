import React from "react";
import { IdeaData } from "../resources/navbar-data/sidebarData";
import Sidebar from "../common/Sidebar";

const IdeaHolder = ({ children }) => {
  // eslint-disable-next-line react/no-children-prop
  return <Sidebar SidebarData={IdeaData} children={children} />;
};

export default IdeaHolder;
