import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GetInvestorDetailService } from "../../../../services/InvestorService";
import "../../../investor/form/InvestorForm";
import { Button } from "@mui/material";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import GoogleIcon from "@mui/icons-material/Google";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { useHistory } from "react-router-dom";
import { getImageUrl } from "../../../../services/BasicService";
export default function InvestorProfileDetail() {
  const [investorType] = useState("Individual Investor");

  const [investor, setInvestor] = useState({});
  const history = useHistory();
  useEffect(async () => {
    let result = await GetInvestorDetailService();
    if (result?.status === "success" && result.investorData) {
      setInvestor(result.investorData);
    } else {
      history.push("/investor/create-profile");
    }
  }, []);
  return (
    <div>
      <div
        className='form-wrapper dashboard-investor-detail'
        id='investor-form'
      >
        <div className='profile_detail_main'>
          <div className='profile_detail_main_h3'>
            <span>Your Investor Profile</span>
          </div>
          <div className='profile_detail_main_social'>
            <ul>
              <li>
                <span className='p_d_m_s_1'>
                  <MailOutlinedIcon />
                </span>
                <span className='p_d_m_s_2'>Email</span>
              </li>
              <li>
                <span className='p_d_m_s_1'>
                  <GoogleIcon />
                </span>
                <span className='p_d_m_s_2'>Google</span>
              </li>
              <li>
                <span className='p_d_m_s_1'>
                  <LocalPhoneIcon/>
                </span>
                <span className='p_d_m_s_2'>Phone</span>
              </li>
            </ul>
          </div>
          <hr />

          <div className='investor-form'>
            <div>
              <span>Status: </span>
              {+investor?.status === 4 ? (
                <span className='table-accepted-btn'>
                  <CheckCircleOutlineOutlinedIcon size={"16px"} />
                  Active
                </span>
              ) : +investor?.status === 5 ? (
                <span className={"table-rejected-btn"}>
                  <CancelOutlinedIcon size={"16px"} />
                  Rejected
                </span>
              ) : (
                <span className={"table-ongoing-btn"}>
                  <HourglassEmptyOutlinedIcon size={"14px"} />
                  Ongoing Screening
                </span>
              )}
            </div>
            <br />
            <div>
              {+investor?.status === 5 ? (
                <div className={"rejected-msg-block"}>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <CancelOutlinedIcon size={"30px"} />
                    </div>
                    <div className={"rejected-msg"}>
                      <span>Your Idea has been rejected.</span>
                      <span>Reason : {investor.screen_remark}</span>
                    </div>
                  </div>
                </div>
              ) : (
                +investor?.status === 6 && (
                  <>
                    <div className={"review-msg-block"}>
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <WarningAmberOutlinedIcon size={"30px"} />
                        </div>
                        <div className={"rejected-msg"}>
                          <span>
                            Your Idea has been rejected. Please make the
                            required changes and the send the idea for screening
                            again.
                          </span>
                          <span>Reason : {investor.screen_remark}</span>
                        </div>
                      </div>

                      <div>
                        <Link
                          to={
                            "/investor/dashboard/edit-investor/" + investor.id
                          }
                        >
                          <Button color="inherit" variant='contained'>
                            Edit
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </>
                )
              )}
            </div>
            <div className='form-body'>
              <b>
                <div className='table-title'>Personal Details</div>
              </b>
              <table id='db-table'>
                <tbody>
                  <tr>
                    <td className='td_investor_title' width='15%'>
                      Type of Investor
                    </td>
                    <td colSpan={3}>{investorType}</td>
                    {/* <td className='res_table'></td>
                    <td className='res_table'></td> */}
                  </tr>
                  <tr>
                    <td className='td_investor_title'> Full Name</td>
                    <td>{investor.name}</td>
                    <td className='td_investor_title1 res_table'> Title</td>
                    <td className='res_table'>{investor.title}</td>
                  </tr>
                  <tr>
                    <td className='td_investor_title'>Age</td>
                    <td>{investor.age}</td>
                    <td className='td_investor_title1 res_table'>Gender</td>
                    <td className='res_table'>
                      {+investor.gender === 0 ? "Male" : "Female"}
                    </td>
                  </tr>
                  
                  <tr>
                    <td className='td_investor_title'>Citizenship</td>
                    <td>
                      {investor.citizenship && (
                        <img
                          src={getImageUrl(
                            "investor-profile",
                            investor.citizenship
                          )}
                          alt='Citizenship'
                        />
                      )}
                    </td>
                    <td className='td_investor_title1 res_table'>Phone</td>
                    <td className='res_table'>{investor.phone}</td>
                  </tr>
                  <tr>
                    <td className='td_investor_title'>Email</td>
                    <td>{investor.email}</td>
                    <td className='td_investor_title1 res_table'>Address</td>
                    <td className='res_table'>{investor.address}</td>
                  </tr>
                  <tr className='res_show_table'>
                    <td className='td_investor_title1'> Title</td>
                    <td>{investor.title}</td>
                  </tr>
                  <tr className='res_show_table'>
                    <td className='td_investor_title1'>Gender</td>
                    <td>{+investor.gender === 0 ? "Male" : "Female"}</td>
                  </tr>
                  <tr className='res_show_table'>
                    <td className='td_investor_title1'>Phone</td>
                    <td>{investor.phone}</td>
                  </tr>
                  <tr className='res_show_table'>
                    <td className='td_investor_title1'>Address</td>
                    <td>{investor.address}</td>
                  </tr>

                  <tr>
                    <td className='td_investor_title'>Investor detail</td>
                    <td colSpan={3}>{investor.investor_detail}</td>
                  </tr>
                  <tr>
                    <td className='td_investor_title'>Interests</td>
                    <td colSpan={3} className='td_investor_value'>
                      {investor.investor_interest}
                    </td>
                  </tr>
                </tbody>
              </table>

              <br />
              <div className={"table-title"}>
                <b>Permanent Location</b>
              </div>
              <table id={"db-table"} style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td style={{width: "15%"}}>
                      <b>Municipality:</b>
                    </td>
                    <td style={{width: "40%"}}>{investor.municipality?.name}</td>
                    <td style={{width: "10%"}} className='res_table'>
                      <b>State:</b>
                    </td>
                    <td className='res_table'>{investor.area}</td>
                  </tr>
                  <tr className='res_show_table'>
                    <td>
                      <b>Street:</b>
                    </td>
                    <td>{investor.area}</td>
                  </tr>
                </tbody>
              </table>
              <br />
              <div className={"table-title"}>
                <b>Temporary Location</b>
              </div>
              <table id={"db-table"} style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td style={{width: "15%"}}>
                      <b>Municipality:</b>
                    </td>
                    <td style={{width: "40%"}}>{investor.temporary_municipality?.name}</td>
                    <td style={{width: "10%"}} className='res_table'>
                      <b>Street:</b>
                    </td>
                    <td className='res_table'>{investor.temp_area}</td>
                  </tr>
                </tbody>
              </table>
              <b>
                {" "}
                <div className='table-title' style={{ paddingTop: "20px" }}>
                  Investment Details
                </div>
              </b>
              <table id='db-table' style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td className='td_investor_title'>Investment Range</td>
                    <td className='td_investor_value'>
                      Rs.{investor.min_investment_amount} - Rs.
                      {investor.max_investment_amount}
                    </td>
                  </tr>
                  <tr className='res_show_table'>
                    <td className='td_investor_title1'>Interested Sectors</td>
                    <td className='td_investor_value '>{}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
