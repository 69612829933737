import React, { Component } from "react";
import axios from "axios";
import PaginationPage from "../../../../components/pagination/Pagination";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
class AgreementsData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ideas: [],
      loading: true,
      currentPage: 1,
      pageLimit: 10,
      totalPage: 0,
    };
  }

  async pagination(page) {
    await this.getItems(page, this.state.pageLimit);
  }

  async componentDidMount() {
    await this.getItems(this.state.currentPage, this.state.pageLimit);
  }

  async getItems(page, limit) {
    const res = await axios.get(
      process.env.REACT_APP_API_BASE_URL+"/ideas/" + page + "/" + limit
    );
    this.setState({
      ideas: res.data.ideas,
      loading: false,
      currentPage: page,
      pageLimit: limit,
      totalPage: Math.ceil(res.data.totalItems / limit),
    });
  }

  render() {
    var ideas_HTMLTABLE = "";
    if (this.state.loading) {
      ideas_HTMLTABLE = (
        <tr>
          <td colSpan='6'>
            <h2>Loading...</h2>
          </td>
        </tr>
      );
    } else {
      ideas_HTMLTABLE = this.state.ideas.map((item) => {
        return (
          <tr key={item.id}>
            <td>{item.id}</td>
            <td>{item.idea_name}</td>
            <td>20</td>
            <td>90</td>
            <td>pending</td>
            <td> Edit</td>
            <td>Delete</td>
          </tr>
        );
      });
    }
    return (
      <>
        <div className='table-contents'>
          <table id='db-table'>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Profile Type</th>
                <th>Subscribed Date</th>
                <th>Expiry Date</th>
                <th>Remaining Days</th>
                <th colSpan='2' style={{ textAlign: "center" }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>ID</td>
                <td>Screening Name</td>
                <td>Views</td>
                <td>Access</td>
                <td>Status</td>
                <td>Status</td>
                <td style={{ textAlign: "center" }}>
                  <RemoveRedEyeOutlinedIcon />
                </td>
                <td style={{ textAlign: "center" }}>
                  <DeleteForeverOutlinedIcon color='red' />
                </td>
              </tr>
              <tr>
                <td>ID</td>
                <td>Idea Name</td>
                <td>Views</td>
                <td>Access</td>
                <td>Status</td>
                <td>Status</td>
                <td style={{ textAlign: "center" }}>
                  <RemoveRedEyeOutlinedIcon />
                </td>
                <td style={{ textAlign: "center" }}>
                  <DeleteForeverOutlinedIcon color='red' />
                </td>
              </tr>
              {ideas_HTMLTABLE}
            </tbody>
          </table>
        </div>
        <div className='pagination'>
          <PaginationPage />
        </div>
      </>
    );
  }
}

export default AgreementsData;
