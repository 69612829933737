import React from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import CardBlock from "./CardBlock";
import LineChart from "./graph/LineChart";
import "./Home.css";
import TopIdeaList from "../../../idea-holder/feed/TopIdeaList";
function DbHome() {
  return (
    <>
      <div className='container'>
        <div className='top-title'>
          <h2>Dashboard </h2>
        </div>
        <div className='row  mt-2'>
          <div className='col-xs-12 col-md-12 col-md-8 col-lg-8'>
            <div className='row cardBlock-center'>
              <div className='cardBlock-row'>
                <CardBlock />
              </div>
            </div>
            <div className='chart'>
              <LineChart />
            </div>
          </div>
          <div className='col-sm-12 col-lg-4 '>
            <TopIdeaList />
          </div>
        </div>
      </div>
    </>
  );
}

export default DbHome;
