import React from "react";
import "./Layout.css";
import {NavLink} from "react-router-dom";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";

function MobileNav() {
  return (
    <div className='mobile-nav-wrapper'>
      <div className='mobile-nav-links'>
        <NavLink
          exact
          to={"/"}
          className={"mobile-nav-link"}
          activeClassName={"mobile-nav-active"}
        >
          <HomeWorkOutlinedIcon/>
          <span>Home</span>
        </NavLink>
        <NavLink
          to={"/idea"}
          className={"mobile-nav-link"}
          activeClassName={"mobile-nav-active"}
        >
          <LightbulbOutlinedIcon/>
          <span>Ideas</span>
        </NavLink>
        <NavLink
          to={"/investor"}
          className={"mobile-nav-link"}
          activeClassName={"mobile-nav-active"}
        >
          <BusinessCenterOutlinedIcon/>
          <span>Investors</span>
        </NavLink>
      </div>
    </div>
  );
}

export default MobileNav;
