/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import logo2 from "../../../assets/img/logo2.png";
import "./Layout.css";

import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LogOutUserService } from "../../../services/AuthService";
import * as ActionTypes from "../../../redux/ActionTypes";
import PasswordModal from "../../../components/modal/ChangePasswordModal";
import LoginIcon from "@mui/icons-material/Login";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import { MenuItem, Menu, Button } from "@mui/material";

function Navigation() {
  const [openProfile, setOpenProfile] = useState(false);
  const [userName, setUserName] = useState("");
  const toggleProfile = () => setOpenProfile(!openProfile);

  const [toggle, setToggle] = useState(false);
  const toggleMenu = () => setToggle(!toggle);

  const [menu, setMenu] = useState(false);
  const viewMenu = () => setMenu(!menu);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const email = useSelector((state) => state.userAuth.isLoggedIn);
  const user = useSelector((state) => state.userAuth.isLoggedIn);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const toggleDropdown = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const dropdownOpen = Boolean(anchorEl);
  const dropdownOpen1 = Boolean(anchorEl1);
  const handleClick = (event, menuType) => {
    if (menuType == "menu-1") {
      setAnchorEl(event.currentTarget);
    } else if (menuType == "menu-2") {
      setAnchorEl1(event.currentTarget);
    } else {
      setAnchorEl2(event.currentTarget);
    }
  };
  const handleClose = (e, menuType) => {
    if (menuType == "menu-1") {
      setAnchorEl(null);
    } else if (menuType == "menu-2") {
      setAnchorEl1(null);
    } else {
      setAnchorEl2(null);
    }
  };
  async function logout() {
    let result = await LogOutUserService();
    if (result && result.status === "success") {
      localStorage.clear();
      dispatch({ type: ActionTypes.LOGOUT_SUCCESS, payload: result });
      history.push("/");
    } else {
      dispatch({ type: ActionTypes.LOGIN_ERROR, result });
    }
  }

  useEffect(() => {
    const userDetail = localStorage.getItem("state");
    if (user) {
      const toJSon = JSON.parse(userDetail);
      setUserName(toJSon.userAuth.details?.user.name);
      console.log(user.name);
    }
  }, [user]);
  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const [profile, SetProfile] = useState({
    investor: false,
    ideaholder: false,
    investorandideaholder: false,
  });
  const userData = useSelector((state) => state.userAuth);
  const names = useSelector(
    (state) => state.userAuth && state.userAuth?.details?.user?.name
  );

  useEffect(() => {
    const loadData = async () => {
      if (userData) {
        userData?.details?.length > 0 &&
          userData.details.user.roles?.map((key, index) => {
            // roles.push(key.slug);
          });
        let data = {
          ...profile,
          investor: userData?.isinvestor,
          ideaholder: userData?.isideaholder,
          investorandideaholder: userData?.isinvestor && userData?.isideaholder,
        };
        SetProfile(data);
        console.log(profile);
      }
    };
    loadData();
  }, []);

  return (
    <>
      <div className='nav-menu-wrapper'>
        <Link to='/'>
          <div className='logo-container'>
            <img
              src={logo2}
              width='40px'
              height='40px'
              className='logo-img'
              alt=''
            />
            <span className='logo-txt'>Spill Your Ideas</span>
          </div>
        </Link>
        <div className='nav-menu-links'>
          <NavLink
            exact
            to='/'
            className='nav-menu-item'
            activeClassName='active'
          >
            {" "}
            <HomeWorkOutlinedIcon /> Home
          </NavLink>
          <NavLink
            to='/idea'
            className='nav-menu-item'
            activeClassName='active'
          >
            {" "}
            <LightbulbOutlinedIcon /> Idea
          </NavLink>
          <NavLink
            to='/investor'
            className='nav-menu-item'
            activeClassName='active'
          >
            <BusinessCenterOutlinedIcon /> Investor
          </NavLink>
        </div>
        <div className='nav-menu'>
          {user ? (
            <div className='navProfile'>
              <>
                <Button
                  id='basic-button'
                  aria-controls={dropdownOpen ? "basic-menu" : undefined}
                  aria-haspopup='true'
                  aria-expanded={dropdownOpen ? "true" : undefined}
                  onClick={(e) => {
                    handleClick(e, "menu-1");
                  }}
                >
                  {userName.split("")[0]}
                </Button>

                <Menu
                  id='basic-menu'
                  anchorEl={anchorEl}
                  open={dropdownOpen}
                  onClose={(e) => {
                    handleClose(e, "menu-1");
                  }}
                >
                  {profile.investorandideaholder ? (
                    <div>
                      <MenuItem
                        onClick={(e) => {
                          handleClose(e, "menu-1");
                        }}
                      >
                        <Link to='/idea/dashboard' style={{fontSize: "0.95rem"}}>
                          <LightbulbOutlinedIcon />
                          <span className="drop-span">View Idea Dashboard</span>
                        </Link>
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => {
                          handleClose(e, "menu-1");
                        }}
                      >
                        <Link to='/investor/dashboard' style={{fontSize: "0.95rem"}}>
                          <BusinessCenterOutlinedIcon
                            // style={{ marginRight: "10px" }}
                          />
                          <span className="drop-span">View Investor Dashboard</span>
                        </Link>
                      </MenuItem>
                    </div>
                  ) : profile.investor ? (
                    <>
                      <MenuItem
                        onClick={(e) => {
                          handleClose(e, "menu-1");
                        }}
                      >
                        <Link to='/idea/idea-form' style={{fontSize: "0.95rem"}}>
                          <LightbulbOutlinedIcon />
                          <span className="drop-span">Add Idea Profile</span>
                        </Link>
                      </MenuItem>

                      <MenuItem
                        onClick={(e) => {
                          handleClose(e, "menu-1");
                        }}
                      >
                        <Link to='/investor/dashboard' style={{fontSize: "0.95rem"}}>
                          <BusinessCenterOutlinedIcon />
                          <span className="drop-span">View Investor Dashboard</span>
                        </Link>
                      </MenuItem>
                    </>
                  ) : profile.ideaholder ? (
                    <div>
                      <MenuItem
                        onClick={(e) => {
                          handleClose(e, "menu-1");
                        }}
                      >
                        <Link to='/idea/dashboard' style={{fontSize: "0.95rem"}}>
                          <LightbulbOutlinedIcon />
                          <span className="drop-span">View Idea Dashboard</span>
                        </Link>
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => {
                          handleClose(e, "menu-1");
                        }}
                      >
                        <Link to='/investor/create-profile' style={{fontSize: "0.95rem"}}>
                          <BusinessCenterOutlinedIcon />
                          <span className="drop-span">Add Investor Profile</span>
                        </Link>
                      </MenuItem>
                    </div>
                  ) : (
                    <div>
                      <MenuItem
                        onClick={(e) => {
                          handleClose(e, "menu-1");
                        }}
                      >
                        <Link to='/idea/idea-form' style={{fontSize: "0.95rem"}}>
                          <LightbulbOutlinedIcon />
                          <span className="drop-span">Add Idea Profile</span>
                        </Link>
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => {
                          handleClose(e, "menu-1");
                        }}
                      >
                        <Link to='/investor/create-profile' style={{fontSize: "0.95rem"}}>
                          <BusinessCenterOutlinedIcon />
                          <span className="drop-span">Add Investor Profile</span>
                        </Link>
                      </MenuItem>
                    </div>
                  )}
                  <MenuItem
                    onClick={(e) => {
                      toggleModal();
                      handleClose(e, "menu-1");
                    }}
                  >
                    <SettingsOutlinedIcon />
                    <span className="drop-span">Account Profile</span>
                  </MenuItem>

                  <MenuItem
                    onClick={(e) => {
                      logout();
                      handleClose(e, "menu-1");
                    }}
                  >
                    <ExitToAppOutlinedIcon />
                    <span className="drop-span">Logout</span>
                  </MenuItem>
                </Menu>
              </>
            </div>
          ) : (
            <div className='mav-menu-login'>
              {" "}
              <div className="inline-login">
                <span>
                 
                  <button onClick={() => history.push({
                    pathname: "/login",
                    state: {
                      prevRoute: location.pathname
                    }
                  })} className='nav-login'>Login</button>
                  <Link to='/signup'>
                    <button className='nav-signup'>Sign Up</button>
                  </Link>
                </span>
              </div>
              <div className="dropdown-login">
                <span>
                  <button
                    id='basic-button'
                    aria-controls={dropdownOpen ? "basic-menu" : undefined}
                    aria-haspopup={true}
                    aria-expanded={dropdownOpen ? true : undefined}
                    onClick={toggleDropdown}
                    className='nav-login'
                  >
                    <LoginIcon/>
                  </button>
                  <Menu
                    id='basic-menu'
                    anchorEl={anchorEl}
                    open={dropdownOpen}
                    onClose={handleDropdownClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <Link to='/login'>
                      <MenuItem onClick={handleDropdownClose}>
                        {/* <button className='nav-login'>Login</button> */}
                        Login 
                      </MenuItem>
                    </Link>
                    <Link to='/signup'>
                      <MenuItem onClick={handleDropdownClose}>
                        {/* <button className='nav-signup'>Sign Up</button> */}
                        Sign Up
                      </MenuItem>
                    </Link>
                  </Menu>
                </span>
              </div>
            </div>
          )}
          <div className='nav-dropdown'>
            <Button
              id='basic-button'
              aria-controls={dropdownOpen1 ? "basic-menu" : undefined}
              aria-haspopup='true'
              aria-expanded={dropdownOpen1 ? "true" : undefined}
              onClick={(e) => {
                handleClick(e, "menu-2");
              }}
            >
              <ListOutlinedIcon />
            </Button>
            <Menu
              id='basic-menu'
              anchorEl={anchorEl1}
              open={dropdownOpen1}
              onClose={(e) => {
                handleClose(e, "menu-2");
              }}
            >
              <MenuItem
                onClick={(e) => {
                  handleClose(e, "menu-2");
                }}
                to='/'
              >
                Downloads
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  handleClose(e, "menu-2");
                }}
                to='/'
              >
                About us
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  handleClose(e, "menu-2");
                }}
                to='/'
              >
                FAQs
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
      {openModal && <PasswordModal toggleModal={toggleModal} />}
    </>
  );
}

export default Navigation;
