import React from "react";
import "./Modal.css";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useHistory} from "react-router-dom";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

function RejectionModal({
  toggleModal,
  data,
  status,
  title,
  action,
  name,
  toastMsg,
  message,
  handleInput,
  value,
}) {
  const history = useHistory();

  const notify = () =>
    toast.info(toastMsg, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  return (
    <>
      <div className='modalBackground'>
        <div className='modalContainer'>
          <div className='modal-title'>
            <span>{title}</span>
            <CancelOutlinedIcon onClick={toggleModal} cursor='pointer'/>
          </div>

          <div className='body'>
            <div>
              <Grid rowSpacing={2}>
                <Grid xs={12}>
                  <TextField
                    id='outlined-multiline-static'
                    label='Reason for Resubmission'
                    multiline
                    rows={5}
                    sx={{width: "100%"}}
                    variant='outlined'
                    required
                    name={name}
                    value={value}
                    onChange={handleInput}
                  />
                </Grid>
                <Grid xs={12}>
                  <span>{message}</span>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className='footer'>
            <button className={"cancel-btn"} onClick={toggleModal}>
                            Cancel
            </button>
            <button
              onClick={() => {
                toggleModal();
                action(data.id, status);
                notify();
                history.goBack();
              }}
              className={"success-btn"}
            >
                            Confirm
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default RejectionModal;
