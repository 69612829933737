import React from "react";
import { Carousel } from "react-responsive-carousel";
import banner5 from "../../assets/img/banner5.png";
import banner_blue from "../../assets/img/banner_blue.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Carousel.css";

export default class carousel extends React.Component {
  render() {
    return (
      <Carousel
        // autoPlay
        showThumbs={false}
        showStatus={false}
        onSwipeMove
        showIndicators={false}
      >
        <div>
          <img alt='banner1' src={banner5} />
        </div>
        <div>
          <img alt='banner2' src={banner_blue} />
        </div>
      </Carousel>
    );
  }
}
