import React, { useEffect, useState } from "react";

import { Link, useHistory, useParams } from "react-router-dom";
import lamp from "../../assets/img/lamp.png";
import man from "../../assets/img/man2.png";
import "./Login.css";
import { useDispatch } from "react-redux";
import { LoginUserService } from "../../services/AuthService";
import { authData } from "../../redux/actions";
import * as ActionTypes from "../../redux/ActionTypes";
import { VerifyUserService } from "../../services/BasicService";
import ForgotPasswordModal from "../modal/ForgotPasswordModal";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
function VerifyEmailLogin() {
  const params = useParams();
  useEffect(async () => {
    let data = {
      link: params.verifylink,
    };
    let result = await VerifyUserService(data);
    if (result && result.status === "success") {
      console.log("user verified");
    } else {
      console.log("error has occurred");
    }
  }, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  async function login(e) {
    e.preventDefault();
    let item = { email, password };
    let result = await LoginUserService(item);
    if (result && result.status === "success") {
      localStorage.setItem("user-token", result.token);
      dispatch(authData(result));
      console.log(result.user.roles[0]?.slug);
      history.push("/");
    } else {
      dispatch({ type: ActionTypes.LOGIN_ERROR, result });
      setError(result);
    }
  }

  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => setOpenModal(!openModal);

  return (
    <div className='login-page-container'>
      <div className='login-left'>
        <div>
          <img src={lamp} height='45%' width='auto' className='lamp' alt='' />
        </div>
        <div>
          <img src={man} height='auto' width='300px' className='man' alt='' />
        </div>
      </div>

      <div className='login-right'>
        <Link to='/'>
          {/*<div className='logo-container'>*/}
          {/*  <img*/}
          {/*      src={logo}*/}
          {/*      width='40px'*/}
          {/*      height='40px'*/}
          {/*      className='logo-img'*/}
          {/*  />*/}
          {/*  <span className='logo-txt'>Spill Your Ideas</span>*/}
          {/*</div>*/}
        </Link>
        <div className='login-form-wrapper'>
          <div className='login-form'>
            <div className='login-head'>
              <span className='loginSubHead'>Login</span>
            </div>
            <div className='login-body'>
              <form onSubmit={login}>
                <div className='login-item'>
                  <span className='login-form-title'>Email Address</span>
                  <input
                    type='text'
                    placeholder='Email or Phone Number'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className='login-item'>
                  <span className='login-form-title'>Password</span>
                  <input
                    type='password'
                    placeholder='Password'
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>

                {0 == 1 && (
                  <div className='login-item'>
                    <label
                      htmlFor='password'
                      className='rememberMe'
                      style={{ float: "right", color: "red" }}
                    >
                      Username Or Password Invalid
                    </label>
                  </div>
                )}

                <div className='login-password-txt'>
                  <label
                    role='presentation'
                    htmlFor='password'
                    className='forgotPwd'
                    onClick={toggleModal}
                  >
                    Forgot Password?
                  </label>
                </div>
                {error ? (
                  <div className={"error-msg"}>
                    <div className='svg_cross'>
                      <CancelOutlinedIcon size={"40px"} />
                    </div>
                    <div>
                      Email or Password is incorrect. <br />
                    </div>
                  </div>
                ) : (
                  <div className={"success-msg"}>
                    <div>
                      <CheckCircleOutlineOutlinedIcon size={"40px"} />
                    </div>
                    <div>
                      Account Verification Successful. <br />
                      Please Login to Continue.
                    </div>
                  </div>
                )}

                <div className='button'>
                  <button type='submit'>Login</button>
                </div>
              </form>
              {/* <div className='login-item'>
                  <p className='noAcc'>
                    Dont have an account yet?
                    <Link to='/signup'>
                      <span className='registerTxt'>&nbsp; Join Us</span>
                    </Link>
                  </p>
                </div> */}
            </div>
          </div>
        </div>
        {openModal && (
          <div>
            <ForgotPasswordModal toggleModal={toggleModal} />
          </div>
        )}
      </div>
    </div>
  );
}

export default VerifyEmailLogin;
