import React, { Component } from "react";

import { toast } from "react-toastify";
import PaginationPage from "../../../../components/pagination/Pagination";
import { AcceptOrRejectAccess } from "../../../../services/IdeaService";
import Chip from "@mui/material/Chip";
import { CircularProgress } from "@mui/material";
import FileOpenOutlined from "@mui/icons-material/FileOpenOutlined";
import { format } from "date-fns";
import { parse } from "date-fns/esm";

const statusName = ["Pending", "Accepted", "Rejected"];

class AccessTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ideas: this.props.renderedData,
      loading: this.props.loading
    };
    this.changeAccess = this.changeAccess.bind(this);
  }
  async changeAccess(id, action) {
    let data = await AcceptOrRejectAccess({
      id,
    });
    if (data.status === "success") {
      const info = this.props.renderedData.filter((d) => {
        return d.id !== id;
      });

      this.props.filteredData(info);

      toast.info(
        action === 1
          ? "Request has been accepted."
          : "Request has been rejected",
        {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  }

  render() {
    return (
      <>
        <div className='container-fluid'>
          <div
            className='table-wrapper table__full_height table-wrapper_tabs'
            style={{ marginTop: "0", borderRadius: "0 0 10px 10px" }}
          >
            <div className='table-block'>
              <div className='table-contents table-contents_tabs'>
                <table id='db-table'>
                  <thead>
                    <tr>
                      <th style={{ width: "5%" }}>ID</th>
                      <th style={{ width: "35%" }}>Idea Name</th>
                      <th style={{ width: "10%" }}>Date</th>
                      <th style={{ width: "10%" }}>Status</th>
                      {this.props.type === "pending" && (
                        <th
                          colSpan='2'
                          style={{ textAlign: "center", width: "20%" }}
                        >
                          Action
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.loading ? (
                      <tr>
                        <td colSpan='6'>
                          <CircularProgress />
                        </td>
                      </tr>
                    ) : (
                      <>
                        {this.props.renderedData &&
                          this.props.renderedData.length === 0 && (
                          <tr>
                            <td colSpan='6'>
                              <div className='no_data_found'>
                                <FileOpenOutlined size='50px' />
                                <h6>No data Available</h6>
                              </div>
                            </td>
                          </tr>
                        )}

                        {this.props.renderedData &&
                          this.props.renderedData.map((da, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{da.idea_detail.title}</td>
                                {/* <td>{da.user_detail.investor_detail.title}</td> */}
                                <td>
                                  {format(
                                    parse(da.created_at, "YYYY-MM-DD H:m:s", new Date()),
                                    "MMM do, YYYY"
                                  )}
                                </td>
                                <td>{statusName[da.access_status]}</td>
                                {this.props.type === "pending" && (
                                  <>
                                    <td style={{ textAlign: "center" }}>
                                      <Chip
                                        style={{
                                          width: "85px",
                                          color: "#fff",
                                          background: "rgb(72 163 72)",
                                          height: "30px",
                                        }}
                                        label='Accept'
                                        onClick={
                                          () => this.changeAccess(da.id, 1,this.state.currentPage,this.state.pageSize) //backend-lumen ma set garna lekheko
                                        }
                                      />
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      <Chip
                                        style={{
                                          width: "85px",
                                          color: "#fff",
                                          background: "rgb(230 64 64)",
                                          height: "30px",
                                        }}
                                        label='Reject'
                                        onClick={
                                          () => this.changeAccess(da.id, 2,this.state.currentPage,this.state.pageSize) //backend-lumen ma set garna lekheko
                                        }
                                      />
                                    </td>
                                  </>
                                )}
                              </tr>
                            );
                          })}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='pagination'>
              <PaginationPage
                totalPage={this.props.totalPage}
                currentPage={this.props.currentPage}
                changePage={this.props.pagination}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AccessTable;
