import CircleOutlined from "@mui/icons-material/CircleOutlined";
import HourglassEmptyOutlined from "@mui/icons-material/HourglassEmptyOutlined";
import { CircularProgress } from "@mui/material";
import { format, parseISO } from "date-fns/esm";
import React, { Component } from "react";

import PaginationPage from "../../../../components/pagination/Pagination";
import { AccessRequestDisplay } from "../../../../services/AdminServices";

class AccessData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access: [],
      loading: true,
      currentPage: 1,
      pageLimit: 10,
      totalPage: 0,
    };
  }

  async componentDidMount() {
    await this.getItems(this.state.currentPage, this.state.pageLimit);
  }

  async getItems(page, limit) {
    let result = await AccessRequestDisplay(page, limit);
    if (result && result.status === "success") {
      this.setState({
        access: result.data,
        loading: false,
        totalPage: Math.ceil(result.totalItems / this.state.pageLimit),
        currentPage: page,
      });
    } else {
      console.log("error has occurred");
    }
  }

  async pagination(page) {
    await this.getItems(page, this.state.pageLimit);
  }

  render() {
    var ideas_HTMLTABLE = "";
    if (this.state.loading) {
      ideas_HTMLTABLE = (
        <tr>
          <td colSpan='7' style={{ textAlign: "center" }}>
            <CircularProgress />
          </td>
        </tr>
      );
    } else {
      ideas_HTMLTABLE = this.state.access.map((item, index) => {
        return (
          <tr key={item.id}>
            <td>{index + 1}</td>
            <td>{item.idea_detail.title}</td>
            <td>{item.user_detail.name}</td>
            <td>
              {format(
                parseISO(item.created_at, "yyyy-MM-dd H:m:s", new Date()),
                "MMM do, yyyy"
              )}
            </td>
            <td style={{ textAlign: "center" }}>
              {+item.access_status === 2 ? (
                <span className={"table-rejected-btn"}>
                  <CircleOutlined size={"22px"} />
                </span>
              ) : +item.access_status === 1 ? (
                <span className='table-accepted-btn'>
                  <CircleOutlined size={"22px"} />
                </span>
              ) : (
                <span className={"table-ongoing-btn"}>
                  <HourglassEmptyOutlined size={"22px"} />
                </span>
              )}
            </td>
          </tr>
        );
      });
    }
    return (
      <>
        {" "}
        <div className='table-wrapper table-wrapper_tabs'>
          <div className='table-block'>
            <div className='table-contents table-contents_tabs'>
              <table id='db-table'>
                <thead>
                  <tr>
                    <th style={{ width: "5%" }}>ID</th>
                    <th style={{ width: "35%" }}>Idea Name</th>
                    <th style={{ width: "20%" }}>Requested By</th>
                    <th style={{ width: "10%" }}>Date</th>
                    <th style={{ width: "10%" }}>Status</th>
                  </tr>
                </thead>
                <tbody>{ideas_HTMLTABLE}</tbody>
              </table>
            </div>
            <div className='pagination'>
              <PaginationPage
                totalPage={this.state.totalPage}
                currentPage={this.state.currentPage}
                changePage={this.pagination.bind(this)}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AccessData;
