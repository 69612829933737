import React, { useEffect, useState } from "react";
import "./InvestorFeed.css";

import { Link } from "react-router-dom";
import { GetInvestorService } from "../../../services/InvestorService";
import { useSelector } from "react-redux";
import {
  FavouriteInvestorService,
  FollowUserService,
  LikeInvestorService,
} from "../../../services/BasicService";
import { CircularProgress, Tooltip } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import AddIcon from "@mui/icons-material/Add";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import CallIcon from "@mui/icons-material/Call";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
function InvestorFeed() {
  const [loadingData, setLoadingData] = useState(true);
  const [investors, setInvestors] = useState([]);

  const user = useSelector((state) => state.userAuth.isLoggedIn);
  const userData = useSelector((state) => state.userAuth);

  const toggleLike = async (investor_id, user_id, action) => {
    let result = await LikeInvestorService({
      investor_id: investor_id,
      user_id: user_id,
    });
    if (result && result.status === "success") {
      const filteredList = investors.map((d) => {
        if (d.id === investor_id) {
          return {
            ...d,
            likes:
              action === "like"
                ? [...d.likes, { user_id }]
                : d.likes.filter((l) => l.user_id !== user_id),
          };
        }
        return d;
      });
      setInvestors(filteredList);
    } else {
      console.log("error has occurred category");
    }
  };
  const toggleFavourite = async (investor_id, user_id, action) => {
    let result = await FavouriteInvestorService({
      investor_id: investor_id,
      user_id: user_id,
    });
    if (result && result.status === "success") {
      const filteredList = investors.map((d) => {
        if (d.id === investor_id) {
          return {
            ...d,
            favourites:
              action === "like"
                ? [...d.favourites, { user_id }]
                : d?.favourites.filter((l) => l.user_id !== user_id) ?? [],
          };
        }
        return d;
      });
      setInvestors(filteredList);
    } else {
      console.log("error has occurred category");
    }
  };
  const toggleFollow = async (followedUserId) => {
    let result = await FollowUserService(followedUserId);
    if (result?.status === "success") {
      const filteredList = investors.map((d) => {
        if (d.user_id===followedUserId) {
          d.followed = result.followed_user?true:false;
          if(!result.followed_user){
            const findFollowedIndex = d.followed_users.findIndex(followedUser=>+followedUser.user_id===+userData?.details?.user?.id);
            d.followed_users.splice(findFollowedIndex,1);
          }
        }
        return d;
      });
      setInvestors([...filteredList]);
    }
  }
  useEffect(async () => {
    let result = await GetInvestorService();
    if (result && result.status === "success") {
      setInvestors(result.investors);
      setLoadingData(false);
    } else {
      console.log("error has occurred");
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      {loadingData ? (
        <div className={"spinner"}>
          <CircularProgress color='primary' />
        </div>
      ) : (
        <div id='investor-feed'>
          {investors.map((investor, index) => {
            return (
              <div
                key={index}
                style={{ width: "100%" }}
                className='investor-feed-wrapper'
              >
                <div className='investor-container'>
                  <div className='investor-detail-left'>
                    <div className='investor-left-top'>
                      <Tooltip id='button-tooltip' title={investor.title}>
                        <span style={{marginLeft:"10px"}}>Active</span>
                      </Tooltip>

                      <div className='investor-status'></div>

                      <ul className='investor-detail'>
                        <li
                          style={{ textTransform: "capitalize" }}
                          className='investor-detail-item i_d_item'
                        >
                          <b>{investor.title}</b>
                        </li>
                        <li
                          className='investor-detail-item'
                          style={{ fontSize: "12px", marginBottom: "10px" }}
                        >
                          {investor.investor_type === 0
                            ? "Individual Investor"
                            : "Institutional Investor"}
                        </li>
                      </ul>
                      <div className='s_v_flex'>
                        <ul className='social-media-icons'>
                          {/* <Tooltip id='button-tooltip'>
                                <span>Verified</span>
                              </Tooltip> */}

                          <li>
                            <FacebookIcon color='#0e8df1' />
                          </li>

                          {/* <Tooltip id='button-tooltip'>
                                <span>Unverified</span>
                              </Tooltip> */}

                          <li>
                            <MailOutlineIcon />
                          </li>

                          {/* <Tooltip id='button-tooltip'>
                                <span>Unverified</span>
                              </Tooltip> */}

                          <li>
                            <CallIcon />
                          </li>
                        </ul>
                      </div>
                      {/*<div className='investor-type' style={{ fontSize: "12px" }}>*/}
                      {/*  <p>*/}
                      {/*    <b>Type: </b>Venture Capitalist*/}
                      {/*  </p>*/}
                      {/*</div>*/}
                    </div>

                    <div className='investment-left-footer'>
                      <div className='investment-size i_size'>
                        <div className='inv_size1'>Investment Size:</div>
                        <div className='inv_size2'>
                          <b>
                            Rs. {investor.min_investment_amount} - Rs.{" "}
                            {investor.max_investment_amount}{" "}
                          </b>
                        </div>
                      </div>
                    </div>
                    <div className='idea-footer-right'>
                      {investor.followed_users.some(
                        (f) => +f?.user_id === +userData?.details?.user?.id
                      ) || investor?.followed ? (
                          <div
                            role='presentation'
                            onClick={() => {
                              toggleFollow(investor.user_id, index);
                            }}
                            className='follow_investor outer idea'
                          >
                            <span className='f_i_2'>Followed</span>
                          </div>
                        ) : (
                          <div
                            role='presentation'
                            onClick={() => {
                              toggleFollow(investor.user_id, index);
                            }}
                            className='follow_investor outer idea'
                          >
                            <span className='f_i_1'>
                              <AddIcon />
                            </span>
                            <span className='f_i_2'>Follow</span>
                          </div>
                        )}
                    </div>
                  </div>

                  <div className='investor-detail-right'>
                    <div className='investor-right-top'>
                      <div className='investor-description i_desc'>
                        <span>Investor Details:</span>
                        <div className='i_desc_data'>
                          {investor.investor_detail}
                        </div>
                      </div>
                      <div className='investor-interests i_desc'>
                        <span>Interests:</span>
                        <div className='i_desc_data'>
                          {investor.investor_interest}
                        </div>
                      </div>
                    </div>

                    <div className='category-list'>
                      <label htmlFor='in'>Interested Sectors</label>
                      <div className='category-lists'>
                        {investor.sector?.map((item, index) => (
                          <span key={index}>{item.name}</span>
                        ))}
                      </div>
                    </div>

                    <div className='location-list'>
                      <label htmlFor='location'>Location</label>
                      <div className='location-lists'>
                        {investor.districts?.map((item, index) => (
                          <span key={index}>{item.name}</span>
                        ))}
                      </div>
                    </div>

                    <div className='investor-feed-footer'>
                      <div className='btn-item'>
                        {user ? (
                          <Link to={"/investor/detail/" + investor.id}>
                            <button>View Details</button>
                          </Link>
                        ) : (
                          <Link to={"/login"}>
                            <button>View Details</button>
                          </Link>
                        )}
                      </div>

                      <div className='feed-btn'>
                        <div className='like-btn'>
                          {investor.likes?.some(
                            (likers) =>
                              likers.user_id === userData?.details?.user?.id
                          ) ? (
                              <>
                                <ThumbUpIcon
                                  style={{
                                    color: "#09f",
                                    marginRight: "10px",
                                    cursor: "pointer",
                                  }}
                                  size={"1.5rem"}
                                  onClick={() =>
                                    toggleLike(
                                      investor.id,
                                      userData.details?.user.id,
                                      "unlike"
                                    )
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <ThumbUpOutlinedIcon
                                  style={{
                                    marginRight: "10px",
                                    color: "#09f",
                                    cursor: "pointer",
                                  }}
                                  size={"1.5rem"}
                                  onClick={() =>
                                    toggleLike(
                                      investor.id,
                                      userData.details?.user.id,
                                      "like"
                                    )
                                  }
                                />
                              </>
                            )}
                          <span style={{fontSize: "1rem"}}>{investor.likes?.length}</span>
                        </div>
                        <div className='views-counter'>
                          <VisibilityIcon
                            style={{
                              marginRight: "10px",
                              fontSize: "23px"
                            }}
                          />
                          <span style={{fontSize: "1rem"}}>{investor?.total_views}</span>
                        </div>
                        <div className='favourite-btn'>
                          {investor.favourites?.some(
                            (fav) => fav.user_id === userData?.details?.user?.id
                          ) ? (
                              <FavoriteOutlinedIcon
                                style={{
                                  color: "#ff5656",
                                  marginRight: "10px",
                                  cursor: "pointer"
                                }}
                                size={"1.5rem"}
                                onClick={() => {
                                  toggleFavourite(
                                    investor.id,
                                    userData.details?.user.id,
                                    "unlike"
                                  );
                                }}
                              />
                            ) : (
                              <FavoriteBorderOutlinedIcon
                                style={{
                                  color: "#ff5656",
                                  marginRight: "10px",
                                  cursor: "pointer"
                                }}
                                size={"1.5rem"}
                                onClick={() => {
                                  toggleFavourite(
                                    investor.id,
                                    userData.details?.user.id,
                                    "like"
                                  );
                                }}
                              />
                            )}
                          <span style={{fontSize: "1rem"}}>{investor.favourites?.length}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default InvestorFeed;

