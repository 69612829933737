import React, { useEffect, useState } from "react";
import "./InvestorDetail.css";

import { Link, useParams } from "react-router-dom";

import ProposalModal from "../../../components/modal/ProposalModal";
import { GetInvestorDetailService } from "../../../services/BasicService";
import { useSelector } from "react-redux";
import { SendProposalService } from "../../../services/IdeaHolderServices";
import { HasSentProposalService } from "../../../services/InvestorService";
import InvestorTemplate from "../../../components/idea-template/InvestorTemplate";
function InvestorDetailPage() {
  const [proposalModal, setProposalModal] = useState(false);
  const toggleProposal = () => {
    setProposalModal(!proposalModal);
  };

  const [proposal] = useState(true);

  // eslint-disable-next-line no-unused-vars
  const [isProposalSent, setIsProposalSent] = useState({});

  const [loadingData, setLoadingData] = useState(true);
  // const [data, setData] = useState({});
  const location = useParams();
  const userData = useSelector((state) => state.userAuth?.details?.user?.id);

  const [investor, setInvestor] = useState({});

  const GetInvestor = async () => {
    let result = await GetInvestorDetailService({ id: location.id });
    if (result && result.status === "success") {
      setInvestor(result.investor);
      setLoadingData(false);
    } else {
      console.log("error occurred getting single");
    }
  };

  const [currentProposalStatus, setCurrentProposalStatus] = useState(0);

  const HasSentProposal = async () => {
    let res = await HasSentProposalService({
      investor_id: investor?.user_id,
      user_id: userData,
    });
    if (res.status === "success") {
      if (res.proposalSent === 0) {
        setIsProposalSent(false);
      } else {
        setIsProposalSent(true);
        setCurrentProposalStatus(res.currentStatus);
      }
    } else console.log("error has occurred");
  };

  useEffect(() => {
    GetInvestor();
  }, []);

  useEffect(() => {
    HasSentProposal();
  }, [investor?.id]);

  const [proposedIdea, setProposedIdea] = useState({});
  const handleChange = (data) => {
    setProposedIdea(data);
  };

  const sendProposal = async () => {
    setCurrentProposalStatus(0);
    let res = await SendProposalService({
      idea_id: proposedIdea.id,
      investor_id: investor.user_id, //proposedinvestorID
      user_id: userData, //ideaholderID
    });
    if (res.status === "success") {
      setIsProposalSent(true);
    } else console.log("error has occurred");
  };

  const user = useSelector((state) => state.userAuth);

  return (
    <>
      <InvestorTemplate
        loadingData={loadingData}
        investor={investor}
        user_id={userData}
        currentProposalStatus={currentProposalStatus}
      >
        <div className='investor-detail-footer'>
          {user?.isLoggedIn ? (
            user?.isideaholder ? (
              currentProposalStatus === 0 ? (
                <div className={"success-msg"}>
                  {/* <CheckCircleOutlineIcon fontSize={"20px"}/>
                                        &nbsp; */}
                  Proposal has been sent.
                </div>
              ) : currentProposalStatus === 1 ? (
                <button className={"proposal-btn"}>Connect &nbsp;</button>
              ) : currentProposalStatus === 2 ? (
                <div className={"error-msg"} style={{ paddingLeft: "10px" }}>
                  Your Proposal has been Rejected.
                </div>
              ) : (
                <button className={"proposal-btn"} onClick={toggleProposal}>
                  Send Proposal
                </button>
              )
            ) : (
              <button className={"proposal-btn"} onClick={toggleProposal}>
                Send Proposal
              </button>
            )
          ) : (
            <Link to={"/login"}>
              <button>Send Proposal</button>
            </Link>
          )}

          {proposalModal && (
            <ProposalModal
              proposal={proposal}
              toggleModal={toggleProposal}
              sendProposal={sendProposal}
              handleChange={handleChange}
              name='proposed_idea'
            />
          )}
        </div>
      </InvestorTemplate>
    </>
  );
}

export default InvestorDetailPage;
