import React from "react";
import "./Modal.css";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useHistory} from "react-router-dom";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
function ConfirmationModal({
  title,
  toggleModal,
  message,
  action,
  toastMsg,
  data,
  status,
}) {
  const history = useHistory();

  const notify = () =>
    toast.info(toastMsg, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  return (
    <>
      <div className='modalBackground'>
        <div className='modalContainer'>
          <div className='modal-title'>
            <span>{title}</span>
            <CancelOutlinedIcon onClick={toggleModal} cursor='pointer'/>
          </div>

          <div className='body'>{message}</div>
          <div className='footer'>
            <button className={"cancel-btn"} onClick={toggleModal}>
                            Cancel
            </button>
            <button
              onClick={() => {
                toggleModal();
                action(data.id, status);
                notify();
                history.goBack();
              }}
              className={"success-btn"}
            >
                            Confirm
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConfirmationModal;
