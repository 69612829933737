import React from "react";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import GridViewIcon from "@mui/icons-material/GridView";
import ChromeReaderModeIcon from "@mui/icons-material/ChromeReaderMode";
import ArticleIcon from "@mui/icons-material/Article";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import KeyOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import RecordVoiceOverOutlinedIcon from "@mui/icons-material/RecordVoiceOverOutlined";
import StackedLineChartOutlinedIcon from "@mui/icons-material/StackedLineChartOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

export const SidebarData = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <GridViewIcon />,
    cName: "nav-text",
  },
  {
    title: "Status",
    path: "/dashboard/status",
    icon: <ChromeReaderModeIcon />,
    cName: "nav-text",
  },
  {
    title: "Screening Requests",
    path: "/dashboard/screening",
    icon: <ArticleIcon />,
    cName: "nav-text",
  },
  {
    title: "Investors",
    path: "/dashboard/investors",
    icon: <PersonOutlineOutlinedIcon />,
    cName: "nav-text",
  },
  {
    title: "Access Requests",
    path: "/dashboard/access",
    icon: <KeyOutlinedIcon />,
    cName: "nav-text",
  },
  {
    title: "Proposals",
    path: "/dashboard/proposals",
    icon: <BusinessCenterOutlinedIcon />,
    cName: "nav-text",
  },
  {
    title: "Meeting Scheduler",
    path: "/dashboard/meeting-schedule",
    icon: <DateRangeOutlinedIcon />,
    cName: "nav-text",
  },
  {
    title: "User Detail",
    path: "/dashboard/user",
    icon: <PersonOutlineOutlinedIcon />,
    cName: "nav-text",
  },
  {
    title: "Agreements",
    path: "/dashboard/agreements",
    icon: <RecordVoiceOverOutlinedIcon />,
    cName: "nav-text",
  },
  {
    title: "Statistics",
    path: "/dashboard/stats",
    icon: <StackedLineChartOutlinedIcon />,
    cName: "nav-text",
  },
  {
    title: "Subscription",
    path: "/dashboard/subscription",
    icon: <LocalOfferOutlinedIcon />,
    cName: "nav-text",
  },

  {
    title: "Category",
    path: "/dashboard/category",
    icon: <FolderOpenOutlinedIcon />,
    cName: "nav-text",
  },
];

export const InvestorData = [
  {
    title: "Dashboard",
    path: "/investor/dashboard",
    icon: <GridViewIcon />,
    cName: "nav-text",
  },
  {
    title: "Profile",
    path: "/investor/dashboard/detail",
    icon: <FilterAltOutlinedIcon />,
    cName: "nav-text",
  },
  //
  // {
  //   title: "Status",
  //   path: "/investor/dashboard/status",
  //   icon: <VscEye />,
  //   cName: "nav-text",
  // },
  {
    title: "Access Requests",
    path: "/investor/dashboard/access-request",
    icon: <KeyOutlinedIcon />,
    cName: "nav-text",
  },
  {
    title: "proposal",
    path: "/investor/dashboard/proposal",
    icon: <MenuBookOutlinedIcon />,
    cName: "nav-text",
  },
];

export const IdeaData = [
  {
    title: "Dashboard",
    path: "/idea/dashboard",
    icon: <GridViewIcon />,
    cName: "nav-text",
  },
  {
    title: "My Profile",
    path: "/idea/dashboard/idea-profile",
    icon: <AccountCircleOutlinedIcon />,
    cName: "nav-text",
  },

  {
    title: "My Ideas",
    path: "/idea/dashboard/idea-details",
    icon: <TipsAndUpdatesOutlinedIcon />,
    cName: "nav-text",
  },
  {
    title: "Access Requests",
    path: "/idea/dashboard/access-requests",
    icon: <KeyOutlinedIcon />,
  },
  {
    title: "Proposals",
    path: "/idea/dashboard/proposal",
    icon: <MenuBookOutlinedIcon />,
  },
];
