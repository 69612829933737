import React from "react";
// import "bootstrap/dist/css/bootstrap.min.css";

import LineChart from "./graph/LineChart";
import {useSelector} from "react-redux";
import CardBlock from "../../admin/home/CardBlock";
import TopIdeaList from "../../../idea-holder/feed/TopIdeaList";

function InvestorDBHome() {
  const name = useSelector((state) => state.userAuth?.details?.user?.name);
  return (
    <>
      <div className='container'>
        <div className='top-title'>
          <h4>
            {" "}
            <span style={{color: "#09f"}}>
              <b>{name?.split(" ")[0]}</b>
            </span>
                        , Welcome to Investor Dashboard{" "}
          </h4>
        </div>
        <div className='row  mt-2'>
          <div className='col-xs-12 col-md-12 col-md-8 col-lg-8'>
            <div className='row cardBlock-center'>
              <div className='cardBlock-row'>
                <CardBlock/>
                {/*<InvestorProfileDetail />*/}
              </div>
            </div>
            <div className='chart'>
              <LineChart/>
            </div>
          </div>
          <div className="col-sm-12 col-lg-4 ">
            <TopIdeaList/>
          </div>
        </div>
      </div>
    </>
  );
}

export default InvestorDBHome;
