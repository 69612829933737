import HttpService from "./HttpService";
import APIEndPoints from "../constants/APIEndPoints";
const http = new HttpService();
export const AddIdeaProfileService = async (credentials) => {
  return await http.postData(credentials, APIEndPoints.createIdeaProfile);
};

export const CreateIdeaService = async (data) => {
  return await http.postData(data, "ideaholder/" + APIEndPoints.createScreeningIdea);
};
export const EditIdeaService = async (credentials) => {
  return await http.postData(credentials, "ideaholder/" + APIEndPoints.editScreeningIdea);
};

export const GetIdeaHolderDetailService = async () => {
  return await http.getData(APIEndPoints.getIdeaHolderDetail);
};

export const GetUsersIdea = async (page,pageSize) => {
  return await http.getData(`${APIEndPoints.getUserIdeas}/${page}/${pageSize}`);
};

export const GetIdeaService = async (credentials) => {
  return await http.postData(credentials, APIEndPoints.getUserIdea);
};

export const SendProposalService = async (credentials) => {
  return await http.postData(credentials, APIEndPoints.sendProposal);
};

export const GetProposalStatusService = async (page,pageSize) => {
  return await http.fetchData(`ideaholder/${APIEndPoints.getProposalStatus}/${page}/${pageSize}`);
};

export const UploadFile = async (file, folder) => {
  return await http.uploadFile(file, folder, APIEndPoints.uploadTempFile);
}
export const RemoveFile = async (folder,file) => {
  return await http.postData({folder,file}, APIEndPoints.removeFile);
}