import React, { useEffect, useState } from "react";
import "../../../idea-holder/form/IdeaForm.css";
import {
  CreateIdeaService,
  RemoveFile,
  UploadFile,
} from "../../../../services/IdeaHolderServices";
import CreatedIdeaModal from "../../../../components/modal/CreatedIdeaModal";
import {
  GetDistrictService,
  getImageUrl,
  GetSectorsService,
} from "../../../../services/BasicService";
import { TextField } from "@material-ui/core";
import { Autocomplete, Button } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import { UploaderComponent } from "@syncfusion/ej2-react-inputs";

export default function CreateIdeaForm() {
  // eslint-disable-next-line no-unused-vars
  const [loadingData, setLoadingData] = useState(true);

  const [category, setCategory] = useState([]);
  const [district, setDistrict] = useState([]);

  const getSectors = async () => {
    let result = await GetSectorsService();
    if (result && result.status === "success") {
      setCategory(result.sectors);
      setLoadingData(false);
    } else {
      console.log("error has occurred category");
    }
  };

  const getDistrict = async () => {
    let result = await GetDistrictService();
    if (result && result.status === "success") {
      setDistrict(result.districts);
      setLoadingData(false);
    } else {
      console.log("error has occurred Districts");
    }
  };
  const onFileSelected = async (event, id) => {
    if (!event.filesData[0]) {
      return;
    }
    let result = await UploadFile(event.filesData[0]?.rawFile, "idea");
    if (result && result.status === "success") {
      setIdea({ ...idea, [id]: result.file });
    } else {
      console.log("error occurred while sending data for screening");
    }
  };
  const onRemoveFile = async (file, id) => {
    let result = await RemoveFile("idea",file);
    if (result && result.status === "success") {
      setIdea({ ...idea, [id]: "" });
    } else {
      console.log("error occurred while sending data for screening");
    }
  };
  useEffect(() => {
    getSectors();
    getDistrict();
  }, []);

  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => {
    setOpenModal(!openModal);
  };
  const [sentBtn, setSentBtn] = useState(false);
  const toggleSentBtn = () => {
    setSentBtn(false);
  };

  const [idea, setIdea] = useState({
    title: "",
    description: "",
    expected_invested: "",
    own_investment: "",
    location: "",
    investment_type: "",
    errors: [],
    errorCount: 0,
    categories: [],
    pref_locations: [],
    tags: [],
    cover_image: "",
    feasibility_study_doc_link: "",
    debt_injection_plan_doc_link: "",
    swot_analysis_doc_link: "",
    financial_projection_doc_link: "",
    others_doc_link: "",
    financial_analysis_doc_link: "",
  });

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setIdea({ ...idea, [name]: value });
  };

  const handleSubmit = async () => {
    idea.errorCount = 0;
    setIdea({ ...idea, errors: [] });
    let data = {};
    if (idea.title === "") {
      idea.errorCount += 1;
      data = {
        ...data,
        title: "Idea name is required",
      };
      setIdea({ ...idea, errors: data });
    }
    if (idea.description === "") {
      idea.errorCount += 1;
      data = {
        ...data,
        description: "Description is required",
      };
      setIdea({ ...idea, errors: data });
    }
    if (idea.own_investment === "") {
      idea.errorCount += 1;
      data = {
        ...data,
        own_investment: "Your Investment is required",
      };
      setIdea({ ...idea, errors: data });
    }

    if (idea.expected_invested === "") {
      idea.errorCount += 1;
      data = {
        ...data,
        expected_invested: "Expected investment is required",
      };
      setIdea({ ...idea, errors: data });
    }
    if (idea.location === "") {
      idea.errorCount += 1;
      data = {
        ...data,
        location: "Location is required",
      };
      setIdea({ ...idea, errors: data });
    }
    if (idea.investment_type === "") {
      // idea.errorCount += 1;
      data = {
        ...data,
        investment_type: "Investment type is required",
      };
      setIdea({ ...idea, errors: data });
    }
    if (idea.errorCount === 0) {
      let result = await CreateIdeaService(idea);
      if (result && result.status === "success") {
        console.log("Screening idea Sent");
      } else {
        console.log("error occurred while sending data for screening");
      }
    }
  };

  return (
    <>
      <div className='container-fluid' id='idea___form'>
        <div className='form-wrapper form_wrap_mobile '>
          <div className='form-header'>
            <div className='header-title'>
              <p style={{ fontSize: "22px", marginBottom: "0", color: "#09f" }}>
                Create new Idea{" "}
              </p>
              <span>Fill in the details below to create an idea</span>
            </div>
          </div>
          <div className='idea-form'>
            <div className='form-body'>
              <span style={{ fontSize: "18px" }}>
                <b>Idea Details</b>
              </span>
              <div className='details custom-mui-input'>
                <div className='row '>
                  <div className='col-sm-12 col-md-6 '>
                    <TextField
                      style={{ width: "100%" }}
                      name='title'
                      label='Idea Name'
                      value={idea.title}
                      onChange={handleInput}
                      variant='outlined'
                      required
                    />
                  </div>
                  <div className='col-sm-12 col-md-6 '>
                    <Autocomplete
                      disablePortal
                      multiple={true}
                      name={"categories"}
                      options={category}
                      onChange={(e, value) => {
                        setIdea({
                          ...idea,
                          categories: value,
                        });
                      }}
                      getOptionLabel={(option) => option.name}
                      sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          variant='outlined'
                          {...params}
                          label='Category'
                        />
                      )}
                    />
                  </div>
                </div>

                <div className='row '>
                  <div className='col-sm-12 '>
                    <TextField
                      id='outlined-multiline-static'
                      label='Your idea in Brief'
                      multiline
                      rows={6}
                      sx={{ width: "100%" }}
                      variant='outlined'
                      required
                      name={"description"}
                      value={idea.description}
                      onChange={handleInput}
                    />
                  </div>
                </div>
                <div className='row '>
                  <div className='col-sm-12 col-md-6 '>
                    <ReactTagInput
                      tags={idea.tags}
                      placeholder='Tags'
                      onChange={(newTags) =>
                        setIdea({
                          ...idea,
                          tags: newTags,
                        })
                      }
                    />
                  </div>
                </div>
                <div className='row '>
                  <div className='idea-form-parameter col-sm-12'>
                    <h5 style={{ marginTop: "20px", marginBottom: "0" }}>
                      Cover Image
                    </h5>
                    <div>
                      {idea.cover_image && (
                        <img src={getImageUrl("idea",idea.cover_image)} alt='' />
                      )}
                    </div>

                    <UploaderComponent
                      id='coverimage'
                      type='file'
                      selected={(event) => onFileSelected(event, "cover_image")}
                      autoUpload={true}
                      multiple={false}
                      removing={() =>
                        onRemoveFile(idea.cover_image, "cover_image")
                      }
                    />
                  </div>
                </div>
                <span style={{ fontSize: "18px" }}>
                  <b>Investment Requirements</b>
                </span>

                <div className='row '>
                  <div className='col-sm-12 col-md-6 '>
                    <TextField
                      style={{ width: "100%" }}
                      name='own_investment'
                      label='Your Investment'
                      value={idea.own_investment}
                      onChange={handleInput}
                      variant='outlined'
                      type='number'
                      required
                    />
                  </div>
                  <div className='col-sm-12 col-md-6 '>
                    <TextField
                      style={{ width: "100%" }}
                      name='expected_invested'
                      label='Expected Investment'
                      value={idea.expected_invested}
                      onChange={handleInput}
                      variant='outlined'
                      type='number'
                      required
                    />
                  </div>
                </div>

                <div className='row '>
                  <div className='col-sm-12 col-md-6 '>
                    <TextField
                      style={{ width: "100%" }}
                      name='location'
                      label='Your Location'
                      value={idea.location}
                      onChange={handleInput}
                      variant='outlined'
                      required
                    />
                  </div>
                  <div className='col-sm-12 col-md-6 '>
                    <Autocomplete
                      disablePortal
                      multiple={true}
                      name='pref_locations'
                      options={district}
                      onChange={(e, value) => {
                        setIdea({
                          ...idea,
                          pref_locations: value,
                        });
                      }}
                      getOptionLabel={(option) => option.name}
                      sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          variant='outlined'
                          {...params}
                          label='Preferred Location'
                        />
                      )}
                    />
                  </div>
                </div>
                <div className='row '>
                  <div className='col-sm-12 col-md-6 '>
                    <FormControl fullWidth>
                      <InputLabel id='investment_type'>
                        Investment Type
                      </InputLabel>
                      <Select
                        style={{ width: "100%" }}
                        labelId='investment_type'
                        name={"investment_type"}
                        value={idea.investment_type}
                        label='Investment Type'
                        onChange={handleInput}
                        variant='outlined'
                      >
                        <MenuItem value={0}>Equity Shares</MenuItem>
                        <MenuItem value={1}>Preference Shares</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  {/*<div className='col-sm-12 col-md-6 '>*/}
                  {/*  <TextField*/}
                  {/*    style={{ width: "100%" }}*/}
                  {/*    name='tags'*/}
                  {/*    label='Idea tags'*/}
                  {/*    value={idea.tags}*/}
                  {/*    onChange={handleInput}*/}
                  {/*    variant='outlined'*/}
                  {/*    value={idea.tags}*/}
                  {/*  />*/}
                  {/*</div>*/}
                </div>
                <hr />

                <div className='row'>
                  <div className='idea-form-parameter col-md-6 col-sm-12'>
                    <label htmlFor='DIP'>Debt Injection Plan</label>
                    <UploaderComponent
                      id='debtInjectionPlan'
                      type='file'
                      selected={(event) =>
                        onFileSelected(event, "debt_injection_plan_doc_link")
                      }
                      autoUpload={true}
                      multiple={false}
                      removing={() =>
                        onRemoveFile(
                          idea.debt_injection_plan_doc_link,
                          "debt_injection_plan_doc_link"
                        )
                      }
                    />
                  </div>
                  <div className='idea-form-parameter col-md-6 col-sm-12'>
                    <label htmlFor='feasibility'>Feasibility Study</label>
                    <UploaderComponent
                      id='debtInjectionPlan'
                      type='file'
                      selected={(event) =>
                        onFileSelected(event, "feasibility_study_doc_link")
                      }
                      autoUpload={true}
                      multiple={false}
                      removing={() =>
                        onRemoveFile(
                          idea.feasibility_study_doc_link,
                          "feasibility_study_doc_link"
                        )
                      }
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='idea-form-parameter col-md-6 col-sm-12'>
                    <label htmlFor='SWOT'>SWOT Analysis</label>
                    <UploaderComponent
                      id='debtInjectionPlan'
                      type='file'
                      selected={(event) =>
                        onFileSelected(event, "swot_analysis_doc_link")
                      }
                      autoUpload={true}
                      multiple={false}
                      removing={() =>
                        onRemoveFile(
                          idea.swot_analysis_doc_link,
                          "swot_analysis_doc_link"
                        )
                      }
                    />
                  </div>
                  <div className='idea-form-parameter col-md-6 col-sm-12'>
                    <label htmlFor='financial_projection'>
                      Financial Projection
                    </label>
                    <UploaderComponent
                      id='debtInjectionPlan'
                      type='file'
                      selected={(event) =>
                        onFileSelected(event, "financial_projection_doc_link")
                      }
                      autoUpload={true}
                      multiple={false}
                      removing={() =>
                        onRemoveFile(
                          idea.financial_projection_doc_link,
                          "financial_projection_doc_link"
                        )
                      }
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='idea-form-parameter col-md-6 col-sm-12'>
                    <label htmlFor='financial_analysis'>
                      Financial Analysis
                    </label>
                    <UploaderComponent
                      id='debtInjectionPlan'
                      type='file'
                      selected={(event) =>
                        onFileSelected(event, "financial_analysis_doc_link")
                      }
                      autoUpload={true}
                      multiple={false}
                      removing={() =>
                        onRemoveFile(
                          idea.financial_analysis_doc_link,
                          "financial_analysis_doc_link"
                        )
                      }
                    />
                  </div>
                </div>
              </div>
              <div className='form-btn'>
                {!sentBtn ? (
                  <Button variant='contained'
                    onClick={() => {
                      toggleModal();
                    }}
                  >
                    {" "}
                    Send for Screening
                  </Button>
                ) : (
                  <div
                    role='presentation'
                    className={"detail-action-response"}
                    onClick={toggleModal}
                  >
                    Idea has been sent
                  </div>
                )}
              </div>
            </div>
            {openModal && (
              <CreatedIdeaModal
                toggleModal={toggleModal}
                handleSubmit={handleSubmit}
                toggleSentBtn={toggleSentBtn}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
