import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import CreatedIdeaModal from "../../../../components/modal/CreatedIdeaModal";
import {
  EditIdeaService,
  GetIdeaService,
  RemoveFile,
  UploadFile,
} from "../../../../services/IdeaHolderServices";
import { useParams } from "react-router-dom";
import {
  GetDistrictService,
  GetSectorsService,
} from "../../../../services/BasicService";
import { getImageUrl } from "../../../../services/BasicService";
import { UploaderComponent } from "@syncfusion/ej2-react-inputs";
import WarningAmberOutlined from "@mui/icons-material/WarningAmberOutlined";
// import "./Modal.css";

function IdeaEditInterface() {
  const [idea, setIdea] = useState([]);
  const [category, setCategory] = useState([]);
  const [district, setDistrict] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [loadingData, setLoadingData] = useState(true);

  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const [sentBtn, setSentBtn] = useState(false);
  const toggleSentBtn = () => {
    setSentBtn(!sentBtn);
  };

  const investment_type = ["Equity Shares", "Preference Shares"];

  const location = useParams();

  const getSectors = async () => {
    let result = await GetSectorsService();
    if (result && result.status === "success") {
      setCategory(result.sectors);
      // setLoadingData(false);
    } else {
      console.log("error has occurred category");
    }
  };

  const getDistrict = async () => {
    let result = await GetDistrictService();
    if (result && result.status === "success") {
      setDistrict(result.districtss);
      setLoadingData(false);
    } else {
      console.log("error has occurred Districts");
    }
  };

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setIdea({ ...idea, [name]: value });
    console.log(name, value);
  };

  const handleSubmit = async () => {
    idea.errorCount = 0;
    if (idea.errorCount === 0) {
      let result = await EditIdeaService(idea);
      if (result && result.status === "success") {
        console.log("Screening idea Sent");
      } else {
        console.log("error occurred while sending data for screening");
      }
    }
  };

  const GetIdeaDetail = async () => {
    let result = await GetIdeaService({ id: location.id });
    if (result && result.status === "success") {
      setIdea(result.idea);
      console.log("accepted");
    } else {
      console.log("error occurred getting single");
    }
  };
  const onFileSelected = async (event, id) => {
    if (!event.filesData[0]) {
      return;
    }
    let result = await UploadFile(event.filesData[0]?.rawFile, "idea");
    if (result && result.status === "success") {
      setIdea({ ...idea, [id]: result.file });
    } else {
      console.log("error occurred while sending data for screening");
    }
  };
  const onRemoveFile = async (file, id) => {
    let result = await RemoveFile("idea", file);
    if (result && result.status === "success") {
      setIdea({ ...idea, [id]: "" });
    } else {
      console.log("error occurred while sending data for screening");
    }
  };
  useEffect(() => {
    GetIdeaDetail();
    getSectors();
    getDistrict();
  }, []);
  return (
    <>
      <div className='container-fluid'>
        <div className='form-wrapper'>
          <div className='form-header'>
            <div className='header-title'>
              <p style={{ fontSize: "22px", marginBottom: "0", color: "#09f" }}>
                Edit Your Idea{" "}
              </p>
            </div>
            <div>
              {idea.status === 5 && (
                <div className={"review-msg-block"}>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <WarningAmberOutlined size={"30px"} />
                    </div>
                    <div className={"rejected-msg"}>
                      <span>Reason : {idea.remark}</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='idea-form'>
            <div className='form-body'>
              <span style={{ fontSize: "18px" }}>
                <b>Idea Details</b>
              </span>
              <div className='details custom-mui-input'>
                <div className='row '>
                  <div className='col-sm-12 col-md-6 '>
                    <TextField
                      style={{ width: "100%" }}
                      name='title'
                      label='Idea Name'
                      value={idea.title ? idea.title : ""}
                      onChange={handleInput}
                      variant='outlined'
                      required
                    />
                  </div>
                  <div className='col-sm-12 col-md-6 '>
                    {/*<ReactTagsDemo fieldName={"Category"} options={category} addFunction={addCategory}/>*/}
                    <Autocomplete
                      disablePortal
                      multiple={"true"}
                      name={"categories"}
                      // value={idea.categories}
                      options={category}
                      onChange={(e, value) => {
                        setIdea({
                          ...idea,
                          categories: value,
                        });
                      }}
                      getOptionLabel={(option) => option.name}
                      sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          variant='outlined'
                          {...params}
                          label='Category'
                        />
                      )}
                    />
                  </div>
                </div>

                <div className='row '>
                  <div className='col-sm-12 '>
                    <TextField
                      id='outlined-multiline-static'
                      label='Your idea in Brief'
                      multiline
                      rows={6}
                      sx={{ width: "100%" }}
                      variant='outlined'
                      required
                      name={"description"}
                      value={idea.description ? idea.description : ""}
                      onChange={handleInput}
                    />
                  </div>
                </div>
                <div className='col-sm-12'>
                  <h5 style={{ marginTop: "20px", marginBottom: "0" }}>
                    Cover Image
                  </h5>
                  <div>
                    {idea.cover_image && (
                      <img src={getImageUrl("idea", idea.cover_image)} alt='' />
                    )}
                  </div>
                </div>
                <div className='row '>
                  <div className='col-sm-12 col-md-6 '>
                    <TextField
                      style={{ width: "100%" }}
                      name='tags'
                      label='Tags'
                      value={idea.tags}
                      onChange={handleInput}
                      variant='outlined'
                    />
                  </div>
                </div>

                <span style={{ fontSize: "18px" }}>
                  <b>Investment Requirements</b>
                </span>

                <div className='row '>
                  <div className='col-sm-12 col-md-6 '>
                    <TextField
                      style={{ width: "100%" }}
                      name='own_investment'
                      label='Your Investment'
                      value={idea.own_investment ? idea.own_investment : ""}
                      onChange={handleInput}
                      variant='outlined'
                      required
                    />
                  </div>
                  <div className='col-sm-12 col-md-6 '>
                    <TextField
                      style={{ width: "100%" }}
                      name='expected_invested'
                      label='Expected Investment'
                      value={
                        idea.expected_invested ? idea.expected_invested : ""
                      }
                      onChange={handleInput}
                      variant='outlined'
                      required
                    />
                  </div>
                </div>

                <div className='row '>
                  <div className='col-sm-12 col-md-6 '>
                    <TextField
                      style={{ width: "100%" }}
                      name='location'
                      label='Your Location'
                      value={idea.location ? idea.location : ""}
                      onChange={handleInput}
                      variant='outlined'
                      required
                    />
                  </div>
                  <div className='col-sm-12 col-md-6 '>
                    <Autocomplete
                      disablePortal
                      multiple={"true"}
                      name='pref_locations'
                      options={district}
                      onChange={(e, value) => {
                        setIdea({
                          ...idea,
                          pref_locations: value,
                        });
                      }}
                      getOptionLabel={(option) => option.name}
                      sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          variant='outlined'
                          {...params}
                          label='Preferred Location'
                        />
                      )}
                    />
                  </div>
                </div>
                <div className='row '>
                  <div className='col-sm-12 col-md-6 '>
                    <Autocomplete
                      disablePortal
                      id='combo-box-demo'
                      options={investment_type}
                      sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          variant='outlined'
                          {...params}
                          label='Investment Type'
                          value='test'
                        />
                      )}
                    />
                  </div>
                  {/*<div className='col-sm-12 col-md-6 '>*/}
                  {/*  <TextField*/}
                  {/*    style={{ width: "100%" }}*/}
                  {/*    name='tags'*/}
                  {/*    label='Idea tags'*/}
                  {/*    value={idea.tags}*/}
                  {/*    onChange={handleInput}*/}
                  {/*    variant='outlined'*/}
                  {/*    value={idea.tags}*/}
                  {/*  />*/}
                  {/*</div>*/}
                </div>
                <hr />

                <div className='row'>
                  <div className='idea-form-parameter col-md-6 col-sm-12'>
                    <label htmlFor='DIP'>Debt Injection Plan</label>
                    <UploaderComponent
                      id='debtInjectionPlan'
                      type='file'
                      selected={(event) =>
                        onFileSelected(event, "debt_injection_plan_doc_link")
                      }
                      autoUpload={true}
                      multiple={false}
                      removing={() =>
                        onRemoveFile(
                          idea.debt_injection_plan_doc_link,
                          "debt_injection_plan_doc_link"
                        )
                      }
                    />
                  </div>
                  <div className='idea-form-parameter col-md-6 col-sm-12'>
                    <label htmlFor='feasibility'>Feasibility Study</label>
                    <UploaderComponent
                      id='debtInjectionPlan'
                      type='file'
                      selected={(event) =>
                        onFileSelected(event, "feasibility_study_doc_link")
                      }
                      autoUpload={true}
                      multiple={false}
                      removing={() =>
                        onRemoveFile(
                          idea.feasibility_study_doc_link,
                          "feasibility_study_doc_link"
                        )
                      }
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='idea-form-parameter col-md-6 col-sm-12'>
                    <label htmlFor='SWOT'>SWOT Analysis</label>
                    <UploaderComponent
                      id='debtInjectionPlan'
                      type='file'
                      selected={(event) =>
                        onFileSelected(event, "swot_analysis_doc_link")
                      }
                      autoUpload={true}
                      multiple={false}
                      removing={() =>
                        onRemoveFile(
                          idea.swot_analysis_doc_link,
                          "swot_analysis_doc_link"
                        )
                      }
                    />
                  </div>
                  <div className='idea-form-parameter col-md-6 col-sm-12'>
                    <label htmlFor='financial_projection'>
                      Financial Projection
                    </label>
                    <UploaderComponent
                      id='debtInjectionPlan'
                      type='file'
                      selected={(event) =>
                        onFileSelected(event, "financial_projection_doc_link")
                      }
                      autoUpload={true}
                      multiple={false}
                      removing={() =>
                        onRemoveFile(
                          idea.financial_projection_doc_link,
                          "financial_projection_doc_link"
                        )
                      }
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='idea-form-parameter col-md-6 col-sm-12'>
                    <label htmlFor='financial_analysis'>
                      Financial Analysis
                    </label>
                    <UploaderComponent
                      id='debtInjectionPlan'
                      type='file'
                      selected={(event) =>
                        onFileSelected(event, "financial_analysis_doc_link")
                      }
                      autoUpload={true}
                      multiple={false}
                      removing={() =>
                        onRemoveFile(
                          idea.financial_analysis_doc_link,
                          "financial_analysis_doc_link"
                        )
                      }
                    />
                  </div>
                </div>
              </div>
              <div className='form-btn'>
                {!sentBtn ? (
                  <button
                    onClick={() => {
                      toggleModal();
                    }}
                  >
                    {" "}
                    Re-Send for Screening
                  </button>
                ) : (
                  <div
                    role='presentation'
                    className={"detail-action-response"}
                    onClick={toggleModal}
                  >
                    Idea has been sent
                  </div>
                )}
              </div>
            </div>
            {openModal && (
              <CreatedIdeaModal
                toggleModal={toggleModal}
                handleSubmit={handleSubmit}
                toggleSentBtn={toggleSentBtn}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default IdeaEditInterface;
