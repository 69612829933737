import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import ImageUpload from "../../../components/image-upload/ImageUpload";
import {
  GetDistrictService,
  GetMunicipalitiesService,
  GetSectorsService,
} from "../../../services/BasicService";
import { AddInvestorProfileService } from "../../../services/InvestorService";
import ProfileCreationSuccess from "./ProfileCreationSucess";
import { authData } from "../../../redux/actions";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { UploaderComponent } from "@syncfusion/ej2-react-inputs";
import { RemoveFile, UploadFile } from "../../../services/IdeaHolderServices";
import { toast } from "react-toastify";
import Loader from "../../../components/loader/Loader";
import AlertDialog from "../../../components/modal/AlertDialog";

function InvestorForm() {
  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => setOpenModal(!openModal);
  const [displayFileErrorMessage,setDisplayFileErrorMessage]  = useState(false);
  const [fileUploadError,setFileUploadError] = useState("");
  const [investorType, setInvestorType] = useState(0);
  const [fileUploading, setFileUploading] = useState(false);
  const dispatch = useDispatch();

  const [category, setCategory] = useState([]);
  const [district, setDistrict] = useState([]);

  const getDistrict = async () => {
    let result = await GetDistrictService();
    if (result && result.status === "success") {
      setDistrict(result.districts);
    }
  };

  const getSectors = async () => {
    let result = await GetSectorsService();
    if (result && result.status === "success") {
      setCategory(result.sectors);
    } else {
      console.log("error has occurred category");
    }
  };

  useEffect(() => {
    getDistrict();
    getSectors();
  }, []);
  const [municipalitiesList, setMunicipalitiesList] = useState([]);
  const [errors, setErrors] = useState({});
  const [investor, setInvestor] = useState({
    name: "",
    title: "",
    age: "",
    citizenship: "",
    email: "",
    phone: "",
    gender: "",
    investor_type: "",
    investor_detail: "",
    investor_interest: "",
    categories: [],
    pref_locations: [],
    selected_municipality_id: "",
    selected_muni_name: "",
    area: "",
    temp_selected_municipality_id: "",
    temp_selected_municipality_name: "",
    temp_area: "",
    profile_picture: [],
    upload_file: [],
    min_investment_amount: "",
    max_investment_amount: "",
    remark: "",
    cv: "",
  });
  const userData = useSelector((state) => state.userAuth);

  useEffect(() => {
    GetMunicipalities();
  }, []);

  const handleDropdownInput = (inp_var, value) => {
    setInvestor({ ...investor, [inp_var]: value });
    if (value || value === 0) {
      errors[inp_var] = false;
    } else if (
      inp_var !== "temp_selected_municipality_name" &&
      inp_var !== "temp_selected_municipality_id" &&
      inp_var !== "temp_area"
    ) {
      errors[inp_var] = true;
    }
    setErrors({ ...errors });
  };

  const GetMunicipalities = async () => {
    const result = await GetMunicipalitiesService(investor.muni_search);
    if (result?.status === "success") {
      let municipalityListMapped = result.municipalityList.map(
        (municipality) => {
          var info = {
            label: municipality.name + ", " + municipality.district.name,
            id: municipality.id,
          };
          return info;
        }
      );
      let name = userData?.details.user?.name;
      let email = userData?.details.user?.email;
      setInvestor({
        ...investor,
        name: name,
        email: email,
      });
      setMunicipalitiesList(municipalityListMapped);
    }
  };

  const handleInput = async (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (value || value === 0) {
      errors[name] = false;
    } else if (
      name !== "temp_selected_municipality_name" &&
      name !== "temp_selected_municipality_id" &&
      name !== "temp_area" &&
      name !== "remark"
    ) {
      errors[name] = true;
    }
    setErrors({ ...errors });
    investor[name] = value;
    setInvestor({ ...investor });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setInvestor({ ...investor, errors: {} });
    for (const input in investor) {
      if (
        input !== "errors" &&
        input != "remarks" &&
        input != "temp_selected_municipality_name" &&
        input !== "temp_selected_municipality_id" &&
        input !== "temp_area" &&
        input !== "remark" &&
        !(
          investor.type !== undefined &&
          +investor.investor_type === 0 &&
          input !== "ocr_certificate" &&
          input !== "pan"
        ) &&
        !(
          +investor.investor_type === 1 &&
          input !== "citizenship" &&
          input !== "cv"
        )
      ) {
        // eslint-disable-next-line no-extra-boolean-cast
        if (!Boolean(investor[input]) && investor[input] !== 0) {
          errors[input] = true;
          setErrors({ ...errors });
        }
      }
    }
    if (Object.values(errors).filter((error) => error).length > 0) {
      return false;
    }
    let result = await AddInvestorProfileService(investor);
    if (result && result.status === "success") {
      localStorage.setItem("user-token", result.token);
      dispatch(authData(result));
      toggleModal();
    } else {
      toast.info("Unable to update investor form", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  function saveImage(pictures) {
    setInvestor({
      ...investor,
      profile_picture: pictures,
    });
  }
  const onFileSelected = async (event, id, fileTypes) => {
    try {
      const file = event.filesData[0];
      if (!fileTypes.some(fileType=>fileType.toLowerCase()===file.type.toLowerCase())) {
        setFileUploadError("File must be "+fileTypes.join(" OR "));
        setDisplayFileErrorMessage(true);
        return;
      }
      setFileUploading(true);
      let result = await UploadFile(file.rawFile, "investor-profile");
      if (result && result.status === "success") {
        setInvestor({ ...investor, [id]: result.file });
        errors[id] = false;
      } else {
        errors[id] = true;
      }
      setErrors({ ...errors });
      setFileUploading(false);
    } catch (e) {
      setFileUploading(false);
    }
  };
  const onRemoveFile = async (args, id) => {
    let result = await RemoveFile(args);
    if (result && result.status === "success") {
      setInvestor({ ...investor, [id]: "" });
    } else {
      console.log("error occurred while sending data for screening");
    }
  };
  return (
    <>
      {fileUploading && <Loader />}
      <div className='form-wrapper form_wrap_mobile' id='investor-form'>
        <div className='form-header'>
          <div className='header-title'>
            <h2>Create an Investor Profile</h2>
            <span>Fill in the details below to create an Investor profile</span>
          </div>
        </div>

        <div className='investor-form  '>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
            columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
          >
            <Grid item sm={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel id='investorType'>Investor Type</InputLabel>
                <Select
                  style={{ width: "100%" }}
                  labelId='investorType'
                  id='investor_type'
                  name='investor_type'
                  value={investor.investor_type}
                  label='Investor Type'
                  onChange={handleInput}
                  variant='outlined'
                >
                  <MenuItem onClick={() => setInvestorType(0)} value={0}>
                    Individual Investor
                  </MenuItem>
                  <MenuItem onClick={() => setInvestorType(1)} value={1}>
                    Institutional Investor
                  </MenuItem>
                </Select>
              </FormControl>
              <p></p>
              {errors?.investor_type && (
                <span style={{ color: "red", fontSize: "11px" }}>
                  Investor type is required
                </span>
              )}
            </Grid>
            <Grid item sm={6} xs={12}></Grid>

            {investorType === 0 ? (
              <>
                <Grid item xs={12}>
                  <h4 className='h4_pd'>Personal Details</h4>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    style={{ width: "100%" }}
                    name='name'
                    label='Full Name'
                    value={investor.name}
                    onChange={handleInput}
                    variant='outlined'
                    required
                    error={errors?.name}
                    helperText={errors?.name && "Name is required"}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    style={{ width: "100%" }}
                    name='email'
                    label='Email'
                    value={investor.email}
                    onChange={handleInput}
                    variant='outlined'
                    required
                    error={errors?.email || errors?.uniqueEmail}
                    helperText={
                      errors?.email
                        ? "Email is required"
                        : errors.uniqueEmail
                          ? "This email is already taken"
                          : ""
                    }
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    style={{ width: "100%" }}
                    name='age'
                    label='Age'
                    value={investor.age}
                    onChange={handleInput}
                    variant='outlined'
                    required
                    error={errors?.age}
                    helperText={errors?.age && "Age is required"}
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id='genderLabel'>Gender</InputLabel>
                    <Select
                      style={{ width: "100%" }}
                      labelId='genderLabel'
                      name={"gender"}
                      value={investor.gender}
                      label='Gender'
                      onChange={handleInput}
                      variant='outlined'
                    >
                      <MenuItem value={0}>Male</MenuItem>
                      <MenuItem value={1}>Female</MenuItem>
                      <MenuItem value={2}>Others</MenuItem>
                    </Select>
                  </FormControl>
                  {errors?.gender && (
                    <span style={{ color: "red", fontSize: "11px" }}>
                      Gender type is required
                    </span>
                  )}
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    style={{ width: "100%" }}
                    name='title'
                    label='Title/Designation'
                    value={investor.title}
                    onChange={handleInput}
                    variant='outlined'
                    required
                    error={errors?.title}
                    helperText={errors?.title && "Title is required"}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    style={{ width: "100%" }}
                    name='phone'
                    label='Phone'
                    value={investor.phone}
                    onChange={handleInput}
                    variant='outlined'
                    required
                    error={errors?.phone}
                    helperText={errors?.phone && "Phone is required"}
                  />
                </Grid>
                <Grid item sm={6} xs={12} className='investor-form-parameter'>
                  <span>Upload your citizenship</span>
                  <UploaderComponent
                    id='citizenship_image'
                    type='file'
                    selected={async (event) => {
                      await onFileSelected(event, "citizenship",["png","jpg","jpeg","JPG","JPEG"]);
                    }}
                    autoUpload={true}
                    multiple={false}
                    removing={() =>
                      onRemoveFile(investor.citizenship, "citizenship")
                    }
                  />
                  {errors?.citizenship && (
                    <span style={{ color: "red", fontSize: "11px" }}>
                      Citizenship is required
                    </span>
                  )}
                </Grid>
                <Grid item sm={6} xs={12} className='investor-form-parameter'>
                  <span>Upload Your CV</span>
                  <UploaderComponent
                    id='cv'
                    type='file'
                    selected={async (event) => {
                      await onFileSelected(event, "cv",["pdf"]);
                    }}
                    autoUpload={true}
                    multiple={false}
                    removing={() => onRemoveFile(investor.cv, "cv")}
                  />
                  {errors?.cv && (
                    <span style={{ color: "red", fontSize: "11px" }}>
                      CV is required
                    </span>
                  )}
                </Grid>
                <Grid item sm={6} xs={12} className='investor-form-parameter'>
                  <span>Profile Picture</span>
                  <UploaderComponent
                    id='profile_pic'
                    type='file'
                    selected={async (event) => {
                      await onFileSelected(event, "profile_pic",["png","jpg","jpeg","JPG","JPEG"]);
                    }}
                    autoUpload={true}
                    multiple={false}
                    removing={() =>
                      onRemoveFile(investor.profile_pic, "profile_pic")
                    }
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <h4>Company Details</h4>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    style={{ width: "100%" }}
                    name='company_name'
                    label='Company Name'
                    value={investor.name}
                    onChange={handleInput}
                    variant='outlined'
                    required
                    error={errors?.company_name}
                    helperText={
                      errors?.company_name && "Company Name is required"
                    }
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    style={{ width: "100%" }}
                    name='email'
                    label='Email'
                    value={investor.email}
                    onChange={handleInput}
                    variant='outlined'
                    required
                    error={errors?.email}
                    helperText={errors?.email && "Email is required"}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    style={{ width: "100%" }}
                    name='phone'
                    label='Phone'
                    value={investor.phone}
                    onChange={handleInput}
                    variant='outlined'
                    required
                    error={errors?.phone}
                    helperText={errors?.phone && "Phone is required"}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    style={{ width: "100%" }}
                    name='title'
                    label='Title'
                    value={investor.title}
                    onChange={handleInput}
                    variant='outlined'
                    required
                    error={errors?.title}
                    helperText={errors?.title && "Title is required"}
                  />
                </Grid>
                <Grid item sm={6} xs={12} className='investor-form-parameter'>
                  <span>Upload your PAN</span>
                  <UploaderComponent
                    id='pan'
                    type='file'
                    selected={(event) => onFileSelected(event, "pan")}
                    autoUpload={true}
                    multiple={false}
                    removing={() => onRemoveFile(investor.pan, "pan")}
                  />
                </Grid>
                <p>
                  {errors?.pan && (
                    <span style={{ color: "red", fontSize: "11px" }}>
                      PAN card is required
                    </span>
                  )}
                </p>
                <Grid item sm={6} xs={12} className='investor-form-parameter'>
                  <span>Upload Your OCR Certificate</span>
                  <UploaderComponent
                    id='ocr_certificate'
                    type='file'
                    selected={(event) =>
                      onFileSelected(event, "ocr_certificate")
                    }
                    autoUpload={true}
                    multiple={false}
                    removing={() =>
                      onRemoveFile(investor.ocr_certificate, "ocr_certificate")
                    }
                  />
                </Grid>
                <p>
                  {errors?.ocr_certificate && (
                    <span style={{ color: "red", fontSize: "11px" }}>
                      OCR certificate is required
                    </span>
                  )}
                </p>
                <Grid item sm={6} xs={12}>
                  <span className='photo-upload'>Company Seal</span>
                  <ImageUpload saveImage={saveImage} />
                </Grid>
              </>
            )}

            <Grid item sm={6} xs={12}></Grid>

            <Grid item xs={12}>
              <span>Permanent Location</span>
            </Grid>

            <Grid item sm={6} xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  style={{ width: "100%" }}
                  className='drop-down-mui'
                  value={investor.selected_municipality_id}
                  onChange={(event, newValue) => {
                    handleDropdownInput("selected_municipality_id", newValue);
                  }}
                  inputValue={investor.selected_muni_name}
                  onInputChange={(event, newInputValue) => {
                    handleDropdownInput("selected_muni_name", newInputValue);
                  }}
                  id='controllable-states-demo'
                  options={municipalitiesList}
                  renderInput={(params) => (
                    <TextField {...params} label='Municipality' />
                  )}
                />
              </FormControl>
              {errors?.selected_muni_name && (
                <span style={{ color: "red", fontSize: "11px" }}>
                  Municipality is required
                </span>
              )}
            </Grid>

            <Grid item sm={6} xs={12}>
              <TextField
                style={{ width: "100%" }}
                name='area'
                label='Street'
                value={investor.area}
                onChange={handleInput}
                variant='outlined'
                required
                error={errors?.area}
                helperText={errors?.area && "Area is required"}
              />
            </Grid>

            <Grid item xs={12}>
              <span>Temporary Location</span>
            </Grid>

            <Grid item sm={6} xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  style={{ width: "100%" }}
                  className='drop-down-mui'
                  value={investor.temp_selected_municipality_id}
                  onChange={(event, newValue) => {
                    handleDropdownInput(
                      "temp_selected_municipality_id",
                      newValue
                    );
                  }}
                  inputValue={investor.temp_selected_municipality_name}
                  onInputChange={(event, newInputValue) => {
                    handleDropdownInput(
                      "temp_selected_municipality_name",
                      newInputValue
                    );
                  }}
                  id='controllable-states-demo'
                  options={municipalitiesList}
                  renderInput={(params) => (
                    <TextField {...params} label='Municipality' />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item sm={6} xs={12}>
              <TextField
                style={{ width: "100%" }}
                name='temp_area'
                label='Street'
                value={investor.temp_area}
                onChange={handleInput}
                variant='outlined'
              />
            </Grid>

            <Grid item xs={12}>
              <h4 className='h4_pd'>Investment Details</h4>
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
                disablePortal
                multiple={true}
                name={"categories"}
                options={category}
                onChange={(e, value) => {
                  setInvestor({
                    ...investor,
                    categories: value,
                  });
                }}
                getOptionLabel={(option) => option.name}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField variant='outlined' {...params} label='Category' />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                disablePortal
                multiple={true}
                name='pref_locations'
                options={district}
                onChange={(e, value) => {
                  setInvestor({
                    ...investor,
                    pref_locations: value,
                  });
                }}
                getOptionLabel={(option) => option.name}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    variant='outlined'
                    {...params}
                    label='Preferred Location'
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id='outlined-multiline-static'
                label='Investor Detail'
                multiline
                rows={6}
                sx={{ width: "100%" }}
                variant='outlined'
                required
                name={"investor_detail"}
                value={investor.investor_detail}
                onChange={handleInput}
                error={errors?.investor_detail}
                helperText={
                  errors?.investor_detail && "Investor detail is required"
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id='outlined-multiline-static'
                label='Interests'
                multiline
                rows={6}
                sx={{ width: "100%" }}
                variant='outlined'
                required
                name={"investor_interest"}
                value={investor.investor_interest}
                onChange={handleInput}
                error={errors?.investor_interest}
                helperText={
                  errors?.investor_interest && "Investor interest is required"
                }
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <TextField
                fullWidth
                name='min_investment_amount'
                label='Min Amount'
                value={investor.min_investment_amount}
                onChange={handleInput}
                variant='outlined'
                type='number'
                required
                error={errors?.min_investment_amount}
                helperText={
                  errors?.min_investment_amount &&
                  "Min Investment amount is required"
                }
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                fullWidth
                name='max_investment_amount'
                label='Max Amount'
                value={investor.max_investment_amount}
                onChange={handleInput}
                variant='outlined'
                type='number'
                required
                error={errors?.max_investment_amount}
                helperText={
                  errors?.max_investment_amount &&
                  "Max investment amount is required"
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id='outlined-multiline-static'
                label='Remarks'
                multiline
                rows={6}
                sx={{ width: "100%" }}
                variant='outlined'
                name={"remark"}
                value={investor.remark}
                onChange={handleInput}
              />
            </Grid>

            <Grid item xs={12}>
              <div className='form-btn'>
                <Button
                  style={{ marginBottom: "10px" }}
                  variant='contained'
                  onClick={handleSubmit}
                  value='Create Profile'
                >
                  Create your Profile
                </Button>
              </div>
            </Grid>
          </Grid>
          {openModal && (
            <div>
              <ProfileCreationSuccess toggleModal={toggleModal} />
            </div>
          )}
          {displayFileErrorMessage && (
            <AlertDialog title="Error" message={fileUploadError} open={displayFileErrorMessage} closeAlert={()=>{setDisplayFileErrorMessage(false)}}  />
          )}
        </div>
      </div>
    </>
  );
}

export default InvestorForm;
