import React from "react";


import "./Home.css";
import { CardData } from "./CardData";

function CardBlock() {
  return (
    <>
      {CardData.map((cardItem, index) => {
        return (
          <div key={index} className='cardBlock'>
            <div key={index} className='cardBlock-content'>
              <div className='cardBlockIcon'>
                <span
                  className='cardIcon'
                  style={{
                    color: cardItem.color,
                    backgroundColor: cardItem.bgColor,
                  }}
                >
                  {cardItem.icon}
                </span>
              </div>

              <div className='cardContent'>
                <div className='cardTitle'>{cardItem.title}</div>
                <span className='cardCount '>
                  {/* <CountUp
                    start='0'
                    end={cardItem.count}
                    duration={2.5}
                    delay={0}
                  /> */}
                </span>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default CardBlock;
