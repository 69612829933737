import React, {useState} from "react";
import IdeaHolderSchedule from "./schedule-data/IdeaHolderSchedule";
import InvestorSchedule from "./schedule-data/InvestorSchedule";
import TripartiteSchedule from "./schedule-data/TripartiteSchedule";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { Tab, Tabs } from "@mui/material";
function IdeaMeetingSchedule() {
  const [search, setSearch] = useState(true);
  const showSearch = () => setSearch(!search);
  return (
    <div className="container-fluid">
      <div className="table-wrapper">
        <div className="table-header">
          <span>Meeting Schedule</span>
          {search ? (
            <SearchIcon onClick={showSearch} style={{marginRight: "20px"}}/>
          ) : (
            <div className="search">
              <CloseIcon onClick={showSearch}/>
              <input type="search" placeholder="Search"/>
            </div>
          )}
        </div>
        <Tabs
          defaultActiveKey="ideaHolderSchedule"
          id="uncontrolled-tab-example"
        >
          <Tab eventKey="ideaHolderSchedule" title="Meeting With Idea Holder">
            <IdeaHolderSchedule/>
          </Tab>
          <Tab eventKey="investorSchedule" title="Meeting With Investor">
            <InvestorSchedule/>
          </Tab>
          <Tab eventKey="tripartiteSchedule" title="Tripartite Meeting">
            <TripartiteSchedule/>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default IdeaMeetingSchedule;
