import React from "react";
import PaginationPage from "../../../../components/pagination/Pagination";
import AddRemarks from "./AddRemarks";
import ViewRemarks from "./ViewRemarks";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import { format } from "date-fns/esm";
import { CircularProgress } from "@mui/material";
import { parseISO } from "date-fns";

// import SchedulerModal from "./schedulerModal";
function IdeaHolderSchedule(props) {
  const [detail, setDetail] = React.useState({});
  const [viewDetail, setViewDetail] = React.useState({});

  const filterData = (id) => {
    const data = props.data.meetings.find((g) => {
      return g.id === id;
    });

    setDetail(data);
    setViewDetail(data);
  };
  const status = [
    "unschledule",
    "scheduled",
    "ongoing",
    "successful",
    "rejected",
  ];

  return (
    <div>
      <>
        <div
          className='table-contents'
          style={{ height: "69vh", overflowY: "auto" }}
        >
          <table id='db-table' className='a_r'>
            <thead>
              <tr>
                <th>SN</th>
                <th>Investor</th>
                <th> Idea Holder</th>
                <th> Idea</th>
                <th>Date & Time</th>

                <th>Status</th>

                <th style={{ textAlign: "center" }}>Remarks</th>
              </tr>
            </thead>
            {props.loading ? (
              <tbody>
                <tr>
                  <td colSpan='7' style={{ textAlign: "center" }}>
                    <CircularProgress />
                  </td>
                </tr>
              </tbody>
            ) : (
              props.data.meetings &&
              props.data.meetings.length === 0 && (
                <tbody>
                  <tr>
                    <td colSpan='6'>
                      <div className='no_data_found'>
                        <FileOpenOutlinedIcon size='50px' />
                        <h6>No data Available</h6>
                      </div>
                    </td>
                  </tr>
                </tbody>
              )
            )}
            <tbody>
              {props &&
                props.data &&
                props.data.meetings &&
                props.data.meetings.map((sub, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{sub.investor.name}</td>
                    <td>{sub.idea_holder.name}</td>
                    <td>{sub.idea.title}</td>
                    <td>
                      
                      {format(
                        parseISO(
                          sub.meeting_request_date,
                          "yyyy-MM-dd H:m:s",
                          new Date()
                        ),
                        "MMM do yyyy H:mm:ss a"
                      )}
                    </td>

                    <td>{status[sub.status]}</td>
                    <td style={{ width: "140px", paddingLeft: "20px" }}>
                      {sub.status == 3 ? (
                        <div
                          role='presentation'
                          className='view_remarks_eye'
                          onClick={() => {
                            filterData(sub.id);
                            props.handleViewRemarks();
                          }}
                        >
                          <RemoveRedEyeIcon /> &nbsp; <span>View</span>
                        </div>
                      ) : (
                        <button
                          onClick={() => {
                            filterData(sub.id);
                            props.handleShowRemarks();
                          }}
                        >
                          {" "}
                          Add Remarks
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className='pagination'>
          <PaginationPage
            currentPage={props.currentPage}
            totalPage={props.totalPage}
            changePage={props.pagination}
          />
        </div>
      </>
      <AddRemarks {...props} detail={detail} />
      <ViewRemarks {...props} detail={viewDetail} />
    </div>
  );
}

export default IdeaHolderSchedule;
