import React, {useEffect, useState} from "react";
import "./Modal.css";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {GetUsersIdea} from "../../services/IdeaHolderServices";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { alpha, Button, Menu, MenuItem } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import styled from "@emotion/styled";
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));
function ProposalModal(props) {
  const notify = () =>
    toast.info("Your proposal has been sent.", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const [idea, setIdea] = useState([]);

  const [proposal, setProposal] = useState(true);
  const sendProposal = () => {
    setProposal(!proposal);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = (data) => {
    setLastClicked({
      id: data.id,
      title: data.title,
    })
    setAnchorEl(null);
    props.handleChange(data);
  };
  const [lastClicked, setLastClicked] = useState(null);
  // console.log(lastClicked)
  const GetIdeaDetail = async () => {
    let result = await GetUsersIdea();
    if (result && result.status === "success") {
      console.log("ideas from user arrived");
      let myData = result.ideas.filter((d) => {
        return d.status === 4;
      });
      // setData(myData[0]);
      setIdea(myData);
    } else {
      console.log("error has occurred");
    }
  };
  useEffect(() => {
    GetIdeaDetail();
  }, []);

  useEffect(() => {
    props.handleChange(lastClicked);
  }, [lastClicked]);

  const user = useSelector((state) => state.userAuth);

  return (
    <>
      {user.isideaholder ? (
        <div className='modalBackground'>
          <div className='modalContainer'>
            <div className='modal-title'>
              <span>Send Proposal</span>
              <CancelOutlinedIcon onClick={props.toggleModal} cursor='pointer'/>
            </div>

            {proposal ? (
              <>
                <div className='body'>
                  <Button
                    id="demo-customized-button"
                    aria-controls={open ? "demo-customized-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleMenuClick}
                    endIcon={<ArrowDropDownIcon />}
                  >
                    {lastClicked === null
                      ? "Select your Idea"
                      : lastClicked.title}
                  </Button>
                  <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                      "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleMenuClose}
                  >
                    {idea.length > 0 ? (
                      <>
                        {idea.map((data, index) => {
                          return (
                            <MenuItem
                              key={index}
                              onClick={handleMenuClose}
                            >
                              {data.title}
                            </MenuItem>
                          );
                        })}
                      </>
                    ) : (
                      <MenuItem disabled>No ideas available</MenuItem>
                    )}
                  </StyledMenu>
                </div>
                <div className='footer'>
                  {lastClicked && (
                    <button onClick={sendProposal}>Send Proposal</button>
                  )}
                </div>
              </>
            ) : (
              <div className='body'>
                <label>
                                    Are you sure you want to send proposal for{" "}
                  <b>{lastClicked.title}</b> to the investor?
                </label>
                <div className='footer'>
                  <button className={"cancel-btn"} onClick={sendProposal}>
                                        Cancel
                  </button>
                  <button
                    className={"success-btn"}
                    onClick={() => {
                      notify();
                      props.sendProposal();
                      props.toggleModal();
                    }}
                  >
                                        Continue
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className='modalBackground'>
          <div className='modalContainer'>
            <div className='modal-title'>
              <span>Send Proposal</span>
              <CancelOutlinedIcon onClick={props.toggleModal} cursor='pointer'/>
            </div>

            <div className='body'>
              <label htmlFor="proposal">
                                You need to create a Idea Holder profile inorder to send
                                proposal to the investor.
              </label>
              <div className='footer'>
                <Link to={"/idea/idea-form"}>
                  <button className='ok-btn'>Create Idea Profile</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ProposalModal;
