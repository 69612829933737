import React, { Component } from "react";
import axios from "axios";
import PaginationPage from "../../../../../components/pagination/Pagination";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

class IdeaHolderSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ideas: [],
      loading: true,
    };
  }

  async componentDidMount() {
    const res = await axios.get(process.env.REACT_APP_API_BASE_URL+"/api/ideas");
    this.setState({
      ideas: res.data.ideas,
      loading: false,
    });
  }

  render() {
    var ideas_HTMLTABLE = "";
    if (!this.state.loading) {
      ideas_HTMLTABLE = (
        <tr>
          <td colSpan='6'>
            <h2>Loading...</h2>
          </td>
        </tr>
      );
    } else {
      ideas_HTMLTABLE = this.state.ideas.map((item) => {
        return (
          <tr key={item.id}>
            <td>{item.id}</td>
            <td>{item.idea_name}</td>
            <td>20</td>
            <td>90</td>
            <td>pending</td>
            <td> Edit</td>
            <td>Delete</td>
          </tr>
        );
      });
    }
    return (
      <>
        <div className='table-contents'>
          <table id='db-table'>
            <thead>
              <tr>
                <th>ID</th>
                <th>Idea Name</th>
                <th>Idea Holder</th>
                <th>Time</th>
                <th>Date</th>
                <th>Status</th>
                <th colSpan='2' style={{ textAlign: "center" }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>ID</td>
                <td>Electro Engineering</td>
                <td>Ram kant</td>
                <td>12:00pm</td>
                <td>2021/10/1</td>
                <td>Ongoing</td>
                <td style={{ textAlign: "center" }}>
                  <DriveFileRenameOutlineIcon />
                </td>
                <td style={{ textAlign: "center" }}>
                  <DeleteOutlineIcon color='red' />
                </td>
              </tr>{" "}
              <tr>
                <td>ID</td>
                <td>Idea Name</td>
                <td>Views</td>
                <td>Access</td>
                <td>Status</td>
                <td>Status</td>
                <td style={{ textAlign: "center" }}>
                  <DriveFileRenameOutlineIcon />
                </td>
                <td style={{ textAlign: "center" }}>
                  <DeleteOutlineIcon color='red' />
                </td>
              </tr>
              {ideas_HTMLTABLE}
            </tbody>
          </table>
        </div>
        <div className='pagination'>
          <PaginationPage />
        </div>
      </>
    );
  }
}

export default IdeaHolderSchedule;
