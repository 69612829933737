import HttpService from "./HttpService";
import APIEndPoints from "../constants/APIEndPoints";
const http = new HttpService();
export const GetInvestorDetailService = async (data) => {
  return await http.postData(
    data, "investors/" + APIEndPoints.getFeedInvestorDetail
  );
};

export const GetMunicipalitiesService = async () => {
  
  return await http.fetchData(APIEndPoints.GetMunicipalities);
};
export const GetSectorsService = async () => {
  
  return await http.fetchData(APIEndPoints.GetSectors);
};
export const GetDistrictService = async () => {
  
  return await http.fetchData(APIEndPoints.GetDistricts);
};

export const resetPasswordService = async (data) => {
  
  return await http.postData(data, APIEndPoints.ResetPassword);
};
export const ForgetPasswordService = async (data) => {
  
  return await http.postData(data, APIEndPoints.ForgotPassword);
};
export const changePasswordService = async (data) => {
  
  return await http.postData(data, APIEndPoints.ResetPassword);
};
export const VerifyUserService = async (data) => {
  
  return await http.postData(data, APIEndPoints.verifyUser);
};

export const LikeIdeaService = async (data) => {
  
  return await http.postData(
    data,
    APIEndPoints.likeIdea
  );
};

export const FavouriteIdeaService = async (data) => {
  
  return await http.postData(
    data,
    APIEndPoints.favouriteIdea
  );
};
export const LikeInvestorService = async (data) => {
  
  return await http.postData(
    data,
    APIEndPoints.likeInvestor
  );
};
export const FavouriteInvestorService = async (data) => {
  return await http.postData(
    data,
    APIEndPoints.favouriteInvestor
  );
};
export const getImageUrl = (folder,image)=>{
  return `${http.url}/uploads/${folder}/${image}`;
}

export const FollowUserService = (followedUserId)=>{
  return http.getData(
    `${APIEndPoints.followUser}/${followedUserId}`
  );
}