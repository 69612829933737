import React from "react";

function PrivacyPolicy() {
  return (
    <>
      <div className='form-wrapper f_wrap'>
        <h1>Privacy Policy</h1>
        <p>
          In order to receive information about your Personal Data, the purposes
          and the parties the Data is shared with, contact the Owner. For more
          information and to understand your rights, you can also view the
          complete version of this privacy policy, by clicking the link at the
          bottom right of this page.
        </p>
        <h3>Contact information</h3>
        <p>Owner and Data Controller</p>
        <p>Scieverinc Pvt. Ltd</p>
        <p>Owner contact email: info@scieverinc.com</p>
      </div>
    </>
  );
}
export default PrivacyPolicy;
