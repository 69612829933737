import React, { useEffect, useState } from "react";

import { GetTopIdeaService } from "../../../services/IdeaService";
import { useHistory } from "react-router-dom";
import NFormatter from "../../../utils/NumberFormatter";
import Skeleton from "@mui/material/Skeleton";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";

export default function TopIdeaList(splitUrl) {
  const history = useHistory();

  const [ideas, setIdeas] = useState([]);
  const [loading, setLoading] = useState(true);

  const getIdeas = async () => {
    let result = await GetTopIdeaService();
    if (result && result.status === "success") {
      setIdeas(result.ideas);
      setLoading(false);
    } else {
      console.log("error has occurred category");
    }
  };
  useEffect(() => {
    getIdeas();
  }, []);

  return (
    <>
      <div className='recommendation-column'>
        {splitUrl[2] != "investor-profile-form" && (
          <div className='top-lists-container'>
            <div className='top-lists-head'>
              <LightbulbOutlinedIcon className='activity-icon' />
              <span>Top Ideas</span>
            </div>
            <div className='top-lists-body'>
              {loading && (
                <div style={{ padding: "0 25px" }}>
                  <Skeleton height='50px' animation='wave' />
                  <Skeleton height='50px' animation='wave' />
                  <Skeleton height='50px' animation='wave' />
                  <Skeleton height='50px' animation='wave' />
                  <Skeleton height='50px' animation='wave' />
                </div>
              )}
              {ideas.map((idea, index) => {
                return (
                  <div
                    key={index}
                    className='top-list-item'
                    role='presentation'
                    onClick={() => history.push("/idea/detail/" + idea.id)}
                  >
                    <span>
                      {index + 1}.&nbsp;
                      {idea.title}
                    </span>
                    <div>
                      <span className='top-ideas-view-count'>
                        {NFormatter(idea.total_views + 1000, 2)} views
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
