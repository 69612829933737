import React from "react";
import "./App.scss";
import "./assets/scss/Responsiveui.scss";
import  "./assets/scss/Grid.scss";
import { ToastContainer } from "react-toastify";
import {  Router, Route, Switch } from "react-router-dom";
//dashboard components imports end here
//Idea Holder dashboard Imports starts here
import  InvestorRoutes  from "./routes/InvestorRoutes";
import  IdeaRoutes  from "./routes/IdeaRoutes";
import  FrontEndRoutes  from "./routes/FrontEndRoutes";
import AdminRoutes from "./routes/AdminRoutes";
import { AdminGuard } from "./routes/Guard";
import History from "./utils/History";
require("dotenv").config();
function App() {
  return (
    <>
      <ToastContainer />
      <Router history={History}>
        <Switch>
          {/*Dashboard Routes*/}
          {/*Admin Routes*/}
          <AdminGuard
            path='/dashboard'
            component={AdminRoutes}
          />
          {/*Admin Routes Ends*/}

          {/*Investor routes*/}
          <Route path="/investor" component={InvestorRoutes}></Route>
          {/*Investor dashboard Routes ends*/}

          {/*Idea dashboard routes*/}
          <Route path="/idea" component={IdeaRoutes}/>
          {/*end of Dashboard Routes*/}
          <Route
            path='/'
            component={FrontEndRoutes}
          />
          <Route
            path='*'
            component={() => {
              return <h3>Page not found 404</h3>;
            }}
          />
        </Switch>
      </Router>
    </>
  );
}
export default App;
