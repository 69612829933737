import { DialogContent } from "@mui/material";
import React from "react";
import styled from "styled-components";
import PDF from "../../../../assets/img/mypdf.png";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../../components/modal/BootstrapModal";

const H3 = styled.h3`
  font-weight: bolder;
  font-size: 1em;
  margin-top: 10px;
`;
export default function ViewRemarks(props) {
  const status = [
    "unschledule",
    "scheduled",
    "ongoing",
    "successful",
    "rejected",
  ];
  return (
    <div>
      <BootstrapDialog
        open={props.viewRemarks}
        onClose={props.handleUnViewRemarks}
      >
        <BootstrapDialogTitle onClose={props.handleUnViewRemarks}>
          
                        View Remarks
        </BootstrapDialogTitle>
        <DialogContent>
          <div className='scheduler-body'>
            <div className='user-detail'>
              <div className='p_d__m_remarks'>
                <div className='profile_details'>
                  <div
                    className='table-contents t__c'
                    style={{height: "unset!important"}}
                  >
                    {/* <div className={"table-title"}>
                      <b>Attendee:</b>
                    </div>
                    <table id='db-table' style={{ marginBottom: "10px" }}>
                      <tbody>
                        <tr>
                          <td>
                            <b>Idea Holder:</b>
                          </td>
                          <td colSpan={2}>
                            {props.detail.idea_holder &&
                              props.detail.idea_holder.name}
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <b>Investor</b>
                          </td>
                          <td className='res_table'>
                            {props.detail.investor &&
                              props.detail.investor.name}
                          </td>
                        </tr>
                      </tbody>
                    </table> */}
                    <div className={"table-title"}>
                      <b>Description:</b>
                    </div>
                    <table id='db-table' style={{marginBottom: "10px"}}>
                      <tbody>
                        <tr style={{height: "45px"}}>
                          <td colSpan={2}>
                            <span>{props.detail.description}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div className={"table-title"}>
                      <b>Status:</b>
                    </div>
                    <table id='db-table' style={{marginBottom: "10px"}}>
                      <tbody>
                        <tr>
                          <td colSpan={2}>
                            <span>{status[props.detail.status]}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className='text__area  volatile'>
                      <H3 style={{margin: "15px 0px"}}>Attached Documents</H3>
                      {/* <FileUpload /> */}
                      <div>
                        <span>
                          <img src={PDF} alt='pdf' width='40'/>
                        </span>
                        <span>
                          <img src={PDF} alt='pdf' width='40'/>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <br/>
              </div>
            </div>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
