import React from "react";
import PaginationPage from "../../../../components/pagination/Pagination";
import SchedulerModal from "./SchedulerModal";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import CircleOutlined from "@mui/icons-material/CircleOutlined";
import { format } from "date-fns/esm";
import { parseISO } from "date-fns";

function SchedulerData(props) {
  const [detail, setDetail] = React.useState({});

  const filterData = (id) => {
    const data = props.data.meetings.find((g) => {
      return g.id === id;
    });
    setDetail(data);
  };

  return (
    <div>
      <>
        <div
          className='table-contents'
          style={{ height: "69vh", overflowY: "auto" }}
        >
          <table id='db-table'>
            <thead>
              <tr>
                <th>SN</th>
                <th>Investor</th>
                <th> Idea Holder</th>
                <th> Idea</th>
                <th>Meeting Request Date</th>

                <th colSpan='2' style={{ textAlign: "center" }}>
                  Action
                </th>
              </tr>
            </thead>

            {props.loading ? (
              <tbody>
                <tr>
                  <td colSpan='7' style={{ textAlign: "center" }}>
                    <CircleOutlined />
                  </td>
                </tr>
              </tbody>
            ) : (
              props.data.meetings &&
              props.data.meetings.length === 0 && (
                <tbody>
                  <tr>
                    <td colSpan='6'>
                      <div className='no_data_found'>
                        <FileOpenOutlinedIcon size='50px' />
                        <h6>No data Available</h6>
                      </div>
                    </td>
                  </tr>
                </tbody>
              )
            )}

            <tbody>
              {props &&
                props.data &&
                props.data.meetings &&
                props.data.meetings.map((sub, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{sub.investor.name}</td>
                    <td>{sub.idea_holder.name}</td>
                    <td>{sub.idea.title}</td>
                    <td>
                      {format(
                        parseISO(sub.created_at, "yyyy-MM-dd H:m:s", new Date()),
                        "MMM do, yyyy"
                      )}
                    </td>
                    <td style={{ width: "200px" }}>
                      <button
                        onClick={() => {
                          filterData(sub.id);
                          props.handleShowTripartite();
                        }}
                      >
                        Schedule Meeting
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className='pagination'>
          <PaginationPage
            currentPage={props.currentPage}
            totalPage={props.totalPage}
            changePage={props.pagination}
          />
        </div>
      </>
      <SchedulerModal {...props} detail={detail} />
    </div>
  );
}

export default SchedulerData;
