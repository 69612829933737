import React from "react";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
export const CardData = [
  {
    title: "Approved",
    icon: <ThumbUpAltOutlinedIcon/>,
    count: "320",
    color: "#fff",
    bgColor: "#f8bdca",
  },
  {
    title: "Rejected",
    icon: <ThumbDownOutlinedIcon/>,
    count: "200",
    color: "#fff",
    bgColor: "#AC9EFF",
  },
  {
    title: "Verified Users",
    icon: <PeopleAltOutlinedIcon/>,
    count: "300",
    color: "#fff",
    bgColor: "#FFC19E",
  },
  {
    title: "Total Users",
    icon: <AccountCircleOutlinedIcon/>,
    count: "920",
    color: "#fff",
    bgColor: "#92C9FB",
  },
];
