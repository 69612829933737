import React from "react";
import UserImg from "../../assets/img/img_user.jpg";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadIcon from "@mui/icons-material/Upload";
import ImageUploading from "react-images-uploading";
export default class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: [],
    };
    this.maxNumber = 69;
    this.onChange = this.onChange.bind(this);
  }

  onChange(imageList) {
    this.setState({images: imageList}); // saved initially
    this.props.saveImage(imageList); //sent to parent component
  }

  render() {
    return (
      <div className='App'>
        <ImageUploading
          multiple
          value={this.state.images}
          onChange={this.onChange}
          maxNumber={this.maxNumber}
          dataURLKey='data_url'
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            isDragging,
            dragProps,
          }) => (
            // write your building UI
            <div className='upload__image-wrapper img__wrap'>
              {imageList <= 1 ? (
                <>
                  <button
                    id='img__main_uploader'
                    style={isDragging ? {color: "red"} : undefined}
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    <img src={UserImg} alt='upload'/>
                    <div className='camera__s'>
                      <span>
                        <CameraAltIcon/>
                      </span>
                    </div>
                  </button>
                </>
              ) : (
                <button onClick={onImageRemoveAll} className='remove_img'>
                  {/* <img src={Delete} alt='delete' /> */}
                  <DeleteIcon/>
                </button>
              )}
                            &nbsp;
              {imageList.map((image, index) => (
                <div key={index} className='image-item '>
                  <div id='img__main_uploader'>
                    <img src={image["data_url"]} alt='' width='100'/>
                  </div>
                  <div
                    className='image-item__btn-wrappers'
                    style={{borderRadius: "unset"}}
                  >
                    <button
                      onClick={() => onImageUpdate(index)}
                      className='refresh_img'
                    >
                      <UploadIcon/>
                      {/* <img src={Refresh} alt='update' /> */}
                    </button>
                    {/* <button onClick={onImageRemoveAll}>Remove</button> */}
                  </div>
                </div>
              ))}
            </div>
          )}
        </ImageUploading>
      </div>
    );
  }
}
