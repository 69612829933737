import React, { Component } from "react";
import axios from "axios";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import PaginationPage from "../../../../components/pagination/Pagination";

class MeetingsData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ideas: [],
      loading: true,
      pageLimit: 10,
      currentPage: 1,
      totalPage: 0,
    };
  }

  async componentDidMount() {
    await this.getItems(this.state.currentPage, this.state.pageLimit);
  }

  async pagination(page) {
    await this.getItems(page, this.state.pageLimit);
  }

  async getItems(page, limit) {
    const res = await axios.get(
      process.env.REACT_APP_API_BASE_URL+"/ideas/" + page + "/" + limit
    );
    this.setState({
      ideas: res.data.ideas,
      loading: false,
      totalPage: Math.ceil(res.totalItems / this.state.pageLimit),
      currentPage: page,
    });
  }

  render() {
    var ideas_HTMLTABLE = "";
    if (this.state.loading) {
      ideas_HTMLTABLE = (
        <tr>
          <td colSpan='6'>
            <h2>Loading...</h2>
          </td>
        </tr>
      );
    } else {
      ideas_HTMLTABLE = this.state.ideas.map((item) => {
        return (
          <tr key={item.id}>
            <td>{item.id}</td>
            <td>{item.idea_name}</td>
            <td>20</td>
            <td>90</td>
            <td>pending</td>
            <td> Edit</td>
            <td>Delete</td>
          </tr>
        );
      });
    }
    return (
      <>
        <div className='table-wrapper table-wrapper_tabs'>
          <div className='table-block'>
            <div className='table-contents table-contents_tabs'>
              <table id='db-table'>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Profile Type</th>
                    <th>Subscribed Date</th>
                    <th>Expiry Date</th>
                    <th>Remaining Days</th>
                    <th colSpan='2' style={{ textAlign: "center" }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>ID</td>
                    <td>Screening Name</td>
                    <td>Views</td>
                    <td>Access</td>
                    <td>Status</td>
                    <td>Status</td>
                    <td style={{ textAlign: "center" }}>
                      <RemoveRedEyeOutlinedIcon />
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <DeleteForeverOutlinedIcon color='red' />
                    </td>
                  </tr>
                  <tr>
                    <td>ID</td>
                    <td>Idea Name</td>
                    <td>Views</td>
                    <td>Access</td>
                    <td>Status</td>
                    <td>Status</td>
                    <td style={{ textAlign: "center" }}>
                      <RemoveRedEyeOutlinedIcon />
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <DeleteForeverOutlinedIcon color='red' />
                    </td>
                  </tr>
                  {ideas_HTMLTABLE}
                </tbody>
              </table>
            </div>
            <div className='pagination'>
              <PaginationPage
                totalPage={this.state.totalPage}
                currentPage={this.state.currentPage}
                changePage={this.pagination.bind(this)}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default MeetingsData;
