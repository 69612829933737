import { DialogContent } from "@mui/material";
import { format, parse } from "date-fns/esm";
import React from "react";
import PDF from "../../../../assets/img/mypdf.png";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../../components/modal/BootstrapModal";

function ViewAgreement(props) {
  return (
    <div>
      <BootstrapDialog
        open={props.showTripartite}
        onClose={props.handleCloseTripartite}
        style={{ width: "110%" }}
      >
        <BootstrapDialogTitle onClose={props.handleCloseTripartite}>
            View Agreements
        </BootstrapDialogTitle>
        <DialogContent>
          <div className='scheduler-body'>
            <div className='user-detail'>
              <div className='p_d__m_remarks'>
                <div className='profile_details'>
                  <hr />
                  <table
                    id='db-table'
                    className='a_r'
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th>SN</th>
                        <th>Description</th>
                        <th>Attached Documents</th>
                        <th> Date & Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.detail &&
                        props.detail.meeting_descriptions &&
                        props.detail.meeting_descriptions.map((sub, index) => (
                          <tr key={index}>
                            <>
                              <td style={{ width: "20px" }}>{index + 1}</td>
                              <td style={{ width: "250px", overflow: "auto" }}>
                                <span style={{ height: "20px" }}>
                                  {sub.description}
                                </span>
                              </td>

                              <td style={{ width: "209px" }}>
                                <div>
                                  <span>
                                    <img src={PDF} alt='pdf' width='25' />
                                  </span>
                                  <span>
                                    <img src={PDF} alt='pdf' width='25' />
                                  </span>
                                </div>
                              </td>
                              <td>
                                {format(
                                  parse(
                                    sub.updated_at,
                                    "YYYY-MM-DD H:m:s",
                                    new Date()
                                  ),
                                  "MMM do YYYY, H:mm:ss a"
                                )}
                              </td>
                            </>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <br />
              </div>
            </div>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

export default ViewAgreement;
