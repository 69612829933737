import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DateTimePicker from "@mui/lab/DateTimePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React from "react";
// import {Button, Modal} from "react-bootstrap";
import {SendScheduledDateService} from "../../../../services/AdminServices";
import "./Scheduler.css";

function SchedulerModal(props) {
  const [value, setValue] = React.useState(new Date());

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const sendScheduledDate = async () => {
    const res = await SendScheduledDateService({
      id: props.detail.id,
      date: value,
    });
    if (res.status === "success") {
      props.handleCloseTripartite();
    }
  };

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Dialog
          id='scheduler'
          open={props.showTripartite}
          onClose={props.handleCloseTripartite}
        >
          <DialogTitle>Schedule Tripartite Meeting</DialogTitle>
          <DialogContent>
            <div className='scheduler-body'>
              <div className='user-detail'>
                <Grid
                  container
                  rowSpacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
                  columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
                >
                  <Grid item sm={6} xs={12}>
                    <form>
                      <div className='scheduler-form'>
                        <div className='form-header'>
                          <h4>Idea Holder</h4>
                        </div>
                        <div className='scheduler-form-item'>
                          <TextField
                            required
                            value={
                              props.detail.idea_holder &&
                            props.detail.idea_holder.name
                            }
                            id='outlined-required'
                            label='Idea-Holder Name'
                          />
                        </div>

                        <div className='scheduler-form-item'>
                          <TextField
                            required
                            value={props.detail.idea && props.detail.idea.title}
                            id='outlined-required'
                            label='Idea Name'
                          />
                        </div>
                        <div className='scheduler-form-item'>
                          <TextField
                            required
                            value={
                              props.detail.idea && props.detail.idea.location
                            }
                            id='outlined-required'
                            label='Location'
                          />
                        </div>
                        <div className='scheduler-form-item'>
                          <TextField
                            required
                            // value={
                            //   props.detail.investor &&
                            //   props.detail.investor.phone
                            // }
                            value='985199283'
                            id='outlined-required'
                            label='Contact'
                          />
                        </div>
                      </div>
                    </form>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <form>
                      <div className='scheduler-form'>
                        <div className='form-header'>
                          <h4>Investor</h4>
                        </div>
                        <div className='scheduler-form-item'>
                          <TextField
                            required
                            value={
                              props.detail.investor && props.detail.investor.name
                            }
                            id='outlined-required'
                            label='Investor Name'
                          />
                        </div>

                        <div className='scheduler-form-item'>
                          <TextField
                            required
                            value={
                              props.detail.investor &&
                            props.detail.investor.investor_detail
                              .max_investement_amount
                            }
                            id='outlined-required'
                            label='Investment'
                          />
                        </div>
                        <div className='scheduler-form-item'>
                          <TextField
                            required
                            value={
                              props.detail.investor &&
                            props.detail.investor.investor_detail.area
                            }
                            id='outlined-required'
                            label='Location'
                          />
                        </div>
                        <div className='scheduler-form-item'>
                          <TextField
                            required
                            value={
                              props.detail.investor &&
                            props.detail.investor.investor_detail.phone
                            }
                            id='outlined-required'
                            label='Contact'
                          />
                        </div>
                      </div>
                    </form>
                  </Grid>
                </Grid>
                <div className='scheduler-form-item'>
                  <DateTimePicker
                    label='Date & Time picker'
                    value={value}
                    onChange={handleChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button variant='primary' onClick={() => sendScheduledDate()}>
            Proceed
            </Button>
          </DialogActions>
        </Dialog>
      </LocalizationProvider>
    </div>
  );
}

export default SchedulerModal;
