import React from "react";
import axios from "axios";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import PaginationPage from "../../../../components/pagination/Pagination";

class Subscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ideas: [],
      loading: true,
      pageSize: 10,
      currentPage: 1,
      totalPage: 0,
    };
  }
  async componentDidMount() {}
  async getItems() {
    const res = await axios.get(process.env.REACT_APP_API_BASE_URL+"/api/ideas");
    console.log(res);
    // this.setState({
    //   ideas: res.data.ideas,
    //   loading: false,
    //   pageSize:pageSize,
    //   currentPage:page,
    //   totalItems: Math.ceil(res.totalItems/pageSize);
    // });
  }
  render() {
    var ideas_HTMLTABLE = "";
    if (!this.state.loading) {
      ideas_HTMLTABLE = (
        <tr>
          <td colSpan='6'>
            <h2>Loading...</h2>
          </td>
        </tr>
      );
    } else {
      ideas_HTMLTABLE = this.state.ideas.map((item) => {
        return (
          <tr key={item.id}>
            <td>{item.id}</td>
            <td>{item.idea_name}</td>
            <td>20</td>
            <td>90</td>
            <td>pending</td>
            <td> Edit</td>
            <td>Delete</td>
          </tr>
        );
      });
    }
    return (
      <>
        <div className='container-fluid'>
          <div className='table-wrapper'>
            <div className='table-block'>
              <div className='table-header'>
                <span>Subscription</span>
              </div>
              <div className='table-contents'>
                <table id='db-table'>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Profile Type</th>
                      <th>Subscribed Date</th>
                      <th>Expiry Date</th>
                      <th>Remaining Days</th>
                      <th colSpan='2' style={{ textAlign: "center" }}>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>ID</td>
                      <td>Idea Name</td>
                      <td>Views</td>
                      <td>Access</td>
                      <td>Status</td>
                      <td>Status</td>
                      <td style={{ textAlign: "center" }}>
                        <RemoveRedEyeOutlinedIcon />
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <DeleteForeverOutlinedIcon color='red' />
                      </td>
                    </tr>
                    <tr>
                      <td>ID</td>
                      <td>Idea Name</td>
                      <td>Views</td>
                      <td>Access</td>
                      <td>Status</td>
                      <td>Status</td>
                      <td style={{ textAlign: "center" }}>
                        <RemoveRedEyeOutlinedIcon />
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <DeleteForeverOutlinedIcon color='red' />
                      </td>
                    </tr>
                    {ideas_HTMLTABLE}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='pagination'>
              <PaginationPage
                totalPage={this.state.totalPage}
                pageSize={this.state.pageSize}
                currentPage={this.state.currentPage}
                changePage={this.state.agreement}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Subscription;
