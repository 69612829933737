import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { GetIdeaService } from "../../../../services/IdeaHolderServices";

import { Button, TextField } from "@material-ui/core";
import IdeaDetailModal from "../../investor/proposal/IdeaDetailModal";
import GetImageUrl from "../../../../utils/ImageHelper";
import ViewPdf from "../../../../utils/ViewPdf";
import { getImageUrl } from "../../../../services/BasicService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import ReactTagInput from "@pathofdev/react-tag-input";
export default function UserIdeaDetail() {
  const [openFile, setOpenFile] = React.useState(false);
  const [currentFile, setCurrentFile] = React.useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const toggleEdit = () => {
    setOpenEdit(!openEdit);
  };
  const closeFileViewer = () => {
    setOpenFile(false);
  };

  const [idea, setIdea] = useState({
    title: "",
    description: "",
    expected_invested: "",
    own_investment: "",
    location: "",
    investment_type: "",
    errors: [],
    errorCount: 0,
    tag:[],
    category: "",
    status: "",
    user: [
      {
        email: "",
        name: "",
      },
    ],
    remark: "",
  });
  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setIdea({ ...idea, [name]: value });
    console.log(name, value);
  };

  let history = useHistory();

  function handleClick() {
    history.goBack();
  }

  const location = useParams();

  const singleIdea = {
    id: location.id,
  };

  const GetIdeaDetail = async () => {
    let result = await GetIdeaService(singleIdea);
    console.log(result);
    if (result && result.status === "success") {
      setIdea(result.idea);
      console.log("accepted");
    } else {
      console.log("error occurred getting single");
    }
  };

  useEffect(() => {
    GetIdeaDetail();
  }, []);

  return (
    <div className='screening_wrap'>
      <div className='header_main'>
        <div className='back' onClick={handleClick} role='presentation'>
          <ArrowBackIcon />
          <span>Back</span>
        </div>
      </div>

      <div className='personal_detail_wrap'>
        <div className='header'>
          <h3>Idea Details</h3>
          {+idea?.status === 3 || +idea?.status === 5 ? (
            <span className={"table-rejected-btn"}>
              <CancelOutlinedIcon size={"16px"} />
              Rejected
            </span>
          ) : +idea?.status === 4 ? (
            <span className='table-accepted-btn'>
              <CheckCircleOutlineOutlinedIcon size={"16px"} />
              Published
            </span>
          ) : (
            <span className={"table-ongoing-btn"}>
              <HourglassEmptyOutlinedIcon size={"14px"} />
              Screening Ongoing
            </span>
          )}
        </div>
        <div className='business_detail_flex'>
          {+idea?.status === 3 ? (
            <div className={"rejected-msg-block"}>
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <div>
                  <CancelOutlinedIcon size={"30px"} />
                </div>
                <div className={"rejected-msg"}>
                  <span>Your Idea has been rejected.</span>
                  <span>Reason : {idea.remark}</span>
                </div>
              </div>
            </div>
          ) : (
            +idea?.status === 5 && (
              <>
                <div className={"review-msg-block"}>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <WarningAmberOutlinedIcon size={"30px"} />
                    </div>
                    <div className={"rejected-msg"}>
                      <span>
                        Your Idea has been rejected. Please make the required
                        changes and the send the idea for screening again.
                      </span>
                      <span>Reason : {idea.remark}</span>
                    </div>
                  </div>

                  <div>
                    <Link to={"/idea/dashboard/edit_idea/" + idea.id}>
                      <Button color='neutral' variant='contained'>
                        Edit
                      </Button>
                    </Link>
                  </div>
                </div>
              </>
            )
          )}

          <div className='idea-form'>
            <div className='form-body'>
              <span style={{ fontSize: "18px" }}>
                <b>Idea Details</b>
              </span>
              <div className='details custom-mui-input'>
                <div className='row '>
                  <div className='col-sm-12 col-md-6 '>
                    <TextField
                      style={{ width: "100%" }}
                      name='title'
                      label='Idea Name'
                      value={idea.title}
                      onChange={handleInput}
                      variant='outlined'
                      required
                      inputProps={{ readOnly: true }}
                    />
                  </div>
                  <div className='col-sm-12 col-md-6 '>
                    <TextField
                      style={{ width: "100%" }}
                      label='Categories'
                      value={
                        idea.sector
                          ? idea.sector
                            .map((category) => category.name)
                            .join(", ")
                          : ""
                      }
                      variant='outlined'
                      required
                      inputProps={{ readOnly: true }}
                    />
                  </div>
                </div>

                <div className='row '>
                  <div className='col-sm-12 '>
                    <TextField
                      id='outlined-multiline-static'
                      label='Your idea in Brief'
                      multiline
                      rows={6}
                      sx={{ width: "100%" }}
                      variant='outlined'
                      required
                      name={"description"}
                      value={idea.description}
                      onChange={handleInput}
                      inputProps={{ readOnly: true }}
                    />
                  </div>
                </div>
                <div className='row '>
                  <div className='col-sm-12 col-md-6 '>
                    <ReactTagInput
                      tags={idea?.tags?.map(t=>t.tags)??[]}
                      placeholder='Tags'
                      onChange={(newTags) =>
                        setIdea({
                          ...idea,
                          tags: newTags,
                        })
                      }
                    />
                  </div>
                </div>
                <div className='col-sm-12' style={{marginBottom:"20px"}}>
                  <h5 style={{ marginTop: "20px", marginBottom: "0" }}>
                      Cover Image
                  </h5>
                  <div>
                    {idea.cover_image && (
                      <img src={getImageUrl("idea",idea.cover_image)} alt='' />
                    )}
                  </div>
                </div>
                <span style={{ fontSize: "18px" }}>
                  <b>Investment Requirements</b>
                </span>

                <div className='row '>
                  <div className='col-sm-12 col-md-6 '>
                    <TextField
                      style={{ width: "100%" }}
                      name='own_investment'
                      label='Your Investment'
                      value={idea.own_investment}
                      onChange={handleInput}
                      variant='outlined'
                      required
                    />
                  </div>
                  <div className='col-sm-12 col-md-6 '>
                    <TextField
                      style={{ width: "100%" }}
                      name='expected_invested'
                      label='Expected Investment'
                      value={idea.expected_invested}
                      onChange={handleInput}
                      variant='outlined'
                      inputProps={{ readOnly: true }}
                      required
                    />
                  </div>
                </div>

                <div className='row '>
                  <div className='col-sm-12 col-md-6 '>
                    <TextField
                      style={{ width: "100%" }}
                      name='location'
                      label='Your Location'
                      value={idea.location}
                      onChange={handleInput}
                      variant='outlined'
                      required
                      inputProps={{ readOnly: true }}
                    />
                  </div>
                  <div className='col-sm-12 col-md-6 '>
                    {/*<Autocomplete*/}
                    {/*    disablePortal*/}
                    {/*    multiple={"true"}*/}
                    {/*    name="pref_locations"*/}
                    {/*    sx={{ width: "100%" }}*/}
                    {/*    renderInput={(params) => (*/}
                    {/*        <TextField*/}
                    {/*            variant='outlined'*/}
                    {/*            {...params}*/}
                    {/*            label='Preferred Location'*/}
                    {/*        />*/}
                    {/*    )}*/}
                    {/*/>*/}
                  </div>
                </div>
                <div className='row '>
                  <div className='col-sm-12 col-md-6 '>
                    <TextField
                      style={{ width: "100%" }}
                      name='investment_type'
                      label='Investment Type'
                      value={
                        idea.investment_type === 0
                          ? "Equity Shares"
                          : "Preference Shares"
                      }
                      onChange={handleInput}
                      variant='outlined'
                      required
                      inputProps={{ readOnly: true }}
                    />
                  </div>
                  {/*<div className='col-sm-12 col-md-6 '>*/}
                  {/*  <TextField*/}
                  {/*    style={{ width: "100%" }}*/}
                  {/*    name='tags'*/}
                  {/*    label='Idea tags'*/}
                  {/*    value={idea.tags}*/}
                  {/*    onChange={handleInput}*/}
                  {/*    variant='outlined'*/}
                  {/*    value={idea.tags}*/}
                  {/*  />*/}
                  {/*</div>*/}
                </div>
                <hr />

                <div className='row'>
                  <div className='idea-form-parameter col-md-6 col-sm-12'>
                    <label htmlFor='DIP'>Debt Injection Plan</label>
                    <div>
                      {idea.debt_injection_plan_doc_link ? (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a
                          role='presentation'
                          onClick={() => {
                            setOpenFile(true);
                            setCurrentFile(
                              GetImageUrl(
                                "idea",
                                idea.debt_injection_plan_doc_link
                              )
                            );
                          }}
                        >
                          <button className="e-css">Open File</button>
                        </a>						
                      ) : (
                        "No File"
                      )}
                    </div>
                  </div>
                  <div className='idea-form-parameter col-md-6 col-sm-12'>
                    <label htmlFor='feasibility'>Feasibility Study</label>
                    <div>
                      {idea.feasibility_study_doc_link ? (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a
                          role='presentation'
                          onClick={() => {
                            setOpenFile(true);
                            setCurrentFile(
                              GetImageUrl(
                                "idea",
                                idea.feasibility_study_doc_link
                              )
                            );
                          }}
                        >
                          <button className="e-css">Open File</button>
                        </a>
                      ) : (
                        "No file"
                      )}
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='idea-form-parameter col-md-6 col-sm-12'>
                    <label htmlFor='SWOT'>SWOT Analysis</label>
                    <div>
                      {idea.swot_analysis_doc_link ? (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a
                          role='presentation'
                          onClick={() => {
                            setOpenFile(true);
                            setCurrentFile(
                              GetImageUrl("idea", idea.swot_analysis_doc_link)
                            );
                          }}
                        >
                          <button className="e-css">Open File</button>
                        </a>
                      ) : (
                        "No file"
                      )}
                    </div>
                  </div>
                  <div className='idea-form-parameter col-md-6 col-sm-12'>
                    <label htmlFor='financial_projection'>
                      Financial Projection
                    </label>
                    <div>
                      {idea.financial_projection_doc_link ? (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a
                          role='presentation'
                          onClick={() => {
                            setOpenFile(true);
                            setCurrentFile(
                              GetImageUrl(
                                "idea",
                                idea.financial_projection_doc_link
                              )
                            );
                          }}
                        >
                          <button className="e-css">Open File</button>
                        </a>
                      ) : (
                        "No File"
                      )}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='idea-form-parameter col-md-6 col-sm-12'>
                    <label htmlFor='financial_analysis'>
                      Financial Analysis
                    </label>
                    <div>
                      {idea.financial_analysis_doc_link ? (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a
                          role='presentation'
                          onClick={() => {
                            setOpenFile(true);
                            setCurrentFile(
                              GetImageUrl("idea", idea.financial_analysis_doc_link)
                            );
                          }}
                        >
                          <button className="e-css">Open File</button>
                        </a>
                      ) : (
                        "No File"
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {openEdit && (
            <IdeaDetailModal toggleEdit={toggleEdit}></IdeaDetailModal>
          )}
          {openFile && (
            <ViewPdf filename={currentFile} toggleModal={closeFileViewer} />
          )}
        </div>
      </div>
    </div>
  );
}
