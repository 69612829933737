import React from "react";
import {Line} from "react-chartjs-2";
// import "../../../../../dashboard/components/menu/investorDB/home/home.css";

const data = {
  labels: ["1", "2", "3", "4", "5", "6", "7"],
  datasets: [
    {
      label: "Idea Holders",
      data: [12, 19, 3, 5, 2, 3, 30],
      fill: false,
      backgroundColor: "rgb(255, 99, 132)",
      borderColor: "rgba(255, 99, 132, 0.2)",
    },
    {
      label: "Investors ",
      data: [16, 9, 23, 9, 21, 23, 10],
      fill: false,
      backgroundColor: "#92C9FB",
      borderColor: "#92C9FB",
    },
  ],
};

const options = {
  responsive: true,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const LineChart = () => (
  <>
    <div className="header">
      <h1 className="title">Users in 24 Hours</h1>
    </div>
    <Line data={data} options={options}/>
  </>
);

export default LineChart;
