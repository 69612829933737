/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import StarRating from "../../../components/star-rating/StarRating";
import "./IdeaDetail.css";

import PDF from "../../../assets/img/mypdf.png";
import { useSelector } from "react-redux";

import feedImg from "../../../assets/img/service1.jpg";
import AccessRequestModal from "../../../components/modal/AccessRequestModal";
import {
  GetIdeaDetail,
  UserExistedIdeaService,
} from "../../../services/IdeaService";
import {
  GetScheduleFromInvestorService,
  OneTimeInvestorConnectService,
} from "../../../services/InvestorService";
import { Button, CircularProgress } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import AddIcon from "@mui/icons-material/Add";
import PublicIcon from "@mui/icons-material/Public";
import LockIcon from "@mui/icons-material/Lock";
import DownloadIcon from "@mui/icons-material/Download";
import ApiIcon from "@mui/icons-material/Api";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import GetImageUrl from "../../../utils/ImageHelper";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  FavouriteIdeaService,
  FollowUserService,
  LikeIdeaService,
} from "../../../services/BasicService";
const IdeaDetail = () => {
  const [rating, setRating] = useState(false);
  const toggleRating = () => {
    setRating(!rating);
  };
  const [loadingData, setLoadingData] = useState(true);
  const [idea, setIdea] = useState({});
  const userData = useSelector((state) => state.userAuth.details.user);
  const params = useParams();
  const [accessRequestSentData, setAccessRequestSentData] = useState(null);
  const [ideaMeetingData, SetIdeaMeetingData] = useState(null);
  const [openAccessRequestModal, setOpenAccessRequestModal] = useState(false);
  const notify = (message) => {
    toast.info(message, {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  // const hasRequestBeenSent = async () => {
  //   let res = await UserExistedIdeaService({
  //     idea_id: params.id,
  //     user_id: userData,
  //   });

  //   if (res.status === "success") {
  //     if (res.result === "found") {
  //       setIsAccessReqSent(true);
  //       setRequestStatus(res.currentStatus);
  //     }
  //   }
  // };
  // const oneTimeConnect = async () => {
  //   let res = await OneTimeInvestorConnectService({
  //     idea_id: params.id,
  //   });

  //   if (res.status === "success") {
  //     if (res.result === "found") {
  //       setIsConnected(true);
  //       // setRequestStatus(res.currentStatus);
  //     }
  //   }
  // };

  const location = useParams();

  const getIdea = async () => {
    let result = await GetIdeaDetail(params.id);
    if (result && result.status === "success") {
      setAccessRequestSentData(
        result.idea.idea_access_requests?.find(
          (req) => +req.user_id === +userData.id
        )
      );
      SetIdeaMeetingData(result.idea.meetings?.find(meeting=>+meeting.investor_id===+userData.id));
      setIdea(result.idea);
      setLoadingData(false);
    } else {
      console.log("error has occurred");
    }
  };

  const sendScheduleRequest = async () => {
    const request = await GetScheduleFromInvestorService({
      idea_id: idea?.id,
      ideaholder_id: idea?.user.id,
      investor_id: userData.id,
    });
    if(request?.status==="success"){
      SetIdeaMeetingData(request.schedule);
    }
  };

  useEffect(() => {
    getIdea();
  }, [location]);

  const files = [
    { name: "Debt Injection Plan", file: "debt_injection_plan_doc_link" },
    { name: "Feasibility Analysis", file: "financial_analysis_doc_link" },
    { name: "SWOT Analysis", file: "swot_analysis_doc_link" },
    { name: "Financial Projection", file: "financial_projection_doc_link" },
    { name: "Financial Analysis", file: "financial_analysis_doc_link" },
  ];
  const downloadFile = (file) => {
    if (+accessRequestSentData?.access_status===1) {
      window.open(GetImageUrl("idea", file), "_blank");
    } else if(+accessRequestSentData?.access_status===0) {
      notify(
        "You don't have access to download this file. Ask admin for approving your request"
      );
    }else{
      notify(
        "You don't have access to download this file. Send Idea access request"
      );
    }
  };
  const toggleLike = async (idea_id, user_id) => {
    const result = await LikeIdeaService({
      idea_id: idea_id,
      user_id: user_id,
    });
    let userLikes = [...idea?.likes];
    if (result.liked_by) {
      userLikes.push(result.liked_by);
    } else {
      userLikes = idea?.likes.filter((like) => +like.user_id !== +userData.id);
    }
    setIdea({ ...idea, likes: userLikes });
  };

  const toggleFavourite = async (idea_id, user_id) => {
    const result = await FavouriteIdeaService({
      idea_id: idea_id,
      user_id: user_id,
    });
    let userFavourites = [...idea?.favourites];
    if (result.followed_user) {
      userFavourites.push(result.followed_user);
    } else {
      userFavourites = idea?.favourites.filter(
        (like) => +like.user_id !== +userData.id
      );
    }
    setIdea({ ...idea, favourites: userFavourites });
  };
  const toggleFollow = async (followedUserId) => {
    let result = await FollowUserService(followedUserId);
    if (result && result.status === "success") {
      let userFollows = [...idea?.followed_users];
      if (result.followed_user) {
        userFollows.push(result.followed_user);
      } else {
        userFollows = idea?.followed_users.filter(
          (like) => +like.user_id !== +userData.id
        );
      }
      setIdea({ ...idea, followed_users: userFollows });
    }
  };
  return (
    <>
      <div className='detail-container'>
        {loadingData ? (
          <div className={"spinner"}>
            <CircularProgress color='primary' />
          </div>
        ) : (
          <div>
            <div className='idea-detail-head'>
              <div className='idea-detail_top'>
                <div className='title__wrap'>
                  <div className='i_d1'>
                    <Link to='/idea'>
                      <span className='back-btn'>
                        <ArrowBackIosIcon />
                      </span>
                    </Link>
                  </div>
                  <div className='i_d2'>
                    <div className='idea-title'>{idea?.title}</div>
                  </div>
                </div>
                <div className='investor-detail-header-mid'>
                  {idea.followed_users.some(
                    (f) => +f?.user_id === +userData?.id
                  ) || idea.followed ? (
                      <div
                        role='presentation'
                        onClick={() => {
                          toggleFollow(idea.user_id);
                        }}
                        className='follow_investor outer idea'
                      >
                        <span className='f_i_2'>Followed</span>
                      </div>
                    ) : (
                      <div
                        role='presentation'
                        onClick={() => {
                          toggleFollow(idea.user_id);
                        }}
                        className='follow_investor outer idea'
                      >
                        <span className='f_i_1'>
                          <AddIcon />
                        </span>
                        <span className='f_i_2'>Follow</span>
                      </div>
                    )}

                  <div className='like-btn'>
                    {idea?.likes?.some(
                      (likers) => +likers.user_id === +userData?.id
                    ) ? (
                        <>
                          <ThumbUpAltIcon
                            style={{
                              color: "#09f",
                              marginRight: "5px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              toggleLike(idea?.id, userData?.id, "unlike")
                            }
                          />
                        </>
                      ) : (
                        <>
                          <ThumbUpOffAltIcon
                            style={{
                              marginRight: "5px",
                              color: "#09f",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              toggleLike(idea?.id, userData?.id, "like")
                            }
                          />
                        </>
                      )}
                    <span>{idea?.likes?.length}</span>
                  </div>

                  <div className='views-counter' style={{ cursor: "initial" }}>
                    <VisibilityIcon
                      style={{
                        fontSize: "24px",
                        marginRight: "5px",
                      }}
                    />
                    <span style={{ fontSize: "1rem" }}>
                      {idea?.total_views ?? 0}
                    </span>
                  </div>
                  <div className='favourite-btn'>
                    {idea?.favourites?.some(
                      (fav) => +fav.user_id === +userData?.id
                    ) ? (
                        <FavoriteIcon
                          style={{
                            color: "#ff5656",
                            marginRight: "5px",
                          }}
                          onClick={() => {
                            toggleFavourite(idea?.id, userData?.id);
                          }}
                        />
                      ) : (
                        <FavoriteBorderIcon
                          style={{
                            color: "#ff5656",
                            marginRight: "5px",
                          }}
                          onClick={() => {
                            toggleFavourite(idea?.id, userData?.id);
                          }}
                        />
                      )}
                    <span>{idea?.favourites?.length}</span>
                  </div>
                </div>
                {/* {!myIdea ? (
                  <div className='investor_follow_wrapper'>
                    <div className='follow_investor inner idea'>
                      <span className='f_i_1'>
                        <AddIcon />
                      </span>
                      <span className='f_i_2'>Follow</span>
                    </div>
                    <div className='give-rating'>
                      <button onClick={toggleRating}>Give a rating?</button>
                    </div>
                  </div>
                ) : (
                  ""
                )} */}

                {rating && (
                  <div className='rate-box'>
                    <StarRating />
                    <button onClick={toggleRating}>Rate</button>
                  </div>
                )}
              </div>

              <div className='feed__location'>
                <div>
                  <PublicIcon />
                  &nbsp;
                  <span style={{ fontSize: "12px" }}>{idea?.location}</span>
                </div>
                <div className='idea_category'>
                  {idea?.sector?.map((item) => {
                    return <span key={item.id}>{item.name}</span>;
                  })}
                </div>
              </div>
            </div>

            <div className='idea-detail-img'>
              <img
                src={
                  idea?.cover_image
                    ? GetImageUrl("idea", idea?.cover_image)
                    : feedImg
                }
                width='100%'
                height='100%'
                className='feed-image'
                alt='test'
              />
            </div>
            <div className='detail-item detail-item1'>
              <div className='detail-title'>About Us</div>
              <div className='details-text'>{+accessRequestSentData?.access_status===1? idea?.description:(idea?.description?.length>50?idea.description.substr(0,50)+"...":idea?.description)}</div>
            </div>
            <div className='details-blocks'>
              <div id={"item1"} className='detail-item'>
                <div className='location-block'>
                  <div className='detail-title'>Preferred Location</div>
                  <div className='location-pills'>
                    {idea?.districts?.map((location, j) => (
                      <label htmlFor={location.name} key={j}>
                        {location.name}
                      </label>
                    ))}
                  </div>
                </div>
              </div>
              &nbsp;
              <div id={"item2"} className='detail-item'>
                <div className='investment-block'>
                  <div className='detail-title'>Expected Investment</div>
                  <label className='investment-label'>
                    Rs. {idea?.expected_invested}
                  </label>
                </div>
              </div>
              &nbsp;
              <div id={"item3"} className='detail-item'>
                <div className='investment-block'>
                  <div className='detail-title'>Own Investment</div>
                  <label className='investment-label'>
                    Rs. {idea?.own_investment}
                  </label>
                </div>
              </div>
            </div>

            <div className='idea-detail-hidden-box '>
              {files.map(
                (file, index) =>
                  idea &&
                  idea[file.file] && (
                    <div key={index} className='detail-item hidden-item  '>
                      <div className='detail-title'>
                        <span>
                          <img src={PDF} alt='pdf' width='20' />
                          <span>{file.name}</span>
                        </span>
                        <Button
                          variant='contained'
                          color='inherit'
                          type='button'
                          onClick={() => {
                            downloadFile(idea ? idea[file.file] : "");
                          }}
                        >
                          {/* {requestStatus !== 1 && !myIdea ? (
                            <LockIcon />
                          ) : (
                            <DownloadIcon />
                          )} */}
                        </Button>
                      </div>
                    </div>
                  )
              )}
            </div>
            {! +accessRequestSentData?.status && (
              <div className='center idea-detail-footer'>
                {!accessRequestSentData ? (
                  <button
                    onClick={() => {
                      setOpenAccessRequestModal(true);
                    }}
                  >
                    Send Access Request
                  </button>
                ) : +accessRequestSentData.access_status === 1 ? (
                  !ideaMeetingData? (
                    <button
                      style={{ color: "green", width: "20%" }}
                      onClick={sendScheduleRequest}
                    >
                      Connect &nbsp;
                      <ApiIcon />
                    </button>
                  ) : (
                    <div className={"detail-action-response"}>
                      <CheckCircleOutlinedIcon
                        style={{
                          color: "green",
                          fontSize: "18px",
                          marginRight: "5px",
                          marginTop: "2px",
                        }}
                      />
                      <span className='d_text'>
                        Thank you! We will contact you soon. &nbsp;{" "}
                      </span>
                    </div>
                  )
                ) : +accessRequestSentData?.status === -1 ? (
                  <div className={"detail-action-response"}>
                    <CancelOutlinedIcon color={"red"} fontSize={"20px"} />
                    Access Request has been rejected. &nbsp;{" "}
                  </div>
                ) : (
                  <div className={"detail-action-response"}>
                    <CheckCircleOutlinedIcon
                      color={"green"}
                      fontSize={"20px"}
                    />
                    Access Request has been sent. Ask admin for approval.
                  </div>
                )}
              </div>
            )}

            <div className='access-request-modal'>
              {openAccessRequestModal && (
                <AccessRequestModal
                  toggleModal={() => {
                    setOpenAccessRequestModal(false);
                  }}
                  accessReqSent={(accessRequest) => {
                    setAccessRequestSentData(accessRequest);
                  }}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default IdeaDetail;
