import React, {useEffect, useRef, useState} from "react";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MyUploader from "../../../../components/image-upload/FileUpload";
import ImageUpload from "../../../../components/image-upload/ImageUpload";
import Autocomplete from "@mui/material/Autocomplete";
import ProfileCreationSuccess from "../../../investor/form/ProfileCreationSucess";
import {GetDistrictService, GetMunicipalitiesService, GetSectorsService} from "../../../../services/BasicService";
import {EditInvestorProfileService, GetInvestorDetailService} from "../../../../services/InvestorService";
import {useDispatch} from "react-redux";
import {authData} from "../../../../redux/actions";
import {useHistory} from "react-router-dom";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
export default function InvestorEditInterface() {

  const history = useHistory()

  const [investor, setInvestor] = useState({});

  const [investorType] = useState(0);

  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [errors,setErrors]=useState([]);
  const [category, setCategory] = useState([]);
  const [district, setDistrict] = useState([]);

  const [location, setLocation] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => setOpenModal(!openModal);

  const cvRef = useRef();


  const handleDropdownInput = (inp_var, value) => {
    setInvestor({...investor, [inp_var]: value});
  };



  const GetMunicipalities = async () => {
    const result = await GetMunicipalitiesService(investor.muni_search);
    if (result?.status === "success") {
      const muni_list_mapped = result.muni_list.map(function (muni) {
        var info = {
          label: muni.name + ", " + muni.districts.name,
          id: muni.id,
        };
        return info;
      });

      setLocation(muni_list_mapped);
    }
  };

  const getSectors = async () => {
    let result = await GetSectorsService();
    if (result && result.status === "success") {
      setCategory(result.sectors);
      // setLoadingData(false);
    } else {
      console.log("error has occurred category");
    }
  }

  const getDistrict = async () => {
    let result = await GetDistrictService();
    if (result && result.status === "success") {
      setDistrict(result.districtss);
      // setLoadingData(false);
    } else {
      console.log("error has occurred Districts");
    }
  }

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInvestor({...investor, [name]: value});
  };

  const GetInvestorDetail = async () => {
    let result = await GetInvestorDetailService();
    if (result && result.status === "success") {
      console.log("data from sector arrived");
      setInvestor(result.investorData)
    } else {
      console.log("error has occurred");
    }
  }

  function saveImage(pictures) {
    setInvestor({
      ...investor,
      profile_picture: pictures,
    });
  }

  function saveFile(files) {
    setInvestor({
      ...investor,
      upload_file: files,
    });
  }

  const handleSubmit = async () => {

    setInvestor({...investor, errors: []});
    investor.errorcount = 0;
    let data = {};
    if (investor.name == "") {
      investor.errorcount += 1;
      data = {
        ...data,
        name: "Name is required",
      };
      setInvestor({...investor, errors: data});
    }
    if (investor.age == "") {
      investor.errorcount += 1;
      data = {
        ...data,
        age: "Age is Required",
      };
      setInvestor({ ...investor, errors: data });
    }
    if (parseInt(investor.age) <= 5 || parseInt(investor.age) >= 120) {
      console.log(parseInt(investor.age));
      investor.errorcount += 1;
      data = {
        ...data,
        age: "Please Enter Valid Age",
      };
      setInvestor({ ...investor, errors: data });
    }
    if (investor.citizenship == "") {
      investor.errorcount += 1;
      data = {
        ...data,
        citizenship: "Citizenship info is required",
      };
      setInvestor({ ...investor, errors: data });
    }
    if (investor.phone == "") {
      investor.errorcount += 1;
      data = {
        ...data,
        phone: "Phone is required",
      };
      setInvestor({...investor, errors: data});
    }
    if (investor.email == "") {
      investor.errorcount += 1;
      data = {
        ...data,
        email: "Email is Required",
      };
      setInvestor({...investor, errors: data});
    }

    if (investor.gender == "") {
      investor.errorcount += 1;
      data = {
        ...data,
        citizenship: "Gender is required",
      };
      setInvestor({ ...investor, errors: data });
    }

    if (investor.selected_muni_name == "") {
      investor.errorcount += 1;
      data = {
        ...data,
        selected_muni_name: "Municipality is required",
      };
      setInvestor({...investor, errors: data});
    }
    if (investor.area == "") {
      investor.errorcount += 1;
      data = {
        ...data,
        area: "Area is required",
      };
      setInvestor({...investor, errors: data});
    }
    console.log(investor.errorcount);
    if (investor.errorcount >= 1) {
      return false;
    }

    // image

    let fd = new FormData();
    fd.append("id", investor.id);
    fd.append("image", investor.profile_picture[0].file);
    fd.append("name", investor.name);
    fd.append("email", investor.email);
    fd.append("age", investor.age);
    fd.append("title", investor.title);
    fd.append("phone", investor.phone);
    fd.append("gender", investor.gender);
    fd.append("citizenship", investor.citizenship);
    // fd.append(`categories`, investor.categories.toString());
    investor.categories.map((item, index) => {
      fd.append(`categories[${index}]`, JSON.stringify(item));
    })
    fd.append("cv", cvRef.current.filesData[0].rawFile);
    fd.append("selected_muni_name", JSON.stringify(investor.selected_muni_name));
    fd.append("area", investor.area);
    fd.append("temp_selected_muni_name", JSON.stringify(investor.temp_selected_muni_name));
    fd.append("temp_area", investor.temp_area);
    fd.append("investor_detail", investor.investor_detail);
    fd.append("investor_interest", investor.investor_interest);
    fd.append("min_investement_amount", investor.min_investement_amount);
    fd.append("max_investement_amount", investor.max_investement_amount);
    fd.append("remark", investor.remark);


    let result = await EditInvestorProfileService(fd);

    if (result && result.status === "success") {
      // console.log("data from IdeaProfile arrived");
      localStorage.setItem("user-token", result.token);
      dispatch(authData(result));
    } else {
      console.log("error occurred");
    }
  };

  useEffect(() => {
    GetMunicipalities()
    GetInvestorDetail()
    getSectors()
    getDistrict()
  }, [])

  return (
    <>
      <div className='form-wrapper' id='investor-form'>
        <div className='form-header'>
          <div className='header-title'>
            <h2>Edit Your Investor Profile</h2>
          </div>
          {investor.status === 5 ? (
            <div className={"rejected-msg-block"}>
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <div>
                  <CancelOutlinedIcon size={"30px"} />
                </div>
                <div className={"rejected-msg"}>
                  <span>Your Idea has been rejected.</span>
                  <span>Reason : {investor.screen_remark}</span>
                </div>
              </div>
            </div>
          ) : (
            investor.status === 6 && (
              <>
                <div className={"review-msg-block"}>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <WarningAmberOutlinedIcon size={"30px"} />
                    </div>
                    <div className={"rejected-msg"}>
                      <span>
                        Your Idea has been rejected. Please make the required
                        changes and the send the idea for screening again.
                      </span>
                      <span>Reason : {investor.screen_remark}</span>
                    </div>
                  </div>
                </div>
              </>
            )
          )}
        </div>

        <div className='investor-form  '>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
            columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
          >
            <Grid item sm={6} xs={12}></Grid>

            {investorType === 0 ? (
              <>
                <Grid item xs={12}>
                  <h4>Personal Details</h4>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    style={{ width: "100%" }}
                    name='name'
                    label='Full Name'
                    value={investor.name ? investor.name : ""}
                    onChange={handleInput}
                    variant='outlined'
                    required
                    error={investor?.errors?.name}
                    helperText={investor?.errors?.name && "Name is required"}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    style={{ width: "100%" }}
                    name='email'
                    label='Email'
                    value={investor.email ? investor.email : ""}
                    onChange={handleInput}
                    variant='outlined'
                    required
                    error={investor?.errors?.name}
                    helperText={investor?.errors?.name && "Invalid email"}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    style={{ width: "100%" }}
                    name='age'
                    label='Age'
                    value={investor.age ? investor.age : ""}
                    onChange={handleInput}
                    variant='outlined'
                    required
                    error={investor?.errors?.age}
                    helperText={investor?.errors?.age && "Age is required"}
                  />
                </Grid>

                <Grid item sm={6} xs={12} mt={1}>
                  <FormControl fullWidth>
                    <InputLabel id='genderLabel'>Gender</InputLabel>
                    <Select
                      style={{ width: "100%" }}
                      labelId='genderLabel'
                      name={"gender"}
                      value={parseInt(investor.gender)}
                      label='Gender*'
                      onChange={handleInput}
                      variant='outlined'
                      required
                      error={investor?.errors?.gender}
                      helperText={
                        errors?.gender && "Please select gender"
                      }
                    >
                      <MenuItem value={0}>Male</MenuItem>
                      <MenuItem value={1}>Female</MenuItem>
                      <MenuItem value={2}>Others</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    style={{ width: "100%" }}
                    name='title'
                    label='Title/Designation'
                    value={investor.title ? investor.title : ""}
                    onChange={handleInput}
                    variant='outlined'
                    required
                    error={investor?.errors?.title}
                    helperText={
                      errors?.title && "Please select gender"
                    }
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    style={{ width: "100%" }}
                    name='phone'
                    label='Phone'
                    value={investor.phone ? investor.phone : ""}
                    onChange={handleInput}
                    variant='outlined'
                    required
                    error={investor?.errors?.phone}
                    helperText={
                      errors?.phone && "Phone is required"
                    }
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <span>Upload your citizenship</span>
                  <MyUploader myRef={cvRef} saveFile={saveFile} />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <span>Upload Your CV</span>
                  <MyUploader myRef={cvRef} saveFile={saveFile} />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <span className='photo-upload'>Photo</span>
                  <ImageUpload saveImage={saveImage} />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <h4>Company Details</h4>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    style={{ width: "100%" }}
                    name='company_name'
                    label='Company Name'
                    value={investor.name ? investor.name : ""}
                    onChange={handleInput}
                    variant='outlined'
                    required
                    error={investor?.errors?.company_name}
                    helperText={
                      errors?.company_name && "Company Name is required"
                    }
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    style={{ width: "100%" }}
                    name='email'
                    label='Email'
                    value={investor.email ? investor.email : ""}
                    onChange={handleInput}
                    variant='outlined'
                    required
                    error={investor?.errors?.email}
                    helperText={
                      errors?.email && "Email is required"
                    }
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    style={{ width: "100%" }}
                    name='phone'
                    label='Phone'
                    value={investor.phone ? investor.phone : ""}
                    onChange={handleInput}
                    variant='outlined'
                    required
                    error={investor?.errors?.phone}
                    helperText={
                      errors?.phone && "Phone is required"
                    }
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    style={{ width: "100%" }}
                    name='title'
                    label='Title/Designation'
                    value={investor.title ? investor.title : ""}
                    onChange={handleInput}
                    variant='outlined'
                    required
                    error={investor?.errors?.title}
                    helperText={
                      errors?.title && "Title is required"
                    }
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <span>Upload your PAN</span>
                  <MyUploader myRef={cvRef} saveFile={saveFile} />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <span>Upload Your OCR Certificate</span>
                  <MyUploader myRef={cvRef} saveFile={saveFile} />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <span className='photo-upload'>Company Seal</span>
                  <ImageUpload saveImage={saveImage} />
                </Grid>
              </>
            )}

            <Grid item sm={6} xs={12}></Grid>

            <Grid item xs={12}>
              <span>Permanent Location</span>
            </Grid>

            <Grid item sm={6} xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  name='selected_muni_name'
                  value={{
                    label:
                      investor.municipality?.name +
                      ", " +
                      investor.municipality?.districts?.name,
                    id: investor.municipality?.id,
                  }}
                  onChange={(e, value) => {
                    setInvestor({
                      ...investor,
                      selected_muni_name: value,
                    });
                  }}
                  inputValue={investor.selected_muni_name}
                  onInputChange={(event, newInputValue) => {
                    handleDropdownInput("selected_muni_name", newInputValue);
                  }}
                  id='controllable-states-demo'
                  options={location || []}
                  // sx={{ width: 400 }}
                  renderInput={(params) => (
                    <TextField {...params} label='Municipality' fullwidth />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item sm={6} xs={12}>
              <TextField
                style={{ width: "100%" }}
                name='area'
                label='Street'
                value={investor.area ? investor.area : ""}
                onChange={handleInput}
                variant='outlined'
                required
                error={investor?.errors?.area}
                helperText={
                  errors?.area && "Area is required"
                }
              />
            </Grid>

            <Grid item xs={12}>
              <span>Temporary Location</span>
            </Grid>
            <Grid item sm={6} xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  style={{ width: "100%" }}
                  class='drop-down-mui'
                  name='temp_selected_muni_name'
                  value={{
                    label:
                      investor.tempmunicipality?.name +
                      ", " +
                      investor.tempmunicipality?.districts?.name,
                    id: investor.tempmunicipality?.id,
                  }}
                  // onChange={(event, newValue) => {
                  //     handleDropdownInput("temp_selected_muni_id", newValue);
                  // }}
                  onChange={(e, value) => {
                    setInvestor({
                      ...investor,
                      temp_selected_muni_name: value,
                    });
                  }}
                  inputValue={investor.temp_selected_muni_name}
                  onInputChange={(event, newInputValue) => {
                    handleDropdownInput(
                      "temp_selected_muni_name",
                      newInputValue
                    );
                  }}
                  id='controllable-states-demo'
                  options={location || []}
                  getOptionLabel={(option) => option.label}
                  // sx={{ width: 400 }}
                  renderInput={(params) => (
                    <TextField {...params} label='Municipality' />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item sm={6} xs={12}>
              <TextField
                style={{ width: "100%" }}
                name='temp_area'
                label='Street'
                value={investor.temp_area ? investor.temp_area : ""}
                onChange={handleInput}
                variant='outlined'
                required
                error={investor?.errors?.temp_area}
                helperText={
                  errors?.temp_area && "Temp area is required"
                }
                
              />
            </Grid>

            <Grid item xs={12}>
              <h4>Investment Details</h4>
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
                disablePortal
                multiple={"true"}
                name={"categories"}
                options={category}
                onChange={(e, value) => {
                  setInvestor({
                    ...investor,
                    categories: value,
                  });
                }}
                value={investor.sector?.map((item) => {
                  return {
                    label: item.name,
                    id: item.id,
                  };
                })}
                getOptionLabel={(option) => option.name}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField variant='outlined' {...params} label='Category' />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
                disablePortal
                multiple={"true"}
                name='pref_locations'
                options={district}
                // value={{
                //     label: investor.tempmunicipality?.name ,
                //     id: investor.sector?.id,
                // }}
                onChange={(e, value) => {
                  setInvestor({
                    ...investor,
                    pref_locations: value,
                  });
                }}
                getOptionLabel={(option) => option.name}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    variant='outlined'
                    {...params}
                    label='Preferred Location'
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id='outlined-multiline-static'
                label='Investor Detail'
                multiline
                rows={6}
                sx={{ width: "100%" }}
                variant='outlined'
                required
                error={investor?.errors?.investor_detail}
                helperText={
                  errors?.investor_detail && "Investor detail  is required"
                }
                name={"investor_detail"}
                value={investor.investor_detail ? investor.investor_detail : ""}
                onChange={handleInput}
                
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id='outlined-multiline-static'
                label='Interests'
                multiline
                rows={6}
                sx={{ width: "100%" }}
                variant='outlined'
                error={investor?.errors?.investor_interest}
                helperText={
                  errors?.investor_interest && "Investor Interest  is required"
                }
                name={"investor_interest"}
                value={
                  investor.investor_interest ? investor.investor_interest : ""
                }
                onChange={handleInput}
              />
            </Grid>

            <Grid item sm={3} xs={12}>
              <TextField
                style={{ width: "100%" }}
                name='min_investement_amount'
                label='Min Amount'
                value={
                  investor.min_investement_amount
                    ? investor.min_investement_amount
                    : ""
                }
                onChange={handleInput}
                variant='outlined'
                required
                error={investor?.errors?.min_investement_amount}
                helperText={
                  errors?.min_investement_amount && "Min investment  is required"
                }
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <TextField
                style={{ width: "100%" }}
                name='max_investement_amount'
                label='Max Amount'
                value={
                  investor.max_investement_amount
                    ? investor.max_investement_amount
                    : ""
                }
                onChange={handleInput}
                variant='outlined'
                required
                error={investor?.errors?.max_investement_amount}
                helperText={
                  errors?.max_investement_amount && "Max investment  is required"
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id='outlined-multiline-static'
                label='Remarks'
                multiline
                rows={6}
                sx={{ width: "100%" }}
                variant='outlined'
                name={"remark"}
                value={investor.remark ? investor.remark : ""}
                onChange={handleInput}
              />
            </Grid>

            <Grid item xs={12}>
              <div className='form-btn'>
                <Button
                  onClick={() => {
                    handleSubmit();
                    history.push("/investor/dashboard/detail");
                  }}
                >
                  Resend for screening
                </Button>
              </div>
            </Grid>
          </Grid>
          {openModal && (
            <div>
              <ProfileCreationSuccess toggleModal={toggleModal} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}