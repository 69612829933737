export const NotificationData = [
  {
    notification: "Janet wilson sent an idea for screening",
    timeStamp: "25 minutes ago",
  },
  {
    notification: "Ryan Higa Sent Idea for screening",
    timeStamp: "1 hour ago",
  },
  {
    notification: "jack Bond Sent Idea for screening",
    timeStamp: "2 hours ago",
  },

  {
    notification: "jack Bond Sent Idea for screening",
    timeStamp: "2 hours ago",
  },
  {
    notification: "Janet wilson sent an idea for screening",
    timeStamp: "25 minutes ago",
  },
  {
    notification: "Ryan Higa Sent Idea for screening",
    timeStamp: "1 hour ago",
  },
  {
    notification: "jack Bond Sent Idea for screening",
    timeStamp: "2 hours ago",
  },

  {
    notification: "jack Bond Sent Idea for screening",
    timeStamp: "2 hours ago",
  },
];
