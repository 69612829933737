const APIEndPoints = {
  login: "login",
  register: "register",
  logout: "logout",
  ResetPassword: "reset-password",
  ForgotPassword: "forgot-password",
  GetMunicipalities: "get-municipalities",
  GetSectors: "get-sectors",
  GetDistricts: "get-districts",


  createIdeaProfile: "ideaholder/create-idea-profile",
  getIdeaHolderDetail: "ideaholder/get-idea-holder-detail",
  getUserIdeas: "ideaholder/get-user-ideas",
  getUserIdea: "ideaholder/get-idea",

  getScreeningStatus: "get-screening-status",
  getProposalStatus: "get-proposal-status",
  getAccessStatus: "get-access-status",

  sendProposal: "ideaholder/send-proposal",

  ideas: "ideas",
  idea: "idea",
  updateViewCount: "update-view-count",
  topIdeas: "get-top-ideas",
  likeIdea: "like-idea",
  favouriteIdea: "favourite-idea",
  likeInvestor: "like-investor",
  favouriteInvestor: "favourite-investor",
  investors: "investors",
  investor: "investor",
  createInvestorProfile: "investors/create-investor-profile",
  editInvestorProfile: "investors/edit-investor-profile",
  getInvestorDetail: "investors/get-investor-detail",
  getFeedInvestorDetail: "get-detail",

  getProposal: "get-proposal",
  proposalAction: "proposal-action",
  hasSentProposal: "has-sent-proposal",

  accessDataTableInvestor: "access-data",

  getUser: "get-user",
  editUser: "edit-user",
  addUser: "add-user",
  UpdateUser: "update-user",
  deleteUser: "delete",

  getSingleIdea: "get-single-idea",
  createScreeningIdea: "create-screening-idea",
  editScreeningIdea: "edit-screening-idea",
  getScreeningIdea: "get-screening-idea",
  getOngoingScreeningIdea: "get-ongoing-screening-idea",
  getRejectedScreeningIdea: "get-rejected-screening-idea",
  getApprovedScreeningIdea: "get-approved-screening-idea",

  getInvestors: "get-investors",
  investorScreenAction: "investor-screen-action",
  getSingleInvestor: "get-single-investor",

  accessIdeaRequest: "idea-access-request",
  existedUserAccess: "idea-existed-user-request",
  accessedDataTable: "accessed-data",
  accessDataTable: "access-data",
  acceptOrReject: "accept-or-reject-status",

  // updateIdeaStatus: "update-idea-status",
  screenIdeaStatus: "screen-idea-status",
  rejectIdeaStatus: "reject-idea-status",
  approveIdeaStatus: "approve-idea-status",
  deleteIdea: "delete-idea",
  poststatus: "post-status",
  schedule_data: "investors/schedule-data",
  meetingschedulegetdata: "get-data-afterconnect",
  existedinvestormeeting: "investor-existed-user-request",
  sendscheduleddate: "send-scheduled-date",
  sendscheduledesc: "send-scheduled-desc",

  getCategory: "category",
  addCategory: "category/add-category",
  editCategory: "category/edit-category",
  updateCategory: "category/update-category",
  deleteCategory: "category/delete-category",
  verifyUser: "verify-user",

  sendpassword: "change-password",

  agreementdata: "agreement-data",
  uploadTempFile: "upload-temp-file",
  removeFile: "remove-file",
  fbLogin: "facebook-login",
  googleLogin: "google-login",
  followUser: "idea/follow-user",
  checkInvestorUniqueEmail: "check-investor-unique-email",
  checkIdeaHolderUniqueEmail:"ideaholder/check-ideaholder-unique-email",
  showIdea:"idea/"
};
export default APIEndPoints;
