import React, { useEffect, useState } from "react";

import { AccessRequestedDisplay } from "../../../../services/IdeaService.js";

import IdeaAccess from "./IdeaAccess";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Tab } from "@mui/material";
function Screening() {
  const [search, setSearch] = useState(true);
  const showSearch = () => setSearch(!search);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState("0");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const dataAccess = async (k, page, pageSize) => {
    setData([]);
    setLoading(true);
    const res = await AccessRequestedDisplay(k, page, pageSize);
    if (res?.status === "success") {
      setLoading(false);
      setData(res.data);
      setCurrentPage(page);
      setTotalPage(Math.ceil(res.totalItems / pageSize));
      setPageSize(pageSize);
    }
  };
  const filteredData = (d) => {
    setData(d);
  };

  const pagination = async (page) => {
    dataAccess(key, page, pageSize);
  };
  useEffect(() => {
    dataAccess(0, currentPage, pageSize);
  }, []);

  return (
    <div className='container-fluid'>
      <div className='table-wrapper'>
        <div className='table-header'>
          <span>Access Request</span>
          {search ? (
            <SearchIcon onClick={showSearch} style={{ marginRight: "20px", cursor:"pointer"}} />
          ) : (
            <div className='search'>
              <input type='search' placeholder='Search' />
              <CloseIcon style={{marginTop: "5px", position:"absolute", marginLeft:"190px", cursor:"pointer"}} onClick={showSearch} />
            </div>
          )}
        </div>
        <div>
          <TabContext value={key}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={(event,value) => {
                  setKey(value);
                  dataAccess(value, currentPage, pageSize); 
                }}
                aria-label='lab API tabs example'
                variant="scrollable"
              >
                <Tab label='New Access Idea Requests' value='0' />
                <Tab label='Accepted' value='1' />
                <Tab label='Rejected' value='2' />
              </TabList>
            </Box>
            <TabPanel value='0'>
              <IdeaAccess
                renderedData={data}
                loading={loading}
                type={"pending"}
                filteredData={filteredData}
                currentPage={currentPage}
                pageSize={pageSize}
                totalPage={totalPage}
                pagination={pagination}
              />
            </TabPanel>
            <TabPanel value='1'>
              <IdeaAccess
                renderedData={data}
                loading={loading}
                type={"accepted"}
                currentPage={currentPage}
                pageSize={pageSize}
                totalPage={totalPage}
                pagination={pagination}
              />
            </TabPanel>
            <TabPanel value='2'>
              <IdeaAccess
                renderedData={data}
                loading={loading}
                type={"rejected"}
                currentPage={currentPage}
                pageSize={pageSize}
                totalPage={totalPage}
                pagination={pagination}
              />
            </TabPanel>
          </TabContext>
        </div>
      </div>
    </div>
  );
}

export default Screening;
