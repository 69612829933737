import React from "react";
import { Link } from "react-router-dom";

import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PropTypes from "prop-types";
import { CircularProgress } from "@mui/material";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import AddIcon from "@mui/icons-material/Add";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import FacebookOutlined from "@mui/icons-material/FacebookOutlined";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";

import {
  FavouriteInvestorService,
  FollowUserService,
  LikeInvestorService,
} from "../../services/BasicService";
class InvestorTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      heart: false,
      isLiked: false,
      rating: false,
      hidden: false,
      proposal: true,
      followed: false,
      totalLikes: 0,
      totalFavourites: 0,
    };
  }
  UNSAFE_componentWillReceiveProps() {
    if (this.props.investor?.followed_users) {
      this.setState({
        followed: this.props.investor.followed_users.some(
          (user) => +this.props.user_id === +user.user_id
        ),
        heart: this.props.investor?.favourites?.some(
          (user) => +this.props.user_id === +user.user_id
        ),
        isLiked: this.props.investor?.likes?.some(
          (user) => +this.props.user_id === +user.user_id
        ),
        totalLikes: this.props.investor?.likes?.length ?? 0,
        totalFavourites: this.props.investor?.favourites?.length ?? 0,
      });
    }
  }
  async toggleLike() {
    const result = await LikeInvestorService({
      investor_id: this.props.investor.id,
      user_id: this.props.user_id,
    });
    if (result?.status === "success") {
      if (result?.liked_user) {
        this.setState({ isLiked: true, totalLikes: result.total_likes });
      }else{
        this.setState({ isLiked: false, totalLikes:result.total_likes });
      }
    } 
  }

  async toggleFavourite() {
    const result = await FavouriteInvestorService({
      investor_id: this.props.investor.id,
      user_id: this.props.user_id,
    });
    if (result.favourite) {
      this.setState({
        heart: true,
        totalFavourites: result.total_favourites,
      });
    } else {
      this.setState({
        heart: false,
        totalFavourites: result.total_favourites,
      });
    }
  }
  async toggleFollow() {
    const result = await FollowUserService(this.props.investor.user_id);
    if (result && result.status === "success") {
      if (result.followed_user) {
        this.setState({ followed: true });
      } else {
        this.setState({ followed: false });
      }
    }
  }
  render() {
    return (
      <div>
        <>
          {this.props.loadingData ? (
            <div className={"spinner"}>
              <CircularProgress color='primary' />
            </div>
          ) : (
            <div className='investor-detail-container'>
              <div className='investor-detail-header'>
                <div className='investor-detail-header-left'>
                  <Link to='/investor'>
                    <div className='investor-back-btn'>
                      <ArrowBackIosNewOutlinedIcon />
                    </div>
                  </Link>
                  <div className='title_investor'>
                    {this.props.investor.title}
                  </div>
                </div>
                <div className='investor-detail-header-mid'>
                  <div
                    role='presentation'
                    onClick={() => {
                      this.toggleFollow();
                    }}
                  >
                    {this.state.followed ? (
                      <div className='follow_investor outer idea'>
                        <span className='f_i_2'>Followed</span>
                      </div>
                    ) : (
                      <div className='follow_investor outer idea'>
                        <span className='f_i_1'>
                          <AddIcon />
                        </span>
                        <span className='f_i_2'>Follow</span>
                      </div>
                    )}
                  </div>
                  <div className='like-btn'>
                    {!this.state.isLiked ? (
                      <ThumbUpOutlinedIcon
                        style={{
                          fontSize: "1.5rem",
                          marginRight: "5px",
                          color: "#09f",
                        }}
                        onClick={() => {
                          this.toggleLike();
                        }}
                      />
                    ) : (
                      
                      <ThumbUpAltIcon
                        style={{
                          fontSize: "1.5rem",
                          marginRight: "5px",
                          color: "#09f",
                        }}
                        onClick={() => {
                          this.toggleLike();
                        }}
                      />
                    )}
                    <span>{this.state.totalLikes}</span>
                  </div>
                  <div className='views-counter'>
                    <VisibilityIcon
                      style={{
                        fontSize: "1.5rem",
                        marginRight: "5px"
                      }}
                    />
                    <span style={{fontSize: "16px"}}>{this.props.investor?.total_views}</span>
                  </div>
                  <div className='favourite-btn'>
                    {this.state.heart ? (
                      <FavoriteIcon
                        style={{
                          color: "#ff5656",
                          fontSize: "1.5rem",
                          marginRight:"5px"
                        }}
                        onClick={() => {
                          this.toggleFavourite();
                        }}
                      />
                    ) : (
                      <FavoriteBorderOutlinedIcon
                        style={{
                          color: "#ff5656",
                          fontSize: "1.5rem",
                          marginRight: "5px"
                        }}
                        onClick={() => {
                          this.toggleFavourite();
                        }}
                      />
                    )}
                    <span>{this.state.totalFavourites}</span>
                  </div>
                </div>
              </div>
              {this.props.currentProposalStatus != 1 ? (
                <div className='hidden-detail'>
                  <p style={{ fontSize: "20px", margin: "unset" }}>
                    Personal Details
                  </p>
                  &nbsp;
                  <LockOutlinedIcon
                    size='16px'
                    onClick={() => {
                      this.setState({
                        hidden: !this.state.hidden,
                      });
                    }}
                  />
                </div>
              ) : (
                <div className='investor-detail-top'>
                  <h4>Personal Details</h4>
                  <div className='investor-personal-detail'>
                    <div className='investor-detail-left'>
                      <div className='investor-left-top'>
                        <ul className='investor-detail'>
                          <li className='investor-detail-item i_d_items'>
                            <b>{this.props.investor.title}</b>
                          </li>
                          <li
                            className='investor-detail-item'
                            style={{ fontSize: "12px" }}
                          >
                            {this.props.investor.investor_type === 0
                              ? "Individual Investor"
                              : "Institutional Investor"}
                          </li>
                        </ul>
                        <div className='s_v_flex'>
                          <ul className='socials-verify'>
                            <li>
                              <FacebookOutlined color='#0e8df1' />
                            </li>
                            <li>
                              <MailOutlinedIcon />
                            </li>
                            <li>
                              <CallOutlinedIcon />
                            </li>
                          </ul>
                        </div>
                        <div
                          className='investor-type'
                          style={{ fontSize: "12px" }}
                        ></div>
                      </div>
                    </div>
                    <div className='investor-detail-right'>
                      <div className='investor-personal-infos'>
                        <div className='personal-detail-item'>
                          <label htmlFor='name'>Name:&nbsp; </label>
                          <span>{this.props.investor.name}</span>
                        </div>
                        <div className='personal-detail-item'>
                          <label htmlFor='name'>Company Name:&nbsp; </label>
                          <span>{this.props.investor.company}</span>
                        </div>
                        <div className='personal-detail-item'>
                          <label htmlFor='name'>Age:&nbsp; </label>
                          <span>{this.props.investor.age}</span>
                        </div>
                        <div className='personal-detail-item'>
                          <label htmlFor='name'>Gender:&nbsp; </label>
                          <span>
                            {this.props.investor.gender === 1
                              ? "Male"
                              : "Female"}
                          </span>
                        </div>
                        <div className='personal-detail-item'>
                          <label htmlFor='name'>Contacts:&nbsp; </label>
                          <span>{this.props.investor.phone}</span>
                        </div>
                        <div className='personal-detail-item'>
                          <label htmlFor='name'>Citizenship No.:&nbsp; </label>
                          <span>{this.props.investor.citizenship}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <hr className='hr_hr' />
              <div className='investor-detail-row'>
                <h4>Investment Details</h4>
                <div className='investor-description'>
                  <span>Investor Details &nbsp;</span>
                  <p>{this.props.investor.investor_detail}</p>
                </div>
                <div className='investor-interests'>
                  <span>Interests &nbsp;</span>
                  <p>{this.props.investor.investor_interest}</p>
                </div>

                <div className='investor-detail-row investor-detail-blockrow'>
                  <div className='location-list'>
                    <label htmlFor='name'>Preferred Location</label>
                    <div className='location-lists'>
                      {this.props.investor.district?.map((item) => (
                        <span key={item.id}>{item.name}</span>
                      ))}
                    </div>
                  </div>
                  <div className='category-list'>
                    <label htmlFor='name'>Interested Sectors</label>
                    <div className='location-lists'>
                      {this.props.investor.sector?.map((item) => {
                        return <span key={item.id}>{item.name}</span>;
                      })}
                    </div>
                  </div>
                  <div className='investment-size'>
                    <p style={{ fontSize: "12px" }}>Investment Size:</p>
                    <span style={{ fontSize: "12px", fontWeight: "100" }}>
                      <b>
                        Rs. {this.props.investor.min_investement_amount} - Rs.{" "}
                        {this.props.investor.max_investement_amount}
                      </b>
                    </span>
                  </div>
                </div>
              </div>

              {/*<div className='investor-detail-row investment-row'>*/}
              {/*  <div className='download-file'>*/}
              {/*    <Link to='#'>*/}
              {/*      <span className='d_f'>Download CV</span>*/}
              {/*      <span className='d_f_svg'>*/}
              {/*        <BsDownload />*/}
              {/*      </span>*/}
              {/*    </Link>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {this.props.children}
            </div>
          )}
        </>
      </div>
    );
  }
}
InvestorTemplate.propTypes = {
  loadingData: PropTypes.any.isRequired,
  investor: PropTypes.any.isRequired,
  currentProposalStatus: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
};
export default InvestorTemplate;
