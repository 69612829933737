import axios from "axios";

export default class HttpService {
  constructor() {
    this.url = process.env.REACT_APP_API_BASE_URL;
  }
  async postData(item, added_url, tokenId = "user-token") {
    const token = await localStorage.getItem(tokenId);
    const requestOptions = this.requestOptions(token);
    try {
      const response = await axios.post(
        this.url + "/" + added_url,
        item,
        requestOptions
      );

      if (response.status === 200) {
        return response.data;
      }
      return { status: "error", message: "Unable to process your request" };
    } catch (e) {
      // alert("hayayayaya");
      return { status: "error", message: "Unable to process your request" };
    }
  }
  async getData(added_url, tokenId = "user-token") {
    const token = await localStorage.getItem(tokenId);
    const requestOptions = this.requestOptions(token);
    try {
      const response = await axios.get(
        this.url + "/" + added_url,
        requestOptions
      );
      if (response.status === 200) {
        return response.data;
      }
      return { status: "error", message: "Unable to process your request" };
    } catch (e) {
      return { status: "error", message: "Unable to process your request" };
    }
  }

  async fetchData(added_url, tokenId = "user-token") {
    const token = await localStorage.getItem(tokenId);
    const requestOptions = this.requestOptions(token);
    try {
      const response = await axios.get(
        this.url + "/" + added_url,
        requestOptions
      );
      if (response.status === 200) {
        return response.data;
      }
      return { status: "error", message: "Unable to process your request" };
    } catch (e) {
      return { status: "error", message: "Unable to process your request" };
    }
  }

  requestOptions(token) {
    return {
      headers: {
        Authorization: "Bearer " + token,
        // "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
      },
    };
  }
  async uploadFile(imagefile, folder, url) {
    const formData = new FormData();
    formData.append("image", imagefile);
    formData.append("folder", folder);
    const token = await localStorage.getItem("user-token");
    try {
      const response = await axios.post(`${this.url}/${url}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
      });
      if (response.status === 200) {
        return response.data;
      }
      return { status: "error", message: "Unable to process your request" };
    } catch (e) {
      return { status: "error", message: "Unable to process your request" };
    }
  }
}
