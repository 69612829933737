import React from "react";
import { Link } from "react-router-dom";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import {
  DeleteUserService,
  GetUserService,
} from "../../../../services/AdminServices";
import PaginationPage from "../../../../components/pagination/Pagination";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";

class UserDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      loading: true,
      currentPage: 1,
      totalPage: 0,
      pageSize: 10,
    };
    this.pagination = this.pagination.bind(this);
    this.getItems = this.getItems.bind(this);
  }

  async componentDidMount() {
    this.getItems(this.state.currentPage, this.state.pageSize);
  }
  async getItems(page, pageSize) {
    let result = await GetUserService(page, pageSize);
    if (result && result.status === "success") {
      this.setState({
        users: result.users,
        loading: false,
        currentPage: page,
        totalPage: Math.ceil(result.totalItems / pageSize),
        pageSize: pageSize,
      });
    } else {
      console.log("error occurred");
    }
  }
  pagination(page) {
    this.getItems(page, this.state.pageSize);
  }
  async deleteUser(id) {
    const del = await DeleteUserService(id);
    if (del.status == "success") {
      let filteredData = this.state.users.filter((user) => {
        return user.id !== id; // jojo xa tyo id bahek sab lai include gareko
      });
      this.setState({
        users: filteredData,
      });
      toast.info("User Deleted Successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  render() {
    var users_HTMLTABLE = "";
    if (this.state.loading) {
      users_HTMLTABLE = (
        <tr>
          <td colSpan='6'>
            <CircularProgress />
          </td>
        </tr>
      );
    } else {
      users_HTMLTABLE = this.state.users.map((item, key) => {
        return (
          <tr key={item.id}>
            <td>{key + 1}</td>
            <td>{item.name}</td>
            {/* <td>{item.roles[0] && item.roles[0].name}</td> */}
            <td>
              {item.roles &&
                item.roles.map((d, i) => (
                  <span key={i}>
                    {i > 0 && " , "}
                    {d.name}
                  </span>
                ))}
            </td>
            <td>{item.email}</td>
            <td>{item.phone}</td>

            <td style={{ textAlign: "center" }}>
              {/* <Link to={"/dashboard/edituser/" + item.id}>
                <RemoveRedEyeOutlinedIcon />
              </Link> */}
              <Link to={"/dashboard/user-detail/" + item.id}>
                <RemoveRedEyeOutlinedIcon />
              </Link>
            </td>

            <td style={{ textAlign: "center" }}>
              <button onClick={() => this.deleteUser(item.id)}>
                <DeleteForeverOutlinedIcon color='red' style={{ cursor: "pointer" }} />
              </button>
            </td>
          </tr>
        );
      });
    }
    return (
      <>
        <div className='container-fluid'>
          <div className='table-wrapper'>
            <div className='table-block'>
              <div className='table-header'>
                <span>User Details</span>
                <span>
                  <Link to={"/dashboard/adduser"}>
                    <button className='btn'>Add User</button>
                  </Link>
                </span>
              </div>
              <div
                className='table-contents'
                style={{ height: "73vh", overflowY: "auto" }}
              >
                <table id='db-table'>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Email</th>
                      <th>Phone</th>

                      <th colSpan='2' style={{ textAlign: "center" }}>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>{users_HTMLTABLE}</tbody>
                </table>
              </div>
            </div>
            <div className='pagination'>
              <PaginationPage
                totalPage={this.state.totalPage}
                pageSize={this.state.pageSize}
                currentPage={this.state.currentPage}
                changePage={this.pagination}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default UserDetails;
