import { CircularProgress } from "@mui/material";
import { format, parseISO } from "date-fns";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PaginationPage from "../../../../components/pagination/Pagination";
import {
  GetScreeningIdeaService,
  ScreenIdeaStatus,
} from "../../../../services/AdminServices";
import PlayCircleFilledOutlinedIcon from "@mui/icons-material/PlayCircleFilledOutlined";
import RemoveRedEyeOutlined from "@mui/icons-material/RemoveRedEyeOutlined";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import { withRouter } from "react-router-dom";

class NewScreeningData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screening_request: [
        {
          title: "",
          description: "",
          location: "",
          expected_invested: "",
          total_like: "",
          total_views: "",
          investment_type: "",
          own_investment: "",
          created_at: "",
        },
      ],
      loading: true,
      data: "",
      currentPage: 1,
      totalPage: 0,
      pageLimit: 10,
    };
  }

  async componentDidMount() {
    await this.pagination(this.state.currentPage, this.state.pageLimit);
  }

  async getItems(page, limit) {
    let result = await GetScreeningIdeaService(page, limit);
    if (result && result.status === "success") {
      console.log("ideas from user arrived");
      this.setState({
        screening_request: result.screening_request,
        loading: false,
        pageLimit: limit,
        currentPage: page,
        totalPage: Math.ceil(result.totalItems / limit),
      });
      console.log(this.state.screening_request, "admin");
    } else {
      console.log("error has occurred");
    }
  }

  async pagination(page) {
    await this.getItems(page, this.state.pageLimit);
  }

  async screenIdea(id) {
    // notify();
    this.props.history?.push("/dashboard/screeningpage/" + id);
    this.state.screening_request.status = 2;
    const screenIdea = {
      id: id,
      status: 2,
    };
    let result = await ScreenIdeaStatus(screenIdea);
    if (result && result.status === "success") {
      // setChange(false);
      // this.props.history.push("/dashboard/screeningpage/" + id)
    } else {
      console.log("error occurred updating screening ideas");
    }
    console.log(this.state.screening_request, "from detail");
  }

  render() {
    var ideas_HTMLTABLE = "";
    if (this.state.loading) {
      ideas_HTMLTABLE = (
        <tr>
          <td colSpan='7' style={{ textAlign: "center" }}>
            <CircularProgress />
          </td>
        </tr>
      );
    } else {
      ideas_HTMLTABLE = this.state.screening_request?.map((item, index) => {
        return (
          <tr key={item.id}>
            <td>{index + 1}</td>
            <td
              style={{
                fontWeight: item.status == 0 && "bolder",
              }}
            >
              {item.user.name}
            </td>
            <td
              style={{
                fontWeight: item.status == 0 && "bolder",
              }}
            >
              {item.title}
            </td>
            <td
              style={{
                fontWeight: item.status == 0 && "bolder",
              }}
            >
              {format(
                parseISO(item.created_at, "yyyy-MM-dd H:m:s", new Date()),
                "MMM do, yyyy"
              )}
            </td>
            <td
              style={{
                fontWeight: item.status == 0 && "bolder",
              }}
            >
              Rs. {item.expected_invested}
            </td>
            <td
              style={{
                fontWeight: item.status == 0 && "bolder",
              }}
            >
              {" "}
              {item.location}
            </td>
            <td style={{ textAlign: "center" }}>
              <Link to={"/dashboard/screeningpage/" + item.id}>
                <RemoveRedEyeOutlined />
              </Link>
            </td>
            <td style={{ textAlign: "center", width: "132px" }}>
              {/*<Link to={"/dashboard/screeningpage/" + item.id}>*/}
              <button
                onClick={() => this.screenIdea(item.id)}
                className={"table-btn"}
              >
                <PlayCircleFilledOutlinedIcon />
                Start Screening
              </button>
              {/*</Link>*/}
            </td>
          </tr>
        );
      });
    }
    return (
      <>
        <div
          className='table-contents'
          style={{ height: "69vh", overflowY: "auto" }}
        >
          <table id='db-table'>
            <thead>
              <tr>
                <th>SN</th>
                <th>Idea Holder</th>
                <th>Idea</th>
                <th> Date</th>
                <th>Investment</th>
                <th>Location</th>
                <th colSpan='2' style={{ textAlign: "center" }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {!this.props.loading ? (
                this.state.screening_request?.length === 0 ? (
                  <tr>
                    <td colSpan='7'>
                      <div className='no_data_found'>
                        <FileOpenOutlinedIcon size='50px' />
                        <h6>No data Available</h6>
                      </div>
                    </td>
                  </tr>
                ) : (
                  ideas_HTMLTABLE
                )
              ) : (
                <tr>
                  <td colSpan='7' style={{ textAlign: "center" }}>
                    <CircularProgress />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className='pagination'>
          <PaginationPage
            totalPage={this.state.totalPage}
            currentPage={this.state.currentPage}
            changePage={this.pagination.bind(this)}
          />
        </div>
      </>
    );
  }
}

export default withRouter(NewScreeningData);