import React, { useEffect, useState } from "react";
import "./ProfileDetail.css";
import profile1 from "../../assets/img/profile2.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import PasswordModal from "../modal/ChangePasswordModal";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

function Profile() {
  const [dropdown, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const user = useSelector((state) => state.userAuth.isLoggedIn);
  const userData = useSelector((state) => state.userAuth);
  const [profile, SetProfile] = useState({
    name: "",
    email: "",
    investor: false,
    ideaholder: false,
    investorandideaholder: false,
  });
  useEffect(() => {
    const loadData = async () => {
      // const localdata = JSON.parse(localStorage.getItem("state"));
      let name = userData?.details?.user?.name;
      let email = userData?.details?.user?.email;
      // let roles = []
      // userData?.details.length>0 && userData.details?.user.roles?.map ( (key, index) => {
      //   roles.push(key.slug);
      // });
      let data = {
        ...profile,
        name,
        email,
        investor: userData?.isinvestor,
        ideaholder: userData?.isideaholder,
        investorandideaholder: userData?.isinvestor && userData?.isideaholder,
      };
      // console.log(data);
      SetProfile(data);
    };
    loadData();
  }, [userData]);

  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => {
    setOpenModal(!openModal);
  };
  return (
    <>
      {user ? (
        <div className='profile-detail'>
          <div className='profile-head'>
            {!profile1 ? (
              <img
                alt=''
                src={profile1}
                width='90px'
                height='90px'
                className='profile-img'
              />
            ) : (
              <div className='user__firstletter large__icon'>
                {profile.name.split("")[0]}
              </div>
            )}
          </div>
          <div className='profile-body'>
            <div className='profile-userDetails'>
              <div className='profile-name'>{profile.name}</div>
              <div className='profile-email'>{profile.email}</div>
              <div className='profile-subscription'>Free Trial</div>
            </div>

            <div className='profile-stats'>
              <div className='stats-item'>
                <div className='stats-count'>2</div>
                <div className='stats-label'>Ideas</div>
              </div>
              <div className='stats-item'>
                <div className='stats-count'>20</div>
                <div className='stats-label'>Access</div>
              </div>
              <div className='stats-item'>
                <div className='stats-count'>10</div>
                <div className='stats-label'>Proposals</div>
              </div>
            </div>
            <div className='add-profile add_profile' id={"profile"}>
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                }}
                component='nav'
                aria-labelledby='nested-list-subheader'
              >
                <ListItemButton onClick={toggle}>
                  <ListItemText
                    primary={
                      profile.investorandideaholder ? (
                        <> Idea/Investor Profiles</>
                      ) : profile.ideaholder ? (
                        <> Idea Profile</>
                      ) : profile.investor ? (
                        <> Investor Profile</>
                      ) : (
                        <> Profiles</>
                      )
                    }
                  />
                  {dropdown ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={dropdown} timeout='auto' unmountOnExit>
                  <List component='div' disablePadding>
                    {profile.investorandideaholder ? (
                      <>
                        <Link to='/idea/dashboard'>
                          <div className='my_profile_h3'>
                            View Idea Dashboard{" "}
                          </div>
                        </Link>

                        <Link to='/investor/dashboard'>
                          <div className='my_profile_h3'>
                            View Investor Dashboard{" "}
                          </div>
                        </Link>
                      </>
                    ) : profile.investor ? (
                      <>
                        <Link to='/idea/idea-form'>
                          <div className='my_profile_h3'>Add Idea Profile</div>
                        </Link>

                        <Link to='/investor/dashboard'>
                          <div className='my_profile_h3'>
                            View Investor Profile{" "}
                          </div>
                        </Link>
                      </>
                    ) : profile.ideaholder ? (
                      <>
                        <Link to='/idea/dashboard'>
                          <div className='my_profile_h3'>
                            View Idea Dashboard{" "}
                          </div>
                        </Link>
                        <Link to='/investor/create-profile'>
                          <div className='my_profile_h3'>
                            Add Investor Profile{" "}
                          </div>
                        </Link>
                      </>
                    ) : (
                      <>
                        <Link to='/idea/idea-form'>
                          <div className='my_profile_h3'>Add Idea Profile</div>
                        </Link>
                        <Link to='/investor/create-profile'>
                          <div className='my_profile_h3'>
                            Add Investor Profile{" "}
                          </div>
                        </Link>
                      </>
                    )}
                  </List>
                </Collapse>
              </List>
            </div>
          </div>
        </div>
      ) : (
        <div className='profile-guest'>
          <div className='profile-body'>
            <div className='profile-body-upper'>
              <h4>Not a Member Yet?</h4>
              <p>Join Spill Your Ideas</p>
              <Link to='/signup'>
                {" "}
                <button className='signup-btn'>Sign Up</button>
              </Link>
            </div>
            <p>
              Already a Member?{" "}
              <Link className='link1' to='/login'>
                Login
              </Link>
            </p>
          </div>
        </div>
      )}
      {openModal && <PasswordModal toggleModal={toggleModal} />}
    </>
  );
}

export default Profile;
