import React from "react";
import { Switch, Route } from "react-router-dom";
import InvestorDBHome from "../modules/dashboard/investor/home/InvestorDBHome";
import InvestorEditInterface from "../modules/dashboard/investor/profile/InvestorEditInterface";
import ProposalTabs from "../modules/dashboard/investor/proposal/ProposalTabs";
import InvestorProfileDetail from "../modules/dashboard/investor/profile/InvestorProfileDetail";
import AccessTabInvestor from "../modules/dashboard/investor/access-request/AccessTabInvestor";
import Status from "../modules/dashboard/admin/status/Status";
import Investor from "../modules/dashboard/investor/Investor";
import InvestorPage from "../modules/investor/pages/Investor";
import InvestorFeed from "../modules/investor/feed/InvestorFeed";
import InvestorForm from "../modules/investor/form/InvestorForm";

import InvestorDetailPage from "../modules/investor/details/InvestorDetailPage";
import { InvestorGuard } from "./Guard";
import Layout from "../modules/common/layouts/Layout";
const InvestorRoutes = () => (
  <>
    <Switch>
      
      <InvestorGuard
        path='/investor/dashboard/'
        component={()=>{
          return (<Investor>
            <Switch>
              {/* //investor-dashboard routes here */}
              <Route path='/investor/dashboard' exact component={InvestorDBHome} />

              <Route
                path='/investor/dashboard/detail'
                exact
                component={InvestorProfileDetail}
              />
              <Route
                path='/investor/dashboard/edit-investor/:id'
                exact
                component={InvestorEditInterface}
              />
              <Route
                path='/investor/dashboard/access-request'
                exact
                component={AccessTabInvestor}
              />

              <Route
                path='/investor/dashboard/proposal'
                exact
                component={ProposalTabs}
              />
              <Route path='/investor/dashboard/schedule' exact component={Status} />
              <Route path='/investor/dashboard/agreement' exact component={Status} />
              <Route path='/investor/dashboard/subscription' exact component={Status} />
            </Switch>
          </Investor>
          )
        }}/>
      <Layout>
        <InvestorPage>
          <Switch>
            <Route path='/investor' exact component={InvestorFeed} />
            <Route
              path='/investor/create-profile'
              exact
              component={InvestorForm}
            />
            <Route
              path='/investor/detail/:id'
              exact
              component={InvestorDetailPage}
            />
            <InvestorFeed />
          </Switch>
        </InvestorPage>
      </Layout>
    </Switch>

  </>

);
export default InvestorRoutes;
