import HttpService from "./HttpService";
import APIEndPoints from "../constants/APIEndPoints";
const http = new HttpService();
export const GetIdeaService = async (page = 1, limit = 10) => {
  return await http.fetchData(APIEndPoints.ideas + "/" + page + "/" + limit);
};
export const GetTopIdeaService = async () => {
  return await http.fetchData(APIEndPoints.topIdeas);
};
export const UserExistedIdeaService = async (credentials) => {
  
  return await http.postData(
    credentials,
    "idea/" + APIEndPoints.existedUserAccess
  );
};
export const AccessRequestedDisplay = async (id,page,pageSize) => {
  
  return await http.getData(
    `idea/${APIEndPoints.accessedDataTable}/${id}/${page}/${pageSize}`
  );
};

export const AcceptOrRejectAccess = async (credentials) => {
  
  return await http.postData(
    credentials,
    "idea/" + APIEndPoints.acceptOrReject
  );
};

export const UpdateViewCountService = async (credentials) => {
  
  return await http.postData(
    credentials,
    APIEndPoints.updateViewCount
  );
};

export const CheckUniqueIdeaHolderEmail = async (data) => {
  return await http.postData(
    data, APIEndPoints.checkIdeaHolderUniqueEmail
  );
};
export const GetIdeaDetail = async (userId)=>{
  return await http.getData(APIEndPoints.showIdea+userId);
}