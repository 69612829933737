import React, { useState } from "react";
import NewScreeningData from "./ScreeningRequestsData";
import OngoingScreeningRequests from "./OngoingScreeningRequests";
import RejectedScreeningRequests from "./RejectedScreeningRequests";
import AcceptedScreeningRequests from "./AcceptedScreeningRequests";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
function Screening() {
  const [search, setSearch] = useState(true);
  const showSearch = () => setSearch(!search);
  const [tabIndex, setTabIndex] = useState("1");
  const handTablChange = (event, index) => {
    setTabIndex(index);
  };
  return (
    <div className='container-fluid'>
      <div className='table-wrapper'>
        <div className='table-header'>
          <span>Screening Requests</span>
          {search ? (
            <SearchIcon onClick={showSearch} style={{ marginRight: "20px", cursor:"pointer" }} />
          ) : (
            <div className='search'>
              <input type='search' placeholder='Search' />
              <CloseIcon style={{marginTop: "5px", position:"absolute", marginLeft:"190px", cursor:"pointer"}} onClick={showSearch} />
            </div>
          )}
        </div>
        <div>
          <TabContext value={tabIndex}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handTablChange}
                aria-label='lab API tabs example'
                variant="scrollable"
              >
                <Tab label='New Screenings' value='1' />
                <Tab label='Ongoing Screenings' value='2' />
                <Tab label='Rejected Screenings' value='3' />
                <Tab label='Accepted Screenings' value='4' />
              </TabList>
            </Box>

            <TabPanel value="1">
              <NewScreeningData />
            </TabPanel>
            <TabPanel value="2">
              <OngoingScreeningRequests />
            </TabPanel>
            <TabPanel value="3">
              <RejectedScreeningRequests />
            </TabPanel>
            <TabPanel value="4">
              <AcceptedScreeningRequests />
            </TabPanel>
          </TabContext>
        </div>
      </div>
    </div>
  );
}

export default Screening;
