import React from "react";
import { Switch, Route } from "react-router-dom";
import DbHome from "../modules/dashboard/admin/home";
import Screening from "../modules/dashboard/admin/screening";
import InvestorTabs from "../modules/dashboard/admin/investors/InvestorTabs";
import InvestorScreeningInterface from "../modules/dashboard/admin/investors/InvestorScreenInterface";

import Access from "../modules/dashboard/admin/access/Access";
import AdminProposal from "../modules/dashboard/admin/proposals/ProposalTabs";
import Users from "../modules/dashboard/admin/user-details";

import MeetingSchedule from "../modules/dashboard/admin/meeting-schedule/";
import Agreements from "../modules/dashboard/admin/agreements/Agreements";
import Scheduler from "../modules/dashboard/admin/meeting-schedule";
import Statistics from "../modules/dashboard/admin/statistics";
import Subscription from "../modules/dashboard/admin/subscription/Subscription";
import Category from "../modules/dashboard/admin/category/Category";
import ScreeningInterface from "../modules/dashboard/admin/screening/ScreeningInterface";
import AddUserForm from "../modules/dashboard/admin/user-details/AddUserForm";
import UserDetail from "../modules/dashboard/admin/user-details/UserDetail";
import EditModal from "../modules/dashboard/admin/user-details/EditModal";
import Status from "../modules/dashboard/admin/status/Status";
import Admin from "../modules/dashboard/admin/Admin";

const AdminRoutes = () => (
  <>
    <Admin>
      <Switch>
        <Route path='/dashboard' exact component={DbHome} />
        <Route path='/dashboard/status' exact component={Status} />
        <Route path='/dashboard/screening' exact component={Screening} />
        <Route
          path='/dashboard/screeningpage/:id'
          exact
          component={ScreeningInterface}
        />
        <Route path='/dashboard/investors' exact component={InvestorTabs} />
        <Route
          path='/dashboard/investor-screen/:id'
          exact
          component={InvestorScreeningInterface}
        />
        <Route path='/dashboard/access' exact component={Access} />
        <Route path='/dashboard/proposals' exact component={AdminProposal} />
        <Route path='/dashboard/user' exact component={Users} />
        <Route path='/dashboard/adduser' exact component={AddUserForm} />
        <Route path='/dashboard/user-detail/:id' exact component={UserDetail} />
        <Route path='/dashboard/edituser/:id' exact component={EditModal} />

        <Route
          path='/dashboard/meeting-schedule'
          exact
          component={MeetingSchedule}
        />
        <Route  path='/dashboard/agreements' exact component={Agreements} />
        <Route path='/dashboard/scheduler' exact component={Scheduler} />
        <Route path='/dashboard/schedule' exact component={MeetingSchedule} />
        <Route path='/dashboard/stats' exact component={Statistics} />
        <Route path='/dashboard/subscription' exact component={Subscription} />
        <Route path='/dashboard/category' exact component={Category} />
      </Switch>
    </Admin>
  </>
);

export default AdminRoutes;
