import HttpService from "./HttpService";
import APIEndPoints from "../constants/APIEndPoints";
const http = new HttpService();

export const GetInvestorService = async () => {
  return await http.fetchData(APIEndPoints.investors);
};
export const AddInvestorProfileService = async (credentials) => {
  return await http.postData(credentials, APIEndPoints.createInvestorProfile);
};
export const EditInvestorProfileService = async (credentials) => {
  return await http.postData(credentials, APIEndPoints.editInvestorProfile);
};
export const GetInvestorDetailService = async () => {
  return await http.getData(APIEndPoints.getInvestorDetail);
};
export const IdeaAccessRequestService = async (credentials) => {
  return await http.postData(
    credentials,
    "investors/" + APIEndPoints.accessIdeaRequest
  );
};
export const GetIService = async (id) => {
  return await http.getData("dashboard/" + APIEndPoints.investor + "/" + id);
};

export const GetProposalService = async (statusCode,page,pageSize) => {
  return await http.getData(
    `investors/${APIEndPoints.getProposal}/${statusCode}/${page}/${pageSize}`
  );
};

export const ProposalActionService = async (credentials) => {
  return await http.postData(
    credentials,
    "investors/" + APIEndPoints.proposalAction
  );
};

export const AccessRequestDisplayInvestor = async (id,page,pageSize) => {
  return await http.getData(
    `investors/${APIEndPoints.accessDataTableInvestor}/${id}/${page}/${pageSize}`
  );
};

export const HasSentProposalService = async (credentials) => {
  return await http.postData(
    credentials,
    "investors/" + APIEndPoints.hasSentProposal
  );
};
export const GetScheduleFromInvestorService = async (credentials) => {
  return await http.postData(credentials, APIEndPoints.schedule_data);
};
export const OneTimeInvestorConnectService = async (credentials) => {
  return await http.postData(
    credentials,
    "investors/" + APIEndPoints.existedinvestormeeting
  );
};
export const CheckUniqueInvestorEmail = async (data) => {
  return await http.postData(
    data,
    "investors/" + APIEndPoints.checkInvestorUniqueEmail
  );
};