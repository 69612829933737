import React from "react";
import "./Layout.css";
import Navigation from "./Navigation";
import Footer from "./Footer";
import MobileNav from "./MobileNav";

function Layout({children}) {
  return (
    <>
      <div className="layout">
        <Navigation/>
        {children}
        <Footer/>
        <MobileNav/>
      </div>
    </>
  );
}

export default Layout;
