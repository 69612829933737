import React, {useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {changePasswordService} from "../../services/BasicService";
import lamp from "../../assets/img/lamp2.png";
import man from "../../assets/img/man2.png";
import {toast} from "react-toastify";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
function ResetPassword() {
  const params = useParams();
  const history = useHistory();

  const [password, setPassword] = useState({
    password: "",
    repassword: "",
    errors: [],
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setPassword({...password, [name]: value});
    console.log(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPassword({...password, errors: []});
    if (password.password !== password.repassword) {
      const data = {
        name: "Password and Confirm password did non match.",
      };
      setPassword({...password, errors: data});
      return false;
    }
    let reset_token = params.resetlink;
    let item = {data: password, resettoken: reset_token};
    let result = await changePasswordService(item);
    if (result && result.status === "success") {
      history.push("/login");
      toast.info(result.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(result.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: "error",
      });
    }
  };

  return (
    <>
      <div className='login-page-container'>
        <div className='login-left'>
          <div>
            <img src={lamp} height='auto' width='170px' className='lamp' alt=""/>
          </div>
          <div>
            <img src={man} height='auto' width='300px' className='man' alt=""/>
          </div>
        </div>

        <div className='login-right'>
          {/*<Link to='/'>*/}
          {/*    <div className='logo-container'>*/}
          {/*        <img*/}
          {/*            src={logo}*/}
          {/*            width='40px'*/}
          {/*            height='40px'*/}
          {/*            className='logo-img'*/}
          {/*        />*/}
          {/*        <span className='logo-txt'>Spill Your Ideas</span>*/}
          {/*    </div>*/}
          {/*</Link>*/}
          <div className='login-form-wrapper'>
            <div className='login-form'>
              <div className='login-head'>
                <span className='loginSubHead'>
                  <button onClick={() => history.goBack()}>
                    <ArrowBackIcon className='mr-2'/>
                  </button>
                  Reset Your Password
                </span>
              </div>
              <div className='login-body'>
                <form onSubmit={handleSubmit}>
                  <div className='login-item'>
                    <span className='login-form-title'>New Password</span>
                    <input
                      type='password'
                      placeholder='password'
                      name='password'
                      // value={password}
                      onChange={handleChange}
                    />
                  </div>
                  <div className='login-item'>
                    <span className='login-form-title'>Confirm Password</span>
                    <input
                      type='password'
                      placeholder='Confirm Password'
                      name='repassword'
                      onChange={handleChange}
                    />
                    {password.errors.name && (
                      <label className='form-error'>
                        {password.errors.name}
                      </label>
                    )}
                  </div>
                  <div className='button'>
                    <input type='submit' value='Reset'/>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
