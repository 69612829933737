import React, { useState } from "react";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PaginationPage from "../../../../components/pagination/Pagination";
import { toast } from "react-toastify";
import { InvestorScreenActionService } from "../../../../services/AdminServices";
import { Link, useHistory } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { parseISO } from "date-fns/esm";
import { format } from "date-fns";

export default function InvestorScreen(props) {
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  const screenAction = async (id, action) => {
    let result = await InvestorScreenActionService({ id, action });
    if (result && result.status === "success") {
      const info = props.data?.filter((d) => {
        return d.id !== id;
      });
      props.filteredData(info);
      toast.info(
        action === 4
          ? "Request has been accepted."
          : action === 5 && "Request has been rejected",
        {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setLoading(false);
    } else {
      console.log("error occurred updating screening ideas");
    }
  };
  return (
    <>
      <div className='container-fluid'>
        <div className='table-wrapper table-wrapper_tabs'>
          <div className='table-block'>
            <div className='table-contents contents_tabs'>
              <table id='db-table'>
                <thead>
                  <tr>
                    <th>SN</th>
                    <th>Investor Name</th>
                    <th>Title</th>
                    <th>Investment</th>
                    <th>Date</th>
                    <th
                      style={{ textAlign: "center", width: "15%" }}
                      colSpan={2}
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!loading ? (
                    <tr>
                      <td colSpan='7' style={{ textAlign: "center" }}>
                        <CircularProgress />
                      </td>
                    </tr>
                  ) : (
                    props.data.length === 0 && (
                      <tr>
                        <td colSpan='6'>
                          <div className='no_data_found'>
                            <FileOpenOutlinedIcon size='50px' />
                            <h6>No data Available</h6>
                          </div>
                        </td>
                      </tr>
                    )
                  )}

                  {props.data?.map((item, index) => {
                    return (
                      <tr key={item.id}>
                        <td
                          style={{ fontWeight: item.status == 1 && "bolder" }}
                        >
                          {index + 1}
                        </td>
                        <td
                          style={{ fontWeight: item.status == 1 && "bolder" }}
                        >
                          {item.name}
                        </td>
                        <td
                          style={{ fontWeight: item.status == 1 && "bolder" }}
                        >
                          {item.title}
                        </td>
                        <td
                          style={{ fontWeight: item.status == 1 && "bolder" }}
                        >
                          Rs. {item.min_investement_amount} - Rs.
                          {item.max_investement_amount}
                        </td>
                        <td
                          style={{ fontWeight: item.status == 1 && "bolder" }}
                        >
                          {format(
                            parseISO(
                              item.created_at,
                              "yyyy-MM-DD H:m:s",
                              new Date()
                            ),
                            "MMM do yyyy"
                          )}
                        </td>
                        <td
                          style={{
                            fontWeight: item.status == 1 && "bolder",
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                        >
                          <Link to={"/dashboard/investor-screen/" + item.id}>
                            <RemoveRedEyeOutlinedIcon
                              onClick={() => {
                                item.status === 1 && screenAction(item.id, 2);
                              }}
                            />
                          </Link>
                        </td>
                        {(item.status === 1 || item.status === 2) && (
                          <td>
                            {/*<Link to={"/dashboard/investor-screen/" + item.id}>*/}
                            <button
                              className={"table-btn"}
                              onClick={() => {
                                screenAction(item.id, 3);
                                history.push(
                                  "/dashboard/investor-screen/" + item.id
                                );
                              }}
                            >
                              <PlayArrowIcon />
                              Start Screening
                            </button>
                            {/*</Link>*/}
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className='pagination'>
            <PaginationPage
              totalPage={props.totalPage}
              currentPage={props.currentPage}
              changePage={props.pagination}
            />
          </div>
        </div>
      </div>
    </>
  );
}
