import React, { useState } from "react";
import "./Form.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  AddCategoryService,
  GetCategoryService,
  UpdateCategoryService,
} from "../../../../services/AdminServices";
import Button from "@mui/material/Button";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

function CategoryFrom({
  toggleModal,
  isAdd,
  category,
  handleChange,
  handleSave,
  setCategory,
  getUpdatedData,
  makeDefault,
}) {
  const addOrEditCategory = async () => {
    category.error = [];
    let data = {};
    if (category.name == "") {
      data = {
        ...data,
        name: "Category name is required",
      };
      setCategory({ ...category, error: data });

      console.log("Name is req");
      return false;
    }
    let result;
    if (category.editcategory) {
      result = await UpdateCategoryService(category);
    } else {
      result = await AddCategoryService(category);
      console.log(result);
    }

    if (result && result.status === "success") {
      console.log("data from IdeaProfile arrived");
      toast.info(result.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      let result2 = await GetCategoryService();
      if (result2 && result2.status === "success") {
        console.log("data from sector arrived");
        makeDefault();
        getUpdatedData(result2.sectors);
        console.log(data);
      } else {
        console.log("error has occurred");
      }
    } else {
      toast.info("Error Occured", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    handleSave();
  };

  const [isEditing, setIsEditing] = useState(false);

  return (
    <div className='modalBackground'>
      <div className='modalContainer' id='category'>
        <div className='modal-title'>
          <span>Category</span>
          <CancelOutlinedIcon onClick={toggleModal} cursor='pointer' />
        </div>

        <div className='body'>
          <div>
            {/*<Button*/}
            {/*    variant='contained'*/}
            {/*    onClick={toggleModal}*/}
            {/*    style={{ background: "#09f" }}*/}
            {/*>*/}
            {/*  Edit*/}
            {/*</Button>*/}
          </div>
          <div className='category-name'>
            <label htmlFor='cName'>Category Name</label>
            <input
              type='text'
              name='name'
              value={category.name}
              onChange={handleChange}
              readOnly={!isEditing && !isAdd}
            />
            {category.error.name && (
              <label className='form-error'>{category.error.name}</label>
            )}
          </div>
          <div className='category-description'>
            <label htmlFor='cDescription'>Category Description</label>
            <textarea
              name='description'
              rows='4'
              value={category.description}
              onChange={handleChange}
              readOnly={!isEditing && !isAdd}
            />
          </div>

          <div className='category-status'>
            <label htmlFor='Status'>Status</label>
            <div className='status-param'>
              <input
                name='status'
                type='radio'
                checked={category.status == 1}
                value={1}
                onChange={handleChange}
                // readOnly={isEditing}
                disabled={!isEditing && !isAdd}
              />
              <label htmlFor='active'>Active</label>
            </div>
            <div className='status-param'>
              <input
                name='status'
                type='radio'
                checked={category.status == 0}
                value={0}
                onChange={handleChange}
                disabled={!isEditing && !isAdd}
              />
              <label htmlFor='inactive'>Inactive</label>
            </div>
          </div>
        </div>
        <div className='footer'>
          <Button className='cancel-btn' onClick={toggleModal}>
            Cancel
          </Button>
          {isAdd ? (
            <Button
              className='ok-btn'
              onClick={() => {
                addOrEditCategory();
              }}
            >
              Add
            </Button>
          ) : !isEditing ? (
            <Button
              className='ok-btn'
              onClick={() => {
                setIsEditing(true);
              }}
            >
              Edit
            </Button>
          ) : (
            <Button
              className='ok-btn'
              onClick={() => {
                addOrEditCategory();
              }}
            >
              Update
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default CategoryFrom;
