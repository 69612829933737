import React from "react";
import "./Layout.css";

function Footer() {
  return (
    <div className="footer-wrapper">
      <div>&copy; 2021,<span> All rights Reserved.</span></div>
      <div>Powered by <b>Sciever inc. Pvt. Ltd.</b></div>
    </div>
  );
}

export default Footer;
