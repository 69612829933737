import CircleOutlined from "@mui/icons-material/CircleOutlined";
import HourglassEmptyOutlined from "@mui/icons-material/HourglassEmptyOutlined";
import { CircularProgress } from "@mui/material";
import { format, parseISO } from "date-fns";
import React, { Component } from "react";
import PaginationPage from "../../../../components/pagination/Pagination";
import { GetScreeningStatusService } from "../../../../services/AdminServices";

class ScreeningData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ideas: [],
      loading: true,
      totalItems: 0,
      pageLimit: 10,
      currentPage: 1,
      totalPage: 1,
    };
  }

  async componentDidMount() {
    await this.getIdeas(this.state.currentPage, this.state.pageLimit);
  }

  async getIdeas(currentPage, pageLimit) {
    let result = await GetScreeningStatusService(currentPage, pageLimit);
    if (result && result.status === "success") {
      this.setState({
        ideas: result.screening_request,
        loading: false,
        currentPage: currentPage,
        totalItems: result.totalItems,
        totalPage: Math.ceil(result.totalItems / this.state.pageLimit),
      });
    } 
  }

  async changePage(page) {
    await this.getIdeas(page, this.state.pageLimit);
  }

  render() {
    var ideas_HTMLTABLE = "";
    if (this.state.loading) {
      ideas_HTMLTABLE = (
        <tr>
          <td colSpan='7' style={{ textAlign: "center" }}>
            <CircularProgress />
          </td>
        </tr>
      );
    } else {
      ideas_HTMLTABLE = this.state.ideas.map((item, index) => {
        return (
          <tr key={item.id}>
            <td>
              {(this.state.currentPage - 1) * this.state.pageLimit + index + 1}
            </td>
            <td
              style={{
                fontWeight: +item.status == 0 && "bolder",
              }}
            >
              {item.user.name}
            </td>
            <td
              style={{
                fontWeight: +item.status == 0 && "bolder",
              }}
            >
              {item.title}
            </td>
            <td
              style={{
                fontWeight: +item.status == 0 && "bolder",
              }}
            >
              {format(
                parseISO(item.created_at, "yyyy-MM-dd H:m:s", new Date()),
                "MMM do, yyyy"
              )}
            </td>
            <td
              style={{
                fontWeight: +item.status == 0 && "bolder",
              }}
            >
              Rs. {item.expected_invested}
            </td>
            <td
              style={{
                fontWeight: +item.status == 0 && "bolder",
              }}
            >
              {" "}
              {item.location}
            </td>
            <td style={{ textAlign: "center" }}>
              {+item.status === 3 ? (
                <span className={"table-rejected-btn"}>
                  <CircleOutlined size={"22px"} />
                </span>
              ) : +item.status === 4 ? (
                <span className='table-accepted-btn'>
                  <CircleOutlined size={"22px"} />
                </span>
              ) : (
                <span className={"table-ongoing-btn"}>
                  <HourglassEmptyOutlined size={"22px"} />
                </span>
              )}
            </td>

          </tr>
        );
      });
    }
    return (
      <>
        <div className='table-wrapper table-wrapper_tabs'>
          <div className='table-block'>
            <div className='table-contents table-contents_tabs'>
              <table id='db-table'>
                <thead>
                  <tr>
                    <th>SN</th>
                    <th>Idea Holder</th>
                    <th>Idea</th>
                    <th> Date</th>
                    <th>Investment</th>
                    <th>Location</th>
                    <th colSpan='2' style={{ textAlign: "center" }}>
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>{ideas_HTMLTABLE}</tbody>
              </table>
            </div>
            <div className='pagination'>
              <PaginationPage
                totalPage={this.state.totalPage}
                currentPage={this.state.currentPage}
                changePage={this.changePage.bind(this)}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ScreeningData;
