import React from "react";
import { Switch, Route } from "react-router-dom";
import CreateIdeaForm from "../modules/dashboard/idea-holder/ideas-detail/CreateIdeaForm";
import IdeaDBHome from "../modules/dashboard/idea-holder/home/IdeaDBHome";
import IdeaDetails from "../modules/dashboard/idea-holder/ideas-detail";
import UserIdeaDetail from "../modules/dashboard/idea-holder/ideas-detail/UserIdeaDetail";
import AccessTabs from "../modules/dashboard/idea-holder/access/AccessTabs";
import ProposalsTab from "../modules/dashboard/idea-holder/proposal/ProposalsTab";
import IdeaMeetingSchedule from "../modules/dashboard/idea-holder/schedule/MeetingSchedule";
import IdeaAgreements from "../modules/dashboard/idea-holder/agreements/Agreements";
import IdeaHolderProfileDetail from "../modules/dashboard/idea-holder/profile/IdeaHolderProfileDetail";
import IdeaEditInterface from "../modules/dashboard/idea-holder/ideas-detail/IdeaEditInterface";
import IdeaHolder from "../modules/dashboard/idea-holder/IdeaHolder";
import IdeaDetail from "../modules/idea-holder/details/IdeaDetail";
import IdeaProfileForm from "../modules/idea-holder/form/IdeaProfileForm";
import Idea from "../modules/idea-holder/pages/Idea";
import IdeaFeed from "../modules/idea-holder/feed/IdeaFeed";
import { IdeaHolderGuard } from "./Guard";
import Layout from "../modules/common/layouts/Layout";

const IdeaRoutes = () => (
  <>
    <Switch>
      <IdeaHolderGuard
        path='/idea/dashboard'
        component={() => {
          return (
            <IdeaHolder>
              <Switch>
                <Route path='/idea/dashboard' exact component={IdeaDBHome} />
                <Route
                  path='/idea/dashboard/idea-details'
                  exact
                  component={IdeaDetails}
                />
                <Route
                  path='/idea/dashboard/idea-detail/:id'
                  exact
                  component={UserIdeaDetail}
                />
                <Route
                  path='/idea/dashboard/idea-profile'
                  exact
                  component={IdeaHolderProfileDetail}
                />
                <Route
                  path='/idea/dashboard/access-requests'
                  exact
                  component={AccessTabs}
                />
                <Route
                  path='/idea/dashboard/proposal'
                  exact
                  component={ProposalsTab}
                />

                <Route
                  path='/idea/dashboard/schedule'
                  exact
                  component={IdeaMeetingSchedule}
                />
                <Route
                  path='/idea/dashboard/agreement'
                  exact
                  component={IdeaAgreements}
                />
                <Route
                  path='/idea/dashboard/add-idea'
                  exact
                  component={CreateIdeaForm}
                />
                <Route
                  path='/idea/dashboard/edit-idea/:id'
                  exact
                  component={IdeaEditInterface}
                />
              </Switch>
            </IdeaHolder>
          );
        }}
      />
      <Layout>
        <Idea>
          <Switch>
            <Route path='/idea' exact component={IdeaFeed} />
            <Route path='/idea/detail/:id' exact component={IdeaDetail} />
            <Route path='/idea/idea-form' exact component={IdeaProfileForm} />
          </Switch>
        </Idea>
      </Layout>
    </Switch>
  </>
);
export default IdeaRoutes;
