import Chip from "@mui/material/Chip";
import React, { Component } from "react";
import { toast } from "react-toastify";
import PaginationPage from "../../../../components/pagination/Pagination";
import IdeaDetail from "../../../../components/idea-template/IdeaDetail";
import { ProposalActionService } from "../../../../services/InvestorService";
import IdeaDetailModal from "./IdeaDetailModal";
import { format, parse } from "date-fns/esm";
import { CircularProgress } from "@mui/material";
import FileOpenOutlined from "@mui/icons-material/FileOpenOutlined";

class Proposals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      ideaData: {},
      proposals: [],
      user: [],
      loading: true,
    };
  }

  toggleModal() {
    this.setState({
      showModal: !this.state.showModal,
    });
  }

  async proposalAction(id, action) {
    // notify();
    let result = await ProposalActionService({ id, action });
    if (result && result.status === "success") {
      const info = this.props.proposals.filter((d) => {
        return d.id !== id;
      });
      this.props.filteredData(info);
      toast.info(
        action === 1
          ? "Request has been accepted."
          : "Request has been rejected",
        {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } else {
      console.log("error occurred updating screening ideas");
    }
  }

  getIdeaModalData(id) {
    const ideaDetail = this.props.proposals.find((data) => {
      return data.id === id;
    });
    this.setState({
      ideaData: ideaDetail.ideas,
    });
    this.toggleModal();
  }

  render() {
    var ideas_HTMLTABLE = "";
    if (this.props.loading) {
      ideas_HTMLTABLE = (
        <tr>
          <td colSpan='7' style={{ textAlign: "center" }}>
            <CircularProgress />
          </td>
        </tr>
      );
    } else {
      ideas_HTMLTABLE = this.props.proposals?.map((item, index) => {
        return (
          <tr key={item.id}>
            <td>{index + 1}</td>
            <td
              role='presentation'
              style={{
                cursor: "pointer",
                fontWeight: item.status == 0 && "bolder",
              }}
              onClick={() => this.getIdeaModalData(item.id)}
            >
              {item.ideas.title}
            </td>
            <td
              style={{
                fontWeight: item.status == 0 && "bolder",
              }}
            >
              {format(
                parse(item.created_at, "YYYY-MM-DD H:m:s", new Date()),
                "MMM do, YYYY"
              )}
            </td>
            <td
              style={{
                fontWeight: item.status == 0 && "bolder",
              }}
            >
              {+item?.status === 0
                ? "Pending"
                : +item.status === 1
                  ? "Accepted"
                  : "Rejected"}
            </td>
            {+this.props.status === 0 && (
              <td style={{ textAlign: "center" }}>
                <Chip
                  style={{
                    marginRight: "10px",
                    width: "85px",
                    color: "#fff",
                    background: "rgb(72 163 72)",
                    height: "30px",
                  }}
                  label='Accept'
                  onClick={() => this.proposalAction(item.id, 1)}
                />
                <Chip
                  style={{
                    width: "85px",
                    color: "#fff",
                    background: "rgb(230 64 64)",
                    height: "30px",
                  }}
                  label='Reject'
                  onClick={() => this.proposalAction(item.id, 2)}
                />
              </td>
            )}
          </tr>
        );
      });
    }
    return (
      <>
        <div className='container-fluid'>
          <div className='table-wrapper'>
            <div className='table-block'>
              <div className='table-contents'>
                <table id='db-table'>
                  <thead>
                    <tr>
                      <th style={{ width: "5%" }}>ID</th>
                      <th style={{ width: "35%" }}>Idea Name</th>
                      {/*<th style={{ width: "20%" }}>Proposed by</th>*/}
                      <th style={{ width: "10%" }}>Date</th>
                      <th style={{ width: "10%" }}>Status</th>
                      {this.props.status === 0 && (
                        <th
                          colSpan='2'
                          style={{ textAlign: "center", width: "20%" }}
                        >
                          Action
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {!this.props.loading ? (
                      this.props.proposals?.length === 0 ? (
                        <tr>
                          <td colSpan='6'>
                            <div className='no_data_found'>
                              <FileOpenOutlined size='50px' />
                              <h6>No data Available</h6>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        ideas_HTMLTABLE
                      )
                    ) : (
                      <tr>
                        <td colSpan='7' style={{ textAlign: "center" }}>
                          <CircularProgress />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {this.state.showModal && (
              <IdeaDetailModal toggleModal={this.toggleModal}>
                <IdeaDetail
                  loadingData={false}
                  requestStatus={1}
                  data={this.state.ideaData}
                />
              </IdeaDetailModal>
            )}
            <div className='pagination'>
              <PaginationPage
                totalPage={this.props.totalPage}
                currentPage={this.props.currentPage}
                changePage={this.props.pagination}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Proposals;
