import CircleOutlined from "@mui/icons-material/CircleOutlined";
import { CircularProgress } from "@mui/material";
import { format, parse } from "date-fns";
import React, {Component} from "react";
import PaginationPage from "../../../../components/pagination/Pagination";
import {GetProposalStatusService} from "../../../../services/IdeaHolderServices";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import HourglassEmptyOutlined from "@mui/icons-material/HourglassEmptyOutlined";
import FileOpenOutlined from "@mui/icons-material/FileOpenOutlined";
class IdeaHolderProposals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proposals: [],
      user: [],
      loading: true,
      currentPage:1,
      pageSize:10,
      totalPage:0
    };
  }

  async componentDidMount() {
    this.getItems(this.state.currentPage,this.state.pageSize);
  }
  async getItems(page,pageSize){
    let result = await GetProposalStatusService(page,pageSize);
    if (result && result.status === "success") {
      console.log("ideas from user arrived");
      if (this.props.status != null) {
        let myData = result.proposal.filter((d) => {
          return d.status === this.props.status;
        });
        this.setState({
          proposals: myData,
          loading: false,
          currentPage:page,
          pageSize:pageSize,
          totalPage: Math.ceil(result.totalItems/pageSize)
        });
      } else {
        this.setState({
          proposals: result.proposal,
          loading: false,
        });
      }
    } else {
      console.log("error has occurred");
    }
  }
  async pagination(page){
    this.getItems(page,this.state.pageSize);
  }
  render() {
    var ideas_HTMLTABLE = "";
    if (this.state.loading) {
      ideas_HTMLTABLE = (
        <tr>
          <td colSpan='7' style={{textAlign: "center"}}>
            <CircularProgress/>
          </td>
        </tr>
      );
    } else {
      ideas_HTMLTABLE = this.state.proposals.map((item, index) => {
        return (
          <tr key={item.id}>
            <td>{index + 1}</td>
            <td>{item.ideas.title}</td>
            <td>
              {format(
                parse(item.created_at, "YYYY-MM-DD H:m:s", new Date()),
                "MMM do, YYYY"
              )}
            </td>
            <td style={{textAlign: "center"}}>
              {item.status === 2 ? (
                <span className={"table-rejected-btn"}>
                  <CircleOutlined size={"22px"}/>
                </span>
              ) : item.status === 1 ? (
                <span className='table-accepted-btn'>
                  <CheckCircleOutlineOutlinedIcon size={"22px"}/>
                </span>
              ) : (
                <span className={"table-ongoing-btn"}>
                  <HourglassEmptyOutlined size={"22px"}/>
                </span>
              )}
            </td>
          </tr>
        );
      });
    }
    return (
      <>
        <div className='container-fluid'>
          <div className='table-wrapper table-wrapper_tabs'>
            <div className='table-block'>
              <div className='table-contents table-contents_tabs'>
                <table id='db-table'>
                  <thead>
                    <tr>
                      <th>SN</th>
                      <th>Idea Name</th>
                      <th>Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!this.state.loading ? (
                      this.state.proposals.length === 0 ? (
                        <tr>
                          <td colSpan='6'>
                            <div className='no_data_found'>
                              <FileOpenOutlined size='50px'/>
                              <h6>No data Available</h6>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        ideas_HTMLTABLE
                      )
                    ) : (
                      <tr>
                        <td colSpan='7' style={{textAlign: "center"}}>
                          <CircularProgress/>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='pagination'>
              <PaginationPage
                totalPage={this.state.totalPage}
                currentPage={this.state.currentPage}
                pageSize={this.state.pageSize}
                changePage={this.pagination}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default IdeaHolderProposals;
