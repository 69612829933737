import React from "react";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import "react-toastify/dist/ReactToastify.css";

// import "./Modal.css";

function IdeaDetailModal(props) {
  return (
    <>
      <div className='modalBackground'>
        <div
          style={{width: "800px", height: "90vh"}}
          className="modalContainer">
          <div className="modal-title">
            <span>Idea Details</span>
            <CancelOutlinedIcon onClick={props.toggleEdit} cursor='pointer'/>
          </div>
          <div
            style={{overflow: "auto", paddingTop: "300px"}}
            className="body">
            {props.children}
          </div>
        </div>
      </div>
    </>
  );
}

export default IdeaDetailModal;
