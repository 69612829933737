import React from "react";
// import "./modal.css";
import "react-toastify/dist/ReactToastify.css";
import {useHistory} from "react-router-dom";
import loading from "../../../assets/img/Loading.gif"
// import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

function ProfileCreationSuccess(/*props*/) {

  const history = useHistory();

  setTimeout(()=>{
    history.push("/idea/dashboard/idea-profile")
  }, 5000)

  return (
    <>
      <div className='modalBackground'>
        <div className='modalContainer'>
          <div className="modal-title">
            <span>Profile Created.</span>
            {/* <CancelOutlinedIcon onClick={props.toggleModal} cursor='pointer'/> */}
          </div>

          <div className='body'>
            <form>
              <label htmlFor="email">
                                Your Idea Holder Profile has been created successfully. <br/> Please wait for a few seconds...while we take you to your profile page.
              </label>

            </form>
            <div className='footer' style={{display: "flex", justifyContent: "center"}}>
              {/* <button onClick={() => {
                history.push("/idea/dashboard")
              }}
              className={"ok-btn"}>Go to Dashboard
              </button> */}
              <img alt='loading' src={loading} style={{height:"50px", width:"50px"}} />

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileCreationSuccess;
