import Pagination from "@mui/material/Pagination";
import React from "react";
// import {MDBCol, MDBPageItem, MDBPageNav, MDBPagination, MDBRow,} from "mdbreact";

const PaginationPage = (props) => {
  return props.totalPage > 0 ? (
    <Pagination
      count={props.totalPage}
      page={props.currentPage}
      onChange={(event,value)=>{
        props.changePage(value);
      }}
    />
  ) : (
    ""
  );
};

export default PaginationPage;
