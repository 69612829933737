import React from "react";
import "./StarRating.css";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
export default class StarRating extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: props.rating,
      hover: null,
    };
  }

  render() {
    return (
      <>
        <div className='Rating-container'>
          {[...Array(5)].map((star, i) => {
            const ratingValue = i + 1;
            return (
              <label htmlFor='test' key={i}>
                <input
                  type='radio'
                  name='rating'
                  value={ratingValue}
                  onClick={() => {
                    this.setState({
                      rating: ratingValue,
                    });
                  }}
                />
                <StarOutlineIcon
                  className='star'
                  color={
                    ratingValue <= (this.sate?.hover || this.state?.rating)
                      ? "#ffc107"
                      : "#e4e5e9"
                  }
                  size={this.props.size}
                  onMouseEnter={() => {
                    this.setState({rating: ratingValue});
                  }}
                  onMouseLeave={() => {
                    this.setState({hover: null});
                  }}
                />
              </label>
            );
          })}
        </div>
      </>
    );
  }
}
