import React from "react";
import Profile from "../../../components/profile";
import "./Investor.css";

function Investor({children}) {
  return (
    <>
      <div id='investor-page'>
        <div className='box-1 '>
          <div className='Profile'>
            <Profile/>
          </div>
        </div>
        <div className='box-2'>
          <div className='feed'>{children}</div>
        </div>
        <div className='box-3'>
        </div>
      </div>
    </>
  );
}

export default Investor;
