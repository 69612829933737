import React, { useEffect, useState } from "react";
import IdeaHolderSchedule from "./IdeaHolderSchedule";
import SchedulerData from "../scheduler/SchedulerData";
import { GetDataAfterConnectService } from "../../../../services/AdminServices";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Box, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

function MeetingSchedule() {
  const [search, setSearch] = useState(true);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const showSearch = () => setSearch(!search);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showInvestor, setShowInvestor] = useState(false);
  const handleCloseInvestor = () => setShowInvestor(false);
  const handleShowInvestor = () => setShowInvestor(true);
  const [key, setKey] = useState("0");
  const handTablChange = (event, index) => {
    setKey(index);
    console.log(index);
    getDataAfterConnect(index,1,pageSize);
  };
  const [showTripartite, setShowTripartite] = useState(false);
  const handleCloseTripartite = () => setShowTripartite(false);
  const handleShowTripartite = () => setShowTripartite(true);

  const [addRemarks, setAddRemarks] = useState(false);
  const handleShowRemarks = () => setAddRemarks(true);
  const handleCloseRemarks = () => setAddRemarks(false);

  const [viewRemarks, setViewRemarks] = useState(false);
  const handleViewRemarks = () => setViewRemarks(true);
  const handleUnViewRemarks = () => setViewRemarks(false);
  

  const getDataAfterConnect = async (k, page, size) => {
    setLoading(true);
    setData([]);
    let res = await GetDataAfterConnectService(k,page,size);
    if (res.status === "success") {
      setData(res);
      setLoading(false);
      setCurrentPage(page);
      setPageSize(size);
      setTotalPage(Math.ceil(res.totalItems / size));
    }
  };
  const pagination = (page) => {
    getDataAfterConnect(key, page, pageSize);
  };
  useEffect(() => {
    getDataAfterConnect(0, currentPage, pageSize);
  }, [showTripartite]);

  return (
    <div className='container-fluid'>
      <div className='table-wrapper'>
        <div className='table-header'>
          <span>Meeting Scheduler</span>
          {search ? (
            <SearchOutlinedIcon onClick={showSearch} style={{ marginRight: "20px", cursor:"pointer" }} />
          ) : (
            <div className='search'>
              <input type='search' placeholder='Search' />
              <CloseOutlinedIcon style={{marginTop: "5px", position:"absolute", marginLeft:"190px", cursor:"pointer"}} onClick={showSearch} />
            </div>
          )}
        </div>
        <TabContext value={key}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handTablChange}
              aria-label='lab API tabs example'
              variant="scrollable"
            >
              <Tab label='Scheduler' value='0' />
              <Tab label='Scheduled Meeting' value='2' />
            </TabList>
          </Box>

          <TabPanel value="0">
            <SchedulerData
              data={data}
              show={show}
              loading={loading}
              handleClose={handleClose}
              handleShow={handleShow}
              showInvestor={showInvestor}
              handleCloseInvestor={handleCloseInvestor}
              handleShowInvestor={handleShowInvestor}
              showTripartite={showTripartite}
              handleCloseTripartite={handleCloseTripartite}
              handleShowTripartite={handleShowTripartite}
              currentPage={currentPage}
              totalPage={totalPage}
            />
          </TabPanel>
          <TabPanel value="2">
            <IdeaHolderSchedule
              data={data}
              show={show}
              handleClose={handleClose}
              handleShow={handleShow}
              showInvestor={showInvestor}
              handleCloseInvestor={handleCloseInvestor}
              handleShowInvestor={handleShowInvestor}
              showTripartite={showTripartite}
              handleCloseRemarks={handleCloseRemarks}
              handleShowRemarks={handleShowRemarks}
              addRemarks={addRemarks}
              getDataAfterConnect={getDataAfterConnect}
              viewRemarks={viewRemarks}
              handleViewRemarks={handleViewRemarks}
              handleUnViewRemarks={handleUnViewRemarks}
              loading={loading}
              pagination={pagination}
              currentPage={currentPage}
              totalPage={totalPage}
            />
          </TabPanel>
            
        </TabContext>
      </div>
    </div>
  );
}

export default MeetingSchedule;
