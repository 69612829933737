export const authData = (data) => {
  return {
    type: "LOGIN_SUCCESS",
    payload: data,
  };
};

export const ideaData = (idea) => {
  return {
    type: "IDEA_SENT_FOR_SCREENING",
    payload: idea,
  };
};

export const logoutUser = () => {
  return {
    type: "LOGOUT",
  };
};
  