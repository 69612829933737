import React from "react";
import "../../App.scss";
import PDF from "../../assets/img/mypdf.png";
import feedImg from "../../assets/img/service1.jpg";
import StarRating from "../star-rating/StarRating";
import PropTypes from "prop-types";
import { CircularProgress } from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PublicIcon from "@mui/icons-material/Public";
import LockIcon from "@mui/icons-material/Lock";
export default class IdeaDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      heart: false,
      isLiked: false,
      rating: false,
      loadingData: false
    };
    this.files = [
      "Debt Injection Plan",
      "Feasibility Analysis",
      "SWOT Analysis",
      "Financial Projection",
      "Financial Analysis",
    ];
    this.prefLocation = [
      {
        name: "Kathmandu",
      },
      {
        name: "Bhaktapur",
      },
      {
        name: "Dhankuta",
      },
      {
        name: "Birgunj",
      },
      {
        name: "Pokhara",
      },
    ];
  }

  // const updateViewCount = async (id) => {
  //   let result = await UpdateViewCountService({ id: id });
  //   if (result && result.status === "success") {
  //   } else {
  //     console.log("error has occurred category");
  //   }
  // };

  render() {
    return (
      <div>
        <>
          {this.state.loadingData ? (
            <div className={"spinner"}>
              <CircularProgress color='primary'/>
            </div>
          ) : (
            <div style={{boxShadow: "unset"}} className='detail-container'>
              <div style={{padding: "unset"}} className='idea-detail-head'>
                <div className='idea-detail_top'>
                  <div className='title__wrap'>
                    <div className='i_d2'>
                      <div className='idea-title'>{this.props.data.title}</div>
                    </div>
                  </div>
                  <div
                    style={{marginRight: "10px"}}
                    className='investor-detail-header-mid'
                  >
                    <div className='like-btn'>
                      {!this.state.isLiked ? (
                        <ThumbUpIcon
                          style={{
                            fontSize: "18px",
                            color: "#09f",
                          }}
                          onClick={() => {
                            this.setState({
                              isLiked: !this.state.isLiked,
                            });
                          }}
                        />
                      ) : (
                        <ThumbUpOffAltIcon
                          style={{
                            fontSize: "18px",
                            color: "#09f",
                          }}
                          onClick={() => {
                            this.setState({
                              isLiked: !this.state.isLiked,
                            });
                          }}
                        />
                      )}
                    </div>
                    <div className='favourite-btn'>
                      {this.state.heart ? (
                        <FavoriteIcon
                          style={{
                            color: "#ff5656",
                            fontSize: "20px",
                          }}
                          onClick={() => {
                            this.setState({
                              heart: !this.state.heart,
                            });
                          }}
                        />
                      ) : (
                        <FavoriteBorderIcon
                          style={{
                            color: "#ff5656",
                            fontSize: "20px",
                          }}
                          onClick={() => {
                            this.setState({
                              heart: !this.state.heart,
                            });
                          }}
                        />
                      )}
                    </div>
                  </div>
                  {this.rating && (
                    <div className='rate-box'>
                      <StarRating/>
                      <button
                        onClick={() => {
                          this.setState({
                            rating: !this.state.rating,
                          });
                        }}
                      >
                                                Rate
                      </button>
                    </div>
                  )}
                </div>

                <div className='feed__location'>
                  <PublicIcon/>
                                    &nbsp;
                  <span style={{fontSize: "12px"}}>{this.props.data.location}</span>
                </div>
              </div>

              <div className='idea-detail-img'>
                <img
                  src={feedImg}
                  width='100%'
                  height='100%'
                  className='feed-image'
                  alt=''
                />
              </div>
              <div className='detail-item detail-item1'>
                <div className='detail-title'>About Us</div>
                <div className='details-text'>{this.props.data.description}</div>
              </div>
              <div className='details-blocks'>
                <div id={"item1"} className='detail-item'>
                  <div className='location-block'>
                    <div className='detail-title'>Preferred Location</div>
                    <div className='location-pills'>
                      {this.prefLocation.map((location) => (
                        <label key={location.id} htmlFor='test'>
                          {location.name}
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
                                &nbsp;
                <div id={"item2"} className='detail-item'>
                  <div className='investment-block'>
                    <div className='detail-title'>Expected Investment</div>
                    <label className='investment-label'>
                                            Rs. {this.props.data.expected_invested}
                    </label>
                  </div>
                </div>
                                &nbsp;
                <div id={"item3"} className='detail-item'>
                  <div className='investment-block'>
                    <div className='detail-title'>Own Investmentt</div>
                    <label className='investment-label'>
                                            Rs. {this.props.data.own_investment}
                    </label>
                  </div>
                </div>
              </div>

              <div className='idea-detail-hidden-box '>
                {this.files.map((file, index) => (
                  <div className='detail-item hidden-item  ' key={index}>
                    <div className='detail-title'>
                      <span>
                        <img src={PDF} alt='pdf' width='20'/>
                        <span>{file}</span>
                      </span>
                      <span style={{cursor: "pointer"}}>
                        <LockIcon/>
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      </div>
    );
  }
}

IdeaDetail.propTypes = {
  loadingData: PropTypes.bool.isRequired,
  data: PropTypes.any.isRequired,
};
