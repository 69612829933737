import {createStore} from "redux";
import {loadState, saveState} from "./redux/LoadState";
import RootReducer from "./redux/reducer/RootReducer";

const persistedState = loadState();
const store = createStore(
  RootReducer,
  persistedState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
store.subscribe(() => {
  saveState(store.getState());
});
export default store;
