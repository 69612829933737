import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {useHistory, useParams} from "react-router-dom";
import {AddUserService, EdituserService, UpdateUserService,} from "../../../../services/AdminServices";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
function AddUserForm() {
  const history = useHistory();
  const params = useParams();

  const [user, setUser] = useState({
    name: "",
    username: "",
    password: "",
    phone: "",
    email: "",
    errors: [],
    errorcount: 0,
  });
  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUser({...user, [name]: value});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUser({...user, errors: []});
    let data = {};

    user.errorcount = 0;

    if (user.name == "") {
      user.errorcount += 1;
      data = {
        ...data,
        name: "Name is required",
      };
      setUser({...user, errors: data});
    }
    if (user.username == "") {
      user.errorcount += 1;
      data = {
        ...data,
        username: "Username is required",
      };
      setUser({...user, errors: data});
    }

    if (user.phone == "") {
      user.errorcount += 1;
      data = {
        ...data,
        phone: "Phone is required",
      };
      setUser({...user, errors: data});
    }
    if (user.email == "") {
      user.errorcount += 1;
      data = {
        ...data,
        email: "Email is Required",
      };
      setUser({...user, errors: data});
    }
    if (user.password == "" && isAdd) {
      user.errorcount += 1;
      data = {
        ...data,
        password: "Password is Required",
      };
      setUser({...user, errors: data});
    }

    console.log(user.errorcount);

    if (user.errorcount >= 1) {
      console.log(user.errors);
      console.log(user);
      return false;
    }

    console.log(user.errors);
    console.log("adding........");
    console.log("adding........");

    if (isAdd == true) {
      const AddUser = await AddUserService(user);
      if (AddUser.status == "success") {
        toast.info("User Added Successfully!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        history.push("/dashboard/user");
      } else {
        toast.info("Error Occured!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      const UpdateUser = await UpdateUserService(user, params.id);
      if (UpdateUser.status == "success") {
        toast.info("User Updated Successfully!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        history.push("/dashboard/user");
      } else {
        toast.info("Error Occured!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };
  const splitH = history.location.pathname.split("/");
  const [isAdd, setIsAdd] = useState(false);

  const getUserData = async () => {
    const editUser = await EdituserService(params.id); //req pathayera edit user ma ayo
    if (editUser.status === "success") {
      let data = {
        ...user,
        name: editUser.user.name,
        username: editUser.user.username,
        phone: editUser.user.phone,
        email: editUser.user.email,
      };
      setUser(data);
      console.log(editUser);
    }
  };

  useEffect(() => {
    console.log(splitH);
    splitH[2] === "adduser" ? setIsAdd(true) : setIsAdd(false);
  }, [splitH, history]);
  useEffect(() => {
    splitH[2] === "edituser" && getUserData();
  }, []);

  return (
    <>
      <div className='form-wrapper form_wrapper' id={"user-form"}>
        <div className='form-header'>
          <div className='header-title'>
            <h2>{isAdd ? "Add User" : "Edit User"}</h2>
            {/* <span>Fill in the details below to create an idea profile</span> */}
          </div>
          <button className='close-btn' onClick={() => history.goBack()}>
            <CloseOutlinedIcon/>
          </button>
        </div>
        <div className='row user-row'>
          <div className='col-md-12'>
            <div className='idea-form'>
              <div className='form-body'>
                <div className='personal-details row personal_detail'>
                  <div className='col-md-6'>
                    <div className='idea-form-parameter'>
                      <label htmlFor="email">Full Name</label>
                      <input
                        type='text'
                        name='name'
                        value={user.name}
                        onChange={handleInput}
                      />

                      {user.errors.name && (
                        <label className='form-error'>{user.errors.name}</label>
                      )}
                    </div>
                    <div className='idea-form-parameter'>
                      <label htmlFor="email">Phone</label>
                      <input
                        type='text'
                        name='phone'
                        value={user.phone}
                        onChange={handleInput}
                      />
                      {user.errors.phone && (
                        <label className='form-error'>
                          {user.errors.phone}
                        </label>
                      )}
                    </div>
                    <div className='idea-form-parameter'>
                      <label htmlFor="email">Password</label>
                      <input
                        type='password'
                        name='password'
                        value={user.password}
                        onChange={handleInput}
                      />
                      {user.errors.password && (
                        <label className='form-error'>
                          {user.errors.password}
                        </label>
                      )}
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='idea-form-parameter'>
                      <label htmlFor="email">Username</label>
                      <input
                        type='text'
                        name='username'
                        value={user.username}
                        onChange={handleInput}
                      />

                      {user.errors.username && (
                        <label className='form-error'>
                          {user.errors.username}
                        </label>
                      )}
                    </div>

                    <div className='idea-form-parameter'>
                      <label htmlFor="email">Email</label>
                      <input
                        type='email'
                        name='email'
                        value={user.email}
                        onChange={handleInput}
                      />
                      {user.errors.email && (
                        <label className='form-error'>
                          {user.errors.email}
                        </label>
                      )}
                    </div>
                  </div>

                  <br/>
                </div>
                <div className='form-btn'>
                  <button onClick={handleSubmit} value='Create Profile'>
                    {isAdd ? "Create" : "Update"} Profile
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-0'></div>
        </div>
      </div>
    </>
  );
}

export default AddUserForm;
