import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Profile from "../../../../assets/img/account.png";
import {
  GetSingleInvestorService,
  InvestorScreenActionService,
} from "../../../../services/AdminServices";
import ConfirmationModal from "../../../../components/modal/ConfirmationModal";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import RejectionModal from "../../../../components/modal/RejectionModal";
import GetImageUrl from "../../../../utils/ImageHelper";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";

export default function InvestorScreeningInterface() {
  const [screening, setScreening] = useState(false);
  const toggleScreen = () => {
    setScreening(true);
  };

  const [reject, setReject] = useState(false);
  const toggleRejectionModal = () => {
    setReject(!reject);
  };

  const [screenStatus, setScreenStatus] = useState(0);

  let history = useHistory();

  function handleClick() {
    history.goBack();
  }

  const location = useParams();

  const [approve, setApprove] = useState(false);
  const approveModal = () => {
    setApprove(!approve);
  };

  const [data, setData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true);

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
    console.log(name, value);
  };

  async function fetchData() {
    let result = await GetSingleInvestorService({ id: location.id });
    if (result && result.status === "success") {
      setData(result.investor);
      setLoading(false);
    } else {
      console.log("error has occurred");
    }
  }

  const screenAction = async (id, action) => {
    const screenData = {
      id: location.id,
      action: action,
      remark: data.screen_remark ? data.screen_remark : "",
    };
    await InvestorScreenActionService(screenData);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className='screening_wrap'>
        <div className='header_main'>
          <div className='back' role='presentation' onClick={handleClick}>
            <ArrowBackOutlinedIcon />
            <span>Back</span>
          </div>
        </div>
        <div className='personal_detail_wrap'>
          <div className='header'>
            <h3>Personal Details of Investor</h3>
          </div>
          <div className='body_flex'>
            <div className='user_image'>
              <img
                src={
                  data.profile_pic
                    ? GetImageUrl("investor-profile", data.profile_pic)
                    : Profile
                }
                alt='user_image'
              />
            </div>
            <div className='detail_table'>
              <table>
                <tbody>
                  <tr>
                    <td className='td_table'>Name</td>
                    <td>{data.name}</td>
                  </tr>
                  <tr>
                    <td className='td_table'>Title</td>
                    <td>{data.title}</td>
                  </tr>
                  <tr>
                    <td className='td_table'>Age</td>
                    <td>{data.age}</td>
                  </tr>
                  <tr>
                    <td className='td_table'>Gender</td>
                    <td>
                      {data.gender === 0
                        ? "Male"
                        : data.gender === 1 && "Female"}
                    </td>
                  </tr>
                  <tr>
                    <td className='td_table'>Phone</td>
                    <td>{data.phone}</td>
                  </tr>
                  <tr>
                    <td className='td_table'>Email</td>
                    <td>{data.email}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className='personal_detail_wrap'>
            <div className='header'>
              <h3>Investor Details</h3>
            </div>
            <div className='business_detail_flex'>
              <div className='investor-form'>
                <Grid
                  container
                  rowSpacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
                  columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
                >
                  <Grid item sm={6} xs={12}>
                    <TextField
                      style={{ width: "100%" }}
                      name='name'
                      label='Investor Type'
                      value={
                        data.investor_type === 0
                          ? "Individual Investor"
                          : "Institutional Investor"
                      }
                      variant='outlined'
                      required
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}></Grid>

                  <Grid item xs={12}>
                    <span>Permanent Location</span>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      style={{ width: "100%" }}
                      label='Municipality'
                      value='Kathmandu'
                      variant='outlined'
                      required
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      style={{ width: "100%" }}
                      label='Street'
                      value={data.area ? data.area : ""}
                      variant='outlined'
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <span>Temporary Location</span>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      style={{ width: "100%" }}
                      label='Municipality'
                      value='Kathmandu'
                      variant='outlined'
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12} mt={1}>
                    <TextField
                      style={{ width: "100%" }}
                      label='Street'
                      value={data.temp_area ? data.temp_area : ""}
                      variant='outlined'
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <h4>Investment Details</h4>
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <TextField
                      style={{ width: "100%" }}
                      label='Categories'
                      value={
                        data.sectors
                          ? data.sectors
                            .map((category) => category.name)
                            .join(", ")
                          : ""
                      }
                      variant='outlined'
                      required
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      style={{ width: "100%" }}
                      label='Preferred Locations'
                      value={
                        data.districts
                          ? data.districts.map((item) => item.name).join(", ")
                          : ""
                      }
                      variant='outlined'
                      required
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id='outlined-multiline-static'
                      label='Investor Detail'
                      multiline
                      rows={6}
                      sx={{ width: "100%" }}
                      variant='outlined'
                      name={"investor_detail"}
                      value={data.investor_detail ? data.investor_detail : ""}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id='outlined-multiline-static'
                      label='Interests'
                      multiline
                      rows={6}
                      sx={{ width: "100%" }}
                      variant='outlined'
                      name={"investor_interest"}
                      value={
                        data.investor_interest ? data.investor_interest : ""
                      }
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>

                  <Grid item sm={3} xs={12}>
                    <TextField
                      style={{ width: "100%" }}
                      name='min_investement_amount'
                      label='Min Amount'
                      value={
                        data.min_investement_amount
                          ? data.min_investement_amount
                          : ""
                      }
                      variant='outlined'
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <TextField
                      style={{ width: "100%" }}
                      name='max_investement_amount'
                      label='Max Amount'
                      value={
                        data.max_investement_amount
                          ? data.max_investement_amount
                          : ""
                      }
                      variant='outlined'
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                  {data.remark && (
                    <Grid item xs={12}>
                      <TextField
                        id='outlined-multiline-static'
                        label='Remarks'
                        multiline
                        rows={6}
                        sx={{ width: "100%" }}
                        variant='outlined'
                        name={"remark"}
                        value={data.remark ? data.remark : ""}
                        inputProps={{ readOnly: true }}
                      />
                    </Grid>
                  )}
                </Grid>
              </div>
              {!screening && (data.status === 1 || data.status === 2) ? (
                <div className='end_buttons'>
                  <Button
                    className='primary-btn'
                    onClick={() => {
                      screenAction(data.id, 3);
                      toggleScreen();
                    }}
                  >
                    Start Screening Process
                  </Button>
                </div>
              ) : (
                <div className='end_buttons'>
                  <Button
                    variant='outlined'
                    className='danger-btn'
                    onClick={() => {
                      toggleRejectionModal();
                      setScreenStatus(5);
                    }}
                  >
                    Reject
                  </Button>
                  <Button
                    className='warning-btn'
                    onClick={() => {
                      toggleRejectionModal();
                      setScreenStatus(6);
                    }}
                  >
                    Review
                  </Button>
                  <Button
                    className='primary-btn'
                    onClick={() => {
                      approveModal();
                      setScreenStatus(4);
                    }}
                  >
                    Approve
                  </Button>
                </div>
              )}
            </div>

            {reject && (
              <RejectionModal
                toggleModal={toggleRejectionModal}
                action={screenAction}
                toastMsg={"Investor has been rejected"}
                status={screenStatus}
                data={data}
                name='screen_remark'
                value={data.screen_remark}
                message={
                  screenStatus === 5
                    ? "Are you sure you want to reject this Investor?"
                    : "Are your sure you want to reject this idea for resubmission?"
                }
                handleInput={handleInput}
                title={screenStatus === 3 ? "Reject" : "Resubmit"}
              />
            )}

            {approve && (
              <ConfirmationModal
                title={"Screening Investor"}
                toggleModal={approveModal}
                message={
                  screenStatus === 4
                    ? "Are you sure You want to Approve this Investor?"
                    : screenStatus === 6
                      ? "Are you sure You want to send this investor for reviewing?"
                      : "Are you sure You want to reject this Investor?"
                }
                status={screenStatus}
                action={screenAction}
                toastMsg={"Investor has been approved"}
                data={data}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
