import React, { useEffect, useState } from "react";
import PaginationPage from "../../../../components/pagination/Pagination";
import { AgreementService } from "../../../../services/AdminServices";

import ViewAgreement from "./ViewAgreement";
import { format, parseISO } from "date-fns";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
export default function Agreements() {
  const [data, setData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [detail, setDetail] = React.useState({});
  const [viewDetail, setViewDetail] = React.useState({});
  const [currentPage, setCurrentPage] = React.useState(1);
  // eslint-disable-next-line no-unused-vars
  const [pageSize, setPageSize] = React.useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [showTripartite, setShowTripartite] = useState(false);
  const handleCloseTripartite = () => setShowTripartite(false);
  const handleShowTripartite = () => setShowTripartite(true);

  const filterData = (id) => {
    const datas = data.agreements.find((g) => {
      return g.id === id;
    });

    setDetail(datas);
    setViewDetail(datas);
  };

  const agreement = async (page) => {
    const res = await AgreementService(page, pageSize);
    setData(res);
    setCurrentPage(page);
    setTotalPage(Math.ceil(res.totalItems / pageSize));
  };
  useEffect(() => {
    agreement(currentPage);
  }, []);

  return (
    <div>
      <div>
        <div className='container-fluid'>
          <div className='table-wrapper'>
            <div className='table-header'>
              <span>Agreements</span>
            </div>
            <div
              className='table-contents'
              style={{ height: "74vh", overflowY: "auto" }}
            >
              <table id='db-table' className='a_r'>
                <thead>
                  <tr>
                    <th>SN</th>
                    <th>Investor</th>
                    <th> Idea Holder</th>
                    <th> Idea</th>
                    <th>Date & Time</th>

                    <th style={{ textAlign: "center" }}>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {data &&
                    data.agreements &&
                    data.agreements.map((sub, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>

                        <td>{sub.investor.investor_detail.name}</td>
                        <td>{sub.idea_holder.name}</td>
                        <td>{sub.idea.title}</td>
                        <td>
                         
                          {format(
                            parseISO(
                              sub.updated_at,
                              "yyyy-MM-dd H:m:s",
                              new Date()
                            ),
                            "MMM Do yyyy, H:mm:ss a"
                          )}
                        </td>
                        <td
                          role='presentation'
                          style={{ textAlign: "center" }}
                          onClick={() => {
                            filterData(sub.id);
                            handleShowTripartite();
                          }}
                        >
                          <RemoveRedEyeOutlinedIcon/>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className='pagination'>
              <PaginationPage
                totalPage={totalPage}
                pageSize={pageSize}
                currentPage={currentPage}
                changePage={agreement}
              />
            </div>
          </div>
        </div>
      </div>

      {/* // /* <AddRemarks {...props} detail={detail} />// */}
      <ViewAgreement
        showTripartite={showTripartite}
        detail={viewDetail}
        handleCloseTripartite={handleCloseTripartite}
        data={data}
      />
    </div>
  );
}
