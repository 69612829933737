/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {AddUserService, EdituserService, GetIdeaServices, UpdateUserService,} from "../../../../services/AdminServices";
import {toast} from "react-toastify";
import EditModal from "./EditModal";
import Button from "@mui/material/Button";
import {GetIService} from "../../../../services/InvestorService";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import GoogleIcon from "@mui/icons-material/Google";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
export default function UserDetail() {
  const history = useHistory();
  const params = useParams();
  const [isAdd, setIsAdd] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [investor, setInvestor] = useState(false);
  const [idea, setIdea] = useState(true);
  const toggleModal = () => {
    setOpenModal(!openModal);
  };
  const [user, setUser] = useState({
    name: "",
    username: "",
    password: "",
    phone: "",
    email: "",
    errors: [],
    errorcount: 0,
    role: "",
    description: "",
    id: "",
  });
  console.log(history);

  const splitH = history.location.pathname.split("/");

  const getUserData = async () => {
    const getUser = await EdituserService(params.id); //req pathayera edit user ma ayo
    if (getUser.status === "success") {
      let data = {
        ...user,
        name: getUser.user.name,
        username: getUser.user.username,
        phone: getUser.user.phone,
        email: getUser.user.email,
        role: getUser.user.roles,
        id: getUser.user.id,
        description: getUser.user.roles[0].description,
      };
      setUser(data);
      console.log(getUser);

      getUser.user.roles &&
            getUser.user.roles.map((da) => {
              if (da.slug === "investor") {
                IData(getUser.user.id);
              }
              if (da.slug === "idea-holder") {
                IdeaData(getUser.user.id);
              }
            });
      // getUser.user.role.map(async (da) => {
      //   if (da.slug === "investor") {
      //     IData(getUser.user.id);

      //   }
      // });
    }
  };

  //idea-holder
  const IdeaData = async (id) => {
    const apiIdea = await GetIdeaServices(id);
    console.log(apiIdea, "kkkkkkkkkkkkkkk");
    if (apiIdea.status === "success") {
      console.log(apiIdea.data, "hello");
      setIdea(apiIdea.data);
    }
  };
    //idea-holder

  const IData = async (id) => {
    const apiInvestor = await GetIService(id);
    if (apiInvestor.status === "success") {
      setInvestor(apiInvestor.data);
    }
  };

  const handleDropdownInput = (inp_var, value) => {
    setUser({...user, [inp_var]: value});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUser({...user, errors: []});
    let data = {};

    user.errorcount = 0;

    if (user.name == "") {
      user.errorcount += 1;
      data = {
        ...data,
        name: "Name is required",
      };
      setUser({...user, errors: data});
    }
    if (user.username == "") {
      user.errorcount += 1;
      data = {
        ...data,
        username: "Username is required",
      };
      setUser({...user, errors: data});
    }

    if (user.phone == "") {
      user.errorcount += 1;
      data = {
        ...data,
        phone: "Phone is required",
      };
      setUser({...user, errors: data});
    }
    if (user.email == "") {
      user.errorcount += 1;
      data = {
        ...data,
        email: "Email is Required",
      };
      setUser({...user, errors: data});
    }
    if (user.password == "" && isAdd) {
      user.errorcount += 1;
      data = {
        ...data,
        password: "Password is Required",
      };
      setUser({...user, errors: data});
    }

    console.log(user.errorcount);

    if (user.errorcount >= 1) {
      console.log(user.errors);
      console.log(user);
      return false;
    }

    console.log(user.errors);
    console.log("adding........");
    console.log("adding........");

    if (isAdd == true) {
      const AddUser = await AddUserService(user);
      if (AddUser.status == "success") {
        toast.info("User Added Successfully!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        history.push("/dashboard/user");
      } else {
        toast.info("Error Occured!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      const UpdateUser = await UpdateUserService(user, params.id);
      if (UpdateUser.status == "success") {
        toast.info("User Updated Successfully!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.info("Error Occured!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  useEffect(() => {
    splitH[2] === "adduser" ? setIsAdd(true) : setIsAdd(false);
  }, [splitH, history]);
  useEffect(() => {
    getUserData();
  }, []);
  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUser({...user, [name]: value});
  };

  // const investorCheck = () => {
  //   if (user.role === "investor") {
  //     setInvestor(!investor);
  //   }
  // };

  return (
    <>
      <div className='profile_detail_main p_d__m'>
        <div className='profile_detail_main_h3'>
          <span>Your Idea Profile Detail</span>
        </div>
        <div className='detail__flex_p'>
          <div className='profile_detail_main_social'>
            <ul>
              <li>
                <span className='p_d_m_s_1'>
                  <MailOutlineIcon/>
                </span>
                <span className='p_d_m_s_2'>Email</span>
              </li>
              <li>
                <span className='p_d_m_s_1'>
                  <GoogleIcon/>
                </span>
                <span className='p_d_m_s_2'>Google</span>
              </li>
              <li>
                <span className='p_d_m_s_1'>
                  <LocalPhoneIcon/>
                </span>
                <span className='p_d_m_s_2'>Phone</span>
              </li>
            </ul>
          </div>
          <Button variant='text' onClick={() => history.goBack()}>
            {" "}
            <ArrowBackIosIcon/> Back
          </Button>
          <div className='d_btn'>
            {/* <Link to='/dashboard/edituser/'> */}
            {/* <button onClick={toggleModal}>Edit</button> */}
            {/* </Link> */}
            <Button
              variant='contained'
              onClick={toggleModal}
              style={{background: "#09f"}}
            >
                            Edit
            </Button>
          </div>
        </div>

        <hr/>

        <div className='profile_details'>
          <div className='table-contents'>
            <div className={"table-title"}>
              <b>Personal Details</b>
            </div>
            <table id='db-table'>
              <tbody>
                <tr>
                  <td style={{width: "10%"}}>
                    <b>Full Name:</b>
                  </td>
                  <td colSpan={2}>{user.name}</td>
                  <td style={{width: "10%"}} className='res_table'>
                    <b>Email:</b>
                  </td>
                  <td className='res_table'>{user.email}</td>
                </tr>

                <tr>
                  <td>
                    <b>Type</b>
                  </td>
                  <td colSpan={2}>
                    {user.role &&
                                        user.role.map((d, i) => (
                                          <span key={i}>
                                            {" "}
                                            {i > 0 && ","}
                                            {d.name}
                                          </span>
                                        ))}
                  </td>

                  <td className='res_table'>
                    <b>Citizenship:</b>
                  </td>
                  <td className='res_table'>{idea.citizenship}</td>
                </tr>

                <tr>
                  <td>
                    <b>Phone:</b>
                  </td>
                  {console.log(user)}
                  <td colSpan={2}>{user.phone}</td>
                  <td className='res_table'>
                    <b>Description:</b>
                  </td>
                  <td className='res_table'>{user.description}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <br/>
        {!idea ? (
          <div className='user_roles'>
            <div className={"table-title"}>
              <b>Permanent Location</b>
            </div>
            <table id={"db-table"}>
              <tbody>
                <tr>
                  <td style={{minWidth: "200px"}}>
                    <b>Municipality:</b>
                  </td>
                  {/* {console.log(idea.municipality.name)} */}
                  <td style={{minWidth: "200px"}}>
                    {idea.municipality.name}
                  </td>
                  <td className='res_table' style={{minWidth: "200px"}}>
                    <b>State:</b>
                  </td>
                  <td className='res_table' style={{minWidth: "200px"}}>
                    {idea.area}
                  </td>
                </tr>
                <tr className='res_show_table'>
                  <td style={{minWidth: "200px"}}>
                    <b>State:</b>
                  </td>
                  <td style={{minWidth: "200px"}}>{idea.area}</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <div className={"table-title"}>
              <b>Temporary Location</b>
            </div>
            <table id={"db-table"}>
              <tbody>
                <tr>
                  <td style={{minWidth: "200px"}}>
                    <b>Municipality:</b>
                  </td>
                  <td style={{minWidth: "200px"}}>
                    {idea.tempmunicipality.name}
                  </td>
                  <td className='res_table' style={{minWidth: "200px"}}>
                    <b>State:</b>
                  </td>
                  <td className='res_table' style={{minWidth: "200px"}}>
                    {idea.area}
                  </td>
                </tr>
                <tr className='res_show_table'>
                  <td style={{minWidth: "200px"}}>
                    <b>State:</b>
                  </td>
                  <td style={{minWidth: "200px"}}>{idea.temp_area}</td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          ""
        )}
      </div>
      {openModal ? (
        <EditModal
          isAdd={isAdd}
          handleInput={handleInput}
          handleSubmit={handleSubmit}
          user={user}
          toggleModal={toggleModal}
        />
      ) : (
        ""
      )}
    </>
  );
}
