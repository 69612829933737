import React from "react";
import "./IdeaPage.css";
import Profile from "../../../components/profile";

function Idea({children}) {
  return (
    <>
      <div id='idea-page'>
        <div className='box-1 '>
          <div className='Profile'>
            <Profile/>
          </div>
        </div>
        <div className='box-2'>
          <div className='feed'>{children}</div>
        </div>
        <div className='box-3'>
        </div>
      </div>
    </>
  );
}

export default Idea;
