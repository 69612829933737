import React from "react";
import { Switch, Route } from "react-router-dom";
import Login from "../components/login";
import VerifyEmailLogin from "../components/login/VerifyEmailLogin";
import SignUp from "../components/signup";
import ResetPassword from "../components/login/ResetPassword";
import Home from "../modules/homepage/home/Home";
import Layout from "../modules/common/layouts/Layout";
import PrivacyPolicy from "../modules/homepage/privacy-policy/PrivacyPolicy";
const FrontEndRoutes = () => (
  <Layout>
    <Switch>
      <Route path='/' exact component={Home} />
      <Route path='/login' exact component={Login} />
      <Route path='/privacy-policy' exact component={PrivacyPolicy} />
      <Route path='/login/:verifylink' exact component={VerifyEmailLogin} />
      <Route
        path='/reset-password/:resetlink'
        exact
        component={ResetPassword}
      />
      <Route path='/signup' exact component={SignUp} />
    </Switch>
  </Layout>
);
export default FrontEndRoutes;
