import React from "react";
import "./Home.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link } from "react-router-dom";
import Carousel from "../../../components/carousel/Carousel";
import { useSelector } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function Home() {
  const user = useSelector((state) => state.userAuth?.isLoggedIn);
  const names = useSelector((state) => state.userAuth?.details?.user?.name);
  return (
    <>
      <div className='main-wrapper'>
        <div className='top-banner'>
          <div className='top-banner-left'>
            <div className='top-banner-txt-container'>
              <span style={{ display: "block" }} className='span_front'>
                {user && (
                  <span
                    className='primary-txt span_front1'
                    style={{ display: "block" }}
                  >
                    Hi {names && names.split(" ")[0]},
                  </span>
                )}
                <span
                  className='primary-txt span_front1'
                  style={{ display: "block" }}
                >
                  Welcome to
                </span>
                <span className='secondary-txt'>Spill Your Ideas</span>
              </span>
              <div className='hidden-banner'>
                <Carousel />
              </div>

              <ul className='banner-links'>
                <Link to='/idea'>
                  <li className='banner-link-item'>
                    <span className='banner-link-text'>Looking For Ideas?</span>
                    <span className='banner-icon'>
                      <ArrowForwardIosIcon />
                    </span>
                  </li>
                </Link>
                <Link to='/investor'>
                  <li className='banner-link-item'>
                    <span className='banner-link-text'>
                      Looking For Investors?
                    </span>
                    <span className='banner-icon'>
                      <ArrowForwardIosIcon />
                    </span>
                  </li>
                </Link>
                <Link to={user ? "/idea/dashboard/add-idea" : "/login"}>
                  <li className='banner-link-item'>
                    <span className='banner-link-text'>Add Your Ideas</span>
                    <span className='banner-icon'>
                      <ArrowForwardIosIcon />
                    </span>
                  </li>
                </Link>
                <Link to={user ? "/investor/investor-form" : "/login"}>
                  <li className='banner-link-item'>
                    <span className='banner-link-text'>Invest</span>
                    <span className='banner-icon'>
                      <ArrowForwardIosIcon />
                    </span>
                  </li>
                </Link>
              </ul>
            </div>
          </div>
          <div className='top-banner-right'>
            <Carousel />
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
