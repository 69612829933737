import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleLogin from "react-google-login";
import GoogleIcon from "@mui/icons-material/Google";
import React from "react";
import {
  FacebookLoginService,
  GoogleLoginService,
} from "../../services/AuthService";
import { authData } from "../../redux/actions";
import * as ActionTypes from "../../redux/ActionTypes";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

function SocialLogin(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  async function facebookLogin(data) {
    props.handleLoading(true);
    const result = await FacebookLoginService(data);
    if (result && result.status === "success") {
      localStorage.setItem("user-token", result.token);
      dispatch(authData(result));
      if (result.user.roles.some((role) => role.slug === "super-admin")) {
        history.push("/dashboard");
      } else {
        history.push("/");
      }
      props.handleError(false);
    } else {
      props.handleError(true);
      dispatch({ type: ActionTypes.LOGIN_ERROR, result });
    }
    props.handleLoading(false);
  }
  async function googleLogin(data) {
    props.handleLoading(true);
    const result = await GoogleLoginService(data);
    if (result && result.status === "success") {
      localStorage.setItem("user-token", result.token);
      dispatch(authData(result));
      if (result.user.roles.some((role) => role.slug === "super-admin")) {
        history.push("/dashboard");
      } else {
        history.push("/");
      }
      props.handleError(false);
    } else {
      dispatch({ type: ActionTypes.LOGIN_ERROR, result });
      props.handleError(true);
    }
    props.handleLoading(false);
  }
  function googleLoginError(e) {
    props.handleError(e);
  }
  return (
    <div>
      <div className='other-logins'>
        <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
          autoLoad={false}
          callback={facebookLogin}
          render={(renderProps) => (
            <div
              className='socials'
              role='presentation'
              onClick={renderProps.onClick}
            >
              <span className='socials-logo'>
                <FacebookIcon style={{ color: "#4064AC" }} />
              </span>
              <span className='socials-txt'> Log in with Facebook</span>
            </div>
          )}
        />
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_APP_ID}
          render={(renderProps) => (
            <div
              className='socials'
              role='presentation'
              onClick={renderProps.onClick}
            >
              <span className='socials-logo'>
                <GoogleIcon style={{ color: "#4282EF" }} />
              </span>
              <span className='socials-txt'> Log in with Google</span>
            </div>
          )}
          onSuccess={googleLogin}
          onFailure={googleLoginError}
          cookiePolicy={"single_host_origin"}
        />
      </div>
      <div className='login-divider'>OR</div>
    </div>
  );
}

export default SocialLogin;
