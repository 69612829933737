import CircleOutlined from "@mui/icons-material/CircleOutlined";
import HourglassEmptyOutlined from "@mui/icons-material/HourglassEmptyOutlined";
import { CircularProgress } from "@mui/material";
import { format } from "date-fns";
import { parseISO } from "date-fns/esm";
import React, { Component } from "react";

import PaginationPage from "../../../../components/pagination/Pagination";
import { GetProposalStatusService } from "../../../../services/AdminServices";

class ProposalsData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proposals: [],
      user: [],
      loading: true,
      currentPage: 1,
      totalPage: 0,
      pageLimit: 10,
    };
  }

  async componentDidMount() {
    await this.getItems(this.state.currentPage, this.state.pageLimit);
  }

  async getItems(page, limit) {
    let result = await GetProposalStatusService(page, limit);
    if (result && result.status === "success") {
      console.log("ideas from user arrived");
      this.setState({
        proposals: result.proposal,
        loading: false,
        pageLimit: limit,
        currentPage: page,
        totalPage: Math.ceil(result.totalItems / limit),
      });
    } else {
      console.log("error has occurred");
    }
  }

  async pagination(page) {
    await this.getItems(page, this.state.pageLimit);
  }

  render() {
    var ideas_HTMLTABLE = "";
    if (this.state.loading) {
      ideas_HTMLTABLE = (
        <tr>
          <td colSpan='7' style={{ textAlign: "center" }}>
            <CircularProgress />
          </td>
        </tr>
      );
    } else {
      ideas_HTMLTABLE = this.state.proposals.map((item, index) => {
        return (
          <tr key={item.id}>
            <td>{index + 1}</td>
            <td>{item.ideas.title}</td>
            <td>{item.ideas.user?.name}</td>
            <td>{item.investors?.name}</td>
            <td> {format(
              parseISO(item.created_at, "yyyy-MM-dd H:m:s", new Date()),
              "MMM do, yyyy"
            )}</td>
            <td style={{ textAlign: "center" }}>
              {+item.status === 2 ? (
                <span className={"table-rejected-btn"}>
                  <CircleOutlined size={"22px"} />
                </span>
              ) : +item.status === 1 ? (
                <span className='table-accepted-btn'>
                  <CircleOutlined size={"22px"} />
                </span>
              ) : (
                <span className={"table-ongoing-btn"}>
                  <HourglassEmptyOutlined size={"22px"} />
                </span>
              )}
            </td>
          </tr>
        );
      });
    }
    return (
      <>
        <div className='table-wrapper table-wrapper_tabs'>
          <div className='table-block'>
            <div className='table-contents table-contents_tabs'>
              <table id='db-table'>
                <thead>
                  <tr>
                    <th>SN</th>
                    <th>Idea Name</th>
                    <th>Idea Holder</th>
                    <th>Investor</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>{ideas_HTMLTABLE}</tbody>
              </table>
            </div>
            <div className='pagination'>
              <PaginationPage
                totalPage={this.state.totalPage}
                currentPage={this.state.currentPage}
                changePage={this.pagination.bind(this)}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ProposalsData;
