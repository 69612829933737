import { Box, Tab } from "@mui/material";
import React, {useState} from "react";

import Proposals from "./Proposals";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
function AdminProposals() {
  const [search, setSearch] = useState(true);
  const showSearch = () => setSearch(!search);
  const [tabIndex, setTabIndex] = useState("1");
  const handTablChange = (event, index) => {
    setTabIndex(index);
  };
  return (
    <div className='container-fluid'>
      <div className='table-wrapper'>
        <div className='table-header'>
          <span>Proposals</span>
          {search ? (
            <SearchIcon onClick={showSearch} style={{marginRight: "20px", cursor:"pointer"}}/>
          ) : (
            <div className='search'>
              <input type='search' placeholder='Search'/>
              <CloseIcon style={{marginTop: "5px", position:"absolute", marginLeft:"190px", cursor:"pointer"}} onClick={showSearch}/>
            </div>
          )}
        </div>
        <div>
          <TabContext value={tabIndex}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handTablChange}
                aria-label='lab API tabs example'
                variant="scrollable"
              >
                <Tab label='Proposals' value='1' />
                <Tab label='Pending' value='2' />
                <Tab label='Accepted' value='3' />
                <Tab label='Rejected' value='4' />
              </TabList>
            </Box>

            <TabPanel value="1">
              <Proposals status={null}/>
            </TabPanel>
            <TabPanel value="2">
              <Proposals status={1}/>
            </TabPanel>
            <TabPanel value="3">
              <Proposals status={3}/>
            </TabPanel>
            <TabPanel value="4">
              <Proposals status={4}/>
            </TabPanel>
          </TabContext>
        </div>
      </div>
    </div>
  );
}

export default AdminProposals;
