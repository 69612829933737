import React, {useState} from "react";
import "./Modal.css";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useDispatch, useSelector} from "react-redux";
import * as ActionTypes from "../../redux/ActionTypes";
import {useHistory} from "react-router-dom";
import {LogOutUserService, SendPasswordService,} from "../../services/AuthService";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
function PasswordModal(props) {
  const notify = () =>
    toast.success("Password Changed. Please re-login to continue", {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const [passwordMatched, setPasswordMatched] = useState(true);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [validationError, setValidationError] = useState({});

  const sendPassword = async () => {
    if (oldPassword == "" || newPassword == "" || confirmPassword == "") {
      let errors = {};

      if (oldPassword == "") {
        errors = {
          ...errors,
          oldPassword: "Old Password is Required!",
        };
      }
      if (newPassword == "") {
        errors = {
          ...errors,
          newPassword: "New Password is Required!",
        };
      }
      if (confirmPassword == "") {
        errors = {
          ...errors,
          confirmPassword: "Confirm Password is Required!",
        };
      }
      setValidationError(errors);
    } else {
      if (newPassword === confirmPassword) {
        const res = await SendPasswordService({
          email,
          newPassword,
          oldPassword,
        });
        if (res.status == "success") {
          notify();
          props.toggleModal();
          logout();
        } else {
          setErrorMsg(res.msg);
        }
        setPasswordMatched(true);
      } else {
        setPasswordMatched(false);
      }
    }
  };
  const dispatch = useDispatch();
  const history = useHistory();

  const name = useSelector((state) => state.userAuth.details.user.name);
  const email = useSelector((state) => state.userAuth.details.user.email);

  async function logout() {
    let result = await LogOutUserService();
    if (result && result.status === "success") {
      localStorage.clear();
      dispatch({type: ActionTypes.LOGOUT_SUCCESS, payload: result});
      history.push("/login");
    } else {
      dispatch({type: ActionTypes.LOGIN_ERROR, result});
    }
  }

  return (
    <>
      <div className='modalBackground'>
        <div className='modalContainer'>
          <div className='modal-title'>
            <span className='login-form-title'>Account Profile</span>
            <CancelOutlinedIcon onClick={props.toggleModal} cursor='pointer'/>
          </div>

          <div className='body '>
            <form className={"changePassword-container"}>
              <label htmlFor='fullName' className='login-form-title'>
                                Full Name
              </label>
              <input
                type='text'
                name='fullName'
                value={name}
                disabled
              />
              <label htmlFor='email' className='login-form-title'>
                                Email
              </label>
              <input
                type='text'
                name='email'
                value={email}
                disabled
              />
              <label htmlFor='oldPassword' className='login-form-title'>
                                Old password
              </label>
              <input
                type='password'
                name='oldPassword'
                onChange={(e) => {
                  delete validationError.oldPassword;
                  setOldPassword(e.target.value);
                }}
              />
              {validationError.oldPassword && (
                <span className='form-error'>
                  {validationError.oldPassword}
                </span>
              )}
              {console.log(validationError)}
              <label htmlFor='newPassword' className='login-form-title'>
                                New password
              </label>
              <input
                type='password'
                name='newPassword'
                onChange={(e) => {
                  delete validationError.newPassword;
                  setNewPassword(e.target.value);
                }}
              />
              {validationError.newPassword && (
                <span className='form-error'>
                  {validationError.newPassword}
                </span>
              )}

              <label htmlFor='rePassword' className='login-form-title'>
                                Re-enter password
              </label>
              <input
                type='password'
                name='rePassword'
                onChange={(e) => {
                  delete validationError.confirmPassword;
                  setConfirmPassword(e.target.value);
                }}
              />
              {validationError.confirmPassword && (
                <span className='form-error'>
                  {validationError.confirmPassword}
                </span>
              )}
            </form>
            {!passwordMatched && (
              <div className={"error-msg"}>
                <div className='svg_cross'>
                  <CancelOutlinedIcon size={"40px"}/>
                </div>
                <div>
                                    Password didn&apos;t match! <br/>
                </div>
              </div>
            )}
            {errorMsg && (
              <div className={"error-msg"}>
                <div className='svg_cross'>
                  <CancelOutlinedIcon size={"40px"}/>
                </div>
                <div>
                  {errorMsg} <br/>
                </div>
              </div>
            )}
            <div className='footer'>
              <button onClick={props.toggleModal} className={"cancel-btn"}>
                                Cancel
              </button>
              <button
                onClick={() => {
                  sendPassword();
                }}
                className={"success-btn"}
              >
                                Change Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PasswordModal;
