/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";

import {
  Button,
  Collapse,
  IconButton,
  List,
  ListItemButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";

import { NotificationData } from "../resources/navbar-data/notifications";
import logo from "../../../assets/img/logo2.png";

import "../Dashboard.css";
import "./Sidebar.scss";
import profile1 from "../../../assets/img/profile2.png";
import { useDispatch, useSelector } from "react-redux";
import { LogOutUserService } from "../../../services/AuthService";
import * as ActionTypes from "../../../redux/ActionTypes";
import PasswordModal from "../../../components/modal/ChangePasswordModal";
import CloseIcon from "@mui/icons-material/Close";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
function Sidebar({ children, SidebarData }) {
  const dispatch = useDispatch();
  const history = useHistory();

  async function logout(e) {
    let result = await LogOutUserService();
    if (result && result.status === "success") {
      localStorage.clear();
      dispatch({ type: ActionTypes.LOGOUT_SUCCESS, payload: result });
      history.push("/");
    } else {
      dispatch({ type: ActionTypes.LOGIN_ERROR, result });
    }
  }

  const location = useLocation();
  const url = location.pathname;
  const splitUrl = url.split("/");

  const [userName, setUserName] = useState("");
  const user = useSelector((state) => state.userAuth.isLoggedIn);

  const [openProfile, setOpenProfile] = useState(false);
  const toggleProfile = () => setOpenProfile(!openProfile);

  useEffect(() => {
    const userDetail = localStorage.getItem("state");
    if (user) {
      const toJSon = JSON.parse(userDetail);
      setUserName(toJSon.userAuth.details?.user.name);
    }
  }, [user]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 796) {
        setSidebar(true);
      }
      else if (window.innerWidth >= 796) {
        setSidebar(false);
      }
    });
  });

  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => {
    setOpenModal(!openModal);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const dropdownOpen = Boolean(anchorEl);

  const toggleDropdown = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDropdownClose = () => {
    setAnchorEl(null);
  };
  const [anchorElNotification, setAnchorElNotification] = useState(null);
  const openNotification = Boolean(anchorElNotification);
  const handleClickNotification = (event) => {
    setAnchorElNotification(event.currentTarget);
  };
  const handleCloseNotification = () => {
    setAnchorElNotification(null);
  };
  const [anchorElNav, setAnchorElNav] = useState(null);
  const openNav = Boolean(anchorElNav);
  const handleClickNav = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNav = () => {
    setAnchorElNav(null);
  };
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);


  const [notification, setNotification] = useState(false);
  const toggleNotification = () => setNotification((prevState) => !prevState);

  const [profile, SetProfile] = useState({
    investor: false,
    ideaholder: false,
    investorandideaholder: false,
  });
  const userData = useSelector((state) => state.userAuth);

  useEffect(() => {
    const loadData = async () => {
      let data = {
        ...profile,
        investor: userData?.isinvestor,
        ideaholder: userData?.isideaholder,
        investorandideaholder: userData?.isinvestor && userData?.isideaholder,
      };
      SetProfile(data);
    };
    loadData();
  }, []);

  const names = useSelector(
    (state) => state.userAuth && state.userAuth?.details?.user?.name
  );

  return (
    <div className='mainpage'>
      {!sidebar ? (
        <div className='sidebar-content'>
          <div className='sidebar'>
            <nav className='sidebar-menu'>
              <div className='menu-1'>
                <div className='sidebar-header'>
                  <div className='sidebar-logo'>
                    <Link id='logo' to='/dashboard/home'>
                      <img
                        alt=''
                        src={logo}
                        width='40'
                        height='40'
                        className='d-inline-block align-top logo-db logoImg mr-2'
                      />{" "}
                      <b style={{ fontWeight: "100", fontSize: "18px" }}>
                        SPILL YOUR IDEAS
                      </b>
                    </Link>
                    {/* <span className='cross_small_sidebar'>
                      <CloseIcon onClick={showSidebar} />
                    </span> */}
                  </div>
                  <div className='sidebar-dropdown'>
                    <Button
                      id='basic-button'
                      aria-controls={dropdownOpen ? "basic-menu" : undefined}
                      aria-haspopup={true}
                      aria-expanded={dropdownOpen ? true : undefined}
                      onClick={toggleDropdown}
                    >
                      {splitUrl[1] === "idea"
                        ? "Idea Holder Dashboard"
                        : splitUrl[1] === "investor"
                          ? "Investor Dashboard"
                          : "Admin Dashboard"}
                      <ExpandMoreOutlinedIcon />
                    </Button>
                    <Menu
                      id='basic-menu'
                      anchorEl={anchorEl}
                      open={dropdownOpen}
                      onClose={handleDropdownClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem onClick={handleDropdownClose}>
                        <Link to='/idea/dashboard'>Idea Holder Dashboard</Link>
                      </MenuItem>
                      <MenuItem onClick={handleDropdownClose}>
                        <Link to='/investor/dashboard'>Investor Dashboard</Link>
                      </MenuItem>
                    </Menu>
                  </div>
                </div>

                <ul className='sidebar-menu-items'>
                  {/* <a className='menu-bars-mini'>
                    <CancelOutlinedIcon onClick={showSidebar} />
                  </a> */}

                  <div className='sidebar-item'>
                    {SidebarData.map((item, index) => {
                      return (
                        <div key={index}>
                          <NavLink
                            exact
                            to={item.path}
                            activeClassName='sidebar-active '
                          >
                            <section className='sidebar-item-section'>
                              <div className='sidebar-link'>
                                <span className='sidebar-icon'>
                                  {item.icon}
                                </span>
                                <span className='sidebar-text'>
                                  {item.title}
                                </span>
                              </div>
                            </section>
                          </NavLink>
                        </div>
                      );
                    })}
                  </div>
                </ul>
                <div className='sidebar-home'>
                  <Link to='/'>
                    <div className='sidebar-link'>
                      <span className='sidebar-icon'>
                        <HomeWorkOutlinedIcon />
                      </span>
                      <span className='sidebar-text'>Go to Home</span>
                    </div>
                  </Link>
                </div>
              </div>
              <div
                className='menu-2'
                onClick={showSidebar}
                role='presentation'
              ></div>
            </nav>
          </div>
        </div>
      ) : (
        <div></div>
        // <div className='mini-sidebar-content'>
        //   <div className='mini-sidebar'>
        //     <nav className='mini-sidebar-menu'>
        //       <div className='sidebar-logo'>
        //         <Link id='logo' to='/dashboard/home'>
        //           <img
        //             alt=''
        //             src={logo}
        //             width='40'
        //             height='40'
        //             className='d-inline-block align-top logo-db logoImg '
        //           />{" "}
        //         </Link>
        //       </div>
        //       <ul className='mini-sidebar-menu-items'>
        //         <div className='sidebar-item'>
        //           {SidebarData.map((item, index) => {
        //             return (
        //               <div key={index}>
        //                 <NavLink
        //                   exact
        //                   to={item.path}
        //                   activeClassName='sidebar-active'
        //                 >
        //                   <section className='sidebar-item-section'>
        //                     <Tooltip id='button-tooltip'>{item.title}</Tooltip>

      //                     <span className='d-inline-block'>
      //                       <div className='mini-sidebar-link'>
      //                         <span className='sidebar-icon'>
      //                           {item.icon}
      //                         </span>
      //                       </div>
      //                     </span>
      //                   </section>
      //                 </NavLink>
      //               </div>
      //             );
      //           })}
      //         </div>
      //       </ul>
      //       <div>
      //         <Link to='/'>
      //           <div className='sidebar-link'>
      //             <span className='sidebar-icon'>
      //               <HomeWorkOutlinedIcon />
      //             </span>
      //           </div>
      //         </Link>
      //       </div>
      //     </nav>
      //   </div>
      // </div>
      )}
      <div className='right-content'>
        <header className='topbar top_bar'>
          <div className='topbar-wrapper'>
            <div className='nav-mini'>
              {!sidebar ? (
                <IconButton onClick={showSidebar}>
                  <HighlightOffOutlinedIcon size={"26px"} />
                </IconButton>
              ) : (
                <IconButton onClick={showSidebar}>
                  <MenuOutlinedIcon size={"26px"} />
                </IconButton>
              )}
            </div>
            <div className='nav-left'>
              {/* <Link to='/' className='menu-bars'>
                <MenuOutlinedIcon onClick={showSidebar} />
              </Link> */}
            </div>
            <div className='nav-right'>
              <div>
                <Button
                  id='demo-positioned-button'
                  aria-controls={
                    openNotification ? "demo-positioned-menu" : undefined
                  }
                  aria-haspopup={true}
                  aria-expanded={openNotification ? true : undefined}
                  onClick={handleClickNotification}
                >
                  <NotificationsActiveOutlinedIcon
                    style={{ fontSize: "20px" }}
                  />
                  <span className='topIconBadge'>
                    {NotificationData.length}
                  </span>
                </Button>
                <Menu
                  id='demo-positioned-menu'
                  aria-labelledby='demo-positioned-button'
                  anchorEl={anchorElNotification}
                  open={openNotification}
                  onClose={handleCloseNotification}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  {NotificationData.map((notificationItem, index) => {
                    return (
                      <MenuItem key={index} onClick={handleCloseNotification}>
                        <p className='notification'>
                          {notificationItem.notification}
                        </p>
                        <p className='time-stamp'>
                          {notificationItem.timeStamp}
                        </p>
                      </MenuItem>
                    );
                  })}
                </Menu>
              </div>
              <div className={"navProfile"}>
                {!profile1 ? (
                  <img
                    style={{
                      borderRadius: "50%",
                      objectFit: "cover",
                      cursor: "pointer",
                    }}
                    src={profile1}
                    alt=''
                    height='40px'
                    width='40px'
                    onClick={toggleProfile}
                    role='presentation'
                  />
                ) : (
                  <>
                    <Button
                      id='demo-positioned-button'
                      aria-controls={
                        openNav ? "demo-positioned-menu" : undefined
                      }
                      aria-haspopup={true}
                      aria-expanded={openNav ? true : undefined}
                      onClick={handleClickNav}
                    >
                      {userName.split("")[0]}
                    </Button>
                    <Menu
                      id='demo-positioned-menu'
                      aria-labelledby='demo-positioned-button'
                      anchorEl={anchorElNav}
                      open={openNav}
                      onClose={handleCloseNav}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      {profile.investorandideaholder ? (
                        <div>
                          <MenuItem onClick={handleCloseNav}>
                            <Link to='/idea/dashboard'>
                              <LightbulbOutlinedIcon
                                style={{ marginRight: "10px" }}
                              />
                              View Idea Dashboard
                            </Link>
                          </MenuItem>
                          <MenuItem onClick={handleCloseNav}>
                            <Link to='/investor/dashboard'>
                              <BusinessCenterOutlinedIcon
                                style={{ marginRight: "10px" }}
                              />
                              View Investor Dashboard
                            </Link>
                          </MenuItem>
                        </div>
                      ) : profile.investor ? (
                        <div>
                          <MenuItem onClick={handleCloseNav}>
                            <Link to='/idea/idea-form'>
                              <LightbulbOutlinedIcon
                                style={{ marginRight: "10px" }}
                              />
                              Add Idea Profile
                            </Link>
                          </MenuItem>
                          <MenuItem onClick={handleCloseNav}>
                            <Link to='/investor/dashboard'>
                              <BusinessCenterOutlinedIcon
                                style={{ marginRight: "10px" }}
                              />
                              View Investor Dashboard
                            </Link>
                          </MenuItem>
                        </div>
                      ) : profile.ideaholder ? (
                        <div>
                          <MenuItem onClick={handleCloseNav}>
                            <Link to='/idea/dashboard'>
                              <LightbulbOutlinedIcon
                                style={{ marginRight: "10px" }}
                              />
                              View Idea Dashboard
                            </Link>
                          </MenuItem>
                          <MenuItem onClick={handleCloseNav}>
                            <Link to='/investor/create-profile'>
                              <BusinessCenterOutlinedIcon
                                style={{ marginRight: "10px" }}
                              />
                              Add Investor Profile
                            </Link>
                          </MenuItem>
                        </div>
                      ) : (
                        <div>
                          <MenuItem onClick={handleCloseNav}>
                            <Link to='/idea/idea-form'>
                              <LightbulbOutlinedIcon
                                style={{ marginRight: "10px" }}
                              />
                              Add Idea Profile
                            </Link>
                          </MenuItem>
                          <MenuItem onClick={handleCloseNav}>
                            <Link to='/investor/create-profile'>
                              <BusinessCenterOutlinedIcon
                                style={{ marginRight: "10px" }}
                              />
                              Add Investor Profile
                            </Link>
                          </MenuItem>
                        </div>
                      )}
                      <MenuItem
                        onClick={() => {
                          handleCloseNav();
                          toggleModal();
                          // toggleProfile();
                        }}
                      >
                        <SettingsOutlinedIcon style={{ marginRight: "10px" }} />
                        Change Password
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleCloseNav();
                          logout();
                        }}
                      >
                        <ExitToAppOutlinedIcon
                          style={{ marginRight: "10px" }}
                        />
                        Logout
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </div>
            </div>
          </div>
        </header>

        {children}
      </div>
      {openModal && <PasswordModal toggleModal={toggleModal} />}
    </div>
  );
}

export default Sidebar;
