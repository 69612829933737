import React, { Component } from "react";
import axios from "axios";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import PaginationPage from "../../../../components/pagination/Pagination";

class IdeaAgreements extends Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    const res = await axios.get(process.env.REACT_APP_API_BASE_URL+"/api/ideas");
    this.setState({
      ideas: res.data.ideas,
      loading: false,
    });
  }

  render() {
    var ideas_HTMLTABLE = "";
    if (!this.state.loading) {
      ideas_HTMLTABLE = (
        <tr>
          <td colSpan='6'>
            <h2>Loading...</h2>
          </td>
        </tr>
      );
    } else {
      ideas_HTMLTABLE = this.state.ideas.map((item) => {
        return (
          <tr key={item.id}>
            <td>{item.id}</td>
            <td>{item.idea_name}</td>
            <td>20</td>
            <td>90</td>
            <td>pending</td>
            <td> Edit</td>
            <td>Delete</td>
          </tr>
        );
      });
    }
    return (
      <>
        <div className='container-fluid'>
          <div className='table-wrapper'>
            <div className='table-block'>
              <div className='table-header'>
                <span>Agreements</span>
              </div>
              <div className='table-contents'>
                <table id='db-table'>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Idea Name</th>
                      <th>Idea Holder Name</th>
                      <th>Investor Name</th>
                      <th>Agreement Date</th>
                      <th colSpan='2' style={{ textAlign: "center" }}>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>ID</td>
                      <td>Idea Name</td>
                      <td>Views</td>
                      <td>Access</td>
                      <td>Status</td>
                      <td style={{ textAlign: "center" }}>
                        <RemoveRedEyeOutlinedIcon />
                      </td>
                    </tr>{" "}
                    <tr>
                      <td>ID</td>
                      <td>Idea Name</td>
                      <td>Views</td>
                      <td>Access</td>
                      <td>Status</td>
                      <td style={{ textAlign: "center" }}>
                        <RemoveRedEyeOutlinedIcon />
                      </td>
                    </tr>
                    {ideas_HTMLTABLE}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='pagination'>
              <PaginationPage />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default IdeaAgreements;
