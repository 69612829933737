import React, { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { AccessRequestDisplay } from "../../../../services/AdminServices";

import IdeaAccess from "./AccessTable";
import { Box, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

function Access() {
  const [search, setSearch] = useState(true);
  const showSearch = () => setSearch(!search);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState([]);
  const [accepted, setAccepted] = useState([]);
  const [rejected, setRejected] = useState([]);
  const [currentPage] = useState(1);
  const [pageLimit] = useState(10);
  const [totalPage] = useState(0);
  const [tabIndex, setTabIndex] = useState("3");
  const handTablChange = (event, index) => {
    setTabIndex(index);
  };
  const dataAccess = async () => {
    setData([]);
    setLoading(true);
    const res = await AccessRequestDisplay(currentPage, pageLimit);
    if (res?.status === "success") {
      setLoading(false);
      setData(res.data);
      const pendingD = res.data.filter((f) => {
        return f.access_status === 0;
      });

      const acceptedD = res.data.filter((f) => {
        return f.access_status === 1;
      });
      const rejectedD = res.data.filter((f) => {
        return f.access_status === 2;
      });
      setRejected(rejectedD);
      setPending(pendingD);
      setAccepted(acceptedD);
    }
  };

  async function pagination(page) {
    dataAccess(page);
  }

  //first ma pending ko auxa ani tab ma click garey 1 or 2
  useEffect(() => {
    dataAccess();
  }, []);

  return (
    <div className='container-fluid'>
      <div className='table-wrapper'>
        <div className='table-header'>
          <span>Access Request</span>
          {search ? (
            <SearchOutlinedIcon onClick={showSearch} style={{ marginRight: "20px", cursor:"pointer" }} />
          ) : (
            <div className='search'>
              <input type='search' placeholder='Search' />
              <CloseIcon style={{marginTop: "5px", position:"absolute", marginLeft:"190px", cursor:"pointer"}} onClick={showSearch} />
            </div>
          )}
        </div>
        <div>
          <TabContext value={tabIndex}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handTablChange}
                aria-label='lab API tabs example'
                variant="scrollable"
              >
                <Tab label='All' value='1' />
                <Tab label='Pending' value='2' />
                <Tab label='Accepted' value='3' />
                <Tab label='Rejected' value='4' />
              </TabList>
            </Box>
            <TabPanel value='1'>
              <IdeaAccess
                renderedData={data}
                loading={loading}
                type={"All"}
                currentPage={currentPage}
                pageLimit={pageLimit}
                totalPage={totalPage}
                changePage={pagination}
              />
            </TabPanel>
            <TabPanel value='2'>
              <IdeaAccess
                renderedData={pending}
                loading={loading}
                type={"rejected"}
                currentPage={currentPage}
                pageLimit={pageLimit}
                totalPage={totalPage}
                changePage={pagination}
              />
            </TabPanel>
            <TabPanel value='3'>
              <IdeaAccess
                renderedData={accepted}
                loading={loading}
                type={"accepted"}
                currentPage={currentPage}
                pageLimit={pageLimit}
                totalPage={totalPage}
                changePage={pagination}
              />
            </TabPanel>

            <TabPanel value='4'>
              <IdeaAccess
                renderedData={rejected}
                loading={loading}
                type={"rejected"}
                currentPage={currentPage}
                pageLimit={pageLimit}
                totalPage={totalPage}
                changePage={pagination}
              />
            </TabPanel>
          </TabContext>
        </div>
      </div>
    </div>
  );
}

export default Access;
