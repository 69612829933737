import React, { useEffect, useRef, useState } from "react";
import "./IdeaForm.css";

import {
  AddIdeaProfileService,
  RemoveFile,
  UploadFile,
} from "../../../services/IdeaHolderServices";
import { GetMunicipalitiesService } from "../../../services/BasicService";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { authData } from "../../../redux/actions";
import ProfileCreationSuccess from "./ProfileCreationSucess";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import { UploaderComponent } from "@syncfusion/ej2-react-inputs";
import { toast } from "react-toastify";

function IdeaProfileForm() {
  // eslint-disable-next-line no-unused-vars
  const [dropdown, setDropdownOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  // const toggleModal = () => this.setState({openModal:!this.state.openModal})
  const toggleModal = () => setOpenModal(!openModal);

  const dispatch = useDispatch();

  const [ideaHolder, setIdeaHolder] = useState({
    name: "",
    age: "",
    citizenship: "",
    citizenship_image: "",
    phone: "",
    gender: "",
    email: "",
    selected_municipality_id: null,
    selected_municipality_name: "",
    area: "",
    temp_selected_municipality_id: null,
    temp_selected_municipality_name: "",
    temp_area: "",
    profile_picture: null,
  });
  const [municipalityList, setMunicipalityList] = useState([]);
  const [errors, setErrors] = useState({});
  const cvRef = useRef();
  useEffect(() => {
    console.log(cvRef);
  }, [cvRef.current]);

  const userData = useSelector((state) => state.userAuth);

  const GetMunicipalities = async () => {
    const result = await GetMunicipalitiesService(ideaHolder.muni_search);
    if (result?.status === "success") {
      let municipality_list_mapped = result.municipalityList.map( (municipality)=> {
        var info = {
          label: municipality?.name + ", " + municipality.district?.name,
          id: municipality.id,
        };
        return info;
      });
      let name = userData?.details.user?.name;
      let email = userData?.details.user?.email;
      setIdeaHolder({
        ...ideaHolder,
        name: name,
        email: email,
      });
      setMunicipalityList(municipality_list_mapped);
    }
  };
  useEffect(() => {
    GetMunicipalities();
  }, []);

  //   const [ideaHolderData, setIdeaHolderData] = useState([]);

  const handleDropdownInput = (inp_var, value) => {
    setIdeaHolder({ ...ideaHolder, [inp_var]: value });
    if (value === "" && inp_var!=="temp_selected_municipality_id" && inp_var!=="temp_selected_municipality_name") {
      errors[inp_var] = true;
    } else {
      errors[inp_var] = false;
    }
    setErrors({
    });
  };

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setIdeaHolder({ ...ideaHolder, [name]: value });
    if (value === "" && name!=="temp_area") {
      errors[name] = true;
    } else {
      errors[name] = false;
    }
    setErrors({
      ...errors,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    for (const [key, value] of Object.entries(ideaHolder)) {
      if (value === "" && key !== "profile_picture" && key!=="temp_area" && key!=="temp_selected_municipality_id" && key!=="temp_selected_municipality_name") {
        errors[key] = true;
      } else {
        errors[key] = false;
      }
      setErrors({ ...errors });
    }
    if (Object.values(errors).filter((error) => error).length > 0) {
      return;
    }
    let result = await AddIdeaProfileService(ideaHolder);
    if (result && result.status === "success") {
      localStorage.setItem("user-token", result.token);
      dispatch(authData(result));
      toggleModal();
    } else {
      toast.info("Unable to update idea form", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const onFileSelected = async (event, id) => {
    let result = await UploadFile(
      event.filesData[0].rawFile,
      id === "cv" ? "idea-holder-cv" : ""
    );
    if (result && result.status === "success") {
      setIdeaHolder({ ...ideaHolder, [id]: result.file });
      errors[id] = false;
    } else {
      errors[id] = true;
    }
    setErrors({
      ...errors,
    });
  };
  const onRemoveFile = async (args, id) => {
    let result = await RemoveFile(args);
    if (result && result.status === "success") {
      setIdeaHolder({ ...ideaHolder, [id]: "" });
    } else {
      console.log("error occurred while sending data for screening");
    }
  };
  return (
    <>
      <div className='form-wrapper f_wrap' id={"idea-form"}>
        <div className='form-header'>
          <div className='header-title'>
            <h2>Create an Idea Profile</h2>
            <span>Fill in the details below to create an idea profile</span>
          </div>
        </div>
        <div className='idea-form'>
          <div className='form-body'>
            <div className='personal-details'>
              <h3>Personal Details</h3>

              <Grid
                container
                rowSpacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
                columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
              >
                <Grid item sm={6} xs={12}>
                  <TextField
                    style={{ width: "100%" }}
                    name='name'
                    label='Full Name'
                    value={ideaHolder.name}
                    onChange={handleInput}
                    variant='outlined'
                    required
                    error={errors?.name}
                    helperText={errors?.name && "Name is required"}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    style={{ width: "100%" }}
                    name='email'
                    label='Email'
                    value={ideaHolder.email}
                    onChange={handleInput}
                    variant='outlined'
                    required
                    error={errors?.email}
                    helperText={ errors?.email
                      ? "Email is required"
                      : errors.uniqueEmail
                        ? "This email is already taken"
                        : ""}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    style={{ width: "100%" }}
                    name='phone'
                    label='Phone'
                    value={ideaHolder.phone}
                    onChange={handleInput}
                    variant='outlined'
                    required
                    error={errors?.phone}
                    helperText={errors?.phone && "Phone is required"}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    style={{ width: "100%" }}
                    name='age'
                    label='Age'
                    value={ideaHolder.age}
                    onChange={handleInput}
                    variant='outlined'
                    required
                    error={errors?.age}
                    helperText={errors?.age && "Age is required"}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id='labelGender'>Gender</InputLabel>
                    <Select
                      style={{ width: "100%" }}
                      labelId='labelGender'
                      name={"gender"}
                      value={ideaHolder.gender}
                      label="Gender"
                      onChange={handleInput}
                      variant='outlined'
                    >
                      <MenuItem value={0}>Male</MenuItem>
                      <MenuItem value={1}>Female</MenuItem>
                      <MenuItem value={2}>Others</MenuItem>
                    </Select>
                  </FormControl>
                  {errors?.gender && (
                    <span style={{ color: "red", fontSize: "11px" }}>
                      Gender is required
                    </span>
                  )}
                </Grid>

                <Grid item sm={6} xs={12}>
                  <TextField
                    style={{ width: "100%" }}
                    name='citizenship'
                    label='Citizenship No'
                    value={ideaHolder.citizenship}
                    onChange={handleInput}
                    variant='outlined'
                    required
                    error={errors?.citizenship}
                    helperText={
                      errors?.citizenship && "Citizenship is required"
                    }
                  />
                </Grid>

                <Grid item sm={6} xs={12} className="idea-form-parameter">
                  <span>Upload your Citizenship</span>
                  <UploaderComponent
                    id='citizenship'
                    type='file'
                    selected={(event) =>
                      onFileSelected(event, "citizenship_image")
                    }
                    autoUpload={true}
                    multiple={false}
                    removing={() =>
                      onRemoveFile(
                        ideaHolder.citizenship_image,
                        "citizenship_image"
                      )
                    }
                  />
                  {errors?.citizenship_image && (
                    <span style={{ color: "red", fontSize: "11px" }}>
                      Citizenship image is required
                    </span>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <span>Permanent Location</span>
                </Grid>

                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <Autocomplete
                      style={{ width: "100%" }}
                      value={ideaHolder.selected_municipality_id}
                      onChange={(event, newValue) => {
                        handleDropdownInput("selected_municipality_id", newValue);
                      }}
                      inputValue={ideaHolder.selected_municipality_name}
                      onInputChange={(event, newInputValue) => {
                        handleDropdownInput(
                          "selected_municipality_name",
                          newInputValue
                        );
                      }}
                      id='controllable-states-demo'
                      options={municipalityList}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} label='Municipality' />
                      )}
                    />
                  </FormControl>
                  {errors?.selected_municipality_name && (
                    <span style={{ color: "red", fontSize: "11px" }}>
                      Please select municipality
                    </span>
                  )}
                </Grid>

                <Grid item sm={6} xs={12}>
                  <TextField
                    style={{ width: "100%" }}
                    name='area'
                    label='Street'
                    value={ideaHolder.area}
                    onChange={handleInput}
                    variant='outlined'
                    required
                    error={errors?.area}
                    helperText={errors?.area && "Area is required"}
                  />
                </Grid>

                <Grid item xs={12}>
                  <span>Temporary Location</span>
                </Grid>

                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <Autocomplete
                      style={{ width: "100%" }}
                      className='drop-down-mui'
                      value={ideaHolder.temp_selected_municipality_id}
                      onChange={(event, newValue) => {
                        handleDropdownInput("temp_selected_municipality_id", newValue);
                      }}
                      inputValue={ideaHolder.temp_selected_municipality_name}
                      onInputChange={(event, newInputValue) => {
                        handleDropdownInput(
                          "temp_selected_municipality_name",
                          newInputValue
                        );
                      }}
                      id='controllable-states-demo'
                      options={municipalityList}
                      sx={{ width: 400 }}
                      renderInput={(params) => (
                        <TextField {...params} label='Municipality' />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item sm={6} xs={12}>
                  <TextField
                    style={{ width: "100%" }}
                    name='temp_area'
                    label='Street'
                    value={ideaHolder.temp_area}
                    onChange={handleInput}
                    variant='outlined'
                  />
                </Grid>
                <Grid item sm={6} xs={12} className="idea-form-parameter">
                  <span>
                    Upload Your CV <span className={"compulsory-field"}>*</span>
                  </span>
                  <UploaderComponent
                    id='cv'
                    type='file'
                    selected={(event) => onFileSelected(event, "cv")}
                    autoUpload={true}
                    multiple={false}
                    removing={() => onRemoveFile(ideaHolder.cv, "cv")}
                  />
                  {errors?.cv && (
                    <span style={{ color: "red", fontSize: "11px" }}>
                      Please upload your CV
                    </span>
                  )}
                </Grid>
                <Grid item sm={6} xs={12} className="idea-form-parameter">
                  <span>Upload Your Profile Pic</span>
                  <UploaderComponent
                    id='photo'
                    type='file'
                    selected={(event) => onFileSelected(event, "profile_pic")}
                    autoUpload={true}
                    multiple={false}
                    removing={() =>
                      onRemoveFile(ideaHolder.profile_pic, "profile_pic")
                    }
                  />
                </Grid>
              </Grid>
            </div>

            <div className='form-btn'>
              <Button
                style={{marginBottom: "10px"}}
                variant='contained'
                onClick={handleSubmit}
                value='Create Profile'
              >
                Create Profile
              </Button>
            </div>
          </div>
          {openModal && (
            <div>
              <ProfileCreationSuccess toggleModal={toggleModal} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default IdeaProfileForm;
