import React, { useState } from "react";

import { Link, useHistory } from "react-router-dom";
import lamp from "../../assets/img/lamp.png";
import man from "../../assets/img/man2.png";
import "./Login.css";

import { useDispatch } from "react-redux";
import {
  LoginUserService,
} from "../../services/AuthService";
import { authData } from "../../redux/actions";
import * as ActionTypes from "../../redux/ActionTypes";
import ForgotPasswordModal from "../modal/ForgotPasswordModal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { CircularProgress } from "@material-ui/core";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import SocialLogin from "../social-login/Index";
import Loader from "../../components/loader/Loader";
function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  async function login(e) {
    // setEmail(false);
    e.preventDefault();
    let item = { email, password };
    setIsSubmitting(true);
    if (email !== "" && password !== "") {
      let result = await LoginUserService(item);
      setIsLoading(false);
      if (result && result.status === "success") {
        localStorage.setItem("user-token", result.token);
        dispatch(authData(result));
        if (result.user.roles.some(role=>role.slug === "super-admin")) {
          history.push("/dashboard");
        } else {
          if(history?.location?.state?.prevRoute === "/signup"){
            history.push("/");
          }else{
            history.goBack();
          }
        }
      } else {
        // setError(true);
        dispatch({ type: ActionTypes.LOGIN_ERROR, result });
        setError(result);
        setIsLoading(false);
        setIsSubmitting(false);
      }
    }
  }

  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => setOpenModal(!openModal);

  return (
    <>
      {isLoading && <Loader/>}
      <div className='login-page-container'>
        <div className='login-left'>
          <div>
            <img src={lamp} className='lamp' alt='' />
          </div>
          <div>
            <img src={man} className='man' alt='' />
          </div>
        </div>

        <div className='login-right'>
          <div className='login-form-wrapper'>
            <div className='login-form'>
              <div className='login-head'>
                <span className='loginSubHead'>
                  <button onClick={() => history.goBack()}>
                    <ArrowBackIosNewOutlinedIcon className='mr-2' />
                  </button>
                Login
                </span>
              </div>
              <div className='login-body'>
                <SocialLogin handleLoading={(loading)=>setIsLoading(loading)} handleError={(error)=>setError(error)} />
                <form onSubmit={login}>
                  <div className='login-form-group'>
                    <TextField
                      id='email'
                      label='Email'
                      type='email'
                      name='email'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      variant='outlined'
                      fullWidth
                      required
                      error={error.email}
                    // helperText={error ? "Invalid Email" : ""}
                    />
                  </div>
                  <div className='login-form-group'>
                    <TextField
                      id='password'
                      label='Password'
                      type='password'
                      name='password'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      variant='outlined'
                      fullWidth
                      required
                      error={error.password}
                    // helperText={error ? "Invalid Password" : ""}
                    />
                  </div>
                  <div className='login-form-group'>
                    <Button
                      type='submit'
                      variant='contained'
                      color='primary'
                      className='login-btn'
                      disabled={isSubmitting}
                      fullWidth
                    >
                      {isSubmitting ? <CircularProgress size={20} /> : "Login"}
                    </Button>
                  </div>

                  {0 == 1 && (
                    <div className='login-item'>
                      <label
                        htmlFor='name'
                        className='rememberMe'
                        style={{ float: "right", color: "red" }}
                      >
                      Username Or Password Invalid
                      </label>
                    </div>
                  )}
                  {error ? (
                    <div className={"error-msg"}>
                      <div className='svg_cross'>
                        <CancelOutlinedIcon size={"40px"} />
                      </div>
                      <div>
                      Email or Password is incorrect. <br />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </form>
                <div className='login_wrap_login'>
                  <div className='login-password-txt'>
                    <label
                      role='presentation'
                      htmlFor='password'
                      className='forgotPwd'
                      onClick={toggleModal}
                    >
                    Forgot Password?
                    </label>
                  </div>
                  <div className='login-item'>
                    <p className='noAcc'>
                    Don&apos;t have an account yet?
                      <Link to='/signup'>
                        <span className='registerTxt'>&nbsp; Sign Up</span>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {openModal && (
            <div>
              <ForgotPasswordModal toggleModal={toggleModal} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Login;
