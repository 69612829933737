import React, {useState} from "react";
import "./Modal.css";
import {ForgetPasswordService} from "../../services/BasicService";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
function ForgotPasswordModal(props) {
  const [sentEmail, setSentEmail] = useState(false);
  const toggleReset = () => setSentEmail(!sentEmail);

  const [email, setEmail] = useState({email: ""});

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setEmail({...email, [name]: value});
  };
  console.log(email);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let item = {email};
    toggleReset();

    let result = await ForgetPasswordService(item);
    if (result && result.status === "success") {
      console.log("working");
    }
  };

  return (
    <>
      <div className='modalBackground'>
        <div className='modalContainer'>
          <div className='modal-title'>
            <span>Forgot Password?</span>
            <CancelOutlinedIcon onClick={props.toggleModal} cursor='pointer'/>
          </div>
          {!sentEmail ? (
            <div className='body'>
              <form>
                <label htmlFor='email'>
                                    Please enter your email to reset your password.
                </label>
                <input
                  style={{width: "100%"}}
                  value={email.email}
                  onChange={handleChange}
                  type='email'
                  name='email'
                  placeholder={"Email Address"}
                />
              </form>
              <div className='footer'>
                <button onClick={props.toggleModal} className={"cancel-btn"}>
                                    Cancel
                </button>
                <button onClick={handleSubmit} className={"success-btn"}>
                                    Submit
                </button>
              </div>
            </div>
          ) : (
            <div className='body'>
              <form>
                <label htmlFor='email'>
                                    Email has been sent to <b>{email.email}</b>. Please check your
                                    Email.
                </label>
              </form>
              <div className='footer'>
                <button
                  onClick={() => {
                    props.toggleModal();
                  }}
                  className={"ok-btn"}
                >
                                    OK
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ForgotPasswordModal;
