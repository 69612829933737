import {React, useEffect, useState} from "react";
import {GetIdeaHolderDetailService} from "../../../../services/IdeaHolderServices";
import "../ideaHolderDB.css";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import GoogleIcon from "@mui/icons-material/Google";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { useHistory } from "react-router";

export default function IdeaHolderProfileDetail() {
  const [ideaHolder, setideaHolder] = useState({
    name: "",
    age: "",
    gender: "",
    citizenship: "",
    email: "",
    phone: "",
    address: "",
    status: 0,
    error: [],
    interestedSectors: "IT and Communication",
    interests: "All",
    area: "",
  });
  const history = useHistory();
  // const [ideaHolder, setideaHolder] = useState([]);
  useEffect(async () => {
    let result = await GetIdeaHolderDetailService();
    if (result && result.status === "success" && result.IdeaProfileData) {
     
      setideaHolder(result.IdeaProfileData)
      // setCategory({ ...category, error:data });
      console.log(ideaHolder);
    } else {
      history.push("/idea/idea-form");
    }
  }, []);

  return (
    <>
      <div className='profile_detail_main p_d__m'>
        <div className='profile_detail_main_h3'>
          <span>Your Idea Profile Detail</span>
        </div>
        <div className='profile_detail_main_social'>
          <ul>
            <li>
              <span className='p_d_m_s_1'>
                <EmailOutlinedIcon/>
              </span>
              <span className='p_d_m_s_2'>Email</span>
            </li>
            <li>
              <span className='p_d_m_s_1'>
                <GoogleIcon/>
              </span>
              <span className='p_d_m_s_2'>Google</span>
            </li>
            <li>
              <span className='p_d_m_s_1'>
                <LocalPhoneIcon/>
              </span>
              <span className='p_d_m_s_2'>Phone</span>
            </li>
          </ul>
        </div>
        <hr/>

        <div className='profile_details'>
          <div className='table-contents'>
            <div className={"table-title"}>
              <b>Personal Details</b>
            </div>
            <table id='db-table'>
              <tbody>
                <tr>
                  <td style={{width: "10%"}}>
                    <b>Full Name:</b>
                  </td>
                  <td colSpan={2}>{ideaHolder?.name}</td>
                  <td style={{width: "10%"}} className='res_table'>
                    <b>Email:</b>
                  </td>
                  <td className='res_table'>{ideaHolder?.email}</td>
                </tr>

                <tr>
                  <td>
                    <b>Age:</b>
                  </td>
                  <td colSpan={2}>{ideaHolder?.age}</td>
                  <td className='res_table'>
                    <b>Gender:</b>
                  </td>
                  <td className='res_table'>{+ideaHolder?.gender===0?"Male":"Female"}</td>
                </tr>

                <tr>
                  <td>
                    <b>Phone:</b>
                  </td>
                  <td colSpan={2}>{ideaHolder?.phone}</td>
                  <td className='res_table'>
                    <b>Citizenship:</b>
                  </td>
                  <td className='res_table'>{ideaHolder?.citizenship}</td>
                </tr>
                <tr className='res_show_table'>
                  <td style={{width: "10%"}}>
                    <b>Email:</b>
                  </td>
                  <td>{ideaHolder?.email}</td>
                </tr>
                <tr className='res_show_table'>
                  <td>
                    <b>Gender:</b>
                  </td>
                  <td>{+ideaHolder?.gender === 0 ? "Male" : "Female"}</td>
                </tr>
                <tr className='res_show_table'>
                  <td>
                    <b>Citizenship:</b>
                  </td>
                  <td>{ideaHolder?.citizenship}</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <div className={"table-title"}>
              <b>Permanent Location</b>
            </div>
            <table id={"db-table"}>
              <tbody>
                <tr>
                  <td style={{width: "20%"}}>
                    <b>Municipality:</b>
                  </td>
                  <td style={{width: "40%"}}>{ideaHolder?.municipality?.name}</td>
                  <td style={{width: "10%"}} className='res_table'>
                    <b>Street:</b>
                  </td>
                  <td className='res_table'>{ideaHolder?.area}</td>
                </tr>
                <tr className='res_show_table'>
                  <td style={{width: "10%"}}>
                    <b>Street:</b>
                  </td>
                  <td>{ideaHolder?.area}</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <div className={"table-title"}>
              <b>Temporary Location</b>
            </div>
            <table id={"db-table"}>
              <tbody>
                <tr>
                  <td style={{width: "20%"}}>
                    <b>Municipality:</b>
                  </td>
                  <td style={{width: "40%"}}>{ideaHolder?.temporary_municipality?.name}</td>
                  <td style={{width: "10%"}} className='res_table'>
                    <b>Street:</b>
                  </td>
                  <td className='res_table'>{ideaHolder?.temp_area}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
