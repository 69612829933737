import React from "react";
// import "./modal.css";
import "react-toastify/dist/ReactToastify.css";
import {useHistory} from "react-router-dom";
import loading from "../../../assets/img/Loading.gif"
// import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

function ProfileCreationSuccess(/*props*/) {

  const history = useHistory();

  // const notify = () =>
  //     toast.info("Password Changed.", {
  //         position: "top-right",
  //         autoClose: 2000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //     });

  // const[email, setEmail] = useState({email:""})

  setTimeout(()=>{
    history.push("/investor/dashboard/detail")
  }, 5000)

  return (
    <>
      <div className='modalBackground'>
        <div className='modalContainer'>
          <div className="modal-title">
            <span>Profile Created.</span>
            {/* <CancelOutlinedIcon onClick={props.toggleModal} cursor='pointer'/> */}
          </div>

          <div className='body'>
            <form>
              <label htmlFor="email">
                                Your Investor Profile has been created successfully. <br/> Please wait for a few seconds...while we take you to your profile page.
              </label>

            </form>
            <div className='footer' style={{display: "flex", justifyContent:"center"}}>
              {/* <button onClick={() => {
                history.push("/investor/dashboard")
              }}
              className={"ok-btn"}>Go to Dashboard
              </button> */}

              <img alt='loading' src={loading} style={{height:"50px", width:"50px"}} />

              {/*<button onClick={()=> {*/}
              {/*    props.toggleModal();*/}
              {/*    history.push("/idea");*/}
              {/*} }*/}
              {/*        className={"cancel-btn"}>Close</button>*/}

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileCreationSuccess;
