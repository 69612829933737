import React from "react";
import LineChart from "../home/graph/LineChart";
import BarChart from "../home/graph/BarChart";
import "./Stats.css";

function Statistics() {
  return (
    <div className="container-fluid">
      <div className="table-header">
        <span>Statistics</span>
      </div>
      <div className="chart-container">
        <LineChart/>
      </div>
      <div className="chart-container">
        <BarChart/>
      </div>
    </div>
  );
}

export default Statistics;
