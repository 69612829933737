import React, { useEffect, useState } from "react";

import ProposalRequests from "./Proposal";
import { GetProposalService } from "../../../../services/InvestorService";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Tab } from "@mui/material";
function ProposalsTabs() {
  const [search, setSearch] = useState(true);
  const showSearch = () => setSearch(!search);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [key, setKey] = useState("0");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const filteredData = (d) => {
    setData(d);
  };

  async function fetchData(code, page, pageSize) {
    let result = await GetProposalService(code, page, pageSize);
    if (result && result.status === "success") {
      console.log("ideas from user arrived");
      setData(result.proposal);
      setLoading(false);
      setCurrentPage(page);
      setPageSize(pageSize);
      setTotalPage(Math.ceil(result.totalItems / pageSize));
    } else {
      console.log("error has occurred");
    }
  }

  useEffect(() => {
    fetchData(0, currentPage, pageSize);
  }, []);
  const pagination = (page) => {
    fetchData(key, page, pageSize);
  };
  return (
    <div className='container-fluid'>
      <div className='table-wrapper'>
        <div className='table-header'>
          <span>Proposals</span>
          {search ? (
            <SearchIcon onClick={showSearch} style={{ marginRight: "20px", cursor:"pointer" }} />
          ) : (
            <div className='search'>
              <CloseIcon style={{marginTop: "5px", position:"absolute", marginLeft:"190px", cursor:"pointer"}} onClick={showSearch} />
              <input type='search' placeholder='Search' />
            </div>
          )}
        </div>
        <div>
          <TabContext value={key}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={(event, k) => {
                  setKey(k);
                  fetchData(k);
                }}
                aria-label='lab API tabs example'
                variant="scrollable"
              >
                <Tab label='Proposals' value='0' />
                <Tab label='Accepted' value='1' />
                <Tab label='Rejected' value='2' />
              </TabList>
            </Box>
            <TabPanel value='0'>
              <ProposalRequests
                proposals={data}
                loading={loading}
                status={0}
                filteredData={filteredData}
                currentPage={currentPage}
                totalPage={totalPage}
                pagination={pagination}
              />
            </TabPanel>
            <TabPanel value='1'>
              <ProposalRequests
                proposals={data}
                loading={loading}
                status={1}
                currentPage={currentPage}
                totalPage={totalPage}
                pagination={pagination}
              />
            </TabPanel>
            <TabPanel value='2'>
              <ProposalRequests
                proposals={data}
                loading={loading}
                status={2}
                currentPage={currentPage}
                totalPage={totalPage}
                pagination={pagination}
              />
            </TabPanel>
          </TabContext>
        </div>
      </div>
    </div>
  );
}

export default ProposalsTabs;
