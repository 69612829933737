import React from "react";
// import "./modal.css";
import "react-toastify/dist/ReactToastify.css";
import { TextField } from "@material-ui/core";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

function EditModal(props) {
  return (
    <>
      <div className='modalBackground'>
        <div className='modalContainer'>
          <div className='form-wrapper  ' id='modal_edit_user'>
            <div className='form-header'>
              <div className='header-title'>
                <h2> Edit User</h2>
                {/* <span>Fill in the details below to create an idea profile</span> */}
              </div>
              <button className='close-btn' onClick={() => props.toggleModal()}>
                <CloseOutlinedIcon />
              </button>
            </div>
            <div className='row user-row'>
              <div className='col-md-12'>
                <div className='idea-form'>
                  <div className='form-body custom-mui-input'>
                    <div className='personal-details row personal_detail'>
                      <div className='col-md-12'>
                        <div>
                          <TextField
                            required
                            id='outlined-required'
                            style={{ width: "100%" }}
                            name='name'
                            label='Full Name'
                            value={props.user.name}
                            onChange={props.handleInput}
                            variant='outlined'
                          />

                          {props.user.errors.name && (
                            <label className='form-error'>
                              {props.user.errors.name}
                            </label>
                          )}
                        </div>
                        {/* <div>
                          <TextField
                            required
                            id='outlined-required'
                            style={{ width: "100%" }}
                            name='username'
                            label='Username'
                            value={props.user.username}
                            onChange={props.handleInput}
                            variant='outlined'
                          />

                          {props.user.errors.username && (
                            <label className='form-error'>
                              {props.user.errors.username}
                            </label>
                          )}
                        </div> */}

                        <div>
                          <TextField
                            required
                            id='outlined-required'
                            style={{ width: "100%" }}
                            name='email'
                            label='Email'
                            value={props.user.email}
                            onChange={props.handleInput}
                            variant='outlined'
                          />

                          {props.user.errors.email && (
                            <label className='form-error'>
                              {props.user.errors.email}
                            </label>
                          )}
                        </div>
                        <div>
                          <TextField
                            required
                            id='outlined-required'
                            style={{ width: "100%" }}
                            name='phone'
                            label='Phone'
                            value={props.user.phone}
                            onChange={props.handleInput}
                            variant='outlined'
                          />

                          {props.user.errors.phone && (
                            <label className='form-error'>
                              {props.user.errors.phone}
                            </label>
                          )}
                        </div>

                        <div>
                          <TextField
                            id='outlined-required'
                            style={{ width: "100%" }}
                            name='password'
                            label='Password'
                            value={props.user.password}
                            onChange={props.handleInput}
                            variant='outlined'
                          />

                          {props.user.errors.password && (
                            <label className='form-error'>
                              {props.user.errors.password}
                            </label>
                          )}
                        </div>
                      </div>

                      <br />
                    </div>
                    <div className='form-btn'>
                      <button
                        onClick={(e) => {
                          props.handleSubmit(e);
                          props.toggleModal();
                        }}
                        value='Create Profile'
                      >
                        Update Profile
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-0'></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditModal;
