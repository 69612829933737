import React, { useEffect, useState } from "react";

// import { AccessRequestDisplay } from "../../../../services/IdeaService";
import { AccessRequestDisplayInvestor } from "../../../../services/InvestorService";
import { useSelector } from "react-redux";

import IdeaAccess from "./AccessTable";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Tab } from "@mui/material";
function AccessTabInvestor() {
  const [search, setSearch] = useState(true);
  const showSearch = () => setSearch(!search);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState("3");
  const [pending, setPending] = useState([]);
  const [accepted, setAccepted] = useState([]);
  const [rejected, setRejected] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const dataAccess = async (page, pageSize) => {
    setData([]);
    setLoading(true);
    const res = await AccessRequestDisplayInvestor(iId, page, pageSize);
    if (res?.status === "success") {
      setLoading(false);
      setData(res.data);
      const pendingD = res.data.filter((f) => {
        return f.access_status === 0;
      });
      const acceptedD = res.data.filter((f) => {
        return f.access_status === 1;
      });
      const rejectedD = res.data.filter((f) => {
        return f.access_status === 2;
      });
      setRejected(rejectedD);
      setPending(pendingD);
      setAccepted(acceptedD);
      setPageSize(pageSize);
      setCurrentPage(page);
      setTotalPage(Math.ceil(res.totalItems / pageSize));
    }
  };
  const iId = useSelector((state) => state.userAuth.details.user.id);
  const pagination = (page) => {
    dataAccess(page, pageSize);
  };
  //first ma pending ko auxa ani tab ma click garey 1 or 2
  useEffect(() => {
    dataAccess(currentPage, pageSize);
  }, []);

  return (
    <div className='container-fluid'>
      <div className='table-wrapper'>
        <div className='table-header'>
          <span>Access Request</span>
          {search ? (
            <SearchIcon onClick={showSearch} style={{ marginRight: "20px", cursor:"pointer" }} />
          ) : (
            <div className='search'>
              <input type='search' placeholder='Search' />
              <CloseIcon style={{marginTop: "5px", position:"absolute", marginLeft:"190px", cursor:"pointer"}} onClick={showSearch} />
            </div>
          )}
        </div>
        <div>
          <TabContext value={key}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={(event, value) => {
                  setKey(value);
                  dataAccess(1, pageSize);
                }}
                aria-label='lab API tabs example'
                variant="scrollable"
              >
                <Tab label='All' value='3' />
                <Tab label='Pending' value='0' />
                <Tab label='Accepted' value='1' />
                <Tab label='Rejected' value='2' />
              </TabList>
            </Box>
            <TabPanel value='0'>
              <IdeaAccess
                renderedData={pending}
                loading={loading}
                type={"rejected"}
                currentPage={currentPage}
                totalPage={totalPage}
                pagination={pagination}
              />
            </TabPanel>
            <TabPanel value='1'>
              <IdeaAccess
                renderedData={accepted}
                loading={loading}
                type={"accepted"}
                currentPage={currentPage}
                totalPage={totalPage}
                pagination={pagination}
              />
            </TabPanel>
            <TabPanel value='2'>
              <IdeaAccess
                renderedData={rejected}
                loading={loading}
                type={"rejected"}
                currentPage={currentPage}
                totalPage={totalPage}
                pagination={pagination}
              />
            </TabPanel>
            <TabPanel value='3'>
              <IdeaAccess renderedData={data} loading={loading} type={"All"} />
            </TabPanel>
          </TabContext>
        </div>
      </div>
    </div>
  );
}

export default AccessTabInvestor;
