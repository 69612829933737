import React from "react";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import CircularProgress from "@mui/material/CircularProgress";
import "./Loader.css";
function Loader(){
  return (
    <>
      <div className="loader-wrapper">
        <Box sx={{ height: 40 }}>
          <Fade
            in={true}
            style={{
              transitionDelay: "0",
            }}
            unmountOnExit
          >
            <CircularProgress />
          </Fade>
        </Box>
      </div>
    </>
  );
}
export default Loader;