import React from "react";
import "./Signup.css";
// import { Link, useHistory } from "react-router-dom";
import lamp from "../../assets/img/lamp.png";
import man from "../../assets/img/man2.png";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import {  RegisterUserService } from "../../services/AuthService";
import EmailSent from "./EmailSent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { CircularProgress } from "@material-ui/core";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { authData } from "../../redux/actions";
import { connect } from "react-redux";
import SocialLogin from "../social-login/Index";
import Loader from "../../components/loader/Loader";
class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      username: "",
      email: "",
      phone: "",
      password: "",
      repassword: "",
      errors: [],
      openModal: false,
      isLoading: false,
    };
    this.handleBack = this.handleBack.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleError = this.handleError.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.registerUser = this.registerUser.bind(this);
  }

  handleBack() {
    this.props.history.goBack();
  }

  handleInput(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleError(errors) {
    this.setState({
      ...this.state,
      errors: errors,
    });
  }

  redirectLogin(){
    this.props.history.push({pathname:"/login", state:{prevRoute:"/signup"}})
  }

  // const [openModal, setOpenModal] = useState(false)
  toggleModal() {
    this.setState({ openModal: !this.state.openModal });
  }

  async registerUser(e) {
    e.preventDefault();

    this.setState({
      isLoading: true,
    });
    let result = await RegisterUserService(this.state);
    if (result && result.status === "success") {
      this.setState({
        ...this.state,
        errors: [],
      });
      this.setState({
        isLoading: false,
        name: "",
        username: "",
        email: "",
        phone: "",
        password: "",
        repassword: "",
        errors: [],
        openModal: true,
      });
    } else {
      this.setState({
        ...this.state,
        errors: [],
      });
      const message = result.error;
      let errorMessage = "";
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(message)) {
        errorMessage += value;
      }
      toast.warning(errorMessage, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      this.setState({
        isLoading: false,
      });
    }
  }

  render() {
    return (
      <>
        {this.state.isLoading && <Loader/>}
  
        <div className='signup-wrapper'>
     
          <div className='signup-left'>
            <div className='signup-form-container'>
              <div className='signup-header'>
                <button onClick={this.handleBack}>
                  <span className='goBack'>
                    <ArrowBackIosNewOutlinedIcon className='mr-2' />
                  </span>
                </button>
                <span className='signup-head'>Sign Up</span>
              </div>
              <div className='signup-form'>
                <SocialLogin handleLoading={(loading)=>this.setState({isLoading:loading})} handleError={(error)=>{
                  if(error){
                    this.setState({errors:["Unable to login"]});
                  }else{
                    this.setState({errors:[]});
                  }
                }} />
                <form onSubmit={this.registerUser}>
                  <Grid container rowSpacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        label='Full Name'
                        name='name'
                        value={this.state.name}
                        onChange={this.handleInput}
                        variant='outlined'
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label='Email'
                        name='email'
                        value={this.state.email || ""}
                        onChange={this.handleInput}
                        variant='outlined'
                        fullWidth
                        required
                        error={this.state.errors?.email}
                        helperText={
                          this.state.errors?.email && "The email is invalid"
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label='Password'
                        name='password'
                        value={this.state.password || ""}
                        onChange={this.handleInput}
                        variant='outlined'
                        type={"password"}
                        fullWidth
                        required
                        error={this.state.errors?.password}
                        helperText={this.state?.errors?.password}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label='Confirm Password'
                        name='repassword'
                        value={this.state.repassword || ""}
                        onChange={this.handleInput}
                        variant='outlined'
                        type={"password"}
                        fullWidth
                        required
                        error={this.state.errors?.repassword}
                        helperText={
                          this.state.errors?.repassword &&
                        "The password and confirm password didn't match"
                        }
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        type='submit'
                        variant='contained'
                        color='primary'
                        fullWidth
                        className='signup-btn'
                        disabled={this.state.isLoading}
                      >
                        {this.state.isLoading ? (
                          <CircularProgress size={24} />
                        ) : (
                          "Sign Up"
                        )}
                      </Button>
                    </Grid>
                  </Grid>

                  <div>
                    <p className='noAcc1'>
                    Already a Member?
                      <button style={{background: "none", color: "inherit",	border: "none", padding: 0, font: "inherit", cursor: "pointer", outline: "inherit"}} onClick={()=>{
                        this.redirectLogin()
                      }}>
                        <span className='registerTxt'>&nbsp; Login</span>
                      </button>
                    </p>
                  </div>
                </form>
                {this.state.openModal && (
                  <div>
                    <EmailSent
                      toggleModal={this.toggleModal}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='signup-right'>
            <div>
              <img
                src={lamp}
                alt=''
                // height='45%'
                // width='auto'
                className='lamp'
              />
            </div>
            <div>
              <img
                src={man}
                alt=''
                // height='auto'
                // width='300px'
                className='man'
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (data) =>  dispatch(authData(data)),
    error: (data)=>dispatch(data)
  }
};
export default connect(null, mapDispatchToProps)(SignUp)
