import * as ActionTypes from "../ActionTypes";

const initState = {
  authResponse: "",
  detail: [],
  isLoggedIn: false,
  isadmin: false,
  isinvestor: false,
  isideaholder: false,
};
const AuthReducer = (state = initState, action) => {
  switch (action.type) {
  case ActionTypes.RESTART_AUTH_RESPONSE:
    return {
      ...state,
      authResponse: "",
    };
  case ActionTypes.LOADING:
    return {
      ...state,
      authResponse: "loading...",
    };
  case ActionTypes.SIGNUP_SUCCESS:
    return {
      ...state,
      authResponse: action.res,
    };
  case ActionTypes.SIGNUP_ERROR:
    return {
      ...state,
      authResponse: action.res,
    };
  case ActionTypes.LOGIN_SUCCESS:
    state = {
      authResponse: "redirecting to homepage...",
      details: action.payload, //yo data pathako
      isLoggedIn: true,
      isadmin: false,
      isinvestor: action.payload
        ? action.payload.user?.roles.some((role) => {
          return role.slug === "investor";
        })
        : false,
      isideaholder: action.payload
        ? action.payload.user?.roles.some((role) => {
          return role.slug === "idea-holder";
        })
        : false,
    };
    return state;

  case ActionTypes.LOGIN_ERROR:
    return {
      ...state,
      authResponse: action.res,
    };
  case ActionTypes.LOGOUT_SUCCESS:
    state = {
      authResponse: "redirecting to dashboard...",
      details: {}, //yo data pathako
      isLoggedIn: false,
      isadmin: false,
      isinvestor: false,
      isideaholder: false,
    };
    return state;
    // return {
    //     ...state,
    //     authResponse: action.res,
    // };
  case ActionTypes.LOGOUT_ERROR:
    return {
      ...state,
      authResponse: action.res,
    };
  case ActionTypes.CODE_ERROR:
    return {
      ...state,
      authResponse:
                    "There seems to be a problem, please refresh your browser",
    };
  default:
    return state;
  }
};
export default AuthReducer;
