import React, { Component } from "react";
import { Link } from "react-router-dom";
import PaginationPage from "../../../../components/pagination/Pagination";
import { GetRejectedScreeningIdeaService } from "../../../../services/AdminServices";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import { CircularProgress } from "@mui/material";
import { format, parseISO } from "date-fns";
import RemoveRedEyeOutlined from "@mui/icons-material/RemoveRedEyeOutlined";

export default class RejectedScreeningRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screening_request: [],
      loading: true,
      currentPage: 1,
      totalPage: 0,
      pageLimit: 10,
    };
  }

  async getItems(page, limit) {
    let result = await GetRejectedScreeningIdeaService(page, limit);
    if (result && result.status === "success") {
      console.log("Rejected ideas from user arrived");
      this.setState({
        screening_request: result.screening_request,
        loading: false,
        currentPage: page,
        totalPage: Math.ceil(result.totalItems / limit),
        pageLimit: limit,
      });
      console.log(this.state.screening_request, "rejected");
    } else {
      console.log("error has occurred");
    }
  }

  async componentDidMount() {
    await this.getItems(this.state.currentPage, this.state.pageLimit);
  }

  async pagination(page) {
    await this.getItems(page, this.state.pageLimit);
  }

  render() {
    var ideas_HTMLTABLE = "";
    if (this.state.loading) {
      ideas_HTMLTABLE = (
        <tr>
          <td colSpan='7' style={{ textAlign: "center" }}>
            <CircularProgress />
          </td>
        </tr>
      );
    } else {
      ideas_HTMLTABLE = this.state.screening_request.map((item, index) => {
        return (
          <tr key={item.id}>
            <td>{index + 1}</td>
            <td>{item.user.name}</td>
            <td>{item.title}</td>
            <td>
              {format(
                parseISO(item.created_at, "yyyy-MM-dd H:m:s", new Date()),
                "MMM do, yyyy"
              )}
            </td>
            <td>Rs. {item.expected_invested}</td>
            <td>
              {item.status === 3
                ? "Rejected"
                : item.status === 5 && "Rejected with Edits"}
            </td>
            <td> {item.location}</td>
            <td style={{ textAlign: "center" }}>
              <Link to={"/dashboard/screeningpage/" + item.id}>
                <RemoveRedEyeOutlined />
              </Link>
            </td>
          </tr>
        );
      });
    }
    return (
      <>
        <div
          className='table-contents'
          style={{ height: "69vh", overflowY: "auto" }}
        >
          <table id='db-table'>
            <thead>
              <tr>
                <th>ID</th>
                <th>Idea Holder</th>
                <th>Idea</th>
                <th>Date</th>
                <th>Investment</th>
                <th>Status</th>
                <th>Location</th>
                <th colSpan='2' style={{ textAlign: "center" }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {!this.state.loading ? (
                this.state.screening_request.length === 0 ? (
                  <tr>
                    <td colSpan='6'>
                      <div className='no_data_found'>
                        <FileOpenOutlinedIcon size='50px' />
                        <h6>No data Available</h6>
                      </div>
                    </td>
                  </tr>
                ) : (
                  ideas_HTMLTABLE
                )
              ) : (
                <tr>
                  <td colSpan='8' style={{ textAlign: "center" }}>
                    <CircularProgress />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className='pagination'>
          <PaginationPage
            totalPage={this.state.totalPage}
            currentPage={this.state.currentPage}
            changePage={this.pagination.bind(this)}
          />
        </div>
      </>
    );
  }
}

