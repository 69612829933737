import React from "react";
import {Bar} from "react-chartjs-2";

const state = {
  labels: ["January", "February", "March", "April", "May"],
  datasets: [
    {
      label: "Investors",
      backgroundColor: "rgba(75,192,192,1)",
      borderColor: "rgba(75,192,192,1)",
      borderWidth: 2,
      data: [65, 59, 80, 81, 56],
    },
    {
      label: "Idea Holders",
      backgroundColor: "#ffcccb",
      borderColor: "#ffcccb",
      borderWidth: 2,
      data: [62, 43, 20, 10, 40],
    },
  ],
};

function BarChart() {
  return (
    <div>
      <div className="header">
        <h1 className="title">Users in last 5 months</h1>
      </div>
      <Bar
        data={state}
        options={{
          title: {
            display: true,
            text: "Average Rainfall per month",
            fontSize: 20,
          },
          legend: {
            display: true,
            position: "right",
          },
        }}
      />
    </div>
  );
}

export default BarChart;
