import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import React from "react";
export default function ViewPdf({ toggleModal, filename }) {
  if (!filename) {
    return;
  }
  return (
    <>
      <div className='modalBackground pdf-modal'>
        <div className='modalContainer' style={{width: "700px",height:"700px"}}>
          <div className='modal-title'>
            <span>File Viewer</span>
            <CancelOutlinedIcon onClick={toggleModal} cursor='pointer' />
          </div>

          <div className='body' style={{width:"100%",height:"600px",overflow:"auto"}}>
            {filename.match(/.pdf/) ? (
              <embed style={{width:"100%",height:"600px"}} src={filename}/>
            ) : (
              <div>
                <img
                  src={filename}
                  className={"img-responsive"}
                  alt='filename'
                />
              </div>
            )}
          </div>
          {/* <div className='footer'>
            <button className={"cancel-btn e-css"} onClick={toggleModal}>
              Close
            </button>
          </div> */}
        </div>
      </div>
    </>
  );
}
