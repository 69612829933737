/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Profile from "../../../../assets/img/account.png";
import {
  ApproveIdeaStatus,
  ChangeStatusService,
  DeleteIdea,
  GetSingleIdeaService,
  RejectIdeaStatus,
  ScreenIdeaStatus,
} from "../../../../services/AdminServices";
import { toast } from "react-toastify";
import ConfirmationModal from "../../../../components/modal/ConfirmationModal";
import RejectionModal from "../../../../components/modal/RejectionModal";
import { Button, TextField } from "@mui/material";
import GetImageUrl from "../../../../utils/ImageHelper";
import ViewPdf from "../../../../utils/ViewPdf";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import PlayCircleFilledOutlinedIcon from "@mui/icons-material/PlayCircleFilledOutlined";
import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import WarningAmberOutlined from "@mui/icons-material/WarningAmberOutlined";
import ReactTagInput from "@pathofdev/react-tag-input";
export default function ScreeningInterface() {
  const notify = () =>
    toast.info("Screening Started", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  // eslint-disable-next-line no-unused-vars
  const [change, setChange] = React.useState(true);
  // eslint-disable-next-line no-unused-vars
  const [personName, setPersonName] = React.useState([]);

  const [reject, setReject] = useState(false);
  const toggleRejectionModal = () => {
    setReject(!reject);
  };
  const [openFile, setOpenFile] = React.useState(false);
  const [currentFile, setCurrentFile] = React.useState(false);
  const [screenStatus, setScreenStatus] = useState(0);
  const [approve, setApprove] = useState(false);
  const [deleteIdea,setDeleteIdea] = useState(false);
  const approveModal = () => {
    setApprove(!approve);
  };
  const closeFileViewer = () => {
    setOpenFile(false);
  };
  
  const [screening, setScreening] = useState(false);
  const toggleScreen = () => {
    setScreening(true);
  };

  const [idea, setIdea] = useState({
    title: "",
    description: "",
    expected_invested: "",
    own_investment: "",
    location: "",
    investment_type: "",
    errors: [],
    errorCount: 0,
    category: "",
    status: "",
    user: [
      {
        email: "",
        name: "",
      },
    ],
    remark: "",
  });

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setIdea({ ...idea, [name]: value });
    console.log(name, value);
  };

  let history = useHistory();

  function handleClick() {
    history.push("/dashboard/screening");
  }

  const location = useParams();

  const screenIdea = async () => {
    notify();
    idea.status = 2;
    const screenIdea = {
      id: location.id,
      status: 2,
    };
    let result = await ScreenIdeaStatus(screenIdea);
    if (result && result.status === "success") {
      setChange(false);
    } else {
      console.log("error occurred updating screening ideas");
    }
  };

  const RejectIdea = async (id, action) => {
    const rejectedData = {
      id: location.id,
      status: action,
      remark: idea.remark,
    };
    let result = await RejectIdeaStatus(rejectedData);
    if (result && result.status === "success") {
      console.log("rejected");
    } else {
      console.log("error occurred updating screening ideas");
    }
    console.log(idea, "from detail");
  };

  const ApproveIdea = async (id, action) => {
    const approvedData = {
      id: location.id,
      status: action,
    };
    await ApproveIdeaStatus(approvedData);
  };
  const deleteUserIdea = async () => {
    await DeleteIdea({id:idea.id});
  };
  const toggleDeleteModal = () => {
    setDeleteIdea(!deleteIdea)
  };
  const singleIdea = {
    id: location.id,
  };

  const GetIdeaDetail = async () => {
    let result = await GetSingleIdeaService(singleIdea);
    if (result && result.status === "success") {
      setIdea(result.idea);
    } else {
      console.log("error occurred getting single");
    }
  };

  useEffect(() => {
    GetIdeaDetail();
  }, []);

  useEffect(() => {
    if (location.id && idea && idea.id > 0 && +idea.status === 0) {
      changeStatusApI();
    }
  }, [location, idea]);
  const changeStatusApI = async () => {
    let res = await ChangeStatusService({ id: location.id });
    console.log(location.id);
    if (res && res.status === "success") {
      console.log(res, "backend-lumen ma status changed");
    }
  };

  return (
    <div className='screening_wrap'>
      <div className='header_main'>
        <div role='presentation' className='back' onClick={handleClick}>
          <ArrowBackIosNewOutlinedIcon />
          <span>Back</span>
        </div>
        {/*<div className='text'>Screening Requests</div>*/}
      </div>
      <div className='personal_detail_wrap'>
        <div className='header'>
          <h3>Personal Details of IdeaHolder</h3>
        </div>
        <div className='body_flex'>
          <div className='user_image'>
            <img
              src={
                idea.user.profile_pic
                  ? GetImageUrl("idea-profile", idea.user.profile_pic)
                  : Profile
              }
              alt='user_image'
            />
          </div>
          <div className='detail_table'>
            <table>
              <tbody>
                <tr>
                  <td className='td_table'>Full Name</td>
                  <td>{idea.user.name}</td>
                </tr>
                <tr>
                  <td className='td_table'>Email</td>
                  <td>{idea.user.email}</td>
                </tr>
                <tr>
                  <td className='td_table'>Age</td>
                  <td>{idea.user.idea_holder_detail?.age}</td>
                </tr>
                <tr>
                  <td className='td_table'>Gender</td>
                  <td>
                    {idea.user?.idea_holder_detail?.gender === 0
                      ? "Male"
                      : "Female"}
                  </td>
                </tr>
                <tr>
                  <td className='td_table'>Phone</td>
                  <td>{idea.user?.idea_holder_detail?.phone}</td>
                </tr>
                <tr>
                  <td className='td_table'>Citizenship</td>
                  <td>{idea.user?.idea_holder_detail?.citizenship}</td>
                </tr>
                {/* <tr>
                  <td className='td_table'>Permanent Location</td>
                  <td>{idea.user?.idea_holder_detail?.}</td>
                </tr>
                <tr>
                  <td className='td_table'>Temporary Location</td>
                  <td>{idea.user?.idea_holder_detail?.}</td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className='personal_detail_wrap'>
        <div className='header'>
          <h3>Idea Details</h3>
          {+idea.status === 3 ? (
            <span className={"table-rejected-btn"}>
              <CancelOutlinedIcon size={"16px"} />
              Rejected
            </span>
          ) : +idea.status === 4 ? (
            <span className='table-accepted-btn'>
              <CheckCircleOutlinedIcon size={"16px"} />
              Published
            </span>
          ) : +idea.status === 1 || +idea.status === 0 ? (
            <button className={"table-btn"} onClick={screenIdea}>
              <PlayCircleFilledOutlinedIcon />
              Start Screening
            </button>
          ) : (
            <span className={"table-ongoing-btn"}>
              <HourglassEmptyOutlinedIcon size={"14px"} />
              Ongoing Screening
            </span>
          )}
        </div>
        <div className='business_detail_flex'>
          {+idea.status === 3 ? (
            <div className={"rejected-msg-block"}>
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <div>
                  <WarningAmberOutlined size={"30px"} />
                </div>
                <div className={"rejected-msg"}>
                  <span>Your Idea has been rejected.</span>
                  <span>Reason : {idea.remark}</span>
                </div>
              </div>
            </div>
          ) : (
            +idea.status === 5 && (
              <div className={"review-msg-block"}>
                <div
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <div>
                    <WarningAmberOutlinedIcon size={"30px"} />
                  </div>
                  <div className={"rejected-msg"}>
                    <span>Your Idea has been rejected.</span>
                    <span>Reason : {idea.remark}</span>
                  </div>
                </div>
              </div>
            )
          )}
          <div className='idea-form'>
            <div className='form-body'>
              <span style={{ fontSize: "18px" }}>
                <b>Idea Details</b>
              </span>
              <div className='details custom-mui-input'>
                <div className='row '>
                  <div className='col-sm-12 col-md-6 '>
                    <TextField
                      style={{ width: "100%" }}
                      name='title'
                      label='Idea Name'
                      value={idea.title}
                      onChange={handleInput}
                      variant='outlined'
                      required
                      inputProps={{ readOnly: true }}
                    />
                  </div>
                  <div className='col-sm-12 col-md-6 '>
                    <TextField
                      style={{ width: "100%" }}
                      label='Categories'
                      value={
                        idea.sectors
                          ? idea.sectors
                            .map((category) => category.name)
                            .join(", ")
                          : ""
                      }
                      variant='outlined'
                      required
                      inputProps={{ readOnly: true }}
                    />
                  </div>
                </div>

                <div className='row '>
                  <div className='col-sm-12 '>
                    <TextField
                      id='outlined-multiline-static'
                      label='Your idea in Brief'
                      multiline
                      rows={6}
                      sx={{ width: "100%" }}
                      variant='outlined'
                      required
                      name={"description"}
                      value={idea.description}
                      onChange={handleInput}
                      inputProps={{ readOnly: true }}
                    />
                  </div>
                </div>
                <div className='row '>
                  <div className='col-sm-12 col-md-6 '>
                    <ReactTagInput
                      tags={idea.tags?.map((t) => t.tags) ?? []}
                      placeholder='Tags'
                    />
                  </div>
                </div>

                <span style={{ fontSize: "18px" }}>
                  <b>Investment Requirements</b>
                </span>

                <div className='row '>
                  <div className='col-sm-12 col-md-6 '>
                    <TextField
                      style={{ width: "100%" }}
                      name='own_investment'
                      label='Your Investment'
                      value={idea.own_investment}
                      onChange={handleInput}
                      variant='outlined'
                      required
                      inputProps={{ readOnly: true }}
                    />
                  </div>
                  <div className='col-sm-12 col-md-6 '>
                    <TextField
                      style={{ width: "100%" }}
                      name='expected_invested'
                      label='Expected Investment'
                      value={idea.expected_invested}
                      onChange={handleInput}
                      variant='outlined'
                      required
                      inputProps={{ readOnly: true }}
                    />
                  </div>
                </div>

                <div className='row '>
                  <div className='col-sm-12 col-md-6 '>
                    <TextField
                      style={{ width: "100%" }}
                      name='location'
                      label='Your Location'
                      value={idea.location}
                      onChange={handleInput}
                      variant='outlined'
                      required
                      inputProps={{ readOnly: true }}
                    />
                  </div>

                  <div className='col-sm-12 col-md-6 '>
                    <TextField
                      style={{ width: "100%" }}
                      label='Preferred Locations'
                      value={
                        idea.districts
                          ? idea.districts
                            .map((location) => location.name)
                            .join(", ")
                          : ""
                      }
                      variant='outlined'
                      required
                      inputProps={{ readOnly: true }}
                    />
                  </div>
                </div>
                <div className='row '>
                  <div className='col-sm-12 col-md-6 '>
                    <TextField
                      style={{ width: "100%" }}
                      name='investment_type'
                      label='Investment Type'
                      value={
                        idea.investment_type
                          ? idea.investment_type == 0
                            ? "Equity Shares"
                            : "Preference Shares"
                          : ""
                      }
                      onChange={handleInput}
                      variant='outlined'
                      required
                      inputProps={{ readOnly: true }}
                    />
                  </div>
                </div>
                <hr />

                <div className='row'>
                  <div className='idea-form-parameter col-md-6 col-sm-12'>
                    <label htmlFor='DIP'>Debt Injection Plan</label>
                    <div>
                      {idea.debt_injection_plan_doc_link ? (
                        <button className="e-css">
                          <a
                            role='presentation'
                            onClick={() => {
                              setOpenFile(true);
                              setCurrentFile(
                                GetImageUrl(
                                  "idea",
                                  idea.debt_injection_plan_doc_link
                                )
                              );
                            }}
                          >
                          Open File
                          </a>
                        </button>
                      ) : (
                        "No File"
                      )}
                    </div>
                  </div>
                  <div className='idea-form-parameter col-md-6 col-sm-12'>
                    <label htmlFor='feasibility'>Feasibility Study</label>

                    <div>
                      {idea.feasibility_study_doc_link ? (
                        <button className="e-css">
                          <a
                            role='presentation'
                            onClick={() => {
                              setOpenFile(true);
                              setCurrentFile(
                                GetImageUrl(
                                  "idea",
                                  idea.feasibility_study_doc_link
                                )
                              );
                            }}
                          >
                          Open File
                          </a>
                        </button>
                      ) : (
                        "No file"
                      )}
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='idea-form-parameter col-md-6 col-sm-12'>
                    <label htmlFor='SWOT'>SWOT Analysis</label>
                    <div>
                      {idea.swot_analysis_doc_link ? (
                        <button className="e-css">
                          <a
                            role='presentation'
                            onClick={() => {
                              setOpenFile(true);
                              setCurrentFile(
                                GetImageUrl("idea", idea.swot_analysis_doc_link)
                              );
                            }}
                          >
                          Open File
                          </a>
                        </button>
                      ) : (
                        "No file"
                      )}
                    </div>
                  </div>
                  <div className='idea-form-parameter col-md-6 col-sm-12'>
                    <label htmlFor='financial_projection'>
                      Financial Projection
                    </label>
                    <div>
                      {idea.financial_projection_doc_link ? (
                        <button className="e-css">
                          <a
                            role='presentation'
                            onClick={() => {
                              setOpenFile(true);
                              setCurrentFile(
                                GetImageUrl(
                                  "idea",
                                  idea.financial_projection_doc_link
                                )
                              );
                            }}
                          >
                          Open File
                          </a>
                        </button>
                      ) : (
                        "No File"
                      )}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='idea-form-parameter col-md-6 col-sm-12'>
                    <label htmlFor='financial_analysis'>
                      Financial Analysis
                    </label>
                    <div>
                      {idea.financial_analysis_doc_link ? (
                        <button className="e-css">
                          <a
                            role='presentation'
                            onClick={() => {
                              setOpenFile(true);
                              setCurrentFile(
                                GetImageUrl(
                                  "idea",
                                  idea.financial_analysis_doc_link
                                )
                              );
                            }}
                          >
                          Open File
                          </a>
                        </button>
                      ) : (
                        "No File"
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!screening && (+idea.status === 0 || +idea.status === 1) ? (
            <div className='end_buttons'>
              <Button
                className='primary-btn'
                onClick={() => {
                  screenIdea();
                  toggleScreen();
                }}
              >
                Start Screening Process
              </Button>
            </div>
          ) : +idea.status !== 4 ? (
            <div className='end_buttons'>
              <Button
                variant='outlined'
                className='danger-btn'
                onClick={() => {
                  toggleRejectionModal();
                  setScreenStatus(3);
                }}
              >
                Reject
              </Button>
              <Button
                className='warning-btn'
                onClick={() => {
                  toggleRejectionModal();
                  setScreenStatus(5);
                }}
              >
                Review
              </Button>
              <Button
                className='primary-btn'
                onClick={() => {
                  approveModal();
                  setScreenStatus(4);
                }}
              >
                Approve
              </Button>
            </div>
          ) : (
            <div className='end_buttons'>
              <Button
                variant='outlined'
                className='danger-btn'
                onClick={() => {
                  toggleDeleteModal();
                }}
              >
                Delete
              </Button>
            </div>
          )}

          {reject && (
            <RejectionModal
              toggleModal={toggleRejectionModal}
              action={RejectIdea}
              toastMsg={"Idea has been rejected"}
              status={screenStatus}
              data={idea}
              name='remark'
              value={idea.remark}
              handleInput={handleInput}
              title={screenStatus === 3 ? "Reject" : "Resubmit"}
              message={
                screenStatus === 3
                  ? "Are you sure you want to reject this idea?"
                  : "Are your sure you want to reject this idea for Resubmission?"
              }
            />
          )}
          {approve && (
            <ConfirmationModal
              title={"Screening Idea"}
              toggleModal={approveModal}
              message={"Are you sure You want to Approve this Idea?"}
              action={ApproveIdea}
              toastMsg={"Idea has been approved"}
              data={idea}
              status={screenStatus}
            />
          )}
          {deleteIdea && (
            <ConfirmationModal
              title={"Delete Idea"}
              toggleModal={toggleDeleteModal}
              message={"Are you sure You want to delete this Idea?"}
              action={deleteUserIdea}
              toastMsg={"Idea has been deleted"}
              data={idea}
              status={screenStatus}
            />
          )}
          {openFile && (
            <ViewPdf filename={currentFile} toggleModal={closeFileViewer} />
          )}
        </div>
      </div>
    </div>
  );
}
