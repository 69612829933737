import React from "react";
import "react-toastify/dist/ReactToastify.css";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
function EmailSent() {
  return (
    <>
      <div>
        <div className='signup_top_msg_box'>
          {/* <div className='modal-title'>
            <span>Please Verify your account</span>
            <IoCloseOutline onClick={props.toggleModal} cursor='pointer' />
          </div> */}

          <div className='body'>
            <div className='success-msg'>
              <div>
                <CheckCircleOutlineOutlinedIcon size={"40px"} />
              </div>
              <form>
                <span htmlFor='email' className='signup_msg'>
                  Email has been sent to your account. Please check your inbox
                  and verify your account .
                </span>
              </form>
            </div>
            <div className='footer'>
              {/* <button
                onClick={() => {
                  props.toggleModal();
                }}
                className={"ok-btn"}
              >
                Close
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmailSent;
