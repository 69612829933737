import TextareaAutosize from "@mui/material/TextareaAutosize";
import React from "react";
import FileUpload from "../../../../components/image-upload/FileUpload";
import { SendScheduledDescService } from "../../../../services/AdminServices";
import PDF from "../../../../assets/img/mypdf.png";
import axios from "axios";
import { Button, DialogActions, DialogContent } from "@mui/material";
import { format, parse } from "date-fns";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../../components/modal/BootstrapModal";
import "./AddRemarks.css"

function AddRemarks(props) {
  const [value, setValue] = React.useState("");
  // eslint-disable-next-line no-unused-vars
  const [files, setFiles] = React.useState([]);
  const [mStatus, setMStatus] = React.useState(2);

  const dwFile = async (file) => {
    await axios.get(process.env.REACT_APP_API_BASE_URL+"/download-files", {
      params: {
        type: "scheduler",
        file,
      },
    });
  };
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const handleStatus = (e) => {
    setMStatus(e.target.value);
  };

 
  const fileRef = React.useRef();

  function saveFile(file) {
    setFiles(file);
  }

  //services

  const sendScheduledDescription = async () => {
    const fd = new FormData();
    fd.append("value", value);
    fd.append("id", props.detail.id);
    fd.append("mStatus", mStatus);
    fileRef.current.filesData.map((f, index) => {
      fd.append(`files[${index}]`, f.rawFile);
    });

    const res = await SendScheduledDescService(fd);
    if (res.status === "success") {
      props.handleCloseRemarks();
      props.getDataAfterConnect(1);
    }
  };

  return (
    <div>
      <BootstrapDialog
        open={props.addRemarks}
        onClose={props.handleCloseRemarks}
        style={{ width: "110%" }}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={props.handleCloseRemarks}>
        Add Remarks
        </BootstrapDialogTitle>
        <DialogContent>
          <div className='scheduler-body'>
            <div className='user-detail'>
              <div className='p_d__m_remarks'>
                <div className='profile_details'>
                  <div className='text__area'>
                    <h3
                      style={{
                        display: "inline-block",
                        paddingRight: "20px",
                        fontWeight: "bolder",
                        fontSize: "1em",
                        marginTop: "10px"
                      }}
                    >
                      Status
                    </h3>

                    <div
                      className='select_remarks'
                      style={{ display: "inline-block" }}
                    >
                      <select onChange={handleStatus}>
                        <option className='a' value={2}>
                          Ongoing
                        </option>
                        <option className='a' value={3}>
                          Successful
                        </option>
                        <option className='a' value={4}>
                          Rejected
                        </option>
                      </select>
                    </div>
                  </div>
                  <div
                    style={{
                      height: "155px",
                      overflow: "auto",
                      marginTop: "10px",
                    }}
                  >
                    <table
                      id='db-table'
                      className='a_r'
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th>SN</th>
                          <th>Description</th>
                          <th>Attached Documents</th>
                          <th> Date & Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.detail &&
                          props.detail.meeting_descriptions &&
                          props.detail.meeting_descriptions.map(
                            (sub, index) => (
                              <tr key={index}>
                                <td style={{ width: "20px" }}>{index + 1}</td>
                                <td
                                  style={{ width: "250px", overflow: "auto" }}
                                >
                                  <span style={{ height: "20px" }}>
                                    {sub.description}
                                  </span>
                                </td>
                                <td style={{ width: "209px" }}>
                                  <div>
                                    {sub.files.map((file, index) => (
                                      <div
                                        key={index}
                                        role='presentation'
                                        onClick={() => dwFile(file.file_name)}
                                        style={{ margin: "5px 0px" }}
                                      >
                                        <img src={PDF} alt='pdf' width='25' />

                                        <span style={{ fontSize: "0.7rem" }}>
                                          {file.file_name}
                                        </span>
                                      </div>
                                    ))}
                                  </div>
                                </td>

                                <td>
                                 
                                  {format(
                                    parse(
                                      sub.created_at,
                                      "YYYY-MM-DD H:m:s",
                                      new Date()
                                    ),
                                    "MMM do YYYY H:mm:ss a"
                                  )}
                                </td>
                              </tr>
                            )
                          )}
                      </tbody>
                    </table>
                  </div>
                  <div
                    className='table-contents t__c'
                    style={{ height: "unset!important" }}
                  >
                    <div className='text__area'>
                      <h3 style={{ fontWeight: "bolder",
                        fontSize: "1em",
                        marginTop: "10px"}}>Description</h3>
                      <TextareaAutosize
                        aria-label='empty textarea'
                        value={value}
                        placeholder=' Write your description'
                        style={{
                          width: "100%",
                          height: 70,
                          border: "1px solid #d2d2d2",
                          borderRadius: "4px",
                          padding: "5px",
                        }}
                        onChange={handleChange}
                      />
                    </div>

                    <div className='text__area'>
                      <h3 style={{
                        fontWeight: "bolder",
                        fontSize: "1em",
                        marginTop: "10px"
                      }}>Attach Document</h3>
                      <FileUpload myRef={fileRef} saveFile={saveFile} />
                    </div>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button style={{marginRight: "35%", marginBottom: "20px"}} variant='contained' color='primary' onClick={() => sendScheduledDescription()}>
            Add Remarks
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

export default AddRemarks;
