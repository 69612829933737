import React, {useEffect, useState} from "react"
import InvestorScreen from "./InvestorScreen";
import {GetInvestorService} from "../../../../services/AdminServices";
import { Box, Tab } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

export default function InvestorTabs() {
  const [search, setSearch] = useState(true);
  const showSearch = () => setSearch(!search);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [code, setCode] = useState([2, 4]);
  const filteredData = (d) => {
    setData(d);
  };
  const [tabIndex, setTabIndex] = useState("1");
  const handTableChange = (event, index) => {
    setTabIndex(index);
    fetchData([index]);
  };
  async function fetchData(code, page = 1, pageLimit = 10) {
    setLoading(false);
    let result = await GetInvestorService(code, page, pageLimit);
    if (result && result.status === "success") {
      setData(result.investor);
    } else {
      console.log("error has occurred");
    }
    setCode(code);
    setCurrentPage(page);
    setPageLimit(pageLimit);
    setTotalPage(Math.ceil(result.totalItems / pageLimit));
  }

  async function pagination(page) {
    await this.fetchData(code, page, pageLimit);
  }

  useEffect(() => {
    fetchData([1, 2], currentPage, pageLimit)
  }, [])

  return (
    <>
      <div className='container-fluid'>
        <div className='table-wrapper'>
          <div className='table-header'>
            <span>New Investor Screenings</span>
            {search ? (
              <SearchOutlinedIcon onClick={showSearch} style={{marginRight: "20px", cursor: "pointer"}}/>
            ) : (
              <div className='search'>
                <CloseOutlinedIcon style={{marginTop: "5px", position:"absolute", marginLeft:"190px", cursor:"pointer"}} onClick={showSearch}/>
                <input type='search' placeholder='Search'/>
              </div>
            )}
          </div>
          <div>

            <TabContext value={tabIndex}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handTableChange}
                  aria-label='lab API tabs example'
                  variant="scrollable"
                >
                  <Tab label='New Investor Screenings' value='1' />
                  <Tab label='Ongoing Investor Screenings' value='2' />
                  <Tab label='Rejected Investor Screenings' value='3' />
                  <Tab label='Accepted Investor Screenings' value='4' />
                </TabList>
              </Box>
              <TabPanel value='1' >
                <InvestorScreen
                  data={data}
                  status={[1, 2]}
                  loading={loading}
                  filteredData={filteredData}
                  totalPage={totalPage}
                  currentPage={currentPage}
                  changePage={pagination}
                />
              </TabPanel>
              <TabPanel value='2'>
                <InvestorScreen
                  data={data}
                  filteredData={filteredData}
                  status={3}
                  loading={loading}
                  totalPage={totalPage}
                  currentPage={currentPage}
                  changePage={pagination}
                />
              </TabPanel>
              <TabPanel value='3' >
                <InvestorScreen
                  data={data}
                  status={5}
                  filteredData={filteredData}
                  loading={loading}
                  totalPage={totalPage}
                  currentPage={currentPage}
                  changePage={pagination}
                />
              </TabPanel>
              <TabPanel value='4' >
                <InvestorScreen
                  data={data}
                  status={4}
                  filteredData={filteredData}
                  loading={loading}
                  totalPage={totalPage}
                  currentPage={currentPage}
                  changePage={pagination}
                />
              </TabPanel>
            </TabContext>
          </div>
        </div>
      </div>
    </>
  )
}