import React from "react";
import "./Modal.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import History from "../../utils/History";

function CreatedIdeaModal(props) {
  const notify = () =>
    toast.info("Idea Has been sent for screening", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  return (
    <>
      <div className='modalBackground'>
        <div className='modalContainer'>
          <div className='modal-title'>
            <span>Send idea for screening?</span>

            <CancelOutlinedIcon onClick={props.toggleModal} cursor='pointer' />
          </div>

          <div className='body'>
            <label htmlFor='idea'>
              Are you sure you want to send this idea for screening?
            </label>
          </div>
          <div className='footer'>
            <button className={"cancel-btn"} onClick={props.toggleModal}>
              Cancel
            </button>
            <button
              onClick={() => {
                props.handleSubmit();
                props.toggleModal();
                props.toggleSentBtn();
                History.push("/idea/dashboard/idea-details");
                notify();
              }}
              className={"success-btn"}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreatedIdeaModal;
