import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import "./IdeaFeed.css";

import feedImg from "../../../assets/img/service1.jpg";
import StarRating from "../../../components/star-rating/StarRating";
import {GetIdeaService, UpdateViewCountService,} from "../../../services/IdeaService";
import {useSelector} from "react-redux";

import {
  FavouriteIdeaService,
  FollowUserService,
  getImageUrl,
  LikeIdeaService,
} from "../../../services/BasicService";
import NFormatter from "../../../utils/NumberFormatter";
import {toast} from "react-toastify";
import {CircularProgress} from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import PublicIcon from "@mui/icons-material/Public";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FavoriteIcon from "@mui/icons-material/Favorite";
import AddIcon from "@mui/icons-material/Add";

function IdeaFeed() {
  const notify = (action) =>
    toast.info(
      action === "favourite"
        ? "Added to favourites"
        : "Removed from favourites",
      {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  const user = useSelector((state) => state.userAuth.isLoggedIn);
  const [loadingData, setLoadingData] = useState(true);
  const [ideas, setIdeas] = useState([]);

  const toggleFollow = async (followedUserId) => {
    let result = await FollowUserService(followedUserId);
    if (result && result.status === "success") {
      const filteredList = ideas.map((d) => {
        if (d.user_id===followedUserId) {
          d.followed = result.followed_user?true:false;
          if(!d.followed_user){
            const findFollowedIndex = d.followed_users.findIndex(followedUser=>+followedUser.user_id===+userData?.details?.user?.id);
            d.followed_users.splice(findFollowedIndex,1);
          }
        }
        return d;
      });
      setIdeas([...filteredList]);
    }
  }

  const toggleLike = async (idea_id, user_id, action) => {
    let result = await LikeIdeaService({idea_id: idea_id, user_id: user_id});
    if (result && result.status === "success") {
      const filteredList = ideas.map((d) => {
        if (d.id === idea_id) {
          return {
            ...d,
            likes:
                action === "like"
                  ? [...d.likes, {user_id}]
                  : d.likes.filter((l) => l.user_id !== user_id),
            total_like: result.likeCount,
          };
        }
        return d;
      });
      setIdeas(filteredList);
    } else {
      console.log("error has occurred category");
    }
  };

  const toggleFavourite = async (idea_id, user_id, action) => {
    let result = await FavouriteIdeaService({
      idea_id: idea_id,
      user_id: user_id,
    });
    if (result && result.status === "success") {
      const filteredList = ideas.map((d) => {
        if (d.id === idea_id) {
          return {
            ...d,
            favourites:
                action === "favourite"
                  ? [...d.favourites, {user_id}]
                  : d.favourites.filter((l) => l.user_id !== user_id),
          };
        }
        return d;
      });
      setIdeas(filteredList);
    } else {
      console.log("error has occurred category");
    }
  };

  const userData = useSelector((state) => state.userAuth);

  const [ideaHolder, setIdeaHolder] = useState(false);

  const updateViewCount = async (id) => {
    let result = await UpdateViewCountService({id: id});
    if (result && result.status === "success") {
      console.log("error has occurred category");
    } else {
      console.log("error has occurred category");
    }
  };

  useEffect(async () => {
    let result = await GetIdeaService();
    if (result && result.status === "success") {
      setIdeas(result.ideas);
      setLoadingData(false);
    } else {
      console.log("error has occurred");
    }
    let temp = userData?.isideaholder;
    setIdeaHolder(temp);
  }, [ideaHolder]);

  const [category, openCategory] = useState(false);
  const toggleCategory = () => {
    openCategory(!category);
  };


  return (
    <>
      {user && (
        <div className='feed-topbar-container'>
          <div className='feed-topbar'>
            <div className='add-new-idea'>
              <Link
                to={ideaHolder ? "/idea/dashboard/add-idea" : "/idea/idea-form"}
              >
                <button>Add New Idea</button>
              </Link>
            </div>
            <div className='idea-category-filter'>
              <FilterAltOutlinedIcon
                marginright='10px'
                onClick={toggleCategory}
              />
            </div>
          </div>
          {category && (
            <div className='idea_category'>
              <label htmlFor='tech'>Technology</label>
              <label htmlFor='marketing'>Marketing</label>
              <label htmlFor='education'>Education</label>
            </div>
          )}
        </div>
      )}

      {loadingData ? (
        <div className={"spinner"}>
          <CircularProgress color='primary'/>
        </div>
      ) : (
        <>
          {ideas.map((idea, index) => {
            return (
              <div key={index}>
                <div className='feed-wrapper'>
                  <div className='feed-head'>
                    <div className='feed-title'>
                      <span>{idea.title} </span>
                      <div className='rating'>
                        <StarRating size={10} rating={4}/>
                      </div>
                    </div>
                    <div className='feed-description'>{idea.description}</div>
                    <div className='idea_tags'>
                      {idea?.tags?.map((tag) => (
                        <Link to={"#"} key={tag.id}>
                          <label
                            htmlFor='tag'
                            style={{
                              fontSize: "12px",
                              color: "#2681e0",
                              cursor: "pointer",
                            }}
                          >
                                    #{tag.tags} &nbsp;
                          </label>
                        </Link>
                      ))}
                    </div>
                  </div>
                  <div className='feed-image-container'>
                    <img
                      src={
                        idea.cover_image
                          ? getImageUrl("idea", idea.cover_image)
                          : feedImg
                      }
                      width='100%'
                      className='feed-image'
                      alt='img'
                    />
                  </div>
                  <span>
                    <div className='idea-feed-block'>
                      <div className='feed__location'>
                        <PublicIcon/>
                        <span style={{fontSize: "12px"}}>
                          {idea.location}
                        </span>
                      </div>
                      <div className='idea_category'>
                        {idea.sector?.map((item, k) => {
                          return <span key={item.id + k}>{item.name}</span>;
                        })}
                      </div>
                    </div>

                    <div className='feed-body'>
                      <div className='idea-detail'>
                        <div className='feed-counter'>
                          <div className='like-counter'>
                            {idea.likes?.some(
                              (likers) =>
                                +likers.user_id === +userData?.details?.user?.id
                            ) ? (
                                <>
                                  <ThumbUpAltIcon
                                    style={{
                                      color: "#09f",
                                      marginright: "10px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      toggleLike(
                                        idea.id,
                                        userData.details?.user.id,
                                        "unlike"
                                      )
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  <ThumbUpOffAltIcon
                                    style={{
                                      marginRight: "10px",
                                      color: "#09f",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      toggleLike(
                                        idea.id,
                                        userData.details?.user.id,
                                        "like"
                                      )
                                    }
                                  />
                                </>
                              )}
                            <span>{idea?.likes?.length??0}</span>
                          </div>

                          <div className='views-counter'>
                            <VisibilityIcon
                              style={{
                                //   fontSize: "14px",
                                marginRight: "10px",
                              }}
                            />
                            <span>{NFormatter(idea.total_views, 2)}</span>
                          </div>
                          <div className='favourite-btn'>
                            {idea.favourites?.some(
                              (fav) =>
                                +fav.user_id === +userData?.details?.user?.id
                            ) ? (
                                <FavoriteIcon
                                  style={{
                                    color: "#ff5656",
                                    // fontSize: "16px",
                                  }}
                                  onClick={() => {
                                    toggleFavourite(
                                      idea.id,
                                      userData.details?.user.id,
                                      "unfavourite"
                                    );
                                    notify("unfavourite");
                                  }}
                                />
                              ) : (
                                <FavoriteBorderIcon
                                  style={{
                                    color: "#ff5656",
                                    // fontSize: "16px",
                                  }}
                                  onClick={() => {
                                    toggleFavourite(
                                      idea.id,
                                      userData.details?.user.id,
                                      "favourite"
                                    );
                                    notify("favourite");
                                  }}
                                />
                              )}
                            <span>{idea?.favourites?.length??0}</span>
                          </div>
                        </div>
                        <div className='detail-item'>
                          <b> Expected Investment:</b>&nbsp; Rs.
                          {idea.expected_invested}
                        </div>
                      </div>
                    </div>
                    <div className='feed-footer'>
                      <div className='feed-footer-btn'>
                        <div className='idea-footer-left'>
                          {user ? (
                            <Link to={"/idea/detail/" + idea.id}>
                              <div
                                role='presentation'
                                className='contact-btn'
                                onClick={() => updateViewCount(idea.id)}
                              >
                                <span>View Details</span>
                              </div>
                            </Link>
                          ) : (
                            <Link to={"/login"}>
                              <div className='contact-btn'>
                                <span>View Details</span>
                              </div>
                            </Link>
                          )}
                        </div>

                        <div className='idea-footer-right'>
                          {idea.followed_users.some(f => +f?.user_id === +userData?.details?.user?.id)||idea.followed? (
                            <div
                              role='presentation'
                              onClick={() => {
                                toggleFollow(idea.user_id, index)
                              }}
                              className='follow_investor outer idea'
                            >
                              <span className='f_i_2'>Followed</span>
                            </div>

                          ) : (
                            <div
                              role='presentation'
                              onClick={() => {
                                toggleFollow(idea.user_id, index)
                              }}
                              className='follow_investor outer idea'
                            >
                              <span className='f_i_1'>
                                <AddIcon/>
                              </span>
                              <span className='f_i_2'>Follow</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            );
          })}
        </>
      )}
    </>
  );
}

export default IdeaFeed;
