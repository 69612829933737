import React, {useState} from "react";
import ScreeningData from "./ScreeningData";
import AgreementsData from "./AgreementsData";
import MeetingsData from "./MeetingsData";
import ProposalsData from "./ProposalsData";
import AccessData from "./AccessData";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Tab } from "@mui/material";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
function Status() {
  const [search, setSearch] = useState(true);
  const showSearch = () => setSearch(!search);
  const [tabIndex, setTabIndex] = useState("1");
  const handTablChange = (event, index) => {
    setTabIndex(index);
  };
  return (
    <div className='container-fluid'>
      <div className='table-wrapper'>
        <div className='table-header'>
          <span>Status</span>
          {search ? (
            <SearchIcon onClick={showSearch} style={{marginRight: "20px", cursor:"pointer"}}/>
          ) : (
            <div className='search'>
              <input type='search' placeholder='Search'/>
              <CloseIcon style={{marginTop: "5px", position:"absolute", marginLeft:"190px", cursor:"pointer"}} onClick={showSearch}/>
            </div>
          )}
        </div>
        <TabContext value={tabIndex}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handTablChange}
              aria-label='lab API tabs example'
              variant="scrollable"
            >
              <Tab label='Screening Requests' value='1' />
              <Tab label='Access Requests' value='2' />
              <Tab label='Proposals' value='3' />
              <Tab label='Meetings' value='4' />
              <Tab label='Agreements' value='5' />
            </TabList>
          </Box>

          <TabPanel value="1">
            <ScreeningData/>
          </TabPanel>
          <TabPanel value="2">
            <AccessData/>
          </TabPanel>
          <TabPanel value="3">
            <ProposalsData/>
          </TabPanel>
          <TabPanel value="4">
            <MeetingsData/>
          </TabPanel>
          <TabPanel value="5">
            <AgreementsData/>
          </TabPanel>
        </TabContext>
      </div>
    </div>
  );
}

export default Status;
