import React from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import "react-toastify/dist/ReactToastify.css";

// import "./Modal.css";

function ModalIdea(props) {
  return (
    <>

      <div className='modalBackground'>
        <div
          style={{width: "800px", height: "90vh"}}
          className="modalContainer">
          <div className="modal-title">
            <span>Investor Details</span>
            <CloseOutlinedIcon onClick={props.toggleModal} cursor='pointer'/>
          </div>
          <div
            style={{overflow: "auto"}}
            className="body">
            {props.children}
          </div>
        </div>
      </div>
    </>
  );
}

export default ModalIdea;
