import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { CircularProgress } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import PaginationPage from "../../../../components/pagination/Pagination";
import { GetUsersIdea } from "../../../../services/IdeaHolderServices";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
class IdeaDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      ideas: [],
      currentPage:1,
      totalPage:0,
      pageSize:10,
      loading: true,
    };
    this.getItems(this.state.currentPage,this.state.pageSize);
  }

  toggleModal() {
    this.setState({
      showModal: !this.state.showModal,
    });
  }

  
  async getItems(page,pageSize){
    let result = await GetUsersIdea(page,pageSize);
    if (result && result.status === "success") {
      this.setState({
        ideas: result.ideas,
        loading: false,
        currentPage:page,
        pageSize:pageSize,
        totalPage:Math.ceil(result.totalItems/pageSize)
      });
    } else {
      console.log("error has occurred");
    }
  }

  async pagination(page) {
    this.getItems(page,this.state.pageSize);
  }
  render() {
    var ideas_HTMLTABLE = "";
    if (this.state.loading) {
      ideas_HTMLTABLE = (
        <tr>
          <td colSpan='7' style={{ textAlign: "center" }}>
            <CircularProgress />
          </td>
        </tr>
      );
    } else {
      ideas_HTMLTABLE = this.state.ideas.map((item, index) => {
        return (
          <tr key={item.id}>
            <td>{index + 1}</td>
            <td>{item.title}</td>
            <td>{item.total_views}</td>
            <td>{item.total_like}</td>
            <td>0</td>
            <td style={{ textAlign: "center" }}>
              {+item.status === 3 ? (
                <span className={"table-rejected-btn"}>
                  <CancelOutlinedIcon size={"22px"} />
                </span>
              ) : +item.status === 4 ? (
                <span className='table-accepted-btn'>
                  <CheckCircleOutlineOutlinedIcon size={"22px"} />
                </span>
              ) : +item.status === 5 ? (
                <span className='table-warning-btn'>
                  <WarningAmberOutlinedIcon size={"22px"} />
                </span>
              ) : (
                <span className={"table-ongoing-btn"}>
                  <HourglassEmptyOutlinedIcon size={"22px"} />
                </span>
              )}
            </td>
            <td style={{ textAlign: "center" }}>
              <Link to={"/idea/dashboard/idea-detail/" + item.id}>
                <RemoveRedEyeOutlinedIcon />
              </Link>
            </td>
            <td style={{ textAlign: "center" }}>
              <DeleteForeverOutlinedIcon color='red' />
            </td>
          </tr>
        );
      });
    }
    return (
      <>
        <div className='container-fluid'>
          <div className='table-wrapper table-wrap-final'>
            <div className='table-block'>
              <div className='table-header'>
                <span>Idea Details</span>
                <span>
                  <Link to={"/idea/dashboard/add-idea"}>
                    <button className='btn'>Add Ideas</button>
                  </Link>
                </span>
              </div>
              <div className='table-contents' style={{padding:"0 15px"}}>
                <table id='db-table'>
                  <thead>
                    <tr>
                      <th>SN</th>
                      <th>Idea Name</th>
                      <th>Views</th>
                      <th>Likes</th>
                      <th>Access</th>
                      <th style={{ width: "120px" }}>Status</th>
                      <th colSpan='2' style={{ textAlign: "center" }}>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!this.state.loading ? (
                      this.state.ideas.length === 0 ? (
                        <tr>
                          <td colSpan='6'>
                            <div className='no_data_found'>
                              <FileOpenOutlinedIcon size='50px' />
                              <h6>No data Available</h6>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        ideas_HTMLTABLE
                      )
                    ) : (
                      <tr>
                        <td colSpan='7' style={{ textAlign: "center" }}>
                          <CircularProgress />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='pagination'>
              <PaginationPage
                totalPage={this.state.totalPage}
                currentPage={this.state.currentPage}
                changePage={this.pagination.bind(this)}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default IdeaDetails;
