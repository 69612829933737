import React from "react";
import {Redirect, Route} from "react-router-dom";

export const Guard = ({component: Component, token: Token, routeRedirect, ...rest}) => (
  <Route {...rest} render={props => (
    localStorage.getItem(Token) ?
      <Component {...props}/> :
      <Redirect to={{pathname: routeRedirect, state: {from: props.location}}}/>
  )}/>
);

export const AdminGuard = ({component: Component, ...rest}) => (
  <Route {...rest} render={props => {
    const localdata = JSON.parse(localStorage.getItem("state"));
    return (
      localdata.userAuth.details.user.roles[0].slug === "super-admin" ?
        <Component {...props}/> :
        <Redirect to="/"/>

    )
  }}/>
);

export const InvestorGuard = ({component: Component, ...rest}) => (
  <Route {...rest} render={props => {
    const userAuthData = JSON.parse(localStorage.getItem("state"));
    // console.log
    return (
      userAuthData?.userAuth?.details?.user?.roles?.find(role=>role.slug=== "investor") ?
        <Component {...props}/> :
        <Redirect to="/investor/create-profile"/>

    )
  }}/>
);

export const IdeaHolderGuard = ({component: Component, ...rest}) => (
  <Route {...rest} render={props => {
    const userAuthData = JSON.parse(localStorage.getItem("state"));
    return (
      userAuthData?.userAuth?.details?.user?.roles?.find(role=>role.slug=== "idea-holder") ?
        <Component {...props}/> :
        <Redirect to="/idea/idea-form"/>

    )
  }}/>
);