import React, { useEffect, useState } from "react";
import PaginationPage from "../../../../components/pagination/Pagination";
import CategoryForm from "./CategoryForm";
import { GetCategoryService } from "../../../../services/AdminServices";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
function Category() {
  // eslint-disable-next-line no-unused-vars
  const [loadingData, setLoadingData] = useState(true);
  const [data, setData] = useState([]);
  const [isAdd, setIsAdd] = useState(true);
  const [currentPage, setCurrentPage] = React.useState(1);
  // eslint-disable-next-line no-unused-vars
  const [pageSize, setPageSize] = React.useState(10);
  const [totalPage, setTotalPage] = useState(0);
  useEffect(async () => {
    pagination(currentPage, pageSize);
  }, []);
  const pagination = async (page) => {
    let result = await GetCategoryService(page, pageSize);
    if (result && result.status === "success") {
      setData(result.sectors);
      setLoadingData(false);
      setCurrentPage(page);
      setTotalPage(Math.ceil(result.totalItems / pageSize));
    } else {
      console.log("error has occurred");
    }
  };
  const [toggleModal, setToggleModal] = useState(false);
  const toggle = () => {
    setToggleModal(!toggleModal);
  };

  const [category, setCategory] = useState({
    name: "",
    description: "",
    status: 0,
    error: [],
    editcategory: false,
    categoryIDForEdit: 0,
  });

  const makeDefault = () => {
    let data = {
      name: "",
      description: "",
      status: 0,
      error: [],
      editcategory: false,
      categoryIDForEdit: 0,
    };
    setCategory(data);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setCategory({ ...category, [name]: value });
    console.log(name, value);
  };
  const handleSave = () => {
    toggle();
    // const newCategory = { ...category };
    // setCategoryData([...categoryData, newCategory]);
    // console.log(categoryData);
  };

  const getUpdatedData = (data) => {
    setData(data);
  };
  const EditCategory = (id, name, description, status) => {
    console.log("editing");
    console.log(name);
    let data = {
      ...category,
      categoryIDForEdit: id,
      editcategory: true,
      name,
      description,
      status,
    };
    console.log(data);
    setCategory(data);
    toggle();
  };

  return (
    <>
      <div className='container-fluid'>
        <div className='table-wrapper'>
          <div className='table-block'>
            <div className='table-header'>
              <span>Category</span>
              <button
                style={{ width: "unset" }}
                className='ok-btn'
                onClick={() => {
                  setCategory({
                    name: "",
                    description: "",
                    status: 0,
                    error: [],
                    editcategory: false,
                    categoryIDForEdit: 0,
                  });
                  setIsAdd(true);
                  toggle();
                }}
              >
                Add Category
              </button>
            </div>
            {toggleModal && (
              <CategoryForm
                toggleModal={toggle}
                isAdd={isAdd}
                category={category}
                handleSave={handleSave}
                handleChange={handleChange}
                setCategory={setCategory}
                getUpdatedData={getUpdatedData}
                makeDefault={makeDefault}
              />
            )}

            <div className='table-contents'>
              <table id='db-table'>
                <thead>
                  <tr>
                    <th style={{ width: "10%" }}>SN</th>
                    <th>Category</th>
                    <th>Description</th>
                    <th>Status</th>
                    <th
                      colSpan='2'
                      style={{ textAlign: "center", width: "10%" }}
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((cat, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td style={{ textTransform: "capitalize" }}>
                          {cat.name}
                        </td>
                        <td style={{ textTransform: "capitalize" }}>
                          {cat.description}
                        </td>
                        <td style={{ textTransform: "capitalize" }}>
                          {cat.status === 1 ? "Active" : "Inactive"}
                        </td>
                        <td style={{ textAlign: "center", cursor: "pointer" }}>
                          <span
                            role='presentation'
                            onClick={() => {
                              setIsAdd(false);
                              EditCategory(
                                cat.id,
                                cat.name,
                                cat.description,
                                cat.status
                              );
                            }}
                          >
                            <RemoveRedEyeOutlinedIcon />
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className='pagination'>
            <PaginationPage
              totalPage={totalPage}
              pageSize={pageSize}
              currentPage={currentPage}
              changePage={pagination}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Category;
