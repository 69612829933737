import HttpService from "./HttpService";
import APIEndPoints from "../constants/APIEndPoints";
const http = new HttpService();
export const RegisterUserService = async (credentials) => {
  return await http.postData(credentials, APIEndPoints.register);
};
export const LoginUserService = async (credentials) => {
 
  const res = await http.postData(credentials, APIEndPoints.login);

  return res;
};
export const LogOutUserService = async () => {
 
  const tokenId = "user-token";
  return await http.getData(APIEndPoints.logout, tokenId);
};
export const SendPasswordService = async (credentials) => {
 
  return await http.postData(credentials, APIEndPoints.sendpassword);
};

export const FacebookLoginService = async (data)=>{
  return await http.postData(data,APIEndPoints.fbLogin);
}
export const GoogleLoginService = async (data)=>{
  return await http.postData(data,APIEndPoints.googleLogin);
}
