import React from "react";
import "./Modal.css";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Link, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {IdeaAccessRequestService} from "../../services/InvestorService";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
// import { IdeaAccessRequestService } from "../../services/investorServices";

function AccessRequestModal(props) {
  const notify = () =>
    toast.info("Access request sent.", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const select = useSelector((state) => state.userAuth.isinvestor);
  const userData = useSelector((state) => state.userAuth.details.user.id);
  const params = useParams();

  const accessRequested = async () => {
    let res = await IdeaAccessRequestService({
      idea_id: params.id,
      user_id: userData,
    });
    if (res?.status === "success") {
      props.accessReqSent(res.accessRequest);
      notify();
    }
  };

  return (
    <>
      <div className='modalBackground'>
        {select ? (
          <div className='modalContainer'>
            <div className='modal-title'>
              <span>Send Access Request</span>
              <CancelOutlinedIcon onClick={props.toggleModal} cursor='pointer'/>
            </div>

            <div className='body'>
              <label htmlFor="access">
                                Are you sure you want to send access request to the idea holder?
              </label>
              <div className='footer'>
                <button className={"cancel-btn"} onClick={props.toggleModal}>
                                    Cancel
                </button>
                <button
                  onClick={() => {
                    props.toggleModal();
                    accessRequested();
                  }}
                  className={"success-btn"}
                >
                                    Continue
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className='modalBackground'>
            <div className='modalContainer'>
              <div className='modal-title'>
                <span>Send Access Request</span>
                <CancelOutlinedIcon onClick={props.toggleModal} cursor='pointer'/>
              </div>
              {/*<div className='titleCloseBtn'>*/}
              {/*    <CancelOutlinedIcon onClick={props.toggleModal} cursor='pointer' />*/}
              {/*</div>*/}

              <div className='body'>
                <label htmlFor="profile">
                                    You need to create a Investor profile in order to send access
                                    request to the IdeaHolder.
                </label>
                <div className='footer'>
                  <Link to={"/investor/create-profile"}>
                    <button className={"ok-btn"}>
                                            Create Investor Profile
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AccessRequestModal;
