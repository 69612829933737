import HttpService from "./HttpService";
import APIEndPoints from "../constants/APIEndPoints";
const http = new HttpService();
export const GetUserService = async (page,pageSize) => {
  return await http.fetchData(`dashboard/${APIEndPoints.getUser}/${page}/${pageSize}`);
};
export const AddUserService = async (credentials) => {
  return await http.postData(credentials, "dashboard/" + APIEndPoints.addUser);
};

export const EdituserService = async (id) => {
  return await http.getData("dashboard/" + APIEndPoints.editUser + "/" + id);
};
export const UpdateUserService = async (credentials, id) => {
  return await http.postData(
    credentials,
    "dashboard/" + APIEndPoints.UpdateUser + "/" + id
  );
};
export const DeleteUserService = async (id) => {
  return await http.getData("dashboard/" + APIEndPoints.deleteUser + "/" + id);
};

export const GetCategoryService = async (page,pageSize) => {
  return await http.fetchData(`dashboard/${APIEndPoints.getCategory}/${page}/${pageSize}`);
};

export const AddCategoryService = async (credentials) => {
  return await http.postData(
    credentials,
    "dashboard/" + APIEndPoints.addCategory
  );
};
export const UpdateCategoryService = async (credentials) => {
  return await http.postData(
    credentials,
    "dashboard/" + APIEndPoints.updateCategory
  );
};

export const GetScreeningStatusService = async (page = 1, limit = 20) => {
  return await http.fetchData("dashboard/" + APIEndPoints.getScreeningStatus + "/" + page + "/" + limit);
};
export const GetProposalStatusService = async (page, limit) => {
  return await http.fetchData("dashboard/" + APIEndPoints.getProposalStatus + "/" + page + "/" + limit);
};
export const GetAccessStatusService = async () => {
  return await http.fetchData("dashboard/" + APIEndPoints.getAccessStatus);
};

export const GetScreeningIdeaService = async (page, limit) => {
  return await http.fetchData("dashboard/" + APIEndPoints.getScreeningIdea + "/" + page + "/" + limit);
};
export const ChangeStatusService = async (id) => {
  return await http.postData(id, "dashboard/" + APIEndPoints.poststatus);
};
export const GetSingleIdeaService = async (credentials) => {
  return await http.postData(
    credentials,
    "dashboard/" + APIEndPoints.getSingleIdea
  );
};

export const GetOngoingScreeningIdeaService = async (page, limit) => {
  return await http.fetchData(
    "dashboard/" + APIEndPoints.getOngoingScreeningIdea + "/" + page + "/" + limit
  );
};
export const GetRejectedScreeningIdeaService = async (page, limit) => {
  return await http.fetchData(
    "dashboard/" + APIEndPoints.getRejectedScreeningIdea + "/" + page + "/" + limit
  );
};
export const GetApprovedScreeningIdeaService = async (page, limit) => {
  return await http.fetchData(
    "dashboard/" + APIEndPoints.getApprovedScreeningIdea + "/" + page + "/" + limit
  );
};

export const ScreenIdeaStatus = async (credentials) => {
  return await http.postData(
    credentials,
    "dashboard/" + APIEndPoints.screenIdeaStatus
  );
};
export const RejectIdeaStatus = async (credentials) => {
  return await http.postData(
    credentials,
    "dashboard/" + APIEndPoints.rejectIdeaStatus
  );
};

export const ApproveIdeaStatus = async (credentials) => {
  return await http.postData(
    credentials,
    "dashboard/" + APIEndPoints.approveIdeaStatus
  );
};
export const DeleteIdea = async (credentials) => {
  return await http.postData(
    credentials,
    "dashboard/" + APIEndPoints.approveIdeaStatus
  );
};

export const AccessRequestDisplay = async (page, limit) => {
  return await http.getData("dashboard/" + APIEndPoints.accessDataTable + "/" + page + "/" + limit);
};
export const GetIdeaServices = async (id) => {
  return await http.getData("dashboard/" + APIEndPoints.idea + "/" + id);
};
export const GetDataAfterConnectService = async (id,page,pageSize) => {
  return await http.getData(
    `dashboard/${APIEndPoints.meetingschedulegetdata}/${id}/${page}/${pageSize}`
  );
};
export const SendScheduledDateService = async (credentials) => {
  return await http.postData(
    credentials,
    "dashboard/" + APIEndPoints.sendscheduleddate
  );
};
export const SendScheduledDescService = async (credentials) => {
  return await http.postData(
    credentials,
    "dashboard/" + APIEndPoints.sendscheduledesc
  );
};

export const GetInvestorService = async (statusCode, page, limit) => {
  return await http.postData(
    {id: statusCode === 1 ? [1, 2] : statusCode, page, limit},
    "dashboard/" + APIEndPoints.getInvestors
  );
};

export const GetSingleInvestorService = async (credentials) => {
  return await http.postData(
    credentials,
    "dashboard/" + APIEndPoints.getSingleInvestor
  );
};

export const InvestorScreenActionService = async (credentials) => {
  return await http.postData(
    credentials,
    "dashboard/" + APIEndPoints.investorScreenAction
  );
};

export const AgreementService = async (page,pageSize) => {
  return await http.getData(`dashboard/${APIEndPoints.agreementdata}/${page}/${pageSize}`);
};

export const DownloadFile = async (file) => {
  return await http.getData("download-files/scheduler/" + file);
};
